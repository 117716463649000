import "./HintsHidden.css";
import React, { useState, useEffect } from "react";
import pic1 from "../../assets/images/hidden-hints-img.png";
import pic2 from "../../assets/images/hidden-hints-img-mob-try.png";
import AddLead from "../add-lead/AddLead";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import Tooltip from "@mui/material/Tooltip";

function HintsHidden(props: any) {
  const [showAddLeadPopup, setShowAddLeadPopup] = useState(false);

  useEffect(() => {
    // dispatch(locationSlice.actions.setLocation({ path: "/developers" }));
  }, []);

  const closeLeadPopup = () => {
    setShowAddLeadPopup(false);
  };
  return (
    <div style={{ width: "100%" }}>
      {window.innerWidth >= 800 ? (
        <div
          className="hidden-out"
          style={{
            backgroundImage: `url(${pic1})`,
            backgroundPosition: "right",
          }}
        >
          <span className="heading-lora">What is lead and how to add it?</span>

    
         
            <div className="show-white-back">
              {props.hideHints && (
                <Tooltip title="Show" placement="bottom" arrow>
                  <WebAssetIcon
                    onClick={() => props.setHideHints(false)}
                    className="hidden-hints-icon"
                  ></WebAssetIcon>
                </Tooltip>
              )}
            </div>
        
          {/* <AddLead show={showAddLeadPopup} onClose={closeLeadPopup} /> */}
        </div>
      ) : (
        <div
          className="mob-hidden-out"
          style={{
            backgroundImage: `url(${pic2})`,
          }}
        >
          <span className="heading-lora">What is lead and how to add it?</span>
          <div className="show-white-back margin-t20">
            <Tooltip title="Show" placement="bottom" arrow>
              <WebAssetIcon
                onClick={() => props.setHideHints(false)}
                className="hidden-hints-icon"
              ></WebAssetIcon>
            </Tooltip>
          </div>

          <AddLead show={showAddLeadPopup} onClose={closeLeadPopup} />
        </div>
      )}
    </div>
  );
}
export default HintsHidden;
