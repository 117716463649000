import React, { useState, useEffect } from "react";
import Moment from "moment";
import "./LeadCard.css";

import WhatshotIcon from "@mui/icons-material/Whatshot";

import { fadeIn, fadeOut, pulse } from "react-animations";
import styled, { keyframes } from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";

const fadeInAnimation = keyframes`${fadeIn}`;
const pulseAnimation = keyframes`${pulse}`;
const FadeIn = styled.div`
  animation: 3s ${fadeInAnimation};
`;
const Pulse = styled.div`
  animation: 3s ${pulseAnimation};
`;

const LeadCard = (props: any) => {
  const auth = useSelector((state: RootState) => state.auth);
  const [numDevs, setNumDevs] = useState(0);
  const [readMore, setReadMore] = useState(false);

  useEffect(() => {
    if (props.lead) {
      setNumDevs(props.lead.devs.length);
    }
  }, [props]);

  return (
    <div>
      <div
        className={
          props.highlight
            ? !readMore
              ? "lead-card-box highlight-selected"
              : "opp-boxes-readmore  highlight-selected"
            : !readMore
              ? "lead-card-box "
              : "opp-boxes-readmore  "
        }
      >
       
          <span className="text-quicksand700 margin-b10">
            {props.lead.name}
          </span>

     
    

        <div className="lead-card-opp-box-row">
          <span className="opp-status">
            Status:{" "}
            {props.lead.status.replace(/_/g, " ").charAt(0).toUpperCase() +
              props.lead.status.replace(/_/g, " ").slice(1).toLowerCase()}
          </span>
          {window.innerWidth > 1000 && <div className="ball"></div>}
          <span className="opp-status">
            Field:{" "}
            {props.lead.industry.replace(/_/g, " ").charAt(0).toUpperCase() +
              props.lead.industry.replace(/_/g, " ").slice(1).toLowerCase()}
          </span>
          {window.innerWidth > 1000 && <div className="ball"></div>}
          <span className="opp-status">
            Client:{" "}
            {props.lead.customer_type.charAt(0).toUpperCase() +
              props.lead.customer_type.slice(1).toLowerCase()}
          </span>
          {window.innerWidth > 1000 && <div className="ball"></div>}
          <span className="opp-status">
            Est. time:{" "}
            {props.lead.duration
              .toLowerCase()
              .replace(/_/g, " ")
              .charAt(0)
              .toUpperCase() +
              props.lead.duration.toLowerCase().replace(/_/g, " ").slice(1) +
              " months"}
          </span>
        </div>
        <div className="padding-up-down">
          <span className="text-quicksand-small opp-grey">
            {!readMore
              ? [...props.lead.description].slice(0, 400)
              : props.lead.description}
          </span>
          {[...props.lead.description].length > 400 && (
            <span className="text-quicksand ">...</span>
          )}
          {[...props.lead.description].length > 400 && (
            <span
              className="text-quicksand-700 orange-text pointer margin-l10"
              onClick={() => setReadMore(!readMore)}
            >
              {readMore ? "Read Less" : "Read More"}
            </span>
          )}
        </div>

        <div className="lead-card-skills">
          {props.lead.devs.map((dev: any, i: number) => {
            return (
              <div key={"dev" + i.toString()} className="lead-card-skills-4mob">
                <div className="row-start-no-width">
                  {props.lead.devs.length > 1 && (

                    <span className="text-quicksand-small padding-t5 padding-b5 margin-right-small">
                      {" "}
                      {dev.developer_level}:{" "}
                    </span>
                  )}
                </div>
                <div className="lead-card-skills-row">
                  {dev.skills.map((skill: string, i: number) => {
                    return (
                      <span
                        key={skill + i.toString()}
                        className="lead-card-skills-buttons"
                      >
                        {skill}
                        {props.hotSkills?.map((hotSkill: any) => {
                          if (hotSkill.name === skill) {
                            return (
                              <WhatshotIcon
                                key={hotSkill.name}
                                style={{
                                  color: "#ff6210",
                                  marginBottom: 5,
                                }}
                              />
                            );
                          }
                        })}
                      </span>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>

        <div
          className={
            [...props.lead.description].length > 400
              ? "row-space-between-center full-percent-width wrap margin-b10n"
              : "row-space-between-center full-percent-width wrap "
          }
        >
          <div className="lead-card-last-row">
            <div>
              <span className="text-quicksand-small padding-r5">
                Starting date:{" "}
              </span>
              <span className="text-quicksand-small opp-lightgrey padding-r20">
                {" "}
                {Moment(props.lead.devs[0].start_date).format("D MMM yy")}{" "}
              </span>
            </div>
            <div>
              <span className="text-quicksand-small padding-r5">
                Developers needed:{" "}
              </span>

              {props.lead.devs.map((dev: any, i: number) => {
                return (
                  <span
                    key={"key" + i.toString()}
                    className="text-quicksand-small opp-lightgrey"
                  >
                    {dev.number_of_devs} {dev.developer_level} &nbsp;
                  </span>
                );
              })}
            </div>

            {auth.selectedView === "GYLD MANAGER" &&
              props.lead.lead_provider?.username && (
                <div className="text-quicksand-small padding-r5">
                  <span className="text-quicksand-small">Lead Provider: </span>

                  <span className="text-quicksand-small opp-lightgrey">
                    {props.lead.lead_provider.username}
                  </span>
                </div>
              )}
          </div>
          <div className="lead-box-icon-end">
            {auth.selectedView === "GYLD MANAGER" &&
              props.lead.status === "PENDING" && (
                <div className="footer">
                  <button
                    className="button-white-small text-quicksand700-small margin-r20"
                    onClick={() => props.acceptLead(props.lead.id)}
                  >
                    <span>Accept Lead</span>
                  </button>
                  <button
                    className="button-white-small text-quicksand700-small margin-r20"
                    onClick={() => props.rejectLead(props.lead.id)}
                  >
                    <span>Reject Lead</span>
                  </button>
                  <button
                    className="button-black-small-nm text-quicksand700-small"
                    onClick={() => props.editLead(props.lead)}
                  >
                    <span>Edit Lead</span>
                  </button>
                </div>
              )}
            {auth.selectedView === "GYLD MANAGER" &&
              props.lead.status === "ACCEPTED" && (
                <div className="">
                  <button
                    className="button-white-small text-quicksand700-small margin-r20"
                    onClick={() => props.editLead(props.lead)}
                  >
                    <span>Edit Lead</span>
                  </button>
                  {!props.lead.has_rfpp && (
                    <button
                      className="button-black-small-nm text-quicksand700-small"
                      onClick={() => props.addRequest(props.lead)}
                    >
                      <span>Add Request</span>
                    </button>
                  )}
                  {numDevs > props.lead.number_of_jobs &&
                    props.lead.has_rfpp && (
                      <button
                        className="button-black-small-nm text-quicksand700-small"
                        onClick={() => props.createJob(props.lead)}
                      >
                        <span>Create Job</span>
                      </button>
                    )}
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadCard;
