import "./RequestsHiddenHints.css";
import React, { useState, useEffect } from "react";
import pic1 from "../../assets/images/hidden-hints-img-turquoise.png";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import Tooltip from "@mui/material/Tooltip";

function RequestsHiddenHints(props: any) {
  return (
    <div
      className="hidden-out-opp"
      style={{
        backgroundImage: `url(${pic1})`,
        backgroundPosition: "right",
      }}
    >
      {/* <span className="heading-lora">Hints</span> */}
      <div className="row-start">
        <img className="bulb" src={require("../../assets/images/bulb.png")} />
        <b className="heading-lora">Hints</b>
      </div>
      <div className="show-white-back">
        <Tooltip title="Show" placement="bottom" arrow>
          <WebAssetIcon
            onClick={() => props.setHideHints(false)}
            className="hidden-hints-icon"
          ></WebAssetIcon>
        </Tooltip>
      </div>
    </div>
  );
}
export default RequestsHiddenHints;
