import { useEffect, useState } from "react";
import "./AddUsersRfpp.css";
import PropTypes from "prop-types";
import axiosService from "../../utils/axios";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Input, TextareaAutosize, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAlert } from "react-alert";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ClientUserProfile from "../../screens/client-user-profile/ClientUserProfile";
import Modal from "@mui/material/Modal";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddUsersRfpp = (props: any) => {
  const alert = useAlert();
  const [show, setShow] = useState(false);
  const [clientUsers, setClientUsers] = useState<any[]>([]);

  const [showProfile, setShowProfile] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>();
  const [isFirst, setIsFirst] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
  const auth = useSelector((state: RootState) => state.auth);

  const closeHandler = (e: any) => {
    setShow(false);
    props.onClose(false);
  };

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        closeHandler(true);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);
  useEffect(() => {
    if (isFirst) {
      getClientUsers();
      setIsFirst(false);
    }
    if (props.request && props.request.allowed_client_users.length > 0) {
      props.request.allowed_client_users.forEach((user: any) => {
        handleChangeProposedDevs(user.username);
      });
    }
  }, [props]);

  const getClientUsers = () => {
    axiosService
      .get(
        `${process.env.REACT_APP_API_URL}/get_client_users/` +
          props.request.client.id
      )
      .then((res) => {
        setClientUsers(res.data);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const addUsers = () => {
    let tmp_allowed_users: number[] = [];
    clientUsers.forEach((user: any) => {
      if (selectedUsers.includes(user.username)) {
        tmp_allowed_users.push(user.id);
      }
    });
    let body = {
      allowed_client_users: tmp_allowed_users,
      rfpp_id: props.request.id,
    };
    axiosService
      .post(`${process.env.REACT_APP_API_URL}/add_users_to_rfpp`, body)
      .then((res) => {
        props.onClose(true);
        alert.success("The allowed users are changed");
      })

      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }
        console.log(err);
      });
  };

  const showPublicProfile = (user: any) => {
    setShowProfile(true);
    setSelectedUser(user.id);
  };
  const closeProfile = () => {
    setShowProfile(false);
    setSelectedUser(null);
  };
  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const handleChangeProposedDevs = (username: string) => {
    if (selectedUsers.indexOf(username) > -1) {
      setSelectedUsers(
        selectedUsers.filter((user: any) => {
          return user !== username;
        })
      );
    } else {
      setSelectedUsers([...selectedUsers, username]);
    }
  };

  return (
    // <div
    //   style={{
    //     visibility: show ? "visible" : "hidden",
    //     opacity: show ? "1" : "0",
    //   }}
    //   className={"overlay"}
    // >
    <Modal
      open={show}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {!showProfile ? (
        <div className={"popup-userRfpp "}>
          <div className="row-space-between-center padding-10-20">
            <span className="heading-lora padding-up-down">
              Add Users to the Request
            </span>
            <span className={"close"} onClick={closeHandler}>
              &times;
            </span>
          </div>

     
            <div className="userRfpp-scroll">
              {clientUsers.map((user: any) => (
                <div className="userRfpp-row-with-line">
                  <div className="row-start-center">
                    <Checkbox
                      onChange={() => handleChangeProposedDevs(user.username)}
                      checked={selectedUsers.indexOf(user.username) > -1}
                    />
                    {user.image && user.image.includes("media") ? (
                      <img
                        src={
                          process.env.REACT_APP_API_URL?.split("/api")[0] +
                          user.image
                        }
                        className="userRfpp-profile-img"
                      />
                    ) : (
                      <div className="profile-img no-pic">
                        <span>{user.username.charAt(0)}</span>
                      </div>
                    )}
                    <div className="userRfpp-names">
                      <span className="text-quicksand700 darkgrey">
                        {user.username}
                      </span>
                      {/* <span
                        style={{ color: "#757575" }}
                        className="text-quicksand-small"
                      >
                        Real Name
                      </span> */}
                    </div>
                  </div>

                  <ChevronRightIcon
                    className="pointer chevron-color"
                    onClick={() => showPublicProfile(user)}
                  />
                </div>
              ))}
            </div>
            <div className="add-phase-row-flex-end padding-around20">
              <button
                className="button-white-small margin-r20 text-quicksand700-small"
                onClick={() => props.onClose(true)}
              >
                {" "}
                Cancel
              </button>
              <button
                className="button-black-small-nm text-quicksand700-small"
                style={{ color: "white" }}
                onClick={addUsers}
              >
                {" "}
                Change Users
              </button>
            </div>

        </div>
      ) : (
        <div className={"select-candi-to-pub-prof"}>
          <div className="row-space-between-center padding-pub-prof-top">
          <ChevronLeftIcon
            onClick={closeProfile}
            className="pointer chevron-color"
          />
          <span className={"close"} onClick={closeHandler}>
              &times;
            </span>
            </div>
          <ClientUserProfile user={selectedUser} />
        </div>
      )}
    </Modal>
  );
};

AddUsersRfpp.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  request: PropTypes.object.isRequired,
};

export default AddUsersRfpp;
