import { useEffect, useState } from "react";
import "./EditRequestSignUp.css";
import PropTypes from "prop-types";
import axiosService from "../../utils/axios";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Button, Input, TextareaAutosize, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import DeleteIcon from "@mui/icons-material/Delete";

import { useAlert } from "react-alert";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const EditRequestSignUp = (props: any) => {
  const [show, setShow] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [notes, setNotes] = useState("");
  const [role, setRole] = useState("");
  const [replied, setReplied] = useState(false);
  const [signedUp, setSignedUp] = useState(true);

  const alert = useAlert();

  const closeHandler = (e: any) => {
    setShow(false);
    props.onClose(false);
  };

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        closeHandler(true);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  useEffect(() => {
    if (props.request) {
      setFirstName(props.request.first_name);
      setLastName(props.request.last_name);
      setRole(props.request.role);
      setMessage(props.request.message);
      setNotes(props.request.admin_notes);
      setEmail(props.request.email);
      setReplied(props.request.replied);
      setSignedUp(props.request.signed_up);
    }
  }, []);

  const editRequest = () => {
    let body = {
      id: props.request.id,
      first_name: firstName,
      last_name: lastName,
      email: email,
      replied: replied,
      signed_up: signedUp,
      admin_notes: notes,
      message: message,
      role: role,
    };
    axiosService
      .post(`${process.env.REACT_APP_API_URL}/edit_request_sign_up`, body)
      .then((res) => {
        alert.success("The Request was updated");
        closeHandler(true);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const reset = () => {
    setFirstName(props.request.first_name);
    setLastName(props.request.last_name);
    setRole(props.request.role);
    setMessage(props.request.message);
    setNotes(props.request.admin_notes);
    setEmail(props.request.email);
    setReplied(props.request.replied);
    setSignedUp(props.request.signed_up);
  };

  return (
    <div
      style={{
        visibility: show ? "visible" : "hidden",
        opacity: show ? "1" : "0",
      }}
      className={"overlay"}
    >
      <div className={"popup-edit-request"}>
        <div className="row-space-between-center">
          <div className="row-start-no-width">
            <span className="heading-lora padding-up-down margin-right">
              Edit a Request for Sign Up
            </span>
            <button className="reset-button" onClick={reset}>
              Reload
            </button>
          </div>
          <span className={"close"} onClick={closeHandler}>
            &times;
          </span>
        </div>

        <div className="column-space-between">
          <div className="column-start-start">
            <div className="row-space-between padding-up-down">
              <Box className="edit-request-width">
                <FormControl fullWidth>
                  <InputLabel shrink={true} id="demo-simple-select-label">
                    Select the Role
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    value={role}
                    onChange={(event) => setRole(event?.target.value)}
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label="Select the Developer"
                        notched
                      />
                    }
                    MenuProps={MenuProps}
                  >
                    <MenuItem style={{ width: 600 }} value={"DEVELOPER"}>
                      <ListItemText primary={"Developer"} />
                    </MenuItem>
                    <MenuItem style={{ width: 600 }} value={"CLIENT"}>
                      <ListItemText primary={"Client"} />
                    </MenuItem>
                    <MenuItem style={{ width: 600 }} value={"LEAD_PROVIDER"}>
                      <ListItemText primary={"Lead Provider"} />
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box className="edit-request-width ">
                <FormControl fullWidth>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label=" First Name"
                    id="outlined-basic"
                    type="text"
                    value={firstName}
                    onChange={(event) => setFirstName(event.target.value)}
                  />
                </FormControl>
              </Box>
            </div>
            <div className="row-space-between padding-up-down">
              <Box className="edit-request-width ">
                <FormControl fullWidth>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label=" Last Name"
                    id="outlined-basic"
                    type="text"
                    value={lastName}
                    onChange={(event) => setLastName(event.target.value)}
                  />
                </FormControl>
              </Box>
              <Box className="edit-request-width">
                <FormControl fullWidth>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label=" Email"
                    id="outlined-basic"
                    type="text"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </FormControl>
              </Box>
            </div>

            <div className="row-space-between padding-up-down ">
              <Box className="padding-up-down margin-b20 full-percent-width">
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    multiline
                    minRows={3}
                    maxRows={5}
                    InputLabelProps={{ shrink: true }}
                    label="Write your message here"
                    value={message}
                    onChange={(event) => setMessage(event.target.value)}
                  />
                </FormControl>
              </Box>
            </div>

            <div className="row-space-between padding-up-down ">
              <Box className="padding-up-down margin-b20 full-percent-width">
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    multiline
                    minRows={3}
                    maxRows={5}
                    InputLabelProps={{ shrink: true }}
                    label="Write your notes here"
                    value={notes}
                    onChange={(event) => setNotes(event.target.value)}
                  />
                </FormControl>
              </Box>
            </div>
            <div className="row-center-start">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={replied}
                    onChange={() => setReplied(!replied)}
                    className="darkgrey"
                  />
                }
                label="Have we reached out?"
                className="darkgrey"
              />{" "}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={signedUp}
                    onChange={() => setSignedUp(!signedUp)}
                    className="darkgrey"
                  />
                }
                label="Did they sign up"
                className="darkgrey"
              />
            </div>

            <div className="row-space-between-center margin-t20">
              <button
                className="button-white-small margin-r20 text-quicksand700-small"
                onClick={() => props.onClose(true)}
              >
                {" "}
                Cancel
              </button>

              <button
                className="button-black-small-nm text-quicksand700-small"
                onClick={editRequest}
              >
                {" "}
                Save Request
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

EditRequestSignUp.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  request: PropTypes.object.isRequired,
};

export default EditRequestSignUp;
