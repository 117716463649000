import React, { useState, useEffect } from "react";
import "./ContactPage.css";
import Box from "@mui/material/Box";
import {
  Button,
  Input,
  InputAdornment,
  OutlinedInput,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { useAlert } from "react-alert";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import axiosService from "../../utils/axios";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import SendIcon from "@mui/icons-material/Send";
import CachedIcon from "@mui/icons-material/Cached";
// @ts-ignore
import {
  loadCaptchaEnginge,
  LoadCanvasTemplateNoReload,
  validateCaptcha, // @ts-ignore
} from "react-simple-captcha";
import { useNavigate } from "react-router";

// function ContactPage(props: any) {
const ContactPage = () => {
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  const [Message, setMessage] = useState("");
  const [captchaCorrect, setCaptchaCorrect] = useState(false);
  const [captchaValue, setCaptchaValue] = useState("");
  const [role, setRole] = useState("DEVELOPER");
  const alert = useAlert();
  const navigate = useNavigate();

  const sendMessage = () => {
    let body = {
      first_name: FirstName,
      last_name: LastName,
      message: Message,
      email: Email,
      role: role,
    };
    axiosService
      .post(`${process.env.REACT_APP_API_URL}/add_request_sign_up`, body)
      .then((res) => {
        alert.success(
          " We received your message and we will contact you soon."
        );
        navigate("/");
      })

      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }
      });
  };

  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);
  const handleCaptchaSubmit = (value: string) => {
    if (value.length === 6) {
      if (validateCaptcha(value, false) == true) {
        setCaptchaCorrect(true);
        alert.success("Captcha Matched");
      } else {
        setCaptchaCorrect(false);
        loadCaptchaEnginge(6);
        alert.error("Captcha Does Not Match");
      }
    }
  };

  return (
    <div className="container">
     
      <div className="contact-out">
        <span className="heading-lora padding-up-down">
          You are contacting us as?
        </span>
        <div className="padding-up-down">
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              value={role}
              name="radio-buttons-group"
              onChange={(event) => setRole(event.target.value)}
            >
              <FormControlLabel
                value="DEVELOPER"
                control={<Radio color="default" />}
                label="Developer"
              />
              <FormControlLabel
                value="CLIENT"
                control={<Radio color="default" />}
                label="Client"
              />
              <FormControlLabel
                value="LEAD_PROVIDER"
                control={<Radio color="default" />}
                label="Lead Provider"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className="contact-row-space-between">
          <Box className="contact-input-width padding-up-down">
            <FormControl fullWidth>
              <TextField
                InputLabelProps={{ shrink: true }}
                label="First name"
                id="outlined-basic-first"
                type="text"
                value={FirstName}
                onChange={(event) => setFirstName(event.target.value)}
              />
            </FormControl>
          </Box>
          <Box className="contact-input-width padding-up-down">
            <FormControl fullWidth>
              <TextField
                InputLabelProps={{ shrink: true }}
                label="Last name"
                id="outlined-basic-last"
                type="text"
                value={LastName}
                onChange={(event) => setLastName(event.target.value)}
              />
            </FormControl>
          </Box>

          <Box className="contact-input-width padding-up-down">
            <FormControl fullWidth>
              <TextField
                InputLabelProps={{ shrink: true }}
                label="E-mail"
                id="outlined-basic-email"
                type="text"
                value={Email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </FormControl>
          </Box>
        </div>
        <span className="text-quicksand padding-up-down">Your message</span>
        <Box className="padding-up-down margin-b20 full-percent-width">
          <FormControl fullWidth>
            <TextField
              id="outlined-basic-message"
              type="text"
              multiline
              minRows={3}
              maxRows={5}
              InputLabelProps={{ shrink: true }}
              label="Message"
              value={Message}
              onChange={(event) => setMessage(event.target.value)}
            />
          </FormControl>
        </Box>

        <div className="contact-captcha-row-space-between">
          <div className="contact-captcha-column">
            <Box className="padding-up-down contact-captcha-width">
              <LoadCanvasTemplateNoReload />
            </Box>
            <div className="row-start-center">
              <Box className="padding-up-down contact-captcha-width ">
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic-captcha"
                    InputLabelProps={{ shrink: true }}
                    label="Enter Captcha Value "
                    onChange={(event) =>
                      handleCaptchaSubmit(event.target.value)
                    }
                  />
                </FormControl>
              </Box>
              {/* <SendIcon
                className="header-icon-color margin-l10"
                onClick={() => loadCaptchaEnginge(6)}
              /> */}
              <Tooltip title="New Captcha" placement="bottom" arrow>
                <CachedIcon
                  className="header-icon-color margin-l10"
                  onClick={() => loadCaptchaEnginge(6)}
                />
              </Tooltip>
            </div>
          </div>
          <button
            className="button-black-small-nm text-quicksand700-small contact-send-button"
            onClick={sendMessage}
            disabled={Email == "" || !captchaCorrect}
            style={{ opacity: Email == "" || !captchaCorrect ? 0.4 : 1 }}
          >
            {" "}
            Send Message
          </button>
        </div>
        {/* <div className="sent-successful">
                    <CheckCircleOutlineIcon className="contact-check-green"/>
                    <span className="text-quicksand-small white">Message sent successfully</span>
                    </div> */}
      </div>

    </div>
  );
};

export default ContactPage;
