import React, { useState, useEffect } from "react";

import locationSlice from "../../store/slices/location";
import "./Requests.css";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";

import RequestsAdmin from "./RequestsAdmin";
import RequestsUser from "./RequestsUser";

const Requests = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    dispatch(
      locationSlice.actions.setLocation({
        path: "/platform/requests",
      })
    );
    if (!auth.account) {
      navigate("/login");
    }
    if (
      auth.selectedView !== "GYLD MANAGER" &&
      auth.selectedView !== "CLIENT"
    ) {
      navigate("/platform");
    }
  }, []);

  return (
    <div className="platform container">
      {auth.selectedView === "GYLD MANAGER" ? (
        <RequestsAdmin />
      ) : (
        <RequestsUser />
      )}
    </div>
  );
};

export default Requests;
