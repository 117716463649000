import React, { useState, useEffect } from "react";

import locationSlice from "../../store/slices/location";
import "./Projects.css";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";

import ProjectsAdmin from "./ProjectsAdmin";
import ProjectsUser from "./ProjectsUser";

function Projects() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    dispatch(
      locationSlice.actions.setLocation({
        path: "/platform/projects",
      })
    );
    if (!auth.account) {
      navigate("/login");
    }
  }, []);

  return (
    <div className="platform container">
      {auth.selectedView === "GYLD MANAGER" ? (
        <ProjectsAdmin />
      ) : (
        <ProjectsUser />
      )}
    </div>
  );
}

export default Projects;
