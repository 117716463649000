import React, { useState, useEffect } from "react";
import axiosService from "../../utils/axios";

import locationSlice from "../../store/slices/location";
import "./LeadInvoices.css";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";

import DoDisturbIcon from "@mui/icons-material/DoDisturb";

import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import LeadCard from "../../components/lead-card/LeadCard";
import OpportunityCard from "../../components/opportunity-card/OpportunityCard";
import JobCardClient from "../../components/job-card/JobCardClient";
import SelectCandidates from "../../components/select-candidates/SelectCandidates";
import JobsHiddenHints from "../../components/jobs-hidden-hints/JobsHiddenHints";
import { OutlinedInput } from "@mui/material";
import MapsUgcIcon from "@mui/icons-material/MapsUgc";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Tooltip from "@mui/material/Tooltip";
import { useAlert } from "react-alert";
import StartIcon from "@mui/icons-material/Start";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import AddInvoice from "../add-invoice/AddInvoice";
import InvoiceCard from "../invoice-card/InvoiceCard";
import ClearAllIcon from "@mui/icons-material/ClearAll";

const LeadInvoices = () => {
  const [chargesLeadRebates, setChargesLeadRebates] = useState<any[]>([]);
  const [showAddInvoicePopup, setShowAddInvoicePopup] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState<Array<any>>([]);
  const [filteredRfppJobs, setFilteredRfppJobs] = useState<Array<any>>([]);
  const [newInvoicesClicked, setNewInvoicesClicked] = useState(false);
  const [cancelledInvoicesClicked, setCancelledInvoicesClicked] =
    useState(false);
  const [paidInvoicesClicked, setPaidInvoicesClicked] = useState(false);
  const [numNewInvoices, setNumNewInvoices] = useState(0);
  const [numCancelledInvoices, setNumCancelledInvoices] = useState(0);
  const [numPaidInvoices, setNumPaidInvoices] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);
  const [totalUninvoiced, setTotalUninvoiced] = useState(0);

  const [totalOpen, setTotalOpen] = useState(0);
  const [numRejected, setNumRejected] = useState(0);
  const [numPending, setNumPending] = useState(0);
  const [numAccepted, setNumAccepted] = useState(0);
  const [numStarted, setNumStarted] = useState(0);
  const [numFinished, setNumFinished] = useState(0);
  const [invoices, setInvoices] = useState<Array<any>>([]);
  const [showSelectCandidatesPopup, setShowSelectCandidatesPopup] =
    useState(false);
  const [selectedJob, setSelectedJob] = useState<any>({});
  const [mobileFilter, setMobileFilter] = useState("");

  const [isFirst, setIsFirst] = useState(true);
  const [hideHints, setHideHints] = useState(false);
  const [hotSkills, setHotSkills] = useState<any[]>([]);

  const [jobsAccepted, setJobsAccepted] = useState<Array<any>>([]);
  const [leads, setLeads] = useState<Array<any>>([]);
  const [selectedLead, setSelectedLead] = useState<any>({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth);
  const alert = useAlert();
  useEffect(() => {
    if (isFirst) {
      getLeads();
      getSkills();
      //   getInvoices();
      setIsFirst(false);
    }
    dispatch(
      locationSlice.actions.setLocation({
        path: "/platform/jobs",
      })
    );
    if (!auth.account) {
      navigate("/login");
    }
  }, []);
  useEffect(() => {
    if (jobs.length > 0 && selectedLead.id) {
      handleLeadSelect(selectedLead.id);
    }
  }, [jobs]);
  useEffect(() => {
    if (jobs.length > 0 && selectedLead.id) {
      handleLeadSelect(selectedLead.id);
    }
  }, [selectedLead]);

  const handleCloseAddInvoice = () => {
    setShowAddInvoicePopup(false);

    getLeads();
  };
  const getSkills = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_skills`)
      .then((res) => {
        let tmpHotSkills: any[] = [];
        res.data.forEach((data: any) => {
          if (data.is_hot_skill) {
            tmpHotSkills.push(data);
          }
        });

        setHotSkills(tmpHotSkills);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCharges = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_charges_lead_rebates_user`)
      .then((res) => {
        let totalPaidTmp = 0;
        let totalOpenTmp = 0;
        let totalUninvoicedTmp = 0;
        res.data.map((charge: any) => {
          if (charge.status === "NEW" && !charge.invoice) {
            totalUninvoicedTmp += charge.net_amount;
          } else if (charge.status === "PAID") {
            totalPaidTmp += charge.net_amount;
          } else if (charge.status === "OPEN") {
            totalOpenTmp += charge.net_amount;
          }
        });
        setChargesLeadRebates(res.data);
        setTotalOpen(totalOpenTmp);
        setTotalPaid(totalPaidTmp);
        setTotalUninvoiced(totalUninvoicedTmp);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  const getJobs = async () => {
    axiosService
      .get(
        `${process.env.REACT_APP_API_URL}/get_user_jobs_client/` + auth.account
      )
      .then((res) => {
        setJobs(res.data);
        if (filteredJobs.length > 0) {
          let tmpFiltered: any[] = [];
          filteredJobs.forEach((filter: any) => {
            res.data.forEach((lead: any) => {
              if (
                lead.status === filter.status &&
                !tmpFiltered.includes(lead)
              ) {
                tmpFiltered.push(lead);
              }
            });
          });
          setFilteredJobs(tmpFiltered);
        }
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  const handleChangeMobileFilter = (value: string) => {
    setMobileFilter(value);
    if (value == "NEW") {
      handleNewClickMobile();
    } else if (value == "PAID") {
      handlePaidClickMobile();
    } else if (value == "CANCELLED") {
      handleCancelledClickMobile();
    } else {
      setInvoices([selectedLead.invoices]);
    }
  };
  const handleNewClickMobile = () => {
    setNewInvoicesClicked(true);
    setCancelledInvoicesClicked(false);
    setPaidInvoicesClicked(false);

    let tmp: any[] = [];
    selectedLead.invoices.forEach((invoice: any) => {
      if (invoice.status === "NEW") {
        tmp.push(invoice);
      }
    });
    setInvoices(tmp);
  };
  const handleCancelledClickMobile = () => {
    setNewInvoicesClicked(false);
    setCancelledInvoicesClicked(true);
    setPaidInvoicesClicked(false);

    let tmp: any[] = [];
    selectedLead.invoices.forEach((invoice: any) => {
      if (invoice.status === "CANCELLED") {
        tmp.push(invoice);
      }
    });
    setInvoices(tmp);
  };
  const handlePaidClickMobile = () => {
    setNewInvoicesClicked(false);
    setCancelledInvoicesClicked(true);
    setPaidInvoicesClicked(false);

    let tmp: any[] = [];
    selectedLead.invoices.forEach((invoice: any) => {
      if (invoice.status === "PAID") {
        tmp.push(invoice);
      }
    });
    setInvoices(tmp);
  };

  const handleNewInvoicesClick = () => {
    setNewInvoicesClicked(!newInvoicesClicked);
    if (!newInvoicesClicked) {
      let tmp = invoices;
      selectedLead.invoices.forEach((invoice: any) => {
        if (invoice.status === "NEW") {
          tmp.push(invoice);
        }
      });
      setInvoices(tmp);
    } else {
      let tmp: any[] = [];
      invoices.forEach((invoice: any) => {
        if (invoice.status !== "NEW") {
          tmp.push(invoice);
        }
      });
      setInvoices(tmp);
    }
  };
  const handleCancelledInvoicesClick = () => {
    setCancelledInvoicesClicked(!cancelledInvoicesClicked);
    if (!cancelledInvoicesClicked) {
      let tmp = invoices;
      selectedLead.invoices.forEach((invoice: any) => {
        if (invoice.status === "CANCELLED") {
          tmp.push(invoice);
        }
      });
      setInvoices(tmp);
    } else {
      let tmp: any[] = [];
      invoices.forEach((invoice: any) => {
        if (invoice.status !== "CANCELLED") {
          tmp.push(invoice);
        }
      });
      setInvoices(tmp);
    }
  };
  const handlePaidInvoicesClick = () => {
    setPaidInvoicesClicked(!paidInvoicesClicked);
    if (!paidInvoicesClicked) {
      let tmp = invoices;
      selectedLead.invoices.forEach((invoice: any) => {
        if (invoice.status === "PAID") {
          tmp.push(invoice);
        }
      });
      setInvoices(tmp);
    } else {
      let tmp: any[] = [];
      invoices.forEach((invoice: any) => {
        if (invoice.status !== "PAID") {
          tmp.push(invoice);
        }
      });
      setInvoices(tmp);
    }
  };

  const getLeads = () => {
    axiosService
      .get(
        `${process.env.REACT_APP_API_URL}/get_user_leads_started/` +
          auth.account
      )
      .then((res) => {
        setLeads(res.data);
        let tmp1 = 0;
        let tmp2 = 0;
        let tmp3 = 0;

        if (res.data.length > 0) {
          setSelectedLead(res.data[0]);

          res.data[0].invoices.forEach((invoice: any) => {
            if (invoice.status === "PAID") {
              tmp1++;
            }
            if (invoice.status === "NEW") {
              tmp2++;
            }
            if (invoice.status === "CANCELLED") {
              tmp3++;
            }
          });
        }

        setNumPaidInvoices(tmp1);
        setNumCancelledInvoices(tmp3);
        setNumNewInvoices(tmp2);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
    getCharges();
  };

  const handleLeadSelect = (value: any) => {
    let tmpPending = 0;
    let tmpAccepted = 0;
    let tmpRejected = 0;
    let tmpStarted = 0;
    let tmpFinished = 0;
    let tmpJobsAccepted: Array<any> = [];
    let tmpJobsFiltered: Array<any> = [];
    console.log("HERE", jobs, leads);

    if (jobs.length > 0) {
      jobs.forEach((job: any) => {
        if (job.rfpp === value) {
          tmpJobsFiltered.push(job);
        }
        console.log(
          job.rfpp === value,
          job.rfpp === value && job.status === "ACCEPTED",
          "ACCEPTED"
        );
        if (job.rfpp === value && job.status === "ACCEPTED") {
          tmpAccepted++;

          tmpJobsAccepted.push(job);
        } else if (job.rfpp === value && job.status === "PENDING") {
          tmpPending++;
        } else if (job.rfpp === value && job.status === "REJECTED") {
          tmpRejected++;
        } else if (job.rfpp === value && job.status === "STARTED") {
          tmpStarted++;
        } else if (job.rfpp === value && job.status === "FINISHED") {
          tmpFinished++;
        }
      });
    }
    console.log(tmpJobsFiltered);
    setNumAccepted(tmpAccepted);
    setNumPending(tmpPending);
    setNumRejected(tmpRejected);
    setNumStarted(tmpStarted);
    setNumFinished(tmpFinished);
    setJobsAccepted(tmpJobsAccepted);
    setFilteredRfppJobs(tmpJobsFiltered);
  };
  const selectCandidates = (job: any) => {
    setSelectedJob(job);
    setShowSelectCandidatesPopup(true);
  };

  return (
    <div className="column-space-between  card-lead-invoices full-percent-width">
      <div className="leads-invoices-card">
        <div className="jobs-row-space-between">
          <span className="text-quicksand700 ">My Started Leads</span>
          <span className="text-quicksand700-small darkgrey">
            Charges Paid: {totalPaid} €
          </span>
          <span className="text-quicksand700-small darkgrey">
            Charges Open: {totalOpen} €
          </span>
          <span className="text-quicksand700-small darkgrey">
            Charges to be invoiced: {totalUninvoiced} €
          </span>
          <span
            className="text-quicksand-700 orange-text pointer max-content-width align-self-center"
            onClick={() => setShowAddInvoicePopup(true)}
          >
            Add Invoice
          </span>
        </div>
        {showAddInvoicePopup && (
          <AddInvoice
            show={showAddInvoicePopup}
            onClose={handleCloseAddInvoice}
            lead={true}
          />
        )}
        <div className="separate-area margin-b20">
          {leads.length === 0 && (
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel shrink={true} id="demo-simple-select-label">
                  Select Lead
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={"You don't have any request yet"}
                  label="Selectedrequest"
                  input={
                    <OutlinedInput
                      id="select-multiple-chip"
                      label="Select Lead"
                      notched
                    />
                  }
                >
                  <MenuItem value={"You don't have any request yet"}>
                    You don't have any leads yet
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          )}

          {leads.length > 0 && (
            <Box sx={{ minWidth: 275 }} className="mob-jobs-search-margin">
              <FormControl fullWidth>
                <InputLabel shrink={true} id="demo-simple-select-label">
                  Select Lead
                </InputLabel>
                <Select
                  input={
                    <OutlinedInput
                      id="select-multiple-chip"
                      label="Select Lead"
                      notched
                    />
                  }
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedLead || ""}
                  label="selectedLead"
                  onChange={(event) => setSelectedLead(event.target.value)}
                >
                  {leads.map((lead: any) => {
                    return (
                      <MenuItem key={lead.id} value={lead}>
                        {lead.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          )}
        </div>
        <div className="white-hack-leadInvoices">
          {selectedLead && selectedLead.name && (
            <LeadCard lead={selectedLead} hotSkills={hotSkills} />
          )}
          <div>
            {window.innerWidth >= 1200 ? (
              <div className="row-lightblue-leads wrap">
                <div
                  className="kpi-card-projects kpi-margin"
                  style={{
                    border: !paidInvoicesClicked
                      ? "0px solid grey"
                      : "1px solid #FF6210",
                    boxShadow: !paidInvoicesClicked
                      ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                      : "none",
                  }}
                  onClick={handlePaidInvoicesClick}
                >
                  <ThumbUpOffAltIcon className="margin-kpi-projects req-purple" />
                  <strong className="text-quicksand700-small darkgrey">
                    Paid Invoices
                  </strong>
                  <span className="grey-text-margin">
                    {" "}
                    {"( " + numPaidInvoices.toString() + " )"}
                  </span>
                </div>

                <div
                  className="kpi-card-projects kpi-margin"
                  style={{
                    border: !newInvoicesClicked
                      ? "0px solid grey"
                      : "1px solid #FF6210",
                    boxShadow: !newInvoicesClicked
                      ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                      : "none",
                  }}
                  onClick={handleNewInvoicesClick}
                >
                  <AddIcon className="margin-kpi-projects req-green" />
                  <strong className="text-quicksand700-small darkgrey">
                    New Invoices
                  </strong>
                  <span className="grey-text-margin">
                    {" "}
                    {"( " + numNewInvoices.toString() + " )"}
                  </span>
                </div>
                <div
                  className="kpi-card-projects kpi-margin"
                  style={{
                    border: !cancelledInvoicesClicked
                      ? "0px solid grey"
                      : "1px solid #FF6210",
                    boxShadow: !cancelledInvoicesClicked
                      ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                      : "none",
                  }}
                  onClick={handleCancelledInvoicesClick}
                >
                  <DoDisturbIcon className="margin-kpi-projects req-red" />
                  <strong className="text-quicksand700-small darkgrey">
                    Cancelled Invoices
                  </strong>
                  <span className="grey-text-margin">
                    {" "}
                    {"( " + numCancelledInvoices.toString() + " )"}
                  </span>
                </div>
              </div>
            ) : (
              <Box className="full-percent-width margin-t40">
                <FormControl fullWidth>
                  <InputLabel shrink={true} id="demo-simple-select-label">
                    Filter Invoices
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={mobileFilter}
                    onChange={(event) => {
                      handleChangeMobileFilter(event.target.value);
                    }}
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label="Filter Invoices"
                        notched
                      />
                    }
                  >
                    <MenuItem value={"ALL"}>
                      <ClearAllIcon className="margin-r3" />
                      All Invoices
                    </MenuItem>
                    <MenuItem value={"PAID"}>
                      {" "}
                      <ThumbUpOffAltIcon className="margin-r3 req-purple" />
                      Paid Invoices {"( " + numPaidInvoices.toString() + " )"}
                    </MenuItem>
                    <MenuItem value={"NEW"}>
                      {" "}
                      <AddIcon className="margin-r3 req-green" />
                      New Invoices {"( " + numNewInvoices.toString() + " )"}
                    </MenuItem>
                    <MenuItem value={"CANCELLED"}>
                      {" "}
                      <DoDisturbIcon className="margin-r3 req-red" />
                      Cancelled Invoices{" "}
                      {"( " + numCancelledInvoices.toString() + " )"}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            )}
          </div>
        </div>

        <div className="separate-area">
          <div>
            {invoices.length === 0 && selectedLead && selectedLead.invoices ? (
              <div className="row-space-between half-percent-width wrap">
                {selectedLead.invoices.map((invoice: any) => {
                  return (
                    <InvoiceCard
                      highlight={false}
                      key={invoice.id}
                      invoice={invoice}
                      getProjects={getLeads}
                    />
                  );
                })}
              </div>
            ) : (
              invoices.length > 0 && (
                <div className="row-space-between wrap">
                  {invoices.map((invoice: any) => {
                    return (
                      <InvoiceCard
                        highlight={false}
                        key={invoice.id}
                        invoice={invoice}
                        getProjects={getLeads}
                      />
                    );
                  })}
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadInvoices;
