import { useEffect, useState } from "react";
import "./AddReport.css";
import PropTypes from "prop-types";
import axiosService from "../../utils/axios";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import {
  FormControlLabel,
  Input,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { useAlert } from "react-alert";
import Checkbox from "@mui/material/Checkbox";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import Modal from "@mui/material/Modal";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddReport = (props: any) => {
  const [show, setShow] = useState(false);
  const [well, setWell] = useState("");
  const [more, setMore] = useState("");
  const [not, setNot] = useState("");
  const [blocking, setBlocking] = useState("");
  const [highlight, setHighlight] = useState("");
  const [escalate, setEscalate] = useState(false);
  const [rate, setRate] = useState("");

  const alert = useAlert();

  const [description, setDescription] = useState("");

  const [isFirst, setIsFirst] = useState(true);

  const auth = useSelector((state: RootState) => state.auth);

  const closeHandler = (e: any) => {
    setShow(false);
    props.onClose(false);
  };

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        closeHandler(true);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);
  useEffect(() => {
    if (isFirst) {
      setIsFirst(false);
    }

    setWell("");
    setNot("");
    setMore("");
    setRate("");
    setEscalate(false);

    setBlocking("");
    setHighlight("");
  }, []);

  const addPhase = () => {
    let body = {
      went_well: well,
      rate: rate,
      escalate: escalate,

      do_more: more,
      not_do: not,
      blocking: blocking,
      highlight: highlight,

      project_id: props.project_id,
    };
    axiosService
      .post(`${process.env.REACT_APP_API_URL}/add_report`, body)
      .then((res) => {
        alert.success("The report was added");
        props.onClose(true);
      })

      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  return (
    // <div
    //   style={{
    //     visibility: show ? "visible" : "hidden",
    //     opacity: show ? "1" : "0",
    //   }}
    //   className={"overlay"}
    // >
    <Modal
      open={show}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={"popup-report"}>
        <div className="phase-scroll">
          <div className="row-space-between-center">
            <span className="text-quicksand700">Add report</span>
            <span className={"close"} onClick={closeHandler}>
              &times;
            </span>
          </div>

          <span className="text-quicksand-small opp-grey padding-up-down">
            How was you day?
          </span>
          <div className="row-space-between padding-normal">
            <img
              src={require("../../assets/images/angry.png")}
              className="img-face"
              style={{
                cursor: "pointer",
                opacity: rate === "ANGRY" ? 1 : 0.5,
              }}
              onClick={() => setRate("ANGRY")}
            />
            <img
              src={require("../../assets/images/sad.png")}
              className="img-face"
              style={{
                cursor: "pointer",
                opacity: rate === "SAD" ? 1 : 0.5,
              }}
              onClick={() => setRate("SAD")}
            />
            <img
              src={require("../../assets/images/neutral.png")}
              className="img-face"
              style={{
                cursor: "pointer",
                opacity: rate === "NEUTRAL" ? 1 : 0.5,
              }}
              onClick={() => setRate("NEUTRAL")}
            />
            <img
              src={require("../../assets/images/happy.png")}
              className="img-face"
              style={{
                cursor: "pointer",
                opacity: rate === "HAPPY" ? 1 : 0.5,
              }}
              onClick={() => setRate("HAPPY")}
            />
            <img
              src={require("../../assets/images/excited.png")}
              className="img-face"
              style={{
                cursor: "pointer",
                opacity: rate === "EXCITED" ? 1 : 0.5,
              }}
              onClick={() => setRate("EXCITED")}
            />
          </div>

          <Box className="add-phase-full-min-width padding-up-down">
            <FormControl fullWidth>
              <TextField
                id="outlined-basic-well"
                multiline
                minRows={3}
                maxRows={5}
                InputLabelProps={{ shrink: true }}
                label="What went well?"
                value={well || ""}
                onChange={(event) => setWell(event.target.value)}
              />
            </FormControl>
          </Box>

          <Box className="add-phase-full-min-width padding-up-down">
            <FormControl fullWidth>
              <TextField
                id="outlined-basic-more"
                multiline
                minRows={3}
                maxRows={5}
                InputLabelProps={{ shrink: true }}
                label="What would you like to do more of?"
                value={more || ""}
                onChange={(event) => setMore(event.target.value)}
              />
            </FormControl>
          </Box>

          <Box className="add-phase-full-min-width padding-up-down">
            <FormControl fullWidth>
              <TextField
                id="outlined-basic-not"
                multiline
                minRows={3}
                maxRows={5}
                InputLabelProps={{ shrink: true }}
                label="What would you like to not do anymore?"
                value={not || ""}
                onChange={(event) => setNot(event.target.value)}
              />
            </FormControl>
          </Box>

          <Box className="add-phase-full-min-width padding-up-down">
            <FormControl fullWidth>
              <TextField
                id="outlined-basic-block"
                multiline
                minRows={3}
                maxRows={5}
                InputLabelProps={{ shrink: true }}
                label="What is blocking your work?"
                value={blocking || ""}
                onChange={(event) => setBlocking(event.target.value)}
              />
            </FormControl>
          </Box>

          <Box className="add-phase-full-min-width padding-up-down">
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                multiline
                minRows={3}
                maxRows={5}
                InputLabelProps={{ shrink: true }}
                label="Is there something you would like to highlight?"
                value={highlight || ""}
                onChange={(event) => setHighlight(event.target.value)}
              />
            </FormControl>
          </Box>

          <Box className="add-phase-full-min-width padding-up-down ">
            <FormControlLabel
              control={
                <Checkbox
                  checked={escalate}
                  onChange={() => setEscalate(!escalate)}
                />
              }
              style={{fontFamily:"Quicksand"}}
              className="opp-grey"
              label="Bring this report to the attention of a Gyld Manager now"
            />
          </Box>

          <div className="add-phase-row-flex-end padding-up-down">
            <button
              className="button-white-small text-quicksand700-small margin-r20"
              onClick={() => props.onClose(true)}
            >
              {" "}
              Cancel
            </button>
            <button
              className="button-black-small-nm text-quicksand700-small"
              onClick={addPhase}
            >
              {" "}
              Save
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

AddReport.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,

  project_id: PropTypes.number.isRequired,

  selected_project: PropTypes.object,
};

export default AddReport;
