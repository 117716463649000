import React, { useState, useEffect } from "react";
import Moment from "moment";
import "./JobCard.css";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import SelectApplicationType from "../select-application-type/SelectApplicationType";
import WhatshotIcon from "@mui/icons-material/Whatshot";

import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import axiosService from "../../utils/axios";
import { useAlert } from "react-alert";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useSelector, useDispatch } from "react-redux";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import JoinLeftIcon from "@mui/icons-material/JoinLeft";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import PublicProfileClient from "../../screens/public-profile/PublicProfileClient";

import PushPinIcon from "@mui/icons-material/PushPin";
import Modal from "@mui/material/Modal";
import { RootState } from "../../store";

function JobCard(props: any) {
  const auth = useSelector((state: RootState) => state.auth);
  const [showProfileClient, setShowProfileClient] = useState(false);
  const [selectedClient, setSelectedClient] = useState<any>();
  const [readMore, setReadMore] = useState(false);

  const [showSelectApplicationType, setShowSelectApplicationType] =
    useState(false);
  const alert = useAlert();
  const applySelect = (value: string) => {
    props.apply(props.job.job.id, value, props.job.id);
  };

  const hideJob = () => {
    let body = { user: auth.account, job_id: props.job.job.id };
    axiosService
      .post(`${process.env.REACT_APP_API_URL}/hide_job`, body)
      .then((res) => {
        alert.success("The Job is Hidden");
        props.getJobs();
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  const unhideJob = () => {
    let body = { user: auth.account, job_id: props.job.job.id };
    axiosService
      .post(`${process.env.REACT_APP_API_URL}/unhide_job`, body)
      .then((res) => {
        alert.success("The Job is no longer Hidden");
        props.getJobs();
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  const closeProfile = () => {
    setShowProfileClient(false);
    setSelectedClient(null);
  };
  const showPublicProfileClient = (user: any) => {
    setShowProfileClient(true);
    setSelectedClient(user.id);
  };

  const handleCloseApplicationSelect = () => {
    setShowSelectApplicationType(false);
  };
  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        closeProfile();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);
  return (
    <div
      className={
        props.highlight
          ? !readMore
            ? "jobs-card-out highlight-selected"
            : "opp-boxes-readmore  highlight-selected"
          : !readMore
            ? "jobs-card-out "
            : "opp-boxes-readmore  "
      }
    >
      {showSelectApplicationType && (
        <SelectApplicationType
          show={showSelectApplicationType}
          applySelect={applySelect}
          setShowSelectApplicationType={setShowSelectApplicationType}
          onClose={handleCloseApplicationSelect}
        />
      )}

      {showProfileClient && (
        // <div
        //   style={{
        //     visibility: showProfileClient ? "visible" : "hidden",
        //     opacity: showProfileClient ? "1" : "0",
        //   }}
        //   className={"overlay"}
        // >
        <Modal
          open={showProfileClient}
          onClose={closeProfile}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className={"popup-pub-profile-client"}>
            <span
              className={"close align-self-end"}
              onClick={closeProfile}
              style={{ marginTop: -10 }}
            >
              &times;
            </span>
            <PublicProfileClient user={selectedClient} />
          </div>
        </Modal>
      )}
      <div className="row-space-between">
        <div className="row-start-start">
        
            <span className="text-quicksand700 margin-right-small">
              {props.job.job.name}
            </span>

            {props.job.status === "WON" ? (
              <Tooltip title="Won" placement="bottom" arrow>
                <EmojiEventsIcon className="job-card-won-icon req-green" />
              </Tooltip>
            ) : props.job.status === "MATCHED" || props.job.status === "NEW" ? (
              <Tooltip title="Is being matched" placement="bottom" arrow>
                <JoinLeftIcon className="job-card-won-icon req-purple" />
              </Tooltip>
            ) : props.job.status === "REJECTED" ? (
              <Tooltip title="Rejected" placement="bottom" arrow>
                <DoDisturbIcon className="job-card-won-icon req-red" />
              </Tooltip>
            ) : (
              !props.job.applied && (
                <Tooltip title="Saved" placement="bottom" arrow>
                  <PushPinIcon className="job-card-won-icon req-blue" />
                </Tooltip>
              )
            )}
          </div>

          {/* <span className="card-information">- Job -</span> */}

          <div className="opp-box-end-end">
            {!props.job.applied && !props.job.hidden && (
              <div className="job-card-simulate-icon">
                <Tooltip title="Simulate" placement="bottom" arrow>
                  <input
                    type="checkbox"
                    style={{
                      color: "#626DA1",
                      cursor: "pointer",
                    }}
                    checked={props.job.job.simulation}
                    onChange={() => {
                      props.handleSimulate(props.job.job);
                    }}
                  />
                </Tooltip>
              </div>
            )}
      
          {!props.job.hidden ? (
            <div className="job-card-hide-icon">
              <Tooltip title="Hide" placement="bottom" arrow>
                <VisibilityOffIcon
                  onClick={hideJob}
                  style={{
                    color: "#626DA1",

                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </div>
          ) : (
            <div className="job-card-hide-icon">
              <Tooltip title="Show" placement="bottom" arrow>
                <VisibilityIcon
                  onClick={unhideJob}
                  style={{
                    color: "#626DA1",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </div>
          )}
        </div>
      </div>
   
      <div className="job-card-row-2column">
        <span className="opp-status">
          Status:{" "}
          {props.job.job.status === "HAS_APPLICANTS"
            ? "Matched"
            : props.job.job.status}
        </span>
        {window.innerWidth > 1200 && <div className="ball"></div>}
        <span className="opp-status">Field: {props.job.job.industry}</span>
        {window.innerWidth > 1200 && <div className="ball"></div>}
        <span className="opp-status">Client: {props.job.job.client_type}</span>
        {window.innerWidth > 1200 && <div className="ball"></div>}
        <span className="opp-status">
          {" "}
          {Moment(props.job.job.start_date).format("D MMM yy")} -{" "}
          {Moment(props.job.job.end_date).format("D MMM yy")}
        </span>
        {window.innerWidth > 1200 && <div className="ball"></div>}
        <span className="opp-status">
          {props.job.job.job_type
            .toLowerCase()
            .replace(/_/g, " ")
            .charAt(0)
            .toUpperCase() +
            props.job.job.job_type.toLowerCase().replace(/_/g, " ").slice(1)}
        </span>
        {props.job.applied && window.innerWidth > 1200 && (
          <div className="ball"></div>
        )}
        {props.job.applied && (
          <span className="opp-status">
            Applied To:
            {props.job.type === "PROJECT"
              ? " Do the Project"
              : props.job.type === "LEARN"
                ? " Learn"
                : props.job.type === "HELP" && " Help another dev"}
          </span>
        )}
      </div>
      <div className="margin-t20 margin-b20">
        <span className="text-quicksand-small opp-grey ">
          {!readMore
            ? [...props.job.job.description].slice(0, 400)
            : props.job.job.description}
        </span>
        {[...props.job.job.description].length > 400 && (
          <span className="text-quicksand ">...</span>
        )}
        {[...props.job.job.description].length > 400 && (
          <span
            className="text-quicksand-700 orange-text pointer margin-l10"
            onClick={() => setReadMore(!readMore)}
          >
            {readMore ? "Read Less" : "Read More"}
          </span>
        )}
      </div>

      <div className="lead-card-skills-row">
        {props.job.job.skills.map((skill: string) => {
          return (
            <span key={skill} className="lead-card-skills-buttons">
              {skill}
              {props.hotSkills?.map((hotSkill: any) => {
                if (hotSkill.name === skill) {
                  return (
                    <WhatshotIcon
                      key={hotSkill.name}
                      style={{
                        color: "#ff6210",
                        marginBottom: 5,
                      }}
                    />
                  );
                }
              })}
            </span>
          );
        })}
      </div>

      <div
        className={
          [...props.job.job.description].length > 400
            ? "row-space-between margin-b10n"
            : "row-space-between "
        }
      >
        <div className="opp-box-row-end">
          <div className="opp-margin row-start-center">
            <span className="text-quicksand-small margin-r3">
              Developer Level:
            </span>
            <span className="text-quicksand-small opp-lightgrey">
              {props.job.job.developer_level}
            </span>
          </div>
          <div className="opp-margin row-start-center">
            <span className="text-quicksand-small margin-r3">
              Developers needed:{" "}
            </span>
            <span className="text-quicksand-small opp-lightgrey">
              {props.job.job.num_developers}
            </span>
          </div>
          {auth.selectedView !== "CLIENT" && props.job.job.client.username && (
            <div className="opp-margin row-start-center">
              <span className="text-quicksand-small">Client: </span>
              {props.job.job.client.image &&
                props.job.job.client.image.includes("media") ? (
                <img
                  onClick={() => showPublicProfileClient(props.job.job.client)}
                  src={
                    process.env.REACT_APP_API_URL?.split("/api")[0] +
                    props.job.job.client.image
                  }
                  className="profile-img-small"
                />
              ) : (
                <div
                  className="profile-img-small no-pic-small"
                  onClick={() => showPublicProfileClient(props.job.job.client)}
                >
                  <span>{props.job.job.client.username.charAt(0)}</span>
                </div>
              )}

              <span className="text-quicksand700 darkgrey padding-normal">
                {props.job.job.client.username}
              </span>
            </div>
          )}
        </div>
        <div className="opp-box-end-end">
          {!props.job.applied && (
            <div className="row-start  pointer"  onClick={() => setShowSelectApplicationType(true)}>
           
            <ThumbUpIcon  className="orange-text" style={{fontSize:15, alignSelf:"center", marginRight:5}}/>
                <span  className="orange-text">
                Apply</span> 
              

         </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default JobCard;
