import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccountResponse } from "../../types";

type State = {
  token: string | null;
  dev: boolean;
  client: boolean;
  staff: boolean;
  admin: boolean;
  lead_provider: boolean;
  account: AccountResponse | null;
  skills: Array<string> | null;
  selectedView: string | null;
  selectedGroup: string | null;
  selectedSubgroup: string | null;
  selectedGroupId: number | null;
  selectedSubgroupId: number | null;
  client_id: AccountResponse | null;
  expert: boolean;
};

const initialState: State = {
  token: null,
  account: null,
  skills: [],
  dev: false,
  staff: false,
  client: false,
  admin: false,
  lead_provider: false,
  selectedView: null,
  expert: false,
  client_id: null,
  selectedGroup: null,
  selectedGroupId: null,
  selectedSubgroup: null,
  selectedSubgroupId: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthTokens(state: State, action: PayloadAction<{ token: string }>) {
      state.token = action.payload.token;
    },
    setAccount(state: State, action: PayloadAction<AccountResponse>) {
      state.account = action.payload;
    },
    setClientId(state: State, action: PayloadAction<AccountResponse>) {
      state.client_id = action.payload;
    },
    setSkills(state: State, action: PayloadAction<Array<string>>) {
      state.skills = action.payload;
    },
    setDev(state: State, action: PayloadAction<boolean>) {
      state.dev = action.payload;
    },
    setClient(state: State, action: PayloadAction<boolean>) {
      state.client = action.payload;
    },
    setStaff(state: State, action: PayloadAction<boolean>) {
      state.staff = action.payload;
    },
    setAdmin(state: State, action: PayloadAction<boolean>) {
      state.admin = action.payload;
    },
    setLeadProvider(state: State, action: PayloadAction<boolean>) {
      state.lead_provider = action.payload;
    },
    setSelectedView(state: State, action: PayloadAction<string>) {
      state.selectedView = action.payload;
    },
    setSelectedGroup(state: State, action: PayloadAction<string | null>) {
      state.selectedGroup = action.payload;
    },
    setSelectedSubgroup(state: State, action: PayloadAction<string | null>) {
      state.selectedSubgroup = action.payload;
    },
    setSelectedGroupId(state: State, action: PayloadAction<number | null>) {
      state.selectedGroupId = action.payload;
    },
    setSelectedSubgroupId(state: State, action: PayloadAction<number | null>) {
      state.selectedSubgroupId = action.payload;
    },
    setExpert(state: State, action: PayloadAction<boolean>) {
      state.expert = action.payload;
    },

    setLogout(state: State) {
      state.account = null;
      state.token = null;
    },
  },
});

export default authSlice;
