import React, { useState, useEffect } from "react";
import axiosService from "../../utils/axios";
import LeadCard from "../../components/lead-card/LeadCard";
import locationSlice from "../../store/slices/location";
import "./Leads.css";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import PlatformNavigation from "../../components/platform-navigation/PlatformNavigation";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import PendingIcon from "@mui/icons-material/Pending";
import CheckIcon from "@mui/icons-material/Check";
import HelpIcon from "@mui/icons-material/Help";
import AddLead from "../../components/add-lead/AddLead";
import LeadsAdmin from "./LeadsAdmin";
import LeadsUser from "./LeadsUser";

function Leads() {
  const [leads, setLeads] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState<Array<any>>([]);

  const [checkedClicked, setCheckedClicked] = useState(false);
  const [acceptedClicked, setAcceptedClicked] = useState(false);
  const [rejectedClicked, setRejectedClicked] = useState(false);
  const [startedClicked, setStartedClicked] = useState(false);
  const [finishedClicked, setFinishedClicked] = useState(false);
  const [numChecked, setNumChecked] = useState(0);
  const [numAccepted, setNumAccepted] = useState(0);
  const [numRejected, setNumRejected] = useState(0);
  const [numStarted, setNumStarted] = useState(0);
  const [numFinished, setNumFinished] = useState(0);
  const [isFirst, setIsFirst] = useState(true);
  const [hideHints, setHideHints] = useState(false);
  const [showAddLeadPopup, setShowAddLeadPopup] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    dispatch(locationSlice.actions.setLocation({ path: "/platform/leads" }));
    if (!auth.account) {
      navigate("/login");
    }
  }, []);

  return (
    <div className="platform container">
      {auth.selectedView === "GYLD MANAGER" ? <LeadsAdmin /> : <LeadsUser />}
    </div>
  );
}

export default Leads;
