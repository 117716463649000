import React, { useEffect } from "react";
import "./DeleteConfirmation.css";

const DeleteConfirmation = (props: any) => {
  //   const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(locationSlice.actions.setLocation({ path: "/developers" }));
  }, []);

  return (
    <div
      style={{
        visibility: "visible",
        opacity: "1",
      }}
      className={"overlay "}
    >
      <div className={"popup-delete "}>
        <img src={require("../../assets/images/delete.png")} />
        <div className="delete-column">
          <span className="heading-lora">Hey Wait!</span>
          <span className="text-quicksand lightgrey">
            Are you sure, you want to delete this opportunity?
          </span>
          <div className="row-start">
            <button
              className="no-button quicksand700-small"
              onClick={() => props.setShowDeletePopup(false)}
            >
              No
            </button>
            <button
              className="yes-button quicksand700-small"
              onClick={props.deleteOpportunity}
            >
              Yes, delete
            </button>
          </div>
          {/* <div className="row-start">
            <button className="check-button"></button>
            <span className="text-quicksand lightgrey">
              Do not show this msg again
            </span>
          </div> */}
        </div>
        <img
          onClick={() => props.setShowDeletePopup(false)}
          className="close-icon"
          src={require("../../assets/images/close.png")}
        />
      </div>
    </div>
  );
};

export default DeleteConfirmation;
