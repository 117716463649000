import React, { useState, useEffect } from "react";
import Moment from "moment";
import "./JobCard.css";
import axiosService from "../../utils/axios";
import EuroIcon from "@mui/icons-material/Euro";
import BusinessIcon from "@mui/icons-material/Business";
import ComputerIcon from "@mui/icons-material/Computer";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import { useAlert } from "react-alert";
import WhatshotIcon from "@mui/icons-material/Whatshot";

import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import AddRequest from "../add-request/AddRequest";
function JobCardAdmin(props: any) {
  const [showAddRequestPopup, setShowAddRequestPopup] = useState(false);
  const [selectedRfpp, setSelectedRfpp] = useState<any>({});
  const [readMore, setReadMore] = useState(false);
  const [applied, setApplied] = useState(0);
  const [invited, setInvited] = useState(0);
  const [client, setClient] = useState("");

  const alert = useAlert();

  const handleCloseAddRequestPopup = () => {
    setShowAddRequestPopup(false);
  };

  useEffect(() => {
    if (selectedRfpp.name) {
      setShowAddRequestPopup(true);
    }
  }, [selectedRfpp]);

  useEffect(() => {
    getAppliedInvited();
  }, []);

  const handleOpenRequestPopup = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_rfpp/` + props.job.rfpp)
      .then((res) => {
        setSelectedRfpp(res.data.rfpp);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  const getAppliedInvited = () => {
    axiosService
      .get(
        `${process.env.REACT_APP_API_URL}/get_number_applied_invited_devs/` +
          props.job.id
      )
      .then((res) => {
        setApplied(res.data.applied);
        setInvited(res.data.invited);
        setClient(res.data.client);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  return (
    <div className={!readMore ? "opp-card-out" : "opp-boxes-readmore"}>
      {showAddRequestPopup && (
        <AddRequest
          show={showAddRequestPopup}
          onClose={handleCloseAddRequestPopup}
          request={selectedRfpp}
          onlyView={true}
        />
      )}
      <div className="row-start-center-no-width">
        <span className="text-quicksand700 opp-grey margin-right">
          {props.job.name}
        </span>
        <span
          className="text-quicksand-700 orange-text pointer max-content-width align-self-center"
          onClick={handleOpenRequestPopup}
        >
          View Request
        </span>
      </div>

      <div className="opp-box-row">
        <span className="opp-status">Status: {props.job.status}</span>
        {window.innerWidth > 1200 && <div className="ball"></div>}
        <span className="opp-status">Field: {props.job.industry}</span>
        {window.innerWidth > 1200 && <div className="ball"></div>}
        <span className="opp-status">Client: {client}</span>
        {window.innerWidth > 1200 && <div className="ball"></div>}
        <span className="opp-status">
          {" "}
          {Moment(props.job.start_date).format("D MMM yy")} -
          {Moment(props.job.end_date).format("D MMM yy")}
        </span>
        {window.innerWidth > 1200 && <div className="ball"></div>}
        <span className="opp-status">
          {props.job.job_type
            .toLowerCase()
            .replace(/_/g, " ")
            .charAt(0)
            .toUpperCase() +
            props.job.job_type.toLowerCase().replace(/_/g, " ").slice(1)}
        </span>
      </div>
      <div className="margin-t20 margin-b20">
        <span className="text-quicksand  ">
          {!readMore
            ? [...props.job.description].slice(0, 400)
            : props.job.description}
        </span>
        {[...props.job.description].length > 400 && (
          <span className="text-quicksand ">...</span>
        )}
        {[...props.job.description].length > 400 && (
          <span
            className="text-quicksand-700 orange-text pointer margin-l10"
            onClick={() => setReadMore(!readMore)}
          >
            {readMore ? "Read Less" : "Read More"}
          </span>
        )}
      </div>

      <div className="opp-card-skills">
        {props.job.skills.map((skill: string) => {
          return (
            <span key={skill} className="lead-card-skills-buttons">
              {skill}
              {props.hotSkills?.map((hotSkill: any) => {
                if (hotSkill.name === skill) {
                  return (
                    <WhatshotIcon
                      key={hotSkill.name}
                      style={{
                        color: "#ff6210",
                        marginBottom: 5,
                      }}
                    />
                  );
                }
              })}
            </span>
          );
        })}
      </div>

      <div
        className={
          [...props.job.description].length > 400
            ? "row-space-between margin-b10n"
            : "row-space-between "
        }
      >
        <div className="opp-box-row-end">
          <div className="opp-margin">
            <span className="text-quicksand-small">Developer Level: </span>
            <span className="text-quicksand-small opp-lightgrey">
              {props.job.developer_level}
            </span>
          </div>
          <div className="opp-margin">
            <span className="text-quicksand-small">Developers needed: </span>
            <span className="text-quicksand-small opp-lightgrey">
              {props.job.num_developers}
            </span>
          </div>
          <div className="opp-margin">
            <span className="text-quicksand-small">Developers Invited: </span>
            <span className="text-quicksand-small opp-lightgrey">
              {invited}
            </span>
          </div>
          <div className="opp-margin">
            <span className="text-quicksand-small">Developers Applied: </span>
            <span className="text-quicksand-small opp-lightgrey">
              {applied}
            </span>
          </div>
        </div>
        <div className="lead-box-icon-end">
        
            <Tooltip title="Edit" placement="bottom" arrow>
              <EditIcon
                onClick={() => props.editJob(props.job)}
                style={{
                  color: "#626DA1",
                  marginBottom: 7,
                  cursor: "pointer",
                  marginRight: 20,
                }}
              />
            </Tooltip>
       

          {props.job.status === "PENDING" &&
            ((props.job.proposed_devs && props.job.proposed_devs.length > 0) ||
              (props.job.proposed_groups &&
                props.job.proposed_groups.length > 0) ||
              (props.job.suggested_proposed_devs &&
                props.job.suggested_proposed_devs.length > 0) ||
              (props.job.suggested_proposed_groups &&
                props.job.suggested_proposed_groups.length > 0)) && (
              <button
                className="button-black-small-nm text-quicksand700-small margin-l10"
                onClick={() => props.proposeCandidates(props.job)}
              >
                View Proposed Candidates
              </button>
            )}
          {props.job.status === "ACCEPTED" &&
            ((props.job.accepted_devs && props.job.accepted_devs.length > 0) ||
              (props.job.accepted_groups &&
                props.job.accepted_groups.length > 0)) && (
              <div>
                <button
                  className="button-black-small-nm text-quicksand700-small margin-l10"
                  onClick={() => props.viewAcceptedCandidates(props.job)}
                >
                  View Accepted Candidates
                </button>
                <button
                  className="button-black-small-nm text-quicksand700-small margin-l10"
                  onClick={() => props.createContract(props.job)}
                >
                  Create Contract
                </button>
              </div>
            )}
          {props.job.status === "HAS_APPLICANTS" &&
            (props.job.proposed_devs.length == 0 ||
              props.job.proposed_groups.length == 0 ||
              (props.job.suggested_proposed_devs &&
                props.job.suggested_proposed_devs.length > 0) ||
              (props.job.suggested_proposed_groups &&
                props.job.suggested_proposed_groups.length > 0)) && (
              <button
                className="button-black-small text-quicksand700-small margin-l10"
                onClick={() => props.proposeCandidates(props.job)}
              >
                Propose Candidates
              </button>
            )}
          {(props.job.status === "NEW" ||
            props.job.status === "HAS_APPLICANTS") && (
            <button
              className="button-black-small-nm text-quicksand700-small margin-l10"
              onClick={() => props.inviteCandidates(props.job)}
            >
              Invite Candidates
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default JobCardAdmin;
