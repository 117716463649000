import React, { useState, useEffect } from "react";
import axiosService from "../../utils/axios";

import locationSlice from "../../store/slices/location";
import "./Users.css";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { useAlert } from "react-alert";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { AddCircleOutlined, Cancel, CheckCircle } from "@mui/icons-material";
import moment from "moment";
import AddUser from "../../components/add-user/AddUser";
import AddClient from "../../components/add-client/AddClient";
import DownloadIcon from "@mui/icons-material/Download";
import AddFrameWorkAgreement from "../../components/add-framework-agreement/AddFrameWorkAgreement";

function Users() {
  const [users, setUsers] = useState<any[]>([]);
  const [clients, setClients] = useState<any[]>([]);
  const [showAddUser, setShowAddUser] = useState(false);
  const [showAddClient, setShowAddClient] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(-1);
  const [addDevFramework, setAddDevFramework] = useState(false);
  const [showAddFramework, setShowAddFramework] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth);
  const alert = useAlert();
  useEffect(() => {
    dispatch(
      locationSlice.actions.setLocation({
        path: "/platform/users",
      })
    );
    getClients();
    getUsers();
    if (!auth.account) {
      navigate("/login");
    }
    if (auth.selectedView !== "GYLD MANAGER") {
      navigate("/platform");
    }
  }, []);

  const getUsers = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_users`)
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  const deleteFrameworkAgreementDev = (user_id: number) => {
    axiosService
      .delete(
        `${process.env.REACT_APP_API_URL}/delete_dev_framework_agreement/` +
          user_id
      )
      .then((res) => {
        alert.success("The Framework Agreement was deleted");
        getUsers();
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  const deleteFrameworkAgreementClient = (client_id: number) => {
    axiosService
      .delete(
        `${process.env.REACT_APP_API_URL}/delete_client_framework_agreement/` +
          client_id
      )
      .then((res) => {
        alert.success("The Framework Agreement was deleted");
        getClients();
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  const getClients = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_clients`)
      .then((res) => {
        setClients(res.data);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  const handleCloseUserPopup = () => {
    setShowAddUser(false);
    getUsers();
  };
  const handleCloseClientPopup = () => {
    setShowAddClient(false);
    getClients();
  };
  const handleAddFrameworkDev = (id: number) => {
    setSelectedId(id);
    setShowAddFramework(true);
    setAddDevFramework(true);
  };
  const handleAddFrameworkClient = (id: number) => {
    setSelectedId(id);
    setShowAddFramework(true);
  };
  const handleCloseAddFramework = () => {
    setSelectedId(-1);
    setAddDevFramework(false);
    getUsers();
    getClients();
    setShowAddFramework(false);
  };

  return (
    <div className=" container column-center">
      <div className="row-start">
        <button
          className="signUp button add-lead-button margin-right "
          onClick={() => setShowAddUser(true)}
        >
          <span className="signUpLink">Add A User</span>
        </button>
        <button
          className="signUp button add-lead-button"
          onClick={() => setShowAddClient(true)}
        >
          <span className="signUpLink">Add A Client</span>
        </button>
      </div>
      {showAddUser && (
        <AddUser show={showAddUser} onClose={handleCloseUserPopup} />
      )}
      {showAddClient && (
        <AddClient show={showAddClient} onClose={handleCloseClientPopup} />
      )}
      <TableContainer component={Paper} className="separate-area">
        <b className="padding-normal">Users</b>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="center">Username</TableCell>
              <TableCell align="center">First Name</TableCell>
              <TableCell align="center">Last Name</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Phone</TableCell>
              <TableCell align="center">Skills</TableCell>
              <TableCell align="center">Roles</TableCell>
              <TableCell align="center">Validated</TableCell>
              <TableCell align="center">Active</TableCell>
              <TableCell align="center">Sign Up Date</TableCell>
              <TableCell align="center">Last Login</TableCell>
              <TableCell align="center">Framework Agreement</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users &&
              users.length > 0 &&
              users.map((user: any) => (
                <TableRow
                  key={user.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {user.id}
                  </TableCell>
                  <TableCell align="center">{user.username}</TableCell>
                  <TableCell align="center">{user.first_name}</TableCell>
                  <TableCell align="center">{user.last_name}</TableCell>
                  <TableCell align="center">{user.email}</TableCell>
                  <TableCell align="center">{user.phone}</TableCell>
                  <TableCell align="center">
                    {user.skills &&
                      user.skills.length > 0 &&
                      user.skills.map((skill: string, i: number) => {
                        return (
                          <span>
                            {skill + (i !== user.skills.length - 1 ? "," : "")}
                          </span>
                        );
                      })}
                  </TableCell>
                  <TableCell align="center">
                    <span>{user.admin ? "Gyld-Manager" : ""}&nbsp;</span>
                    <span>{user.dev ? "Developer " : ""}&nbsp;</span>
                    <span>
                      {user.lead_provider ? "Lead-Provider " : ""}&nbsp;
                    </span>
                    <span>{user.client_user ? "Client-User " : ""}&nbsp;</span>
                    <span>
                      {user.client_admin ? "Client-Admin " : ""}&nbsp;
                    </span>
                    <span>
                      {user.client_manager ? "Client-Manager " : ""}&nbsp;
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    {user.validated ? <CheckCircle /> : <Cancel />}
                  </TableCell>
                  <TableCell align="center">
                    {user.is_active ? <CheckCircle /> : <Cancel />}
                  </TableCell>
                  <TableCell align="center">
                    {moment(user.createdAt).format("D MMM yy")}
                  </TableCell>
                  <TableCell align="center">
                    {moment(user.last_login).format("D MMM yy")}
                  </TableCell>
                  <TableCell align="center">
                    {user.dev && user.framework_agreement_dev && (
                      <div className="row-start">
                        <a
                          target="_blank"
                          href={
                            process.env.REACT_APP_API_URL?.split("/api")[0] +
                            "/media/" +
                            user.framework_agreement_dev
                          }
                          download
                        >
                          <DownloadIcon
                            style={{ color: "#626DA1", marginTop: 10 }}
                          />
                        </a>
                        <img
                          onClick={() => {
                            deleteFrameworkAgreementDev(user.id);
                          }}
                          className="opp-box-end-icon"
                          src={require("../../assets/images/trash.png")}
                        />
                      </div>
                    )}
                    {user.dev && !user.framework_agreement_dev && (
                      <AddCircleOutlined
                        onClick={() => handleAddFrameworkDev(user.id)}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {showAddFramework && selectedId > 0 && (
        <AddFrameWorkAgreement
          show={showAddFramework}
          onClose={handleCloseAddFramework}
          dev={addDevFramework}
          id={selectedId}
        />
      )}

      <TableContainer component={Paper} className="separate-area">
        <b className="padding-normal">Clients</b>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="center">Admin User ID</TableCell>
              <TableCell align="center">Username</TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Phone</TableCell>
              <TableCell align="center">Link</TableCell>
              <TableCell align="center">User IDs</TableCell>
              <TableCell align="center">Created At</TableCell>
              <TableCell align="center">Framework Agreement</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clients &&
              clients.length > 0 &&
              clients.map((client: any) => (
                <TableRow
                  key={client.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {client.id}
                  </TableCell>
                  <TableCell align="center">{client.admin_user}</TableCell>
                  <TableCell align="center">{client.username}</TableCell>
                  <TableCell align="center">{client.name}</TableCell>
                  <TableCell align="center">{client.email_contact}</TableCell>
                  <TableCell align="center">{client.phone_contact}</TableCell>
                  <TableCell align="center">{client.link}</TableCell>
                  <TableCell align="center">
                    {client.users &&
                      client.users.length > 0 &&
                      client.users.map((user: string, i: number) => {
                        return (
                          <span>
                            {user + (i !== client.users.length - 1 ? "," : "")}
                          </span>
                        );
                      })}
                  </TableCell>

                  <TableCell align="center">
                    {moment(client.createdAt).format("D MMM yy")}
                  </TableCell>
                  <TableCell align="center">
                    {client.framework_agreement_client && (
                      <div className="row-center">
                        <a
                          target="_blank"
                          href={
                            process.env.REACT_APP_API_URL?.split("/api")[0] +
                            client.framework_agreement_client
                          }
                          download
                        >
                          <DownloadIcon style={{ color: "#626DA1" }} />
                        </a>
                        <img
                          onClick={() => {
                            deleteFrameworkAgreementClient(client.id);
                          }}
                          className="opp-box-end-icon"
                          src={require("../../assets/images/trash.png")}
                        />
                      </div>
                    )}
                    {!client.framework_agreement_client && (
                      <AddCircleOutlined
                        onClick={() => handleAddFrameworkClient(client.id)}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Users;
