import React, { useState, useEffect } from "react";
import axiosService from "../../utils/axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import locationSlice from "../../store/slices/location";
import "./RequestsSignUp.css";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AddCircleOutlined } from "@mui/icons-material";

import EditIcon from "@mui/icons-material/Edit";

import Paper from "@mui/material/Paper";

import moment from "moment";
import DownloadIcon from "@mui/icons-material/Download";

import { Button } from "@mui/material";

import AlertUnassigned from "../../components/alert-unassigned-contracts-dif-client/AlertUnassigned";
import AddContract from "../../components/add-contract/AddContract";
import EditRequestSignUp from "../../components/edit-request-sign-up/EditRequestSignUp";

function RequestsSignUp() {
  const [requestsSignUp, setRequestsSignUp] = useState<any[]>([]);
  const [requestsSignUpDeveloper, setRequestsSignUpDeveloper] = useState<any[]>(
    []
  );
  const [requestsSignUpClient, setRequestsSignUpClient] = useState<any[]>([]);
  const [requestsSignUpLeadProvider, setRequestsSignUpLeadProvider] = useState<
    any[]
  >([]);
  const [selectedContract, setSelectedContract] = useState<any>({});
  const [timesheetsClicked, setTimesheetsClicked] = useState(false);
  const [reportsClicked, setReportsClicked] = useState(false);
  const [phasesClicked, setPhasesClicked] = useState(false);
  const [milestonesClicked, setMilestonesClicked] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState<any>({});
  const [unassignedContracts, setUnassignedContracts] = useState<any[]>([]);
  const [selectedUnassigned, setSelectedUnassigned] = useState<any[]>([]);
  const [showEditRequestPopup, setShowEditRequestPopup] = useState(false);
  const [showAddContractPopup, setShowAddContractPopup] = useState(false);
  const [foundDifClients, setFoundDifClients] = useState(false);
  const [editRequest, setEditRequest] = useState(false);
  const [showEditContract, setShowEditContract] = useState(false);
  const [addContractDev, setAddContractDev] = useState(false);
  const [addContractClient, setAddContractClient] = useState(false);
  const [selectedContractId, setSelectedContractId] = useState(-1);
  const alert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    dispatch(
      locationSlice.actions.setLocation({
        path: "/platform/requestsSignUp",
      })
    );

    getRequestsSignUp();

    if (!auth.account) {
      navigate("/login");
    }
  }, []);
  const handleClosePopup = () => {
    setShowEditRequestPopup(false);

    getRequestsSignUp();
  };

  const handleEditRequest = (request: any) => {
    setSelectedRequest(request);

    setShowEditRequestPopup(true);
  };

  const getRequestsSignUp = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_requests_sign_up`)
      .then((res) => {
        setRequestsSignUp(res.data);
        let tmp_devs: any[] = [];
        let tmp_clients: any[] = [];
        let tmp_lead_providers: any[] = [];
        res.data.forEach((request: any) => {
          if (request.role == "DEVELOPER") {
            tmp_devs.push(request);
          } else if (request.role == "CLIENT") {
            tmp_clients.push(request);
          } else {
            tmp_lead_providers.push(request);
          }
        });
        setRequestsSignUpDeveloper(tmp_devs);
        setRequestsSignUpClient(tmp_clients);
        setRequestsSignUpLeadProvider(tmp_lead_providers);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const handleRequestClick = (project: any) => {
    setSelectedRequest(project);
    setTimesheetsClicked(false);
  };

  return (
    <div className=" container column-center">
      {showEditRequestPopup && (
        <EditRequestSignUp
          show={showEditRequestPopup}
          onClose={handleClosePopup}
          request={selectedRequest}
        />
      )}
      <TableContainer component={Paper} className="separate-area">
        <b className="padding-normal">Requests for Sign Up Developers</b>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="center">First Name</TableCell>
              <TableCell align="center">Last Name</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Replied</TableCell>
              <TableCell align="center">Signed Up</TableCell>
              <TableCell align="center">Created At</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requestsSignUpDeveloper.length > 0 &&
              requestsSignUpDeveloper.map((request: any) => (
                <TableRow
                  key={request.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {request.id}
                  </TableCell>
                  <TableCell align="center">{request.first_name}</TableCell>
                  <TableCell align="center">{request.last_name}</TableCell>
                  <TableCell align="center">{request.email}</TableCell>
                  <TableCell align="center">
                    {request.replied ? (
                      <CheckIcon className="nav-icon" />
                    ) : (
                      <CloseIcon className="nav-icon" />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {request.signed_up ? (
                      <CheckIcon className="nav-icon" />
                    ) : (
                      <CloseIcon className="nav-icon" />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {moment(request.createdAt).format("D MMM yy")}
                  </TableCell>

                  <TableCell align="center">
                    {" "}
                    <Button onClick={() => handleEditRequest(request)}>
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer component={Paper} className="separate-area">
        <b className="padding-normal">Requests for Sign Up Clients</b>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="center">First Name</TableCell>
              <TableCell align="center">Last Name</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Replied</TableCell>
              <TableCell align="center">Signed Up</TableCell>
              <TableCell align="center">Created At</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requestsSignUpClient.length > 0 &&
              requestsSignUpClient.map((request: any) => (
                <TableRow
                  key={request.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {request.id}
                  </TableCell>
                  <TableCell align="center">{request.first_name}</TableCell>
                  <TableCell align="center">{request.last_name}</TableCell>
                  <TableCell align="center">{request.email}</TableCell>
                  <TableCell align="center">
                    {request.replied ? (
                      <CheckIcon className="nav-icon" />
                    ) : (
                      <CloseIcon className="nav-icon" />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {request.signed_up ? (
                      <CheckIcon className="nav-icon" />
                    ) : (
                      <CloseIcon className="nav-icon" />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {moment(request.createdAt).format("D MMM yy")}
                  </TableCell>

                  <TableCell align="center">
                    {" "}
                    <Button onClick={() => handleEditRequest(request)}>
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>{" "}
      <TableContainer component={Paper} className="separate-area">
        <b className="padding-normal">Requests for Sign Up Lead Providers</b>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="center">First Name</TableCell>
              <TableCell align="center">Last Name</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Replied</TableCell>
              <TableCell align="center">Signed Up</TableCell>
              <TableCell align="center">Created At</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requestsSignUpLeadProvider.length > 0 &&
              requestsSignUpLeadProvider.map((request: any) => (
                <TableRow
                  key={request.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {request.id}
                  </TableCell>
                  <TableCell align="center">{request.first_name}</TableCell>
                  <TableCell align="center">{request.last_name}</TableCell>
                  <TableCell align="center">{request.email}</TableCell>
                  <TableCell align="center">
                    {request.replied ? (
                      <CheckIcon className="nav-icon" />
                    ) : (
                      <CloseIcon className="nav-icon" />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {request.signed_up ? (
                      <CheckIcon className="nav-icon" />
                    ) : (
                      <CloseIcon className="nav-icon" />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {moment(request.createdAt).format("D MMM yy")}
                  </TableCell>

                  <TableCell align="center">
                    {" "}
                    <Button onClick={() => handleEditRequest(request)}>
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default RequestsSignUp;
