import React, { useEffect } from "react";
import locationSlice from "../../store/slices/location";
import { useDispatch } from "react-redux";
import "./Footer.css";
import R from "../../assets/images/R.png";
import C from "../../assets/images/C.png";

import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LanguageIcon from "@mui/icons-material/Language";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { Link } from "react-router-dom";

const Footer = (props: any) => {
  //   const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(locationSlice.actions.setLocation({ path: "/" }));
  }, []);

  const handleChangeCookiePreferences = () => {
    // Remove consent from localStorage
    localStorage.removeItem("analyticsConsent");
    props.onUpdateConsent();
    document.cookie =
      "CookieConsent=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    // Reload the page to trigger cookie consent prompt again
    window.location.reload();
  };

  return (
    <div className="footer-black">
      <div className="footer-back">
        <div className="home-end-left">
          {/* <CopyrightIcon className="C" sx={{ fontSize: 15 }} /> */}
          <span className="text-quicksand700-small white">THE GYLD</span>
          {/* <span className="R">®</span> */}
        </div>
        <div className="column-center-no-width margin-t20 margin-b20">
          <div className="footer-middle  ">
            <Link className=" link " to="/privacy-policy">
              <span className="text-quicksand700-extra-small white">
                {" "}
                Privacy Policy
              </span>
            </Link>
            <Link className=" link " to="/terms-conditions">
              <span className="text-quicksand700-extra-small white">
                {" "}
                Terms and Conditions
              </span>
            </Link>
          </div>
          <span
            className="text-quicksand700-extra-small white pointer"
            onClick={handleChangeCookiePreferences}
          >
            {" "}
            Change Cookie Preferences
          </span>
        </div>

        <div className="home-end-right">
          {/* <InstagramIcon sx={{ fontSize: 30 }} className="white margin-r20" />
        <FacebookIcon sx={{ fontSize: 30 }} className="white margin-r20" />
        <TwitterIcon sx={{ fontSize: 30 }} className="white margin-r20" />
        <LinkedInIcon sx={{ fontSize: 30 }} className="white margin-r20" />
        <LanguageIcon sx={{ fontSize: 30, marginRight: 1 }} className="white" /> */}
          <span className="text-quicksand700-small white">
            AVEROF 16, 10433 ATHENS
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
