import React, { useState, useEffect } from "react";
import axiosService from "../../utils/axios";

import locationSlice from "../../store/slices/location";
import "./Platform.css";

import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";

import Messages from "../../components/messages/Messages";
import DragII from "../../components/draw-line/DrawLine";
import FinancesDash from "../../components/finances-dash/FinancesDash";
import DragI from "../../components/drag1/Drag1";
import Streamgraph from "../../components/steamgraph/SteamGraph";
import { useAlert } from "react-alert";
const PlatformAdmin = () => {
  const [leadsClicked, setLeadsClicked] = useState(false);
  const [jobsClicked, setJobsClicked] = useState(false);
  const [projectsClicked, setProjectsClicked] = useState(false);
  const [requestsClicked, setRequestsClicked] = useState(false);
  const [newLeads, setNewLeads] = useState<number>();
  const [jobsNeedProposals, setJobsNeedProposals] = useState<number>();
  const [jobsNeedApplicants, setJobsNeedApplicants] = useState<number>();
  const [acceptedJobs, setAcceptedJobs] = useState<number>();
  const [pendingProjects, setPendingProjects] = useState<number>();
  const [activeProjects, setActiveProjects] = useState<number>();
  const [messagesInbox, setMessagesInbox] = useState<Array<any>>([]);
  const [messagesOutbox, setMessagesOutbox] = useState<Array<any>>([]);
  const [contacts, setContacts] = useState<any[]>([]);
  const [selectedLead, setSelectedLead] = useState<any>({});
  const [selectedRequest, setSelectedRequest] = useState<any>({});
  const [selectedJob, setSelectedJob] = useState<any>({});
  const [selectedProject, setSelectedProject] = useState<any>({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();
  const auth = useSelector((state: RootState) => state.auth);
  const [notifications, setNotifications] = React.useState<Array<any>>([]);
  const [filteredNotifications, setFilteredNotifications] = React.useState<
    Array<any>
  >([]);

  useEffect(() => {
    getJobsNeedProposals();
    getJobsNeedApplicants();
    getNewLeads();
    getPendingProjects();
    getActiveProjects();
    getAcceptedJobs();

    getMessages();
    getContacts();
    dispatch(locationSlice.actions.setLocation({ path: "/platform" }));
    if (!auth.account) {
      navigate("/login");
    }
  }, []);

  const getContacts = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_contacts_admin`)
      .then((res) => {
        if (res.data.length > 0) {
          setContacts(res.data);
        }
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  useEffect(() => {
    getMessages();

    const timer = setInterval(() => getMessages(), 360000);
    return () => clearInterval(timer);
  }, [auth]);

  const handleClickLeads = () => {
    setLeadsClicked(!leadsClicked);
    if (!leadsClicked) {
      let tmp = filteredNotifications;
      notifications.forEach((notification: any) => {
        if (notification.group === "LEAD") {
          tmp.push(notification);
        }
      });
      setFilteredNotifications(tmp);
    } else {
      let tmp: any[] = [];
      filteredNotifications.forEach((notification: any) => {
        if (notification.group !== "LEAD") {
          tmp.push(notification);
        }
      });

      setFilteredNotifications(tmp);
    }
  };

  const handleClickProjects = () => {
    setProjectsClicked(!projectsClicked);
    if (!projectsClicked) {
      let tmp = filteredNotifications;
      notifications.forEach((notification: any) => {
        if (notification.group === "PROJECT") {
          tmp.push(notification);
        }
      });
      setFilteredNotifications(tmp);
    } else {
      let tmp: any[] = [];
      filteredNotifications.forEach((notification: any) => {
        if (notification.group !== "PROJECT") {
          tmp.push(notification);
        }
      });
      setFilteredNotifications(tmp);
    }
  };

  const handleClickJobs = () => {
    setJobsClicked(!jobsClicked);
    if (!jobsClicked) {
      let tmp = filteredNotifications;
      notifications.forEach((notification: any) => {
        if (notification.group === "JOB") {
          tmp.push(notification);
        }
      });
      setFilteredNotifications(tmp);
    } else {
      let tmp: any[] = [];
      filteredNotifications.forEach((notification: any) => {
        if (notification.group !== "JOB") {
          tmp.push(notification);
        }
      });
      setFilteredNotifications(tmp);
    }
  };
  const handleClickRequests = () => {
    setRequestsClicked(!requestsClicked);
    if (!requestsClicked) {
      let tmp = filteredNotifications;
      notifications.forEach((notification: any) => {
        if (notification.group === "REQUEST") {
          tmp.push(notification);
        }
      });
      setFilteredNotifications(tmp);
    } else {
      let tmp: any[] = [];
      filteredNotifications.forEach((notification: any) => {
        if (notification.group !== "REQUEST") {
          tmp.push(notification);
        }
      });
      setFilteredNotifications(tmp);
    }
  };
  const getMessages = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_admin_messages`)
      .then((res: any) => {
        if (res.data) {
          let tmpIn: any[] = [];
          let tmpOut: any[] = [];
          res.data.forEach((message: any) => {
            if (message.from_admin || message.from_user.id === auth.account) {
              tmpOut.push(message);
            } else {
              tmpIn.push(message);
            }
          });
          setMessagesInbox(tmpIn);
          setMessagesOutbox(tmpOut);
        } else {
          setMessagesInbox([]);
          setMessagesOutbox([]);
        }
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const getJobsNeedProposals = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_jobs_need_proposals`)
      .then((res) => {
        setJobsNeedProposals(res.data);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  const getJobsNeedApplicants = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_jobs_need_applicants`)
      .then((res) => {
        setJobsNeedApplicants(res.data);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const getAcceptedJobs = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_accepted_jobs`)
      .then((res) => {
        setAcceptedJobs(res.data);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  const getPendingProjects = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_pending_projects`)
      .then((res) => {
        setPendingProjects(res.data);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const getNewLeads = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_pending_leads`)
      .then((res) => {
        setNewLeads(res.data);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const getActiveProjects = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_started_projects`)
      .then((res) => {
        setActiveProjects(res.data);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  return (
    <div className="column-space-between ">
      <div className="dashboard-box-out">
        <div className="dashboard-box">
          <img
            src={require("../../assets/images/line.png")}
            className="dash-line-disappear"
          />

          <div className="dash-row-space-around">
            <div
              className="column-center"
              // onClick={handleClickRequests}
            >
              <strong className="dashboard-text-blue"> New Leads</strong>
              <strong className="dashboard-number">{newLeads}</strong>
            </div>

            <img
              src={require("../../assets/images/line-vertical.png")}
              className="dash-line-between"
            />

            <div
              className="column-center"
              // onClick={handleClickLeads}
            >
              {/* <DragI
                    width={auth.selectedView === "CLIENT" ? 300 : 400}
                    height={100}
                  /> */}

              <strong className="dashboard-text-blue">
                {" "}
                Jobs to add Proposals
              </strong>
              <strong className="dashboard-number">{jobsNeedProposals}</strong>
            </div>

            <img
              src={require("../../assets/images/line-vertical.png")}
              className="dash-line-between"
            />
            <div
              className="column-center"
              // onClick={handleClickLeads}
            >
              {/* <DragI
                    width={auth.selectedView === "CLIENT" ? 300 : 400}
                    height={100}
                  /> */}

              <strong className="dashboard-text-blue">
                {" "}
                Jobs that need Applicants
              </strong>
              <strong className="dashboard-number">{jobsNeedApplicants}</strong>
            </div>

            <img
              src={require("../../assets/images/line-vertical.png")}
              className="dash-line-between"
            />

            <div
              className="column-center"

              // onClick={handleClickJobs}
            >
              <strong className="dashboard-text-blue">
                {" "}
                Jobs ready for Projects
              </strong>
              <strong className="dashboard-number">{acceptedJobs}</strong>
            </div>

            <img
              src={require("../../assets/images/line-vertical.png")}
              className="dash-line-between"
            />

            <div
              className="column-center"
              // onClick={handleClickProjects}
            >
              <strong className="dashboard-text-blue"> Pending Projects</strong>
              <strong className="dashboard-number">{pendingProjects}</strong>
            </div>

            <img
              src={require("../../assets/images/line-vertical.png")}
              className="dash-line-between"
            />

            <div
              className="column-center"
              // onClick={handleClickProjects}
            >
              <strong className="dashboard-text-blue"> Active Projects</strong>
              <strong className="dashboard-number">{activeProjects}</strong>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="  card-finances separate-area">
          <FinancesDash
            leadsClicked={leadsClicked}
            jobsClicked={jobsClicked}
            projectsClicked={projectsClicked}
          />
        </div> */}
      <div className="separate-area">
        <Messages
          contacts={contacts}
          messagesInbox={messagesInbox}
          messagesOutbox={messagesOutbox}
          getMessages={getMessages}
        />
      </div>
    </div>
  );
};

export default PlatformAdmin;
