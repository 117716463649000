import React, { useEffect, useMemo, useState } from "react";
import { BarStack } from "@visx/shape";
import { AnyScaleBand, SeriesPoint } from "@visx/shape/lib/types";
import { Group } from "@visx/group";
import "./CapaJobs.css";
import { Grid } from "@visx/grid";
import { AxisBottom } from "@visx/axis";
import { LinePath } from "@visx/shape";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { timeParse, timeFormat } from "d3-time-format";
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";
import { LegendOrdinal } from "@visx/legend";
import { localPoint } from "@visx/event";
import { scaleLinear as scaleLinearLine } from "@visx/scale";
import { Text } from "@visx/text";
import { RootState } from "../../store";

interface JobData {
  date: string;
  "100% capacity": number;
  Applied: number;
  Simulation: number;
  Won: number;
}
const lineData = [
  [0, 100],
  [10, 100],
  [50, 100],
  [80, 100],
  [150, 100],
  [300, 100],
  [500, 100],
  [800, 100],
];

const padding = { left: 10, right: 10, bottom: 10, top: 10 };

type TypeName = "Won" | "Applied" | "Simulation" | "100% capacity";

type TooltipData = {
  bar: SeriesPoint<JobData>;
  key: TypeName;
  index: number;
  height: number;
  width: number;
  x: number;
  y: number;
  color: string;
};

export type BarStackProps = {
  data: any;
  capaWasSet: boolean;
  lineData: any;
  width: number;
  capa: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  events?: boolean;
};

const purple1 = "#1dd27d";
const purple2 = "#00ddc6";
export const purple3 = "rgb(174, 238, 248)";
export const background = "#eaedff";
const defaultMargin = { top: 40, right: 0, bottom: 0, left: 0 };
const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: "rgba(0,0,0,0.9)",
  color: "white",
  zIndex: 14,
};

let tooltipTimeout: number;

export default function CapaJobsCustom({
  width,
  height,
  events = false,
  margin = defaultMargin,
  data,
  capa,
}: BarStackProps) {
  const getX = (d: any) => d[0];
  const getY = (d: any) => d[1];
  const xs = lineData.map(getX);
  const ys = lineData.map(getY);
  const xScale = scaleLinear({
    domain: [Math.min(...xs) - padding.left, Math.max(...xs) + padding.right],
    range: [0, width],
  });
  const yScale = scaleLinear({
    domain: [Math.min(...ys) - padding.top, Math.max(...ys) + padding.bottom],
    range: [height, 0],
  });

  const x = (d: any) => xScale(getX(d));
  const y = (d: any) => hoursScale(getY(d));

  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<TooltipData>();

  const keys = Object.keys(data[0]).filter((d) => d !== "date") as TypeName[];

  const hoursTotal = data.reduce((allTotals: any, currentDate: any) => {
    const totalHours = keys.reduce((dailyTotal, k) => {
      dailyTotal += Number(currentDate[k]);
      return dailyTotal;
    }, 0);
    allTotals.push(totalHours);
    return allTotals;
  }, [] as number[]);

  const parseDate = timeParse("%Y-%m-%d");
  const format = timeFormat("%b");
  const formatDate: any = (date: string) => format(parseDate(date) as Date);

  // accessors
  const getDate = (d: JobData) => d.date;

  // scales
  const dateScale = scaleBand<string>({
    domain: data.map(getDate),
    padding: 0.2,
  });

  //   const dateScaleLine = scaleBand<string>({
  //     domain: lineData.map(getDate),
  //     padding: 0.2,
  //   });

  const hoursScale = scaleLinear<number>({
    domain: [0, Math.max(...hoursTotal)],
    nice: true,
  });

  const colorScale = scaleOrdinal<TypeName, string>({
    domain: [...keys, "100% capacity"],
    range: [purple1, purple2, purple3, "#ff0000"],
  });

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // TooltipInPortal is rendered in a separate child of <body /> and positioned
    // with page coordinates which should be updated on scroll. consider using
    // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
    scroll: true,
  });
  const xMax = width;
  const yMax = height - margin.top - 100;
  dateScale.rangeRound([0, xMax]);
  hoursScale.range([yMax, 0]);

  return width < 10 ? null : (
    <div className="full-percent-width">
      {dateScale && (
        <div
          style={{ position: "relative", padding: "20px 10px 10px 20px" }}
          className="column-space-between  card-finances full-percent-width"
        >
          <span
            className="text-quicksand700 margin-t20"
            style={{ alignSelf: "flex-start", zIndex: 14 }}
          >
            My Working Capacity
          </span>

          <svg
            ref={containerRef}
            width={"100%"}
            height={height}
            style={{ marginTop: -20, zIndex: 10 }}
          >
            <rect
              x={0}
              y={0}
              width={"100%"}
              height={height}
              fill={"#fff"}
              rx={14}
            />
            <Grid
              top={margin.top}
              left={margin.left}
              xScale={dateScale}
              yScale={hoursScale}
              width={xMax}
              height={yMax}
              stroke="black"
              strokeOpacity={0.1}
              xOffset={10}
            />
            {capa && data.length > 0 ? (
              <Group top={margin.top}>
                <BarStack<JobData, TypeName>
                  data={data}
                  keys={keys}
                  x={getDate}
                  xScale={dateScale}
                  yScale={hoursScale}
                  color={colorScale}
                >
                  {(barStacks) =>
                    barStacks.map((barStack) =>
                      barStack.bars.map((bar) => (
                        <rect
                          key={`bar-stack-${barStack.index}-${bar.index}`}
                          x={bar.x}
                          y={bar.y}
                          height={bar.height}
                          width={bar.width}
                          fill={bar.color}
                          onClick={() => {
                            if (events)
                              alert(`clicked: ${JSON.stringify(bar)}`);
                          }}
                          onMouseLeave={() => {
                            tooltipTimeout = window.setTimeout(() => {
                              hideTooltip();
                            }, 300);
                          }}
                          onMouseMove={(event) => {
                            if (tooltipTimeout) clearTimeout(tooltipTimeout);
                            // TooltipInPortal expects coordinates to be relative to containerRef
                            // localPoint returns coordinates relative to the nearest SVG, which
                            // is what containerRef is set to in this example.
                            const eventSvgCoords = localPoint(event);
                            const left = bar.x + bar.width / 2;
                            showTooltip({
                              tooltipData: bar,
                              tooltipTop: eventSvgCoords?.y,
                              tooltipLeft: left,
                            });
                          }}
                        />
                      ))
                    )
                  }
                </BarStack>

                {/* <rect
                  x={width - 120}
                  y={hoursScale(lineData.map(getY)[0]) - 70}
                  width={120}
                  height={70}
                  fill={"lighgrey"}
                  rx={14}
                />
                <Text
                  width={width}
                  fill={"red"}
                  fontWeight={"bold"}
                  verticalAnchor="end"
                  x={width - 50}
                  y={hoursScale(lineData.map(getY)[0]) - 50}
                >
                  {"100%"}
                </Text>

                <Text
                  fontWeight={"bold"}
                  width={width}
                  fill={"red"}
                  verticalAnchor="end"
                  x={width - 110}
                  y={hoursScale(lineData.map(getY)[0]) - 20}
                >
                  {"(" + capa + " hrs/week)"}
                </Text> */}

                <LinePath
                  data={lineData}
                  x={x}
                  y={y}
                  stroke="red"
                  strokeWidth={4}
                  shapeRendering="geometricPrecision"
                  strokeLinecap="butt"
                  strokeLinejoin="round"
                />
              </Group>
            ) : (
              <foreignObject
                x={0}
                y={height / 2 - 80}
                width={width}
                height={height / 5}
              >
                <p
                  className="text-quicksand700 row-center full-percent-height"
                  style={{ textAlign: "center", padding: 5, opacity: 0.5 }}
                  data-xmlns="http://www.w3.org/1999/xhtml"
                >
                  Set your working hours in your profile and visualize your
                  capacity.View your commitment to your applied and won jobs or
                  simulate a job to see if it fits in your schedule.
                </p>
              </foreignObject>
            )}

            <AxisBottom
              top={yMax + margin.top}
              scale={dateScale}
              tickFormat={formatDate}
              stroke={"black"}
              tickStroke={"black"}
              tickLabelProps={() => ({
                fill: "black",
                fontSize: 11,
                textAnchor: "middle",
              })}
            />
          </svg>
          <div
            style={{
              position: "absolute",
              top: margin.top / 2 - 10,
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              fontSize: "14px",
              zIndex: 14,
            }}
          >
            <LegendOrdinal
              scale={colorScale}
              direction="row"
              labelMargin="0 15px 0 0"
            />
            {window.innerWidth > 500 && (
              <foreignObject
              // x={0}
              // y={height / 2 - 80}
              // width={width}
              // height={height / 5}
              >
                <p
                  style={{ marginLeft: -10 }}
                  data-xmlns="http://www.w3.org/1999/xhtml"
                >
                  ({capa} hrs/week)
                </p>
              </foreignObject>
            )}
          </div>

          {tooltipOpen && tooltipData && (
            <TooltipInPortal
              top={tooltipTop}
              left={tooltipLeft}
              style={tooltipStyles}
            >
              <div style={{ color: colorScale(tooltipData.key), zIndex: 14 }}>
                <strong>{tooltipData.key}</strong>
              </div>
              <div>
                {Math.trunc(tooltipData.bar.data[tooltipData.key])} % Capacity (
                {Math.trunc(
                  (tooltipData.bar.data[tooltipData.key] / 100) * capa
                )}{" "}
                hours per week){" "}
              </div>
              <div>
                <small>{formatDate(getDate(tooltipData.bar.data))}</small>
              </div>
            </TooltipInPortal>
          )}
        </div>
      )}
    </div>
  );
}
