import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import "./BlogDetail.css";

interface Blog {
  id: number;
  createdAt: string;
  title: string;
  content: string;
  keywords: string;
  image_preview_url?: string;
  main_image_url?: string;
}

const BlogDetail: React.FC = () => {
  const { blog_id } = useParams<{ blog_id: string }>();
  const [blog, setBlog] = useState<Blog | null>(null);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/get_blog_details/${blog_id}`)
      .then((response) => {
        setBlog(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the blog!", error);
      });
  }, [blog_id]);

  if (!blog) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container ">
      <Helmet>
        <title>{blog.title} - Gyld Blog</title>
        <meta name="description" content={blog.content.slice(0, 160)} />
        <meta name="keywords" content={blog.keywords} />
        <link rel="canonical" href={`https://gyldhub.com/blogs/${blog.id}`} />
      </Helmet>
      <h1 className="home-title">{blog.title}</h1>
      {blog.main_image_url && (
        <div className="column-center">
          <img
            src={
              process.env.REACT_APP_API_URL?.split("/api")[0] +
              blog.main_image_url
            }
            alt={blog.title}
            className="blog-main-image"
          />
        </div>
      )}
      <div className="blog-content">
        <span
          className="text-quicksand darkgrey text-align-left"
          dangerouslySetInnerHTML={{ __html: blog.content }}
        />
      </div>
    </div>
  );
};

export default BlogDetail;
