import { useEffect, useState } from "react";
import "./AddLead.css";
import PropTypes from "prop-types";
import axiosService from "../../utils/axios";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  Input,
  InputAdornment,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { useAlert } from "react-alert";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import DeleteIcon from "@mui/icons-material/Delete";
import { borderRadius } from "@mui/system";

import Modal from "@mui/material/Modal";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "moment/locale/en-gb";
import Tooltip from "@mui/material/Tooltip";
import UndoIcon from "@mui/icons-material/Undo";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddLead = (props: any) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageSkills, setErrorMessageSkills] = useState("");

  const [show, setShow] = useState(false);
  const [nameOfClient, setNameOfClient] = useState("");
  const [totalCost, setTotalCost] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [customerType, setCustomerType] = useState("");
  const [industry, setIndustry] = useState("");
  const [duration, setDuration] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [hotSkills, setHotSkills] = useState<any[]>([]);

  const [description, setDescription] = useState("");
  const [skills, setSkills] = useState<Array<string>>([]);
  const [devSkills, setDevSkills] = useState<string[]>([]);
  const alert = useAlert();
  const [users, setUsers] = useState<any[]>([]);
  const [selectedUser, setSelectedUser] = useState<any>("");

  const [devHours, setDevHours] = useState("");
  const [devLevel, setDevLevel] = useState("");
  const [devDuration, setDevDuration] = useState(0);
  const [devNumber, setDevNumber] = useState(0);
  const [devStartDate, setDevStartDate] = useState("");
  const [isFirst, setIsFirst] = useState(true);
  const [selectedDevs, setSelectedDevs] = useState<any[]>([]);
  const auth = useSelector((state: RootState) => state.auth);

  const junior: number = parseInt(
    process.env.JUNIOR_PRICE ? process.env.JUNIOR_PRICE : "30"
  );
  const mid: number = parseInt(
    process.env.MID_PRICE ? process.env.MID_PRICE : "40"
  );
  const senior: number = parseInt(
    process.env.SENIOR_PRICE ? process.env.SENIOR_PRICE : "50"
  );

  const closeHandler = (e: any) => {
    setShow(false);
    props.onClose(false);
  };
  useEffect(() => {
    if (isFirst) {
      getSkills();
      setIsFirst(false);
    }
  }, []);
  useEffect(() => {
    calculateProps();
  }, [hotSkills]);
  useEffect(() => {
    console.log(selectedUser);
  }, [selectedUser]);

  useEffect(() => {
    if (nameOfClient && nameOfClient.length >= 50) {
      setErrorMessage("The name can't exceed 50 characters");
    }
  }, [nameOfClient]);

  useEffect(() => {
    if (nameOfClient && nameOfClient.length < 50 && errorMessage) {
      setErrorMessage("");
    }
  }, [nameOfClient, errorMessage]);

  useEffect(() => {
    if (devSkills && devSkills.length > 6) {
      setErrorMessageSkills("You can choose up to 6 skills");
    }
  }, [devSkills]);

  useEffect(() => {
    if (devSkills && devSkills.length <= 6 && errorMessageSkills) {
      setErrorMessageSkills("");
    }
  }, [devSkills, errorMessageSkills]);

  const getUsers = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_users`)
      .then((res) => {
        setUsers(res.data);
        res.data.forEach((user: any) => {
          if (props.lead?.user_id_id === user.id) {
            setSelectedUser(user.username);
            console.log("HERE", user.username);
          }
        });
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        closeHandler(true);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  const calculateProps = () => {
    if (props.lead) {
      let tmpCost = 0;
      setNameOfClient(props.lead.name);
      setDescription(props.lead.description);
      setCustomerEmail(props.lead.email_contact);
      setCustomerType(props.lead.customer_type);
      setIndustry(props.lead.industry);
      setStartDate(props.lead.start_date);
      setDuration(props.lead.duration);
      let tmpSelectedDevs: any[] = [];
      props.lead.devs?.forEach((dev: any) => {
        let cost = 0;
        let daily = 0;
        let extra = 0;
        dev.skills.forEach((skill: string) => {
          hotSkills.forEach((hotSkill: any) => {
            if (hotSkill.name === skill) {
              extra += hotSkill.price_markup;
            }
          });
        });
        let dur = typeof dev.duration === "number" ? dev.duration : 6;
        if (typeof parseInt(dev.duration) === "number") {
          dur = parseInt(dev.duration);
        }

        if (dev.developer_level === "JUNIOR") {
          if (!dev.dev_hours || dev.dev_hours === "FULL_TIME") {
            cost = dev.number_of_devs * 8 * dur * (junior + extra);
            daily = dev.number_of_devs * 8 * (junior + extra);
          } else if (dev.dev_hours === "PART_TIME") {
            cost = dev.number_of_devs * 4 * dur * (junior + extra);
            daily = dev.number_of_devs * 4 * (junior + extra);
          }
        } else if (dev.developer_level === "MID") {
          if (!dev.dev_hours || dev.dev_hours === "FULL_TIME") {
            cost = dev.number_of_devs * 8 * dur * (mid + extra);
            daily = dev.number_of_devs * 8 * (mid + extra);
          } else if (dev.dev_hours === "PART_TIME") {
            cost = dev.number_of_devs * 4 * dur * (mid + extra);
            daily = dev.number_of_devs * 4 * (mid + extra);
          }
        } else if (dev.developer_level === "SENIOR") {
          if (!dev.dev_hours || dev.dev_hours === "FULL_TIME") {
            cost = dev.number_of_devs * 8 * dur * (senior + extra);
            daily = dev.number_of_devs * 8 * (senior + extra);
          } else if (dev.dev_hours === "PART_TIME") {
            cost = dev.number_of_devs * 4 * dur * (senior + extra);
            daily = dev.number_of_devs * 4 * (senior + extra);
          }
        }

        let tmp = {
          skills: dev.skills,
          duration: dur,
          developer_level: dev.developer_level,
          number_of_devs: dev.number_of_devs,
          start_date: dev.start_date,
          dev_hours: dev.dev_hours,
          cost: cost,
          daily: daily,
          extra: extra,
        };
        tmpSelectedDevs.push(tmp);
        tmpCost += cost;
      });
      setTotalCost(tmpCost);
      setSelectedDevs(tmpSelectedDevs);
    } else {
      // setSelectedDevs([]);
      setDevSkills([]);
      setDevDuration(0);
      setDevLevel("");
      setDevNumber(0);
      setDevStartDate("");
      setCustomerEmail("");
      setCustomerType("");
      setNameOfClient("");
      setStartDate("");

      setIndustry("");
      setDuration("");
      setDescription("");
    }
    if (auth.selectedView === "GYLD MANAGER") {
      getUsers();
    }
  };

  const addLead = () => {
    let tmp_devs: any[] = [];
    selectedDevs.forEach((dev: any) => {
      let tmp = {
        skills: dev.skills,
        duration: dev.duration,
        developer_level: dev.developer_level,
        number_of_devs: dev.number_of_devs,
        start_date: moment(dev.start_date).format("YYYY-MM-DD").toString(),
        dev_hours: dev.dev_hours,
      };
      tmp_devs.push(tmp);
    });

    if (props.lead && props.lead.id) {
      let body = {
        id: props.lead.id,
        user_id: props.lead.user_id,
        name: nameOfClient,
        description: description,
        email_contact: customerEmail,
        customer_type: customerType,
        industry: industry,
        start_date: moment(startDate).format("YYYY-MM-DD").toString(),
        duration: duration,
        devs: tmp_devs,
        status: "PENDING",
      };
      axiosService
        .post(`${process.env.REACT_APP_API_URL}/edit_lead`, body)
        .then((res) => {
          alert.success("The Lead was updated");
          props.onClose(true);
        })

        .catch((err) => {
          alert.error(err.message);

          console.log(err.message);
        });
    } else {
      let leadProvider = -1;
      users.forEach((user: any) => {
        if (user.username === selectedUser) {
          leadProvider = user.id;
        }
      });
      let body = {
        user_id: leadProvider > 0 ? leadProvider : auth.account,
        name: "Lead: " + nameOfClient,
        description: description,
        email_contact: customerEmail,
        customer_type: customerType,
        industry: industry,
        start_date: moment(startDate).format("YYYY-MM-DD").toString(),
        duration: duration,
        devs: tmp_devs,
        status: "PENDING",
      };
      axiosService
        .post(`${process.env.REACT_APP_API_URL}/add_lead`, body)
        .then((res) => {
          alert.success("The Lead was added");
          props.onClose(true);
        })

        .catch((err) => {
          if (err.response?.status !== 500) {
            if (err.response) {
              Object.keys(err.response.data).forEach(function (key, index) {
                alert.error(key + ":" + err.response.data[key]);
              });
            } else {
              alert.error(err.message);
            }
          }

          console.log(err.message);
        });
    }
  };

  useEffect(() => {
    setShow(props.show);
    console.log(props.show);
  }, [props.show]);

  const handleChangeDevSkillsMobile = (
    event: SelectChangeEvent<typeof devSkills>
  ) => {
    const {
      target: { value },
    } = event;
    setDevSkills(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangeDevSkills = (skill: string) => {
    if (devSkills.includes(skill)) {
      setDevSkills(
        devSkills.filter((name: string) => {
          return name !== skill;
        })
      );
    } else {
      setDevSkills([...devSkills, skill]);
    }
  };
  const removeSelectedDev = (index: number) => {
    let tmp = [...selectedDevs];
    tmp.splice(index, 1);
    setTotalCost(totalCost - selectedDevs[index].cost);
    setSelectedDevs(tmp);
  };

  const reset = () => {
    setSelectedDevs([]);
    setDevSkills([]);
    setDevDuration(0);
    setDevLevel("");
    setDevNumber(0);
    setDevStartDate("");
    setCustomerEmail("");
    setCustomerType("");
    setNameOfClient("");
    setStartDate("");
    setTotalCost(0);
    setIndustry("");
    setDuration("");
    setDescription("");
  };
  const handleAddDeveloper = () => {
    let cost = 0;
    let daily = 0;
    let extra = 0;
    devSkills.forEach((skill: string) => {
      hotSkills.forEach((hotSkill: any) => {
        if (hotSkill.name === skill) {
          extra += hotSkill.price_markup;
        }
      });
    });
    if (devLevel === "JUNIOR") {
      if (devHours === "FULL_TIME") {
        if (devDuration !== null) {
          cost = devNumber * 8 * devDuration * (junior + extra);
          daily = devNumber * 8 * (junior + extra);
        }
      } else if (devHours === "PART_TIME") {
        if (devDuration !== null) {
          cost = devNumber * 4 * devDuration * (junior + extra);
          daily = devNumber * 4 * (junior + extra);
        }
      }
    } else if (devLevel === "MID") {
      if (devHours === "FULL_TIME") {
        if (devDuration !== null) {
          cost = devNumber * 8 * devDuration * (mid + extra);
          daily = devNumber * 8 * (mid + extra);
        }
      } else if (devHours === "PART_TIME") {
        if (devDuration !== null) {
          cost = devNumber * 4 * devDuration * (mid + extra);
          daily = devNumber * 4 * (mid + extra);
        }
      }
    } else if (devLevel === "SENIOR") {
      if (devHours === "FULL_TIME") {
        if (devDuration !== null) {
          cost = devNumber * 8 * devDuration * (senior + extra);
          daily = devNumber * 8 * (senior + extra);
        }
      } else if (devHours === "PART_TIME") {
        if (devDuration !== null) {
          cost = devNumber * 4 * devDuration * (senior + extra);
          daily = devNumber * 4 * (senior + extra);
        }
      }
    }
    let tmp = {
      skills: devSkills,
      duration: devDuration,
      developer_level: devLevel,
      number_of_devs: devNumber,
      start_date: devStartDate,
      dev_hours: devHours,
      cost: cost,
      daily: daily,
      extra: extra,
    };
    setTotalCost(totalCost + cost);
    let tmpSelectedDevs = selectedDevs;
    tmpSelectedDevs.push(tmp);
    setSelectedDevs(tmpSelectedDevs);
    setDevSkills([]);
    setDevDuration(0);
    setDevLevel("");
    setDevNumber(0);
    setDevStartDate("");
    setDevHours("");
  };
  const getSkills = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_skills`)
      .then((res) => {
        let tmpSkills: any[] = [];
        let tmpHotSkills: any[] = [];
        if (res.data.length > 0) {
          res.data.forEach((data: any) => {
            tmpSkills.push(data.name);
            if (data.is_hot_skill) {
              tmpHotSkills.push(data);
            }
          });

          setSkills(tmpSkills);
          setHotSkills(tmpHotSkills);
        }
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  return (
    // <div
    //   style={{
    //     visibility: show ? "visible" : "hidden",
    //     opacity: show ? "1" : "0",
    //   }}
    //   className={"overlay"}
    // >
    <div>
      {window.innerWidth >= 800 ? (
        <Modal
          open={show}
          onClose={closeHandler}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className={"popup-leads"}>
            {" "}
            <div className="row-space-between">
              <div className="row-start">
                <span className="text-quicksand700 margin-up-down">
                  Lead Configurator
                </span>
                <Tooltip title="Reset" placement="bottom" arrow>
                  <UndoIcon
                    className="add-lead-reload-icon blue pointer"
                    onClick={reset}
                  />
                </Tooltip>
              </div>
              <span
                className={"close-add-lead margin-right-small"}
                onClick={closeHandler}
              >
                &times;
              </span>
            </div>
            <div className="add-lead-scroll">
              <div className="lead-configurator-top">
                {auth.selectedView === "GYLD MANAGER" && (
                  <Box className="margin-up-down lead-configurator-width ">
                    <FormControl fullWidth>
                      <Autocomplete
                        id="lead-provider"
                        options={users}
                        autoHighlight
                        getOptionLabel={(option) => option.username}
                        // onChange={(event: any, newValue: string | null) => {
                        //   console.log(newValue);
                        // }}

                        value={selectedUser.username}
                        inputValue={selectedUser || ""}
                        onInputChange={(event: any, newInputValue) => {
                          if (newInputValue !== "") {
                            setSelectedUser(newInputValue);
                          }
                        }}
                        renderOption={(propsComp, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...propsComp}
                          >
                            <div>{option.username}</div>
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Lead Provider"
                            inputProps={{
                              ...params.inputProps,
                              shrink: true,
                              defaultValue: selectedUser,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Box>
                )}
                <div className="column-start-start full-percent-width">
                  <Box className="margin-up-down lead-configurator-width">
                    <FormControl fullWidth>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        label="Title of Lead"
                        id="outlined-basic"
                        type="text"
                        error={nameOfClient?.length >= 50}
                        inputProps={{ maxLength: 50 }}
                        helperText={errorMessage}
                        value={nameOfClient}
                        onChange={(event) =>
                          setNameOfClient(event.target.value)
                        }
                      />
                    </FormControl>
                  </Box>
                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    adapterLocale="en-gb"
                  >
                    <Box className="margin-up-down lead-configurator-width">
                      <FormControl fullWidth>
                        <DatePicker
                          inputFormat="DD/MM/YYYY"
                          value={startDate || null}
                          onChange={(newValue) => {
                            if (newValue) {
                              setStartDate(newValue?.toString());
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputLabelProps={{ shrink: true }}
                              label="Start Date"
                            />
                          )}
                        />

                        {/* <TextField
                        InputLabelProps={{ shrink: true }}
                        type="date"
                        helperText=""
                        label="Start Date"
                        id="demo-simple-select"
                        value={startDate}
                        InputProps={{
                          inputComponent: InputComponentDatePicker,
                        }}
                        onChange={(event) => setStartDate(event.target.value)}
                      /> */}
                      </FormControl>
                    </Box>
                  </LocalizationProvider>
                </div>

                <div className="column-start-start full-percent-width">
                  <Box className="margin-up-down lead-configurator-width">
                    <FormControl fullWidth>
                      <InputLabel shrink={true} id="demo-simple-select-label">
                        Customer Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={customerType || ""}
                        label="SelectedProject"
                        onChange={(event) =>
                          setCustomerType(event.target.value)
                        }
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Customer Type"
                            notched
                          />
                        }
                      >
                        <MenuItem value={"COMPANY"}>Corporate</MenuItem>
                        <MenuItem value={"PRIVATE"}>Private</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box className="margin-up-down lead-configurator-width">
                    <FormControl fullWidth>
                      <InputLabel shrink={true} id="demo-simple-select-label">
                        Industry
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={industry || ""}
                        label="SelectedProject"
                        onChange={(event) => setIndustry(event.target.value)}
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Industry"
                            notched
                          />
                        }
                      >
                        <MenuItem value={"TECH"}>Tech</MenuItem>
                        <MenuItem value={"RETAIL"}>Retail</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
                <div className="column-start-start full-percent-width">
                  <Box className="margin-up-down lead-configurator-width">
                    <FormControl fullWidth>
                      <InputLabel shrink={true} id="demo-simple-select-label">
                        Length of Project
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={duration || ""}
                        label="SelectedProject"
                        onChange={(event) => setDuration(event.target.value)}
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Length of Project"
                            notched
                          />
                        }
                      >
                        <MenuItem value={"ONE_TO_THREE"}>1-3 Months</MenuItem>
                        <MenuItem value={"THREE_TO_SIX"}>3-6 Months</MenuItem>
                        <MenuItem value={"OVER_SIX"}>Over 6 Months</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box className="margin-up-down lead-configurator-width">
                    <FormControl fullWidth>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        id="outlined-basic"
                        label=" Customer Email"
                        type="text"
                        value={customerEmail}
                        onChange={(event) =>
                          setCustomerEmail(event.target.value)
                        }
                      />
                    </FormControl>
                  </Box>
                </div>

                <Box className="margin-up-down lead-configurator-width">
                  <FormControl fullWidth>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      id="outlined-basic"
                      multiline
                      // minRows={1}
                      // maxRows={4}
                      rows={1.25}
                      label="Description"
                      value={description || ""}
                      onChange={(event) => setDescription(event.target.value)}
                    />
                  </FormControl>
                </Box>

                {/* <button className="reset-button" onClick={reset}>
                Reset
              </button> */}
              </div>

              <div className="lead-configurator-grey-bottom">
                <div className="lead-configurator-bottom-left">
                  <span className="text-quicksand700-small padding-b10">
                    Specifications of the Developers
                  </span>
                  {/* <div className="row-space-between"> */}
                  <Box className="margin-up-down lead-configurator-width-small">
                    <FormControl fullWidth>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        label="Number of Developers"
                        id="demo-simple-select"
                        InputProps={{ inputProps: { min: 0 } }}
                        value={devNumber}
                        onChange={(event) =>
                          setDevNumber(parseInt(event.target.value))
                        }
                      />
                    </FormControl>
                  </Box>
                  <Box className="margin-up-down lead-configurator-width-small">
                    <FormControl fullWidth>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        id="demo-simple-select-label-duration"
                        label=" Project Days"
                        InputProps={{ inputProps: { min: 0 } }}
                        value={devDuration}
                        onChange={(event) =>
                          setDevDuration(parseInt(event.target.value))
                        }
                      />
                    </FormControl>
                  </Box>
                  {/* </div>
                  <div className="row-space-between"> */}
                  <Box className="margin-up-down lead-configurator-width-small">
                    <FormControl fullWidth>
                      <InputLabel shrink={true} id="demo-simple-select-label">
                        Availability Needed
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={devHours || ""}
                        label="SelectedProject"
                        onChange={(event) => setDevHours(event.target.value)}
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Availability Needed"
                            notched
                          />
                        }
                      >
                        <MenuItem value={"FULL_TIME"}>
                          Full Time (40 hours/week)
                        </MenuItem>
                        <MenuItem value={"PART_TIME"}>
                          Part Time (20 hours/week)
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                  {/* <span className="text-quicksand700-small margin-up-down">
                  What skills do you need the developer to have?
                </span> */}

                  <Box className="margin-up-down lead-configurator-width-small">
                    <FormControl fullWidth>
                      <InputLabel
                        shrink={true}
                        id="demo-simple-select-label-level"
                      >
                        Developer Level
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label-level"
                        id="demo-simple-select-label-level"
                        value={devLevel || ""}
                        label="SelectedProject"
                        onChange={(event) => setDevLevel(event.target.value)}
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Developer Level"
                            notched
                          />
                        }
                      >
                        <MenuItem value={"JUNIOR"}>Junior</MenuItem>
                        <MenuItem value={"MID"}>Mid</MenuItem>
                        <MenuItem value={"SENIOR"}>Senior</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  {/* </div>

                  <div className="row-space-between"> */}
                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    adapterLocale="en-gb"
                  >
                    <Box className="margin-up-down lead-configurator-width-small">
                      <FormControl fullWidth>
                        <DatePicker
                          inputFormat="DD/MM/YYYY"
                          value={devStartDate || null}
                          onChange={(newValue) => {
                            if (newValue) {
                              setDevStartDate(newValue?.toString());
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputLabelProps={{ shrink: true }}
                              label="Dev Start Date"
                            />
                          )}
                        />

                        {/* <TextField
                        InputLabelProps={{ shrink: true }}
                        type="date"
                        label="Dev Start Date"
                        id="demo-simple-select"
                        value={devStartDate}
                        InputProps={{
                          inputComponent: InputComponentDatePicker,
                        }}
                        onChange={(event) =>
                          setDevStartDate(event.target.value)
                        }
                      /> */}
                      </FormControl>
                    </Box>
                  </LocalizationProvider>
                  <Box className="margin-t10 lead-configurator-width-small ">
                    <FormControl fullWidth>
                      <Autocomplete
                        sx={{ minHeight: 70 }}
                        multiple
                        clearIcon={null}
                        popupIcon={null}
                        disableCloseOnSelect={true}
                        id="multiple-limit-tags"
                        options={skills}
                        getOptionLabel={(option) => option}
                        value={[]}
                        disablePortal={true}
                        onChange={(event: any, newValue: string[]) => {
                          if (
                            devSkills.length <= 6 ||
                            devSkills.includes(newValue[0])
                          ) {
                            handleChangeDevSkills(newValue[0]);
                          }
                        }}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 7 }}
                              checked={devSkills.includes(option)}
                            />
                            {option}
                            {hotSkills.map((hotSkill: any) => {
                              if (hotSkill.name === option) {
                                return (
                                  <WhatshotIcon
                                    key={hotSkill.name}
                                    style={{
                                      color: "#ff6210",
                                      marginBottom: 5,
                                    }}
                                  />
                                );
                              }
                            })}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={devSkills?.length > 6}
                            helperText={errorMessageSkills}
                            label="Choose Developer Skills"
                            InputLabelProps={{ shrink: true }}
                          />
                        )}

                        // sx={{ maxHeight: "43px" }}
                      />
                    </FormControl>
                  </Box>
                  <div className="skill-chips">
                    {devSkills.map((skill: any) => {
                      return (
                        <>
                          <Chip
                            size="small"
                            key={skill}
                            label={skill}
                            onDelete={(newValue: string[]) => {
                              handleChangeDevSkills(skill);
                            }}
                          />
                        </>
                      );
                    })}
                  </div>

                  {/* </div> */}

                  <button
                    className="text-quicksand700-small orange  margin-up-down"
                    style={{
                      opacity:
                        devNumber === 0 ||
                        devHours === "" ||
                        devLevel === "" ||
                        devStartDate === "" ||
                        devDuration === 0 ||
                        devSkills.length === 0
                          ? 0.4
                          : 1,
                    }}
                    onClick={handleAddDeveloper}
                    disabled={
                      devNumber === 0 ||
                      devHours === "" ||
                      devLevel === "" ||
                      devStartDate === "" ||
                      devDuration === 0 ||
                      devSkills.length === 0 ||
                      errorMessageSkills !== ""
                    }
                  >
                    + Add Developer and get Estimate
                  </button>
                </div>

                <div className="lead-configurator-bottom-right">
                  <span className="text-quicksand700 align-self-center">
                    Resources:
                  </span>
                  <div className="add-lead-column-list-scroll">
                    {selectedDevs.length > 0 &&
                      selectedDevs.map((dev: any, i: number) => {
                        return (
                          <div className="add-lead-list-row-sb" key={i}>
                            <div className="column-start-start">
                              <div className="row-start text-quicksand-small margin-b5">
                                <span className="text-quicksand700-small">
                                  {dev.number_of_devs +
                                    " " +
                                    dev.developer_level +
                                    " Developer" +
                                    (parseInt(dev.number_of_devs) > 1
                                      ? "s"
                                      : "") +
                                    " for " +
                                    dev.duration +
                                    " Day" +
                                    (parseInt(dev.duration) > 1 ? "s" : "") +
                                    ", starting on " +
                                    moment(dev.start_date).format("D MMM yy")}
                                </span>
                              </div>
                              <div className="row-start margin-b5">
                                <span className="text-quicksand-small">
                                  Skills:
                                </span>
                                <div className="row-start-start wrap ">
                                  {dev.skills.map(
                                    (skill: string, j: number) => {
                                      return (
                                        <span
                                          key={skill}
                                          className="text-quicksand-small darkgrey"
                                        >
                                          &nbsp;{skill}
                                          {hotSkills.map((hotSkill: any) => {
                                            if (hotSkill.name === skill) {
                                              return (
                                                <WhatshotIcon
                                                  key={hotSkill.name}
                                                  style={{
                                                    color: "#ff6210",
                                                    fontSize: 18,
                                                    position: "relative",
                                                    bottom: 3,
                                                  }}
                                                />
                                              );
                                            }
                                          })}
                                          {j < dev.skills.length - 1
                                            ? " ,"
                                            : ""}{" "}
                                        </span>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                              <br />
                              <br />

                              {/* <div className="column-flex-start"> */}
                              <div className="row-start text-quicksand-small">
                                <span className="text-quicksand-small margin-r3">
                                  Daily Cost:
                                </span>
                                <span className="text-quicksand-small darkgrey">
                                  {dev.number_of_devs +
                                    " Developer" +
                                    (parseInt(dev.number_of_devs) > 1
                                      ? "s x"
                                      : " x")}{" "}
                                  {dev.extra !== 0 ? " ( " : ""}
                                  {dev.developer_level === "JUNIOR"
                                    ? junior
                                    : dev.developer_level === "MID"
                                    ? mid
                                    : senior}
                                  €&nbsp;
                                  {dev.extra !== 0
                                    ? "+ " + dev.extra.toString() + "€ )"
                                    : ""}{" "}
                                  x&nbsp;
                                  {dev.dev_hours === "FULL_TIME" ? 8 : 4}
                                  &nbsp;hours&nbsp;= {dev.daily}€
                                </span>{" "}
                              </div>
                              <div className="row-start text-quicksand700-small padding-t1p">
                                <span className="margin-r3">Total Cost:</span>
                                <span> {dev.cost}€</span>
                              </div>
                              {/* </div> */}
                            </div>
                            <DeleteIcon
                              className="align-self-end pointer"
                              onClick={() => removeSelectedDev(i)}
                            />
                          </div>
                        );
                      })}
                  </div>

                  {totalCost > 0 &&
                    (auth.selectedView === "LEAD PROVIDER" ? (
                      <span className="lead-total-cost padding-t10">
                        TOTAL PROJECT COST: {totalCost}€ , TOTAL LEAD SHARE:{" "}
                        {totalCost * 0.25 * 0.1}€
                      </span>
                    ) : (
                      <span className="lead-total-cost padding-t10">
                        TOTAL LEAD COST: {totalCost}€
                      </span>
                    ))}
                </div>
              </div>
              <div className="row-flex-end-end padding-t20">
                <button
                  className="button-white-small text-quicksand700-small margin-r20"
                  onClick={() => props.onClose(true)}
                >
                  {" "}
                  Cancel
                </button>
                <button
                  className="button-black-small-nm text-quicksand700-small"
                  onClick={addLead}
                >
                  {" "}
                  Save Lead
                </button>
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        <Modal
          open={show}
          onClose={closeHandler}
          aria-labelledby="mobile-modal-modal-title"
          aria-describedby="mobile-modal-modal-description"
        >
          <div className={"mob-popup-leads"}>
            <div className="row-space-between-center padding-b10">
              <div className="row-start">
                <span className="text-quicksand700">Lead Configurator</span>
                <Tooltip title="Reset" placement="bottom" arrow>
                  <UndoIcon
                    className="add-lead-reload-icon blue pointer"
                    onClick={reset}
                  />
                </Tooltip>
              </div>
              <span className={"close-add-lead"} onClick={closeHandler}>
                &times;
              </span>
            </div>
            <div className="mob-add-lead-scroll">
              <div className="leads-mob-row-to-column">
                <Box className="leads-half-width margin-up-down">
                  <FormControl fullWidth>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      label=" Name"
                      id="outlined-basic"
                      type="text"
                      value={nameOfClient}
                      onChange={(event) => setNameOfClient(event.target.value)}
                    />
                  </FormControl>
                </Box>
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  adapterLocale="en-gb"
                >
                  <Box className="leads-half-width margin-up-down">
                    <FormControl fullWidth>
                      <DatePicker
                        inputFormat="DD/MM/YYYY"
                        value={startDate || null}
                        onChange={(newValue) => {
                          if (newValue) {
                            setStartDate(newValue?.toString());
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{ shrink: true }}
                            label="Start Date"
                          />
                        )}
                      />
                      {/* <TextField
                      InputLabelProps={{ shrink: true }}
                      type="date"
                      helperText=""
                      label="Start Date"
                      id="demo-simple-select"
                      InputProps={{
                        inputComponent: InputComponentDatePicker,
                      }}
                      value={startDate}
                      onChange={(event) => setStartDate(event.target.value)}
                    /> */}
                    </FormControl>
                  </Box>
                </LocalizationProvider>
              </div>
              <div className="leads-mob-row-to-column">
                <Box className="leads-half-width margin-up-down">
                  <FormControl fullWidth>
                    <InputLabel shrink={true} id="demo-simple-select-label">
                      Customer Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={customerType || ""}
                      label="SelectedProject"
                      onChange={(event) => setCustomerType(event.target.value)}
                      input={
                        <OutlinedInput
                          id="select-multiple-chip"
                          label="Customer Type"
                          notched
                        />
                      }
                    >
                      <MenuItem value={"COMPANY"}>Corporate</MenuItem>
                      <MenuItem value={"PRIVATE"}>Private</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box className="leads-half-width margin-up-down">
                  <FormControl fullWidth>
                    <InputLabel shrink={true} id="demo-simple-select-label">
                      Industry
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={industry || ""}
                      label="SelectedProject"
                      onChange={(event) => setIndustry(event.target.value)}
                      input={
                        <OutlinedInput
                          id="select-multiple-chip"
                          label="Industry"
                          notched
                        />
                      }
                    >
                      <MenuItem value={"TECH"}>Tech</MenuItem>
                      <MenuItem value={"RETAIL"}>Retail</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div className="leads-mob-row-to-column">
                <Box className="leads-half-width margin-up-down">
                  <FormControl fullWidth>
                    <InputLabel shrink={true} id="demo-simple-select-label">
                      Length of Project
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={duration || ""}
                      label="SelectedProject"
                      onChange={(event) => setDuration(event.target.value)}
                      input={
                        <OutlinedInput
                          id="select-multiple-chip"
                          label="Length of Project"
                          notched
                        />
                      }
                    >
                      <MenuItem value={"ONE_TO_THREE"}>1-3 Months</MenuItem>
                      <MenuItem value={"THREE_TO_SIX"}>3-6 Months</MenuItem>
                      <MenuItem value={"OVER_SIX"}>Over 6 Months</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box className="leads-half-width margin-up-down">
                  <FormControl fullWidth>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      id="outlined-basic"
                      label=" Customer Email"
                      type="text"
                      value={customerEmail}
                      onChange={(event) => setCustomerEmail(event.target.value)}
                    />
                  </FormControl>
                </Box>
              </div>
              <Box className="leads-full-width margin-up-down">
                <FormControl fullWidth>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="outlined-basic"
                    multiline
                    minRows={1}
                    maxRows={2}
                    label="Description"
                    value={description || ""}
                    onChange={(event) => setDescription(event.target.value)}
                  />
                </FormControl>
              </Box>

              <div className="mob-lead-grey-bottom">
                <div className="mob-configurator-bottom-left">
                  <span className="text-quicksand700-small padding-b10">
                    Specifications of the Developers
                  </span>
                  <div className="leads-mob-row-to-column">
                    <Box className="leads-half-width margin-up-down">
                      <FormControl fullWidth>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          type="number"
                          label="Number of Developers"
                          id="demo-simple-select"
                          InputProps={{ inputProps: { min: 0 } }}
                          value={devNumber}
                          onChange={(event) =>
                            setDevNumber(parseInt(event.target.value))
                          }
                        />
                      </FormControl>
                    </Box>
                    <Box className="leads-half-width margin-up-down">
                      <FormControl fullWidth>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          type="number"
                          id="demo-simple-select-label-duration"
                          label="Project Days"
                          InputProps={{ inputProps: { min: 0 } }}
                          value={devDuration}
                          onChange={(event) =>
                            setDevDuration(parseInt(event.target.value))
                          }
                        />
                      </FormControl>
                    </Box>
                  </div>
                  <div className="leads-mob-row-to-column">
                    <Box className="leads-half-width margin-up-down">
                      <FormControl fullWidth>
                        <InputLabel shrink={true} id="demo-simple-select-label">
                          Availability Needed
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={devHours || ""}
                          label="SelectedProject"
                          onChange={(event) => setDevHours(event.target.value)}
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              label="Availability Needed"
                              notched
                            />
                          }
                        >
                          <MenuItem value={"FULL_TIME"}>Full Time</MenuItem>
                          <MenuItem value={"PART_TIME"}>Part Time</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>

                    <Box className="leads-half-width margin-up-down">
                      <FormControl fullWidth>
                        <InputLabel
                          shrink={true}
                          id="demo-simple-select-label-level"
                        >
                          Developer Level
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label-level"
                          id="demo-simple-select-label-level"
                          value={devLevel || ""}
                          label="SelectedProject"
                          onChange={(event) => setDevLevel(event.target.value)}
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              label="Developer Level"
                              notched
                            />
                          }
                        >
                          <MenuItem value={"JUNIOR"}>Junior</MenuItem>
                          <MenuItem value={"MID"}>Mid</MenuItem>
                          <MenuItem value={"SENIOR"}>Senior</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                  <div className="leads-mob-row-to-column">
                    <LocalizationProvider
                      dateAdapter={AdapterMoment}
                      adapterLocale="en-gb"
                    >
                      <Box className="leads-half-width margin-up-down">
                        <FormControl fullWidth>
                          <DatePicker
                            inputFormat="DD/MM/YYYY"
                            value={devStartDate || null}
                            onChange={(newValue) => {
                              if (newValue) {
                                setDevStartDate(newValue?.toString());
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                InputLabelProps={{ shrink: true }}
                                label="Dev Start Date"
                              />
                            )}
                          />

                          {/* <TextField
                          InputLabelProps={{ shrink: true }}
                          type="date"
                          label="Dev Start Date"
                          id="demo-simple-select"
                          InputProps={{
                            inputComponent: InputComponentDatePicker,
                          }}
                          value={devStartDate}
                          onChange={(event) =>
                            setDevStartDate(event.target.value)
                          }
                        /> */}
                        </FormControl>
                      </Box>
                    </LocalizationProvider>
                    <Box className="leads-half-width margin-up-down">
                      <FormControl fullWidth>
                        <InputLabel shrink={true} id="demo-simple-select-label">
                          Skills
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={devSkills}
                          onChange={handleChangeDevSkillsMobile}
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              label="Skills"
                              notched
                            />
                          }
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          {skills.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox
                                checked={devSkills.indexOf(name) > -1}
                              />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                  <button
                    className="text-quicksand700-small orange margin-up-down"
                    style={{
                      alignSelf: "flex-start",
                      opacity:
                        devNumber === 0 ||
                        devHours === "" ||
                        devLevel === "" ||
                        devStartDate === "" ||
                        devDuration === 0 ||
                        devSkills.length === 0
                          ? 0.4
                          : 1,
                    }}
                    onClick={handleAddDeveloper}
                    disabled={
                      devNumber === 0 ||
                      devHours === "" ||
                      devLevel === "" ||
                      devStartDate === "" ||
                      devDuration === 0 ||
                      devSkills.length === 0
                    }
                  >
                    + Add Developer and Get Estimate
                  </button>
                </div>

                <div className="mob-add-lead-resources">
                  <span className="text-quicksand700 align-self-center">
                    Resources:
                  </span>

                  <div className="configurator-column-list-scroll">
                    {selectedDevs.length > 0 &&
                      selectedDevs.map((dev: any, i: number) => {
                        return (
                          <div className="mob-configurator-list-row-sb" key={i}>
                            <div className="column-start-start">
                              <div className="row-start text-quicksand700-small">
                                <span className="text-quicksand700-small">
                                  {dev.number_of_devs +
                                    " " +
                                    dev.developer_level +
                                    " Developer" +
                                    (parseInt(dev.number_of_devs) > 1
                                      ? "s"
                                      : "") +
                                    " for " +
                                    dev.duration +
                                    " Day" +
                                    (parseInt(dev.duration) > 1 ? "s" : "") +
                                    ", starting on " +
                                    moment(dev.start_date).format("D MMM yy")}
                                </span>
                              </div>
                              <div className="row-start wrap darkgrey">
                                <span className="text-quicksand-small">
                                  Skills:
                                </span>

                                {dev.skills.map((skill: string, j: number) => {
                                  return (
                                    <span
                                      key={skill}
                                      className="text-quicksand-small"
                                    >
                                      &nbsp;{skill}
                                      {hotSkills.map((hotSkill: any) => {
                                        if (hotSkill.name === skill) {
                                          return (
                                            <WhatshotIcon
                                              key={hotSkill.name}
                                              style={{
                                                color: "#ff6210",
                                                marginBottom: 5,
                                              }}
                                            />
                                          );
                                        }
                                      })}
                                      {j < dev.skills.length - 1 ? " ," : ""}{" "}
                                    </span>
                                  );
                                })}
                              </div>
                              <br />
                              <br />

                              <div className="row-start">
                                <span className="text-quicksand700-small">
                                  Daily Cost: {dev.daily} €
                                </span>
                              </div>
                              <div className="row-start">
                                <span className="text-quicksand700-small">
                                  Cost: {dev.cost} €
                                </span>
                              </div>
                            </div>
                            <DeleteIcon
                              style={{
                                cursor: "pointer",
                                color: "black",
                                alignSelf: "flex-end",
                              }}
                              onClick={() => removeSelectedDev(i)}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
                <span className="pricing-total-cost">
                  TOTAL LEAD COST: {totalCost}€
                </span>
              </div>

              <div className="mob-leads-end">
                <button
                  className="button-black-small text-quicksand700-small white"
                  onClick={() => props.onClose(true)}
                >
                  {" "}
                  Cancel
                </button>
                <button
                  className="button-white-small text-quicksand700-small"
                  onClick={addLead}
                  disabled={errorMessage !== ""}
                >
                  {" "}
                  Save Lead
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

AddLead.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  lead: PropTypes.object,
};

export default AddLead;
