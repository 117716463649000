import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { RootState } from "../../store";
import authSlice from "../../store/slices/auth";
import "./PlatformNavigation.css";
import StarIcon from "@mui/icons-material/Star";
import WorkIcon from "@mui/icons-material/Work";
import PeopleIcon from "@mui/icons-material/People";
import DvrIcon from "@mui/icons-material/Dvr";
import InventoryIcon from "@mui/icons-material/Inventory";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PaymentIcon from "@mui/icons-material/Payment";
import AddCircleIcon from "@mui/icons-material/AddCircle";

function PlatformNavigation() {
  const auth = useSelector((state: RootState) => state.auth);
  const path = useSelector((state: RootState) => state.location);
  const dispatch = useDispatch();

  return (
    <div className="navigation-column">
      {/* <img
        src={require("../../assets/images/logo-gyld.png")}
        className="pn-logo"
      /> */}
      <Link className=" logo" to="/">
        <div className="mb">
          <span className="pn-logo-text-small">THE </span>
          <span className="pn-logo-text-big">Gyld</span>
        </div>
      </Link>

      <button
        className="nav-button"
        style={{
          backgroundColor:
            path.location === "/platform" ? "#E4EDFF" : "transparent",
          // borderRadius: 8,
          borderRight:
            path.location === "/platform"
              ? "3px solid #626da1"
              : "0px solid #626da1",
        }}
      >
        <Link className="nav-link" to="/platform">
          {" "}
          <DvrIcon className="nav-icon" />
          Dashboard
        </Link>
      </button>

      {auth.selectedView === "DEVELOPER" ? (
        <button
          className="nav-button "
          style={{
            backgroundColor:
              path.location === "/platform/opportunities"
                ? "#E4EDFF"
                : "transparent",
            // borderRadius: 8,
            borderRight:
              path.location === "/platform/opportunities"
                ? "3px solid #626da1"
                : "0px solid #626da1",
          }}
        >
          <Link className="nav-link" to="/platform/opportunities">
            <InventoryIcon className="nav-icon" />
            Opportunities
          </Link>
        </button>
      ) : (
        (auth.selectedView === "CLIENT" ||
          auth.selectedView === "GYLD MANAGER") && (
          <button
            className="nav-button"
            style={{
              backgroundColor:
                path.location === "/platform/requests"
                  ? "#E4EDFF"
                  : "transparent",
            }}
          >
            <Link className="nav-link" to="/platform/requests">
              <QuestionAnswerIcon className="nav-icon" />
              Requests
            </Link>
          </button>
        )
      )}
      {auth.selectedView !== "LEAD PROVIDER" && (
        <button
          className="nav-button"
          style={{
            backgroundColor:
              path.location === "/platform/jobs" ? "#E4EDFF" : "transparent",
            borderRight:
              path.location === "/platform/jobs"
                ? "3px solid #626da1"
                : "0px solid #626da1",
          }}
        >
          <Link className="nav-link" to="/platform/jobs">
            <StarIcon className="nav-icon" />
            Jobs
          </Link>
        </button>
      )}
      {auth.selectedView !== "LEAD PROVIDER" && (
        <button
          className="nav-button"
          style={{
            backgroundColor:
              path.location === "/platform/projects"
                ? "#E4EDFF"
                : "transparent",

            borderRight:
              path.location === "/platform/projects"
                ? "3px solid #626da1"
                : "0px solid #626da1",
          }}
        >
          <Link className="nav-link" to="/platform/projects">
            <WorkIcon className="nav-icon" />
            Projects
          </Link>
        </button>
      )}
      {auth.selectedView === "GYLD MANAGER" && (
        <button
          className="nav-button"
          style={{
            backgroundColor:
              path.location === "/platform/users" ? "#E4EDFF" : "transparent",
          }}
        >
          <Link className="nav-link" to="/platform/users">
            <GroupAddIcon className="nav-icon" />
            Users
          </Link>
        </button>
      )}
      {auth.selectedView === "GYLD MANAGER" && (
        <button
          className="nav-button"
          style={{
            backgroundColor:
              path.location === "/platform/requests_sign_up"
                ? "#E4EDFF"
                : "transparent",
          }}
        >
          <Link className="nav-link" to="/platform/requests_sign_up">
            <AddCircleIcon className="nav-icon" />
            Requests for Sign Up
          </Link>
        </button>
      )}
      {auth.selectedView === "GYLD MANAGER" && (
        <button
          className="nav-button "
          style={{
            backgroundColor:
              path.location === "/platform/payments"
                ? "#E4EDFF"
                : "transparent",
          }}
        >
          <Link className="nav-link" to="/platform/payments">
            <PaymentIcon className="nav-icon" />
            Payments
          </Link>
        </button>
      )}
      <div className=" separator"></div>
      <button
        className=" separate-area nav-button"
        style={{
          backgroundColor:
            path.location === "/platform/leads" ? "#E4EDFF" : "transparent",

          borderRight:
            path.location === "/platform/leads"
              ? "3px solid #626da1"
              : "0px solid #626da1",
        }}
      >
        <Link className="nav-link" to="/platform/leads">
          <PeopleIcon className="nav-icon" />
          Leads
        </Link>
      </button>
    </div>
  );
}

export default PlatformNavigation;
