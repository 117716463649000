import "./OppHiddenHints.css";
import React, { useState, useEffect } from "react";
import pic1 from "../../assets/images/hidden-hints-img-orange.png";
import pic2 from "../../assets/images/hidden-hints-img-orange-mob.png";
import AddLead from "../add-lead/AddLead";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import Tooltip from "@mui/material/Tooltip";

function OppHiddenHints(props: any) {
  return (
    <div style={{ width: "100%" }}>
      {window.innerWidth >= 800 ? (
        <div
          className="hidden-out-opp"
          style={{
            backgroundImage: `url(${pic1})`,
            backgroundPosition: "right",
          }}
        >
          <div className="row-start">
            <img
              className="bulb"
              src={require("../../assets/images/bulb.png")}
            />
            <b className="heading-lora">
              Hints and your path to next big project!
            </b>
          </div>
          <div className="show-white-back">
            {props.hideHints && (
              <Tooltip title="Show" placement="bottom" arrow>
                <WebAssetIcon
                  onClick={() => props.setHideHints(false)}
                  className="hidden-hints-icon"
                ></WebAssetIcon>
              </Tooltip>
            )}
          </div>
        </div>
      ) : (
        <div
          className="opp-mob-hidden-out"
          style={{
            backgroundImage: `url(${pic2})`,
          }}
        >
          <span className="heading-lora">
            Hints and your path to next big project!
          </span>

          <div className="show-white-back margin-b20">
            {props.hideHints && (
              <Tooltip title="Show" placement="bottom" arrow>
                <WebAssetIcon
                  onClick={() => props.setHideHints(false)}
                  className="hidden-hints-icon"
                ></WebAssetIcon>
              </Tooltip>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
export default OppHiddenHints;
