import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccountResponse } from "../../types";

type State = {
  location: string | null;
};

const initialState: State = { location: null };

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setLocation(state: State, action: PayloadAction<{ path: string }>) {
      state.location = action.payload.path;
    },
  },
});

export default locationSlice;
