import { useEffect, useState } from "react";
import "./AddMilestone.css";
import PropTypes from "prop-types";
import axiosService from "../../utils/axios";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Input, TextareaAutosize, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { useAlert } from "react-alert";
import Modal from "@mui/material/Modal";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "moment/locale/en-gb";
import moment from "moment";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddMilestone = (props: any) => {
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const alert = useAlert();

  const [status, setStatus] = useState("");

  const [description, setDescription] = useState("");

  const [isFirst, setIsFirst] = useState(true);

  const auth = useSelector((state: RootState) => state.auth);

  const closeHandler = (e: any) => {
    setShow(false);
    props.onClose(false);
  };

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        closeHandler(true);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);
  useEffect(() => {
    if (name.length >= 32) {
      setErrorMessage("The name can't exceed 32 characters");
    }
  }, [name]);

  useEffect(() => {
    if (name.length < 32 && errorMessage) {
      setErrorMessage("");
    }
  }, [name, errorMessage]);
  useEffect(() => {
    if (isFirst) {
      setIsFirst(false);
    }
    if (props.milestone_id) {
      props.milestones.forEach((milestone: any) => {
        if (props.milestone_id === milestone.id) {
          setName(milestone.name);
          setDate(milestone.date);

          setDescription(milestone.description);
          setStatus(milestone.status);
        }
      });
    } else {
      setName("");
      setDate("");

      setDescription("");
      setStatus("");
    }
  }, []);

  const addPhase = () => {
    if (props.milestone_id) {
      let body = {
        name: name,
        description: description,
        status: status,

        date: moment(date).format("YYYY-MM-DD").toString(),

        project_id: props.project_id,
        id: props.milestone_id,
      };
      axiosService
        .post(`${process.env.REACT_APP_API_URL}/edit_milestone`, body)
        .then((res) => {
          alert.success("The Milestone was updated");
          props.onClose(true);
        })

        .catch((err) => {
          if (err.response?.status !== 500) {
            if (err.response) {
              Object.keys(err.response.data).forEach(function (key, index) {
                alert.error(key + ":" + err.response.data[key]);
              });
            } else {
              alert.error(err.message);
            }
          }

          console.log(err);
        });
    } else {
      let body = {
        name: name,
        description: description,
        status: status,

        date: moment(date).format("YYYY-MM-DD").toString(),

        project_id: props.project_id,
      };
      axiosService
        .post(`${process.env.REACT_APP_API_URL}/add_milestone`, body)
        .then((res) => {
          alert.success("The Milestone was added");
          props.onClose(true);
        })

        .catch((err) => {
          if (err.response?.status !== 500) {
            if (err.response) {
              Object.keys(err.response.data).forEach(function (key, index) {
                alert.error(key + ":" + err.response.data[key]);
              });
            } else {
              alert.error(err.message);
            }
          }

          console.log(err);
        });
    }
  };

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  return (
    // <div
    //   style={{
    //     visibility: show ? "visible" : "hidden",
    //     opacity: show ? "1" : "0",
    //   }}
    //   className={"overlay"}
    // >

    <Modal
      open={show}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={"popup-milestone "}>
        <div className="phase-scroll">
          <div className="row-space-between-center">
            <span className="text-quicksand700">Add Milestone</span>
            <span className={"close"} onClick={closeHandler}>
              &times;
            </span>
          </div>

          <div className="padding-up-down">
            <Box className="add-phase-full-min-width">
              <FormControl fullWidth>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label=" Name"
                  id="outlined-basic"
                  type="text"
                  error={name.length >= 50}
                  inputProps={{ maxLength: 50 }}
                  helperText={errorMessage}
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
              </FormControl>
            </Box>
          </div>
          <div className="padding-up-down">
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale="en-gb"
            >
              <Box className="add-phase-full-min-width">
                <FormControl fullWidth>
                  <DatePicker
                    inputFormat="DD/MM/YYYY"
                    value={date || null}
                    onChange={(newValue) => {
                      if (newValue) {
                        setDate(newValue?.toString());
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        label="Date"
                      />
                    )}
                  />
                </FormControl>
              </Box>
            </LocalizationProvider>
            {/* <Box className="add-phase-full-min-width">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label"></InputLabel>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  label="Date"
                  id="demo-simple-select"
                  InputProps={{
                    inputComponent: InputComponentDatePicker,
                  }}
                  value={date}
                  onChange={(event) => setDate(event.target.value)}
                />
              </FormControl>
            </Box> */}
          </div>

          <div className="padding-up-down">
            <Box className="add-phase-full-min-width">
              <FormControl fullWidth>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="outlined-basic"
                  multiline
                  minRows={3}
                  maxRows={5}
                  label="Description"
                  value={description || ""}
                  onChange={(event) => setDescription(event.target.value)}
                />
              </FormControl>
            </Box>
          </div>

          <div className="padding-up-down">
            <Box className="add-phase-full-min-width">
              <FormControl fullWidth>
                <InputLabel shrink={true} id="demo-simple-select-label">
                  Status
                </InputLabel>
                <Select
                  input={
                    <OutlinedInput
                      id="select-multiple-chip"
                      label="Status"
                      notched
                    />
                  }
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={status || ""}
                  label="SelectedProject"
                  onChange={(event) => setStatus(event.target.value)}
                >
                  <MenuItem value={"OK"}>OK</MenuItem>
                  <MenuItem value={"BREACHED"}>Breached</MenuItem>
                  <MenuItem value={"NEW"}>New</MenuItem>
                  <MenuItem value={"IN_PROGRESS"}>In Progress</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>

          <div className="add-phase-row-flex-end padding-up-down">
            <button
              className="button-white-small text-quicksand700-small margin-r20"
              onClick={() => props.onClose(true)}
            >
              {" "}
              Cancel
            </button>
            <button
              className="button-black-small-nm text-quicksand700-small"
              onClick={addPhase}
              disabled={errorMessage !== ""}
            >
              {" "}
              Save
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

AddMilestone.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  milestone_id: PropTypes.number,
  project_id: PropTypes.number.isRequired,
  milestones: PropTypes.array,
  selected_project: PropTypes.object,
};

export default AddMilestone;
