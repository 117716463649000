export const appointments = [
  {
    title: "Website Re-Design Plan",
    startDate: new Date(2022, 2, 22, 9, 32),
    endDate: new Date(2022, 2, 22, 11, 30),
    id: 0,
    location: "Room 1",
  },
  {
    title: "Book Flights to San Fran for Sales Trip",
    startDate: new Date(2022, 2, 22, 12, 11),
    endDate: new Date(2022, 2, 22, 13, 0),
    id: 1,
    location: "Room 1",
  },
  {
    title: "Install New Router in Dev Room",
    startDate: new Date(2022, 2, 22, 14, 30),
    endDate: new Date(2022, 2, 22, 12, 32),
    id: 2,
    location: "Room 2",
  },
  {
    title: "Approve Personal Computer Upgrade Plan",
    startDate: new Date(2022, 2, 23, 10, 0),
    endDate: new Date(2022, 2, 23, 11, 0),
    id: 3,
    location: "Room 2",
  },
  {
    title: "Final Budget Review",
    startDate: new Date(2022, 2, 23, 12, 0),
    endDate: new Date(2022, 2, 23, 13, 32),
    id: 4,
    location: "Room 2",
  },
  {
    title: "New Brochures",
    startDate: new Date(2022, 2, 23, 14, 30),
    endDate: new Date(2022, 2, 23, 12, 42),
    id: 2,
    location: "Room 2",
  },
  {
    title: "Install New Database",
    startDate: new Date(2022, 2, 27, 9, 42),
    endDate: new Date(2022, 2, 27, 11, 12),
    id: 3,
    location: "Room 1",
  },
  {
    title: "Approve New Online Marketing Strategy",
    startDate: new Date(2022, 2, 27, 12, 0),
    endDate: new Date(2022, 2, 27, 14, 0),
    id: 7,
    location: "Room 3",
  },
  {
    title: "Upgrade Personal Computers",
    startDate: new Date(2022, 2, 27, 12, 12),
    endDate: new Date(2022, 2, 27, 13, 30),
    id: 8,
    location: "Room 3",
  },
  {
    title: "Customer Workshop",
    startDate: new Date(2022, 2, 28, 11, 0),
    endDate: new Date(2022, 2, 28, 12, 0),
    id: 9,
    location: "Room 3",
  },
  {
    title: "Prepare 2012 Marketing Plan",
    startDate: new Date(2022, 2, 28, 11, 0),
    endDate: new Date(2022, 2, 28, 13, 30),
    id: 10,
    location: "Room 1",
  },
  {
    title: "Brochure Design Review",
    startDate: new Date(2022, 2, 28, 14, 0),
    endDate: new Date(2022, 2, 28, 12, 30),
    id: 11,
    location: "Room 2",
  },
  {
    title: "Create Icons for Website",
    startDate: new Date(2022, 2, 29, 10, 0),
    endDate: new Date(2022, 2, 29, 11, 30),
    id: 12,
    location: "Room 2",
  },
  {
    title: "Upgrade Server Hardware",
    startDate: new Date(2022, 2, 29, 14, 30),
    endDate: new Date(2022, 2, 29, 13, 0),
    id: 13,
    location: "Room 3",
  },
  {
    title: "Submit New Website Design",
    startDate: new Date(2022, 2, 29, 13, 30),
    endDate: new Date(2022, 2, 29, 18, 0),
    id: 14,
    location: "Room 3",
  },
  {
    title: "Launch New Website",
    startDate: new Date(2022, 2, 29, 12, 20),
    endDate: new Date(2022, 2, 29, 14, 0),
    id: 12,
    location: "Room 2",
  },
  {
    title: "Website Re-Design Plan",
    startDate: new Date(2022, 3, 2, 9, 30),
    endDate: new Date(2022, 3, 2, 12, 30),
    id: 13,
    location: "Room 1",
  },
  {
    title: "Book Flights to San Fran for Sales Trip",
    startDate: new Date(2022, 3, 2, 12, 0),
    endDate: new Date(2022, 3, 2, 13, 0),
    id: 17,
    location: "Room 3",
  },
  {
    title: "Install New Router in Dev Room",
    startDate: new Date(2022, 3, 2, 14, 30),
    endDate: new Date(2022, 3, 2, 17, 30),
    id: 18,
    location: "Room 2",
  },
  {
    title: "Approve Personal Computer Upgrade Plan",
    startDate: new Date(2022, 3, 2, 13, 0),
    endDate: new Date(2022, 3, 3, 9, 0),
    id: 19,
    location: "Room 2",
  },
  {
    title: "Final Budget Review",
    startDate: new Date(2022, 3, 3, 10, 12),
    endDate: new Date(2022, 3, 3, 13, 32),
    id: 20,
    location: "Room 1",
  },
  {
    title: "New Brochures",
    startDate: new Date(2022, 3, 3, 14, 30),
    endDate: new Date(2022, 3, 3, 12, 42),
    id: 21,
    location: "Room 3",
  },
  {
    title: "Install New Database",
    startDate: new Date(2022, 3, 3, 12, 42),
    endDate: new Date(2022, 3, 4, 12, 12),
    id: 22,
    location: "Room 3",
  },
  {
    title: "Approve New Online Marketing Strategy",
    startDate: new Date(2022, 3, 4, 12, 32),
    endDate: new Date(2022, 3, 4, 14, 12),
    id: 23,
    location: "Room 3",
  },
  {
    title: "Upgrade Personal Computers",
    startDate: new Date(2022, 3, 4, 12, 12),
    endDate: new Date(2022, 3, 4, 20, 30),
    id: 24,
    location: "Room 2",
  },
  {
    title: "Customer Workshop",
    startDate: new Date(2022, 3, 2, 3, 0),
    endDate: new Date(2022, 3, 2, 14, 20),
    id: 22,
    location: "Room 1",
  },
  {
    title: "Customer Workshop",
    startDate: new Date(2022, 3, 2, 14, 32),
    endDate: new Date(2022, 3, 2, 13, 20),
    id: 23,
    location: "Room 1",
  },
  {
    title: "Customer Workshop 2",
    startDate: new Date(2022, 3, 2, 10, 0),
    endDate: new Date(2022, 3, 2, 11, 20),
    id: 27,
    location: "Room 2",
  },
  {
    title: "Prepare 2012 Marketing Plan",
    startDate: new Date(2022, 3, 2, 20, 0),
    endDate: new Date(2022, 3, 3, 13, 30),
    id: 28,
    location: "Room 3",
  },
  {
    title: "Brochure Design Review",
    startDate: new Date(2022, 3, 3, 14, 10),
    endDate: new Date(2022, 3, 3, 12, 30),
    id: 29,
    location: "Room 3",
  },
  {
    title: "Create Icons for Website",
    startDate: new Date(2022, 3, 3, 10, 0),
    endDate: new Date(2022, 3, 7, 14, 30),
    id: 30,
    location: "Room 1",
  },
  {
    title: "Upgrade Server Hardware",
    startDate: new Date(2022, 3, 3, 9, 30),
    endDate: new Date(2022, 3, 3, 12, 22),
    id: 31,
    location: "Room 2",
  },
  {
    title: "Submit New Website Design",
    startDate: new Date(2022, 3, 3, 12, 30),
    endDate: new Date(2022, 3, 3, 18, 0),
    id: 32,
    location: "Room 2",
  },
  {
    title: "Launch New Website",
    startDate: new Date(2022, 3, 3, 12, 20),
    endDate: new Date(2022, 3, 3, 14, 10),
    id: 33,
    location: "Room 2",
  },
  {
    title: "Book Flights to San Fran for Sales Trip",
    startDate: new Date(2022, 2, 23, 0, 0),
    endDate: new Date(2022, 2, 27, 0, 0),
    id: 34,
    location: "Room 1",
  },
  {
    title: "Customer Workshop",
    startDate: new Date(2022, 2, 29, 10, 0),
    endDate: new Date(2022, 2, 30, 14, 30),
    id: 32,
    location: "Room 1",
  },
  {
    title: "Google AdWords Strategy",
    startDate: new Date(2022, 3, 3, 0, 0),
    endDate: new Date(2022, 3, 4, 10, 30),
    id: 33,
    location: "Room 3",
  },
  {
    title: "Rollout of New Website and Marketing Brochures",
    startDate: new Date(2022, 3, 2, 10, 0),
    endDate: new Date(2022, 3, 9, 14, 30),
    id: 37,
    location: "Room 3",
  },
  {
    title: "Update NDA Agreement",
    startDate: new Date(2022, 3, 1, 10, 0),
    endDate: new Date(2022, 3, 3, 14, 30),
    id: 38,
    location: "Room 2",
  },
  {
    title: "Customer Workshop",
    startDate: new Date(2022, 3, 1),
    endDate: new Date(2022, 3, 2),
    allDay: true,
    id: 39,
    location: "Room 1",
  },
];
