import React, { useState, useEffect } from "react";
import axiosService from "../../utils/axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import locationSlice from "../../store/slices/location";
import "./Projects.css";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AddCircleOutlined } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";

import Paper from "@mui/material/Paper";

import moment from "moment";
import DownloadIcon from "@mui/icons-material/Download";

import { Button } from "@mui/material";
import AddProject from "../../components/add-project/AddProject";
import AlertUnassigned from "../../components/alert-unassigned-contracts-dif-client/AlertUnassigned";
import AddContract from "../../components/add-contract/AddContract";

function ProjectsAdmin() {
  const [projects, setProjects] = useState<any[]>([]);
  const [selectedContract, setSelectedContract] = useState<any>({});
  const [timesheetsClicked, setTimesheetsClicked] = useState(false);
  const [reportsClicked, setReportsClicked] = useState(false);
  const [phasesClicked, setPhasesClicked] = useState(false);
  const [milestonesClicked, setMilestonesClicked] = useState(false);
  const [selectedProject, setSelectedProject] = useState<any>({});
  const [unassignedContracts, setUnassignedContracts] = useState<any[]>([]);
  const [selectedUnassigned, setSelectedUnassigned] = useState<any[]>([]);
  const [showAddProjectPopup, setShowAddProjectPopup] = useState(false);
  const [showAddContractPopup, setShowAddContractPopup] = useState(false);
  const [foundDifClients, setFoundDifClients] = useState(false);
  const [editProject, setEditProject] = useState(false);
  const [showEditContract, setShowEditContract] = useState(false);
  const [addContractDev, setAddContractDev] = useState(false);
  const [addContractClient, setAddContractClient] = useState(false);
  const [selectedContractId, setSelectedContractId] = useState(-1);
  const alert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    dispatch(
      locationSlice.actions.setLocation({
        path: "/platform/projects",
      })
    );

    getProjects();
    getUnassignedContracts();
    setSelectedUnassigned([]);
    if (!auth.account) {
      navigate("/login");
    }
  }, []);
  const handleClosePopup = () => {
    setShowAddProjectPopup(false);
    setEditProject(false);
    getProjects();
    getUnassignedContracts();
    setSelectedUnassigned([]);
  };
  const handleClosePopupAddContract = () => {
    setShowAddContractPopup(false);

    setSelectedContractId(-1);
    setAddContractClient(false);
    setAddContractDev(false);

    getProjects();
  };
  const handleAddContractDev = (contract_id: number) => {
    setSelectedContractId(contract_id);
    setAddContractClient(false);
    setAddContractDev(true);

    setShowAddContractPopup(true);
  };
  const handleAddContractClient = (contract_id: number) => {
    setSelectedContractId(contract_id);
    setAddContractClient(true);
    setAddContractDev(false);

    setShowAddContractPopup(true);
  };
  const closeAlert = () => {
    setFoundDifClients(false);
    setSelectedUnassigned([]);
  };

  const getProjects = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_projects`)
      .then((res) => {
        setProjects(res.data);
        if (selectedProject) {
          res.data.forEach((project: any) => {
            if (selectedProject.id === project.id) {
              setSelectedProject(project);
              if (selectedContract && project.contracts.length > 0) {
                project.contracts.map((contract: any) => {
                  if (selectedContract.id === contract.id) {
                    setSelectedContract(contract);
                  }
                });
              }
            }
          });
        }
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const deleteContractClient = (contract_id: number) => {
    axiosService
      .delete(
        `${process.env.REACT_APP_API_URL}/delete_contract_client/` + contract_id
      )
      .then((res) => {
        alert.success("The Contract was deleted");
        getProjects();
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const deleteContractDev = (contract_id: number) => {
    axiosService
      .delete(
        `${process.env.REACT_APP_API_URL}/delete_contract_dev/` + contract_id
      )
      .then((res) => {
        alert.success("The Contract was deleted");
        getProjects();
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const getUnassignedContracts = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_unassigned_contracts`)
      .then((res) => {
        setUnassignedContracts(res.data);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const handleTimesheetClick = (contract: any) => {
    setSelectedContract(contract);
    setTimesheetsClicked(true);
    setPhasesClicked(false);
    setReportsClicked(false);
    setMilestonesClicked(false);
  };
  const handlePhaseClick = (contract: any) => {
    setSelectedContract(contract);
    setTimesheetsClicked(false);
    setPhasesClicked(true);
    setReportsClicked(false);
    setMilestonesClicked(false);
  };
  const handleReportClick = (contract: any) => {
    setSelectedContract(contract);
    setTimesheetsClicked(false);
    setPhasesClicked(false);
    setReportsClicked(true);
    setMilestonesClicked(false);
  };
  const handleMilestoneClick = (contract: any) => {
    setSelectedContract(contract);
    setTimesheetsClicked(false);
    setPhasesClicked(false);
    setReportsClicked(false);
    setMilestonesClicked(true);
  };
  const handleProjectClick = (project: any) => {
    setSelectedProject(project);
    setTimesheetsClicked(false);
  };
  const handleAcceptTimesheet = (timesheet_id: number) => {
    let body = {
      contract_id: selectedContract.id,
    };
    axiosService
      .post(
        `${process.env.REACT_APP_API_URL}/accept_timesheet/` + timesheet_id,
        body
      )
      .then((res) => {
        getProjects();
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  const closeEditContractPopup = () => {
    setShowEditContract(false);
    getProjects();
    setSelectedContract({});
  };
  const handleEditContract = (contract: any) => {
    setSelectedContract(contract);

    setShowEditContract(true);
  };
  const handleRejectTimesheet = (timesheet_id: number) => {
    let body = {
      contract_id: selectedContract.id,
    };
    axiosService
      .post(
        `${process.env.REACT_APP_API_URL}/reject_timesheet/` + timesheet_id,
        body
      )
      .then((res) => {
        getProjects();
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  const handleUnassignedClick = (contract: any) => {
    if (selectedUnassigned.includes(contract)) {
      setSelectedUnassigned(
        selectedUnassigned.filter((current: any) => {
          return current !== contract;
        })
      );
    } else {
      setSelectedUnassigned((current) => [...current, contract]);
    }
  };
  const handleCreateProject = () => {
    let tmp_client = selectedUnassigned[0].client.id;
    let foundDif = false;
    selectedUnassigned.forEach((contract: any) => {
      if (contract.client.id !== tmp_client) {
        foundDif = true;
      }
    });
    if (!foundDif) {
      setShowAddProjectPopup(true);
    } else {
      setFoundDifClients(true);
    }
  };
  const handleEditProject = (project: any) => {
    setSelectedProject(project);
    setShowAddProjectPopup(true);
    setEditProject(true);
  };
  return (
    <div className=" container column-center">
      <TableContainer component={Paper} className="separate-area">
        <b className="padding-normal">Unassigned Contracts</b>
        <button
          disabled={selectedUnassigned.length === 0}
          onClick={handleCreateProject}
        >
          Create Project
        </button>
        {selectedUnassigned.length > 0 &&
        showAddProjectPopup &&
        !editProject ? (
          <AddProject
            show={showAddProjectPopup}
            onClose={handleClosePopup}
            contracts={selectedUnassigned}
          />
        ) : (
          showAddProjectPopup &&
          selectedProject.contracts &&
          editProject && (
            <AddProject
              show={showAddProjectPopup}
              onClose={handleClosePopup}
              contracts={selectedProject.contracts}
              project={selectedProject}
            />
          )
        )}
        {foundDifClients && (
          <AlertUnassigned
            closeAlert={closeAlert}
            setFoundDifClients={setFoundDifClients}
          />
        )}
        {showAddContractPopup && selectedContractId > 0 && (
          <AddContract
            show={showAddContractPopup}
            onClose={handleClosePopupAddContract}
            contract_id={selectedContractId}
            dev={addContractDev}
            client={addContractClient}
          />
        )}
        {showEditContract && (
          <AddContract
            show={showEditContract}
            onClose={closeEditContractPopup}
            contract={selectedContract}
            job={selectedContract.job}
          />
        )}

        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Client</TableCell>
              <TableCell align="center">Job</TableCell>
              <TableCell align="center">Dev</TableCell>
              <TableCell align="center">Manager</TableCell>
              <TableCell align="center">Hours Per Week</TableCell>
              <TableCell align="center">Euros Per Hour</TableCell>
              <TableCell align="center">Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {unassignedContracts.length > 0 &&
              unassignedContracts.map((contract: any) => (
                <TableRow
                  key={contract.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={() => handleUnassignedClick(contract)}
                  style={{
                    backgroundColor: selectedUnassigned.includes(contract)
                      ? "lightgrey"
                      : "transparent",
                  }}
                >
                  <TableCell component="th" scope="row">
                    {contract.id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {contract.job?.name}
                  </TableCell>
                  <TableCell align="center">
                    {contract.client?.username}
                  </TableCell>
                  <TableCell align="center">{contract.job?.id}</TableCell>
                  <TableCell align="center">{contract.dev?.username}</TableCell>
                  <TableCell align="center">
                    {contract.client_manager?.username}
                  </TableCell>
                  <TableCell align="center">
                    {contract.hours_per_week}
                  </TableCell>
                  <TableCell align="center">
                    {contract.euros_per_hour}
                  </TableCell>
                  <TableCell align="center">
                    {moment(contract.createdAt).format("D MMM yy")}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer component={Paper} className="separate-area">
        <b className="padding-normal">Projects</b>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">RFPP</TableCell>
              <TableCell align="center">Client</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Created At</TableCell>
              <TableCell align="center">Contracts</TableCell>
              <TableCell align="center">Devs</TableCell>
              <TableCell align="center">Client Notes</TableCell>
              <TableCell align="center">Dev Notes</TableCell>
              <TableCell align="center">Admin Notes</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projects.length > 0 &&
              projects.map((project: any) => (
                <TableRow
                  key={project.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={() => handleProjectClick(project)}
                  style={{
                    backgroundColor:
                      selectedProject.id === project.id
                        ? "lightgrey"
                        : "transparent",
                  }}
                >
                  <TableCell component="th" scope="row">
                    {project.id}
                  </TableCell>
                  <TableCell align="center">{project.name}</TableCell>
                  <TableCell align="center">{project.rfpp}</TableCell>
                  <TableCell align="center">
                    {project.client.username}
                  </TableCell>
                  <TableCell align="center">{project.status}</TableCell>
                  <TableCell align="center">
                    {moment(project.createdAt).format("D MMM yy")}
                  </TableCell>
                  <TableCell align="center">
                    {project.contracts.length}
                  </TableCell>
                  <TableCell align="center">
                    {project.dev_ids.map((dev: number, i: number) => {
                      return (
                        <span key={dev}>
                          {dev}
                          {i < project.dev_ids.length - 1 ? "," : ""}
                        </span>
                      );
                    })}
                  </TableCell>
                  <TableCell align="center">{project.client_notes}</TableCell>
                  <TableCell align="center">{project.dev_notes}</TableCell>
                  <TableCell align="center">{project.admin_notes}</TableCell>
                  <TableCell align="center">
                    {" "}
                    <Button onClick={() => handleEditProject(project)}>
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer component={Paper} className="separate-area">
        <b className="padding-normal">Contracts</b>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Dev</TableCell>
              <TableCell align="center">Phases</TableCell>
              <TableCell align="center">Reports</TableCell>
              <TableCell align="center">Timesheets</TableCell>
              <TableCell align="center">Milestones</TableCell>
              <TableCell align="center">Active Phase</TableCell>
              <TableCell align="center">Job</TableCell>
              <TableCell align="center">Project Days</TableCell>
              <TableCell align="center">Hours/Days Spent</TableCell>
              <TableCell align="center">File Dev</TableCell>
              <TableCell align="center">File Client</TableCell>
              <TableCell align="center">Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedProject.contracts &&
              selectedProject.contracts.length > 0 &&
              selectedProject.contracts.map((contract: any) => (
                <TableRow
                  key={contract.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {contract.id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {contract.job?.name}
                  </TableCell>
                  <TableCell align="center">{contract.dev.username}</TableCell>
                  <TableCell
                    align="center"
                    onClick={() => handlePhaseClick(contract)}
                  >
                    {contract.phases.length} <VisibilityIcon />
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => handleReportClick(contract)}
                  >
                    {contract.reports.length} <VisibilityIcon />
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => handleTimesheetClick(contract)}
                  >
                    {contract.timesheets.length} <VisibilityIcon />
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => handleMilestoneClick(contract)}
                  >
                    {contract.milestones.length} <VisibilityIcon />
                  </TableCell>
                  <TableCell align="center">{contract.active_phase}</TableCell>
                  <TableCell align="center">{contract.job.id}</TableCell>
                  <TableCell align="center">
                    {" "}
                    <div className="row-center">
                      <span>{contract.project_days}</span>
                      <EditIcon onClick={() => handleEditContract(contract)} />
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    {contract.hours_spent}/
                    {contract.hours_spent /
                      (contract.job.job_type === "FULL_TIME" ? 8 : 4)}
                  </TableCell>
                  <TableCell align="center">
                    {contract.file !== "" ? (
                      <div className="row-center">
                        <a
                          target="_blank"
                          href={
                            process.env.REACT_APP_API_URL?.split("/api")[0] +
                            contract.file
                          }
                          download
                        >
                          <DownloadIcon style={{ color: "#626DA1" }} />
                        </a>
                        <img
                          onClick={() => {
                            deleteContractDev(contract.id);
                          }}
                          className="opp-box-end-icon"
                          src={require("../../assets/images/trash.png")}
                        />
                      </div>
                    ) : (
                      <AddCircleOutlined
                        onClick={() => handleAddContractDev(contract.id)}
                      />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {contract.file_client !== "" ? (
                      <div className="row-center">
                        <a
                          target="_blank"
                          href={
                            process.env.REACT_APP_API_URL?.split("/api")[0] +
                            contract.file_client
                          }
                          download
                        >
                          <DownloadIcon style={{ color: "#626DA1" }} />
                        </a>
                        <img
                          onClick={() => {
                            deleteContractClient(contract.id);
                          }}
                          className="opp-box-end-icon"
                          src={require("../../assets/images/trash.png")}
                        />
                      </div>
                    ) : (
                      <AddCircleOutlined
                        onClick={() => handleAddContractClient(contract.id)}
                      />
                    )}
                  </TableCell>

                  <TableCell align="center">
                    {moment(contract.createdAt).format("D MMM yy")}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {timesheetsClicked && (
        <TableContainer component={Paper} className="separate-area">
          <b className="padding-normal">Timesheets</b>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="center">From Date</TableCell>
                <TableCell align="center">To Date</TableCell>
                <TableCell align="center">Hours</TableCell>
                <TableCell align="center">File</TableCell>
                <TableCell align="center">Created At</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedContract &&
                selectedContract.timesheets &&
                selectedContract.timesheets.map((timesheet: any) => (
                  <TableRow
                    key={timesheet.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {timesheet.id}
                    </TableCell>
                    <TableCell align="center">
                      {moment(timesheet.from_date).format("D MMM yy")}
                    </TableCell>
                    <TableCell align="center">
                      {moment(timesheet.to_date).format("D MMM yy")}
                    </TableCell>
                    <TableCell align="center">{timesheet.hours}</TableCell>

                    <TableCell align="center">
                      <a
                        target="_blank"
                        href={
                          process.env.REACT_APP_API_URL?.split("/api")[0] +
                          "/media/" +
                          timesheet.file
                        }
                        download
                      >
                        Download
                      </a>
                    </TableCell>

                    <TableCell align="center">
                      {moment(timesheet.createdAt).format("D MMM yy")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {timesheet.status}
                    </TableCell>
                    <TableCell align="center">
                      {timesheet.status === "NEW" && (
                        <div>
                          <Button
                            onClick={() => handleAcceptTimesheet(timesheet.id)}
                          >
                            OK
                          </Button>
                          <Button
                            onClick={() => handleRejectTimesheet(timesheet.id)}
                          >
                            Reject
                          </Button>
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {reportsClicked && (
        <TableContainer component={Paper} className="separate-area">
          <b className="padding-normal">Reports</b>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="center">Created At</TableCell>
                <TableCell align="center">Rate</TableCell>
                <TableCell align="center">Escalate</TableCell>
                <TableCell align="center">Went Well</TableCell>
                <TableCell align="center">Do More</TableCell>
                <TableCell align="center">Not do</TableCell>
                <TableCell align="center">Blocking</TableCell>
                <TableCell align="center">Highlight</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedContract &&
                selectedContract.reports &&
                selectedContract.reports.map((report: any) => (
                  <TableRow
                    key={report.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {report.id}
                    </TableCell>

                    <TableCell align="center">
                      {moment(report.createdAt).format("D MMM yy")}
                    </TableCell>
                    <TableCell align="center">{report.rate}</TableCell>
                    <TableCell align="center">
                      {report.escalate ? <CheckIcon /> : <CloseIcon />}
                    </TableCell>
                    <TableCell align="center">{report.went_well}</TableCell>
                    <TableCell align="center">{report.do_more}</TableCell>
                    <TableCell align="center">{report.not_do}</TableCell>
                    <TableCell align="center">{report.blocking}</TableCell>
                    <TableCell align="center">{report.highlight}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {phasesClicked && (
        <TableContainer component={Paper} className="separate-area">
          <b className="padding-normal">Phases</b>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Description</TableCell>
                <TableCell align="center">Start Date</TableCell>
                <TableCell align="center">End Date</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Predecessors</TableCell>
                <TableCell align="center">Completion Rate</TableCell>
                <TableCell align="center">Challenges</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedContract &&
                selectedContract.phases &&
                selectedContract.phases.map((phase: any) => (
                  <TableRow
                    key={phase.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {phase.id}
                    </TableCell>

                    <TableCell align="center">{phase.name}</TableCell>
                    <TableCell align="center">{phase.description}</TableCell>
                    <TableCell align="center">
                      {moment(phase.start_date).format("D MMM yy")}
                    </TableCell>
                    <TableCell align="center">
                      {moment(phase.end_date).format("D MMM yy")}
                    </TableCell>
                    <TableCell align="center">{phase.status}</TableCell>
                    <TableCell align="center">{phase.predecessors}</TableCell>
                    <TableCell align="center">{phase.completion}</TableCell>
                    <TableCell align="center">{phase.challenges}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {milestonesClicked && (
        <TableContainer component={Paper} className="separate-area">
          <b className="padding-normal">Milestones</b>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Description</TableCell>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">Responsible</TableCell>
                <TableCell align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedContract &&
                selectedContract.milestones &&
                selectedContract.milestones.map((milestone: any) => (
                  <TableRow
                    key={milestone.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {milestone.id}
                    </TableCell>

                    <TableCell align="center">{milestone.name}</TableCell>
                    <TableCell align="center">
                      {milestone.description}
                    </TableCell>
                    <TableCell align="center">
                      {moment(milestone.date).format("D MMM yy")}
                    </TableCell>
                    <TableCell align="center">
                      {milestone.responsible.map((resp: any, i: number) => {
                        return (
                          <span key={resp.username}>
                            {resp.username}
                            {i < milestone.responsible.length - 1 ? " ," : ""}
                          </span>
                        );
                      })}
                    </TableCell>
                    <TableCell align="center">{milestone.status}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}

export default ProjectsAdmin;
