import { useEffect, useState } from "react";
import "./AddJob.css";
import PropTypes from "prop-types";
import axiosService from "../../utils/axios";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Input, TextareaAutosize, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import DeleteIcon from "@mui/icons-material/Delete";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "moment/locale/en-gb";

import { useAlert } from "react-alert";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddJob = (props: any) => {
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [leads, setLeads] = useState<any[]>([]);
  const [clients, setClients] = useState<any[]>([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedRfpp, setSelectedRfpp] = useState("");
  const [rfpps, setRfpps] = useState<any[]>([]);
  const [selectedLead, setSelectedLead] = useState("");
  const [nameOfClient, setNameOfClient] = useState("");
  const alert = useAlert();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [customerType, setCustomerType] = useState("");
  const [industry, setIndustry] = useState("");
  const [duration, setDuration] = useState("");
  const [clientLink, setClientLink] = useState("");
  const [description, setDescription] = useState("");
  const [skills, setSkills] = useState<Array<string>>([]);
  const [hotSkills, setHotSkills] = useState<Array<string>>([]);

  const [devSkills, setDevSkills] = useState<string[]>([]);
  const [verifiedClient, setVerifiedClient] = useState(false);
  const [devHours, setDevHours] = useState("");
  const [devLevel, setDevLevel] = useState("");
  const [devDuration, setDevDuration] = useState("");
  const [devNumber, setDevNumber] = useState(1);
  const [devStartDate, setDevStartDate] = useState("");
  const [isFirst, setIsFirst] = useState(true);
  const [selectedDevs, setSelectedDevs] = useState<any[]>([]);
  const auth = useSelector((state: RootState) => state.auth);
  const [leadId, setLeadId] = useState<number>();
  const [name, setName] = useState("");
  const [hoursPerWeek, setHoursPerWeek] = useState(0);
  const [status, setStatus] = useState("NEW");
  const [rfppId, setRfppId] = useState<number>();
  const [clientId, setClientId] = useState<number>();
  const [proposedDevs, setProposedDevs] = useState<any[]>([]);

  const closeHandler = (e: any) => {
    setShow(false);
    props.onClose(false);
  };
  useEffect(() => {
    if (isFirst) {
      getSkills();
      getAcceptedLeads();
      getClients();

      getRfpps();
      setIsFirst(false);
    }
  }, []);

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        closeHandler(true);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);
  useEffect(() => {
    if (name.length >= 40) {
      setErrorMessage("The name can't exceed 40 characters");
    }
  }, [name]);

  useEffect(() => {
    if (name.length < 50 && errorMessage) {
      setErrorMessage("");
    }
  }, [name, errorMessage]);

  useEffect(() => {
    console.log(props);
    if (props.lead) {
      getRfpp();
      getClient();
      setSelectedDevs(props.lead.devs);
      setDevSkills(props.lead.devs[0].skills);
      setDevDuration(props.lead.devs[0].duration);
      setDevLevel(props.lead.devs[0].developer_level);
      setDevNumber(props.lead.devs[0].number_of_devs);
      setDevStartDate(props.lead.devs[0].start_date);
      setName(props.lead.name);
      setDescription(props.lead.description);
      setClientLink("");
      setCustomerType(props.lead.customer_type);
      setIndustry(props.lead.industry);
      setStartDate(props.lead.start_date);
      setDuration(props.lead.duration);
      setLeadId(props.lead.id);
      setSelectedLead(props.lead.name);
    } else if (props.job) {
      setDevSkills(props.job.skills);
      setStartDate(props.job.start_date);
      setEndDate(props.job.end_date);
      setName(props.job.name);
      setCustomerType(props.job.client_type);
      setClientLink(props.job.client_link);
      setDescription(props.job.description);
      setHotSkills(props.job.hot_skills);
      setHoursPerWeek(props.job.hours_per_week);
      setVerifiedClient(props.job.verified_client);
      setDevLevel(props.job.developer_level);
      setLeadId(props.job.lead_id);
      setRfppId(props.job.rfpp);
      setClientId(props.job.client);
      setStatus(props.job.status);
      setProposedDevs(props.job.proposed_devs);
      setDevHours(
        props.job.job_type === "FULL_TIME" ? "FULL_TIME" : "PART_TIME"
      );
    }
  }, [props]);

  const addJob = () => {
    let selectedLeadId = 0;
    let selectedRfppId = 0;
    let selectedClientId = 0;
    leads.forEach((lead: any) => {
      if (lead.name === selectedLead) {
        selectedLeadId = lead.id;
      }
    });
    rfpps.forEach((rfpp: any) => {
      if (rfpp.name === selectedRfpp) {
        selectedRfppId = rfpp.id;
      }
    });
    clients.forEach((client: any) => {
      if (client.username === selectedClient) {
        selectedClientId = client.id;
      }
    });
    if (props.job) {
      let body = {
        id: props.job.id,
        lead_id: selectedLeadId,
        client: selectedClientId,
        rfpp: selectedRfppId,
        // proposed_devs: proposedDevs,
        name: name,
        skills: devSkills,
        verified_client: verifiedClient,
        description: description,
        client_link: clientLink,
        client_type: customerType,
        job_type: devHours,
        hours_per_week: hoursPerWeek,
        start_date: moment(startDate).format("YYYY-MM-DD").toString(),
        end_date: moment(endDate).format("YYYY-MM-DD").toString(),
        status: status,
        developer_level: devLevel,
        num_developers: devNumber,
      };
      axiosService
        .post(`${process.env.REACT_APP_API_URL}/edit_job`, body)
        .then((res) => {
          alert.success("The Job was updated");
          props.onClose(true);
        })

        .catch((err) => {
          if (err.response?.status !== 500) {
            if (err.response) {
              Object.keys(err.response.data).forEach(function (key, index) {
                alert.error(key + ":" + err.response.data[key]);
              });
            } else {
              alert.error(err.message);
            }
          }

          console.log(err);
        });
    } else {
      let body = {
        lead_id: selectedLeadId,
        client: selectedClientId,
        rfpp: selectedRfppId,
        proposed_devs: proposedDevs,
        name: "Job: " + name,
        skills: devSkills,
        verified_client: verifiedClient,
        description: description,
        client_link: clientLink,
        client_type: customerType,
        job_type: devHours,
        hours_per_week: hoursPerWeek,
        start_date: moment(startDate).format("YYYY-MM-DD").toString(),
        end_date: moment(endDate).format("YYYY-MM-DD").toString(),
        status: status,
        developer_level: devLevel,
        num_developers: devNumber,
      };
      axiosService
        .post(`${process.env.REACT_APP_API_URL}/add_job`, body)
        .then((res) => {
          alert.success("The Job was added");
          props.onClose(true);
        })

        .catch((err) => {
          if (err.response?.status !== 500) {
            if (err.response) {
              Object.keys(err.response.data).forEach(function (key, index) {
                alert.error(key + ":" + err.response.data[key]);
              });
            } else {
              alert.error(err.message);
            }
          }

          console.log(err);
        });
    }
  };

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const handleChangeDevSkills = (
    event: SelectChangeEvent<typeof devSkills>
  ) => {
    const {
      target: { value },
    } = event;
    setDevSkills(typeof value === "string" ? value.split(",") : value);
  };
  const removeSelectedDev = (index: number) => {
    let tmp = [...selectedDevs];
    tmp.splice(index, 1);
    setSelectedDevs(tmp);
  };

  useEffect(() => {
    console.log(selectedRfpp);
  }, [selectedRfpp]);

  const reset = () => {
    setSelectedDevs([]);
    setDevSkills([]);
    setDevDuration("");
    setDevLevel("");
    setDevNumber(1);
    setDevStartDate("");
    setClientLink("");
    setCustomerType("");
    setNameOfClient("");
    setStartDate("");
    setSelectedRfpp("");
    setIndustry("");
    setDuration("");
    setDescription("");
    setSelectedClient("");
    setSelectedLead("");
  };

  const getRfpp = () => {
    axiosService
      .get(
        `${process.env.REACT_APP_API_URL}/get_rfpp_from_lead/` + props.lead.id
      )
      .then((res) => {
        setSelectedRfpp(res.data.name);
        getRfpps();
        getAcceptedLeads();
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  const getClient = () => {
    axiosService
      .get(
        `${process.env.REACT_APP_API_URL}/get_client_from_lead/` + props.lead.id
      )
      .then((res) => {
        setSelectedClient(res.data.name);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const getAcceptedLeads = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_accepted_leads`)
      .then((res) => {
        setLeads(res.data);
        if (props.job) {
          res.data.forEach((lead: any) => {
            if (lead.id === props.job.lead_id) {
              setSelectedLead(lead.name);
            }
          });
        }
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const getClients = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_clients`)
      .then((res) => {
        setClients(res.data);
        if (props.job) {
          res.data.forEach((client: any) => {
            if (client.id === props.job.client) {
              setSelectedClient(client.username);
            }
          });
        }
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const getRfpps = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_rfpps`)
      .then((res) => {
        setRfpps(res.data);
        if (props.job) {
          res.data.forEach((rfpp: any) => {
            if (rfpp.id === props.job.rfpp) {
              setSelectedRfpp(rfpp.name);
            }
          });
        }
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  const getSkills = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_skills`)
      .then((res) => {
        let tmpSkills: any[] = [];
        res.data.forEach((data: any) => {
          tmpSkills.push(data.name);
        });
        setSkills(tmpSkills);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  return (
    <div
      style={{
        visibility: show ? "visible" : "hidden",
        opacity: show ? "1" : "0",
      }}
      className={"overlay"}
    >
      <div className={"popup-job "}>
        <div className="row-space-between">
          <span className="text-quicksand700">
            {props.job ? "Edit Job" : "Add a Job"}
          </span>

          <button className="reset-button" onClick={reset}>
            Reset
          </button>
          <span className={"add-job-close"} onClick={closeHandler}>
            &times;
          </span>
        </div>

        <div className="row-space-between padding-up-down">
          <Box
            className="add-job-3-box-width"
            sx={{ minWidth: 225, maxWidth: 225, minHeight: 68 }}
          >
            <FormControl fullWidth>
              <InputLabel shrink={true} id="demo-simple-select-label">
                Client
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedClient}
                label="SelectedProject"
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                    label="Client"
                    notched
                  />
                }
                onChange={(event) => setSelectedClient(event.target.value)}
              >
                {clients.length > 0 &&
                  clients.map((client: any) => {
                    return (
                      <MenuItem value={client.username}>
                        {client.username}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Box>
          <Box
            className="add-job-3-box-width"
            sx={{ minWidth: 225, maxWidth: 225, minHeight: 68 }}
          >
            <FormControl fullWidth>
              <InputLabel shrink={true} id="demo-simple-select-label">
                RFPP
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedRfpp}
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                    label="RFPP"
                    notched
                  />
                }
                label="SelectedProject"
                onChange={(event) => setSelectedRfpp(event.target.value)}
              >
                <MenuItem value={"-"}>-</MenuItem>
                {rfpps.length > 0 &&
                  rfpps.map((rfpp: any) => {
                    return (
                      <MenuItem key={rfpp.id} value={rfpp.name}>
                        {rfpp.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Box>
          <Box
            className="add-job-3-box-width"
            sx={{ minWidth: 225, maxWidth: 225, minHeight: 68 }}
          >
            <FormControl fullWidth>
              <InputLabel shrink={true} id="demo-simple-select-label">
                Lead
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedLead || ""}
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                    label="Lead"
                    notched
                  />
                }
                label="SelectedProject"
                onChange={(event) => setSelectedLead(event.target.value)}
              >
                {leads.length > 0 &&
                  leads.map((lead: any) => {
                    return (
                      <MenuItem key={lead.id} value={lead.name}>
                        {lead.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Box>
        </div>

        <div className="row-space-between padding-up-down">
          <Box
            className="add-job-3-box-width"
            sx={{ minWidth: 225, maxWidth: 225, minHeight: 68 }}
          >
            <FormControl fullWidth>
              <TextField
                InputLabelProps={{ shrink: true }}
                label=" Name"
                id="outlined-basic"
                type="text"
                error={name.length >= 50}
                inputProps={{ maxLength: 50 }}
                helperText={errorMessage}
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </FormControl>
          </Box>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale="en-gb"
          >
            <Box
              className="add-job-3-box-width"
              sx={{ minWidth: 225, maxWidth: 225, minHeight: 68 }}
            >
              <FormControl fullWidth>
                <DatePicker
                  inputFormat="DD/MM/YYYY"
                  value={startDate || null}
                  onChange={(newValue) => {
                    if (newValue) {
                      setStartDate(newValue?.toString());
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      label="Start Date"
                    />
                  )}
                />
              </FormControl>
            </Box>
            <Box
              className="add-job-3-box-width"
              sx={{ minWidth: 225, maxWidth: 225, minHeight: 68 }}
            >
              <FormControl fullWidth>
                <DatePicker
                  inputFormat="DD/MM/YYYY"
                  value={endDate || null}
                  minDate={moment(startDate)}
                  onChange={(newValue) => {
                    if (newValue) {
                      setEndDate(newValue?.toString());
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      label="End Date"
                    />
                  )}
                />
              </FormControl>
            </Box>
          </LocalizationProvider>
          {/* <Box
            className="add-job-3-box-width"
            sx={{ minWidth: 225, maxWidth: 225, minHeight: 68 }}
          >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label"></InputLabel>
              <TextField
                InputLabelProps={{ shrink: true }}
                type="date"
                label="Start Date"
                id="demo-simple-select"
                InputProps={{
                  inputComponent: InputComponentDatePicker,
                }}
                value={startDate}
                onChange={(event) => setStartDate(event.target.value)}
              />
            </FormControl>
          </Box> */}
          {/* <Box
            className="add-job-3-box-width"
            sx={{ minWidth: 225, maxWidth: 225, minHeight: 68 }}
          >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label"></InputLabel>
              <TextField
                InputLabelProps={{ shrink: true }}
                type="date"
                label="End Date"
                InputProps={{
                  inputProps: { min: startDate },
                  inputComponent: InputComponentDatePicker,
                }}
                id="demo-simple-select"
                value={endDate}
                onChange={(event) => setEndDate(event.target.value)}
              />
            </FormControl>
          </Box> */}
        </div>
        <div className="row-space-between padding-up-down">
          <Box
            className="add-job-3-box-width"
            sx={{ minWidth: 225, maxWidth: 225, minHeight: 68 }}
          >
            <FormControl fullWidth>
              <InputLabel shrink={true} id="demo-simple-select-label">
                Customer Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={customerType || ""}
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                    label="Customer Type"
                    notched
                  />
                }
                label="SelectedProject"
                onChange={(event) => setCustomerType(event.target.value)}
              >
                <MenuItem value={"COMPANY"}>Corporate</MenuItem>
                <MenuItem value={"PRIVATE"}>Private</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box
            className="add-job-3-box-width"
            sx={{ minWidth: 225, maxWidth: 225, minHeight: 68 }}
          >
            <FormControl fullWidth>
              <TextField
                InputLabelProps={{ shrink: true }}
                type="number"
                label="Hours Per Week"
                id="demo-simple-select"
                value={hoursPerWeek}
                InputProps={{
                  inputProps: { min: 0 },
                }}
                onChange={(event) =>
                  setHoursPerWeek(parseInt(event.target.value))
                }
              />
            </FormControl>
          </Box>
          <Box
            className="add-job-3-box-width"
            sx={{ minWidth: 225, maxWidth: 225, minHeight: 68 }}
          >
            <FormControl fullWidth>
              <TextField
                InputLabelProps={{ shrink: true }}
                id="outlined-basic"
                label=" Customer Link"
                type="text"
                value={clientLink}
                onChange={(event) => setClientLink(event.target.value)}
              />
            </FormControl>
          </Box>
        </div>

        <div className="row-space-between padding-up-down">
          <Box className="add-job-1-box-width">
            <FormControl fullWidth>
              <TextField
                InputLabelProps={{ shrink: true }}
                id="outlined-basic"
                multiline
                minRows={3}
                maxRows={5}
                label="Description"
                value={description || ""}
                onChange={(event) => setDescription(event.target.value)}
              />
            </FormControl>
          </Box>
        </div>

        <div className="row-space-between padding-up-down">
          <Box
            className="add-job-3-box-width"
            sx={{ minWidth: 225, maxWidth: 225, minHeight: 68 }}
          >
            <FormControl fullWidth>
              <TextField
                InputLabelProps={{ shrink: true }}
                type="number"
                label="Number of Devs"
                id="demo-simple-select"
                value={devNumber}
                onChange={(event) => setDevNumber(parseInt(event.target.value))}
              />
            </FormControl>
          </Box>

          <Box
            className="add-job-3-box-width"
            sx={{ minWidth: 225, maxWidth: 225, minHeight: 68 }}
          >
            <FormControl fullWidth>
              <InputLabel shrink={true} id="demo-simple-select-label">
                Availability Needed
              </InputLabel>
              <Select
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                    label="Availability Needed"
                    notched
                  />
                }
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={devHours || ""}
                label="SelectedProject"
                onChange={(event) => setDevHours(event.target.value)}
              >
                <MenuItem value={"FULL_TIME"}>Full Time</MenuItem>
                <MenuItem value={"PART_TIME"}>Part Time</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box
            className="add-job-3-box-width"
            sx={{ minWidth: 225, maxWidth: 225, minHeight: 68 }}
          >
            <FormControl fullWidth>
              <InputLabel shrink={true} id="demo-simple-select-label">
                Developer Level
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={devLevel || ""}
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                    label="Developer Level"
                    notched
                  />
                }
                label="SelectedProject"
                onChange={(event) => setDevLevel(event.target.value)}
              >
                <MenuItem value={"JUNIOR"}>Junior</MenuItem>
                <MenuItem value={"MID"}>Mid</MenuItem>
                <MenuItem value={"SENIOR"}>Senior</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>

        <div className="row-start padding-up-down">
          <Box
            className="add-job-2-box-width"
            sx={{ minWidth: 225, maxWidth: 225, minHeight: 68 }}
          >
            <FormControl fullWidth>
              <InputLabel shrink={true} id="demo-simple-select-label">
                Status
              </InputLabel>
              <Select
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                    label="Status"
                    notched
                  />
                }
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={status || ""}
                label="SelectedProject"
                onChange={(event) => setStatus(event.target.value)}
              >
                <MenuItem value={"NEW"}>New</MenuItem>
                <MenuItem value={"STARTED"}>Started</MenuItem>
                <MenuItem value={"ACCEPTED"}>Accepted</MenuItem>
                <MenuItem value={"PENDING"}>Pending</MenuItem>
                <MenuItem value={"REJECTED"}>Rejected</MenuItem>
                <MenuItem value={"FINISHED"}>Finished</MenuItem>
                <MenuItem value={"HAS_APPLICANTS"}>Has Applicants</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box
            className="add-job-2-box-width"
            sx={{ minWidth: 225, maxWidth: 225, minHeight: 68 }}
          >
            <FormControl fullWidth>
              <InputLabel shrink={true} id="demo-simple-select-label">
                Skills
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                    label="Skills"
                    notched
                  />
                }
                value={devSkills}
                onChange={handleChangeDevSkills}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {skills.length > 0 &&
                  skills.map((name: any) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={devSkills.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </div>
        {/* <div className="row-space-between padding-up-down"> */}
        <FormControlLabel
          control={
            <Checkbox
              checked={verifiedClient}
              onChange={() => setVerifiedClient(!verifiedClient)}
              className="darkgrey"
            />
          }
          label="Verified Client"
          className="darkgrey"
        />

        {/* <button className="reset-button" onClick={reset}>
            Reset
          </button> */}
        {/* </div> */}

        <div className="row-flex-end-end">
          <button
            className="button-white-small margin-r20 text-quicksand700-small"
            onClick={() => props.onClose(true)}
          >
            {" "}
            Cancel
          </button>
          <button
            className="button-black-small-nm text-quicksand700-small"
            onClick={addJob}
          >
            {" "}
            {props.job ? "Save Job" : "Create Job"}
          </button>
        </div>
      </div>
    </div>
  );
};

AddJob.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  job: PropTypes.object,
  lead: PropTypes.object,
};

export default AddJob;
