import React, { useState, useEffect } from "react";
import axiosService from "../../utils/axios";

import locationSlice from "../../store/slices/location";
import "./Jobs.css";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";

import JobsDev from "./JobsDev";
import JobsClient from "./JobsClient";
import JobsAdmin from "./JobsAdmin";

function Jobs() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    dispatch(
      locationSlice.actions.setLocation({
        path: "/platform/jobs",
      })
    );
    if (!auth.account) {
      navigate("/login");
    }
  }, []);

  return (
    <div className="platform container">
      {auth.selectedView === "DEVELOPER" ? (
        <JobsDev />
      ) : auth.selectedView === "CLIENT" ? (
        <JobsClient />
      ) : (
        <JobsAdmin />
      )}
    </div>
  );
}

export default Jobs;
