import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./Calendar.css";
import { useDispatch } from "react-redux";
import locationSlice from "../../store/slices/location";
import PlatformScheduler from "../../components/scheduler/Scheduler";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function Calendar() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      locationSlice.actions.setLocation({
        path: "/platform/calendar",
      })
    );
  }, []);
  return (
    <div className="calendarScreen">
      <div className="goBack">
        <button className="button ">
          <ArrowBackIcon />

          <Link className="link" to="/platform">
            Go Back
          </Link>
        </button>
      </div>
      <div className="calendar">
        <PlatformScheduler />
      </div>
    </div>
  );
}

export default Calendar;
