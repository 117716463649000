import React, { useEffect } from "react";
import "./Terms.css";
import Box from "@mui/material/Box";
import locationSlice from "../../store/slices/location";
import { useDispatch } from "react-redux";

const Terms = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(locationSlice.actions.setLocation({ path: "/terms-conditions" }));
  }, []);

  return (
    <div className="container">
      <div className="column-start-center">
        <div
          className="dev-row-space-between-center full-percent-width"
          style={{ paddingBottom: 20 }}
        >
          <div className="dev-leftSideHeader">
            <strong className="home-title">Terms and Conditions</strong>
          </div>
        </div>
        <p className="text-quicksand darkgrey margin-b40">
          Effective Date: 01/05/2022 <br />
          <br />
          Welcome to The Gyld website ("we," "our," "us"). By accessing or using
          our website, you agree to comply with these Terms and Conditions. If
          you disagree with any part of these terms, please do not use our
          website.
          <br />
          <br />
          <b>1. Use of Website</b> <br />
          You may use our website for informational purposes only. Unauthorized
          use of this website may give rise to a claim for damages and/or be a
          criminal offense.
          <br />
          <br />
          <b>2. Intellectual Property</b> <br />
          All content on this website, including text, graphics, logos, and
          images, is the property of The Gyld and is protected by copyright and
          trademark laws unless stated otherwise in the href attribution. You
          may not reproduce, distribute, or create derivative works from any
          content without our explicit permission.
          <br />
          <br />
          <b>3. User Conduct</b> <br />
          You agree not to use our website in any way that may harm or disrupt
          our services, or for any unlawful purpose.
          <br />
          <br />
          <b>4. Links to Other Websites</b> <br />
          Our website may contain links to third-party websites. We are not
          responsible for the content or privacy practices of these sites.
          <br />
          <br />
          <b>5. Disclaimer</b> <br />
          The information provided on this website is for general informational
          purposes only. We do not warrant the accuracy, completeness, or
          usefulness of this information.
          <br />
          <br />
          <b>6. Limitation of Liability</b> <br />
          The Gyld will not be liable for any direct, indirect, incidental, or
          consequential damages arising from the use of or inability to use this
          website.
          <br />
          <br />
          <b>7. Governing Law</b> <br />
          These terms shall be governed by and construed in accordance with the
          laws of Greece.
          <br />
          <br />
          <b>8. Changes to These Terms</b> <br />
          We may update these Terms and Conditions from time to time. Any
          changes will be posted on this page with an updated effective date.
          <br />
          <br />
          <b>9. Contact Us</b> <br />
          If you have any questions about these Terms and Conditions, please
          contact us at office@gyld.me.
        </p>
      </div>
    </div>
  );
};

export default Terms;
