import { useEffect, useState } from "react";
import "./MobileAddRequest.css";
import PropTypes from "prop-types";
import axiosService from "../../utils/axios";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Button, Input, TextareaAutosize, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "moment/locale/en-gb";

import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAlert } from "react-alert";

import Modal from "@mui/material/Modal";

import Tooltip from "@mui/material/Tooltip";
import UndoIcon from "@mui/icons-material/Undo";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MobileAddRequest = (props: any) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [show, setShow] = useState(false);
  const [nameOfClient, setNameOfClient] = useState("");
  const [startDate, setStartDate] = useState("");
  const [customerType, setCustomerType] = useState("");
  const [industry, setIndustry] = useState("");
  const [duration, setDuration] = useState("");
  const [selectedLead, setSelectedLead] = useState<any>({});
  const [selectedClient, setSelectedClient] = useState<any>({});
  const [customerEmail, setCustomerEmail] = useState("");
  const [description, setDescription] = useState("");
  const [skills, setSkills] = useState<Array<string>>([]);
  const [devSkills, setDevSkills] = useState<string[]>([]);
  const [file, setFile] = useState<any>();
  const [hotSkills, setHotSkills] = useState<any[]>([]);

  const [leads, setLeads] = useState([]);
  const [users, setUsers] = useState<any[]>([]);
  const [selectedUser, setSelectedUser] = useState<any>({});
  const [devHours, setDevHours] = useState("");
  const [clients, setClients] = useState<any[]>([]);
  const [devLevel, setDevLevel] = useState("");
  const [devDuration, setDevDuration] = useState(1);
  const [devNumber, setDevNumber] = useState(1);
  const [totalCost, setTotalCost] = useState(0);
  const [devStartDate, setDevStartDate] = useState("");
  const [isFirst, setIsFirst] = useState(true);
  const [selectedDevs, setSelectedDevs] = useState<any[]>([]);
  const auth = useSelector((state: RootState) => state.auth);
  const alert = useAlert();
  const junior: number = parseInt(
    process.env.JUNIOR_PRICE ? process.env.JUNIOR_PRICE : "30"
  );
  const mid: number = parseInt(
    process.env.MID_PRICE ? process.env.MID_PRICE : "40"
  );
  const senior: number = parseInt(
    process.env.SENIOR_PRICE ? process.env.SENIOR_PRICE : "50"
  );

  const closeHandler = (e: any) => {
    setShow(false);
    props.onClose(false);
  };

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        closeHandler(true);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);
  useEffect(() => {
    if (isFirst) {
      getSkills();
      setIsFirst(false);
    }
    if (auth.selectedView === "GYLD MANAGER") {
      getUsers();
      getLeads();
      getClients();
    }
  }, []);

  useEffect(() => {
    CalculateProps();
  }, [hotSkills]);
  useEffect(() => {
    if (nameOfClient.length >= 50) {
      setErrorMessage("The name can't exceed 50 characters");
    }
  }, [nameOfClient]);

  useEffect(() => {
    if (nameOfClient.length < 50 && errorMessage) {
      setErrorMessage("");
    }
  }, [nameOfClient, errorMessage]);

  const onFileChange = (event: any) => {
    setFile(event.target.files[0]);
  };

  const getUsers = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_users`)
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const CalculateProps = () => {
    if (props.request) {
      let tmpCost = 0;

      setNameOfClient(props.request.name);
      setDescription(props.request.leads[0].description);
      setCustomerEmail(props.request.leads[0].email_contact);
      setCustomerType(props.request.leads[0].customer_type);
      setIndustry(props.request.leads[0].industry);
      setStartDate(
        moment(props.request.leads[0].start_date)
          .format("DD-MM-YYYY")
          .toString()
      );
      setDuration(props.request.leads[0].duration);
      let tmpSelectedDevs: any[] = [];
      let leadProvider = "";
      props.request.leads.forEach((lead: any) => {
        users.forEach((user: any) => {
          if (user.id === lead.user_id_id) {
            leadProvider = user.username;
          }
        });

        lead.devs?.forEach((dev: any) => {
          let cost = 0;
          let daily = 0;
          let extra = 0;

          dev.skills.forEach((skill: string) => {
            hotSkills.forEach((hotSkill: any) => {
              if (hotSkill.name === skill) {
                extra += hotSkill.price_markup;
              }
            });
          });

          let dur = typeof dev.duration === "number" ? dev.duration : 6;
          if (typeof parseInt(dev.duration) === "number") {
            dur = parseInt(dev.duration);
          }

          if (dev.developer_level === "JUNIOR") {
            if (!dev.dev_hours || dev.dev_hours === "FULL_TIME") {
              cost = dev.number_of_devs * 8 * dur * (junior + extra);
              daily = dev.number_of_devs * 8 * (junior + extra);
            } else if (dev.dev_hours === "PART_TIME") {
              cost = dev.number_of_devs * 4 * dur * (junior + extra);
              daily = dev.number_of_devs * 4 * (junior + extra);
            }
          } else if (dev.developer_level === "MID") {
            if (!dev.dev_hours || dev.dev_hours === "FULL_TIME") {
              cost = dev.number_of_devs * 8 * dur * (mid + extra);
              daily = dev.number_of_devs * 8 * mid;
            } else if (dev.dev_hours === "PART_TIME") {
              cost = dev.number_of_devs * 4 * dur * (mid + extra);
              daily = dev.number_of_devs * 4 * mid;
            }
          } else if (dev.developer_level === "SENIOR") {
            if (!dev.dev_hours || dev.dev_hours === "FULL_TIME") {
              cost = dev.number_of_devs * 8 * dur * (senior + extra);
              daily = dev.number_of_devs * 8 * (senior + extra);
            } else if (dev.dev_hours === "PART_TIME") {
              cost = dev.number_of_devs * 4 * dur * (senior + extra);
              daily = dev.number_of_devs * 4 * (senior + extra);
            }
          }

          let tmp = {
            skills: dev.skills,
            duration: dur,
            developer_level: dev.developer_level,
            number_of_devs: dev.number_of_devs,
            start_date: moment(dev.start_date).format("DD-MM-YYYY").toString(),
            dev_hours: dev.dev_hours,
            cost: cost,
            daily: daily,
            lead_provider: leadProvider,
            added: true,
            status: lead.status,
            extra: extra,
          };
          tmpSelectedDevs.push(tmp);
          tmpCost += cost;
        });
      });
      setTotalCost(tmpCost);
      setSelectedDevs(tmpSelectedDevs);
      setSelectedLead(props.lead);
    } else if (props.lead) {
      let tmpCost = 0;
      setNameOfClient(props.lead.name);
      setDescription(props.lead.description);
      setCustomerEmail(props.lead.email_contact);
      setCustomerType(props.lead.customer_type);
      setIndustry(props.lead.industry);
      setStartDate(
        moment(props.lead.start_date).format("DD-MM-YYYY").toString()
      );
      setDuration(props.lead.duration);
      let tmpSelectedDevs: any[] = [];
      let leadProvider = "";
      users.forEach((user: any) => {
        if (user.id === props.lead.user_id_id) {
          leadProvider = user.username;
        }
      });
      props.lead.devs?.forEach((dev: any) => {
        let cost = 0;
        let daily = 0;
        let extra = 0;
        dev.skills.forEach((skill: string) => {
          hotSkills.forEach((hotSkill: any) => {
            if (hotSkill.name === skill) {
              extra += hotSkill.price_markup;
            }
          });
        });
        let dur = typeof dev.duration === "number" ? dev.duration : 6;
        if (typeof parseInt(dev.duration) === "number") {
          dur = parseInt(dev.duration);
        }

        if (dev.developer_level === "JUNIOR") {
          if (!dev.dev_hours || dev.dev_hours === "FULL_TIME") {
            cost = dev.number_of_devs * 8 * dur * (junior + extra);
            daily = dev.number_of_devs * 8 * (junior + extra);
          } else if (dev.dev_hours === "PART_TIME") {
            cost = dev.number_of_devs * 4 * dur * (junior + extra);
            daily = dev.number_of_devs * 4 * (junior + extra);
          }
        } else if (dev.developer_level === "MID") {
          if (!dev.dev_hours || dev.dev_hours === "FULL_TIME") {
            cost = dev.number_of_devs * 8 * dur * (mid + extra);
            daily = dev.number_of_devs * 8 * (mid + extra);
          } else if (dev.dev_hours === "PART_TIME") {
            cost = dev.number_of_devs * 4 * dur * (mid + extra);
            daily = dev.number_of_devs * 4 * (mid + extra);
          }
        } else if (dev.developer_level === "SENIOR") {
          if (!dev.dev_hours || dev.dev_hours === "FULL_TIME") {
            cost = dev.number_of_devs * 8 * dur * (senior + extra);
            daily = dev.number_of_devs * 8 * (senior + extra);
          } else if (dev.dev_hours === "PART_TIME") {
            cost = dev.number_of_devs * 4 * dur * (senior + extra);
            daily = dev.number_of_devs * 4 * (senior + extra);
          }
        }

        let tmp = {
          skills: dev.skills,
          duration: dur,
          developer_level: dev.developer_level,
          number_of_devs: dev.number_of_devs,
          dev_hours: dev.dev_hours,
          start_date: moment(dev.start_date).format("DD-MM-YYYY").toString(),
          cost: cost,
          daily: daily,
          lead_provider: leadProvider,
          added: true,
          status: props.lead.status,
          extra: extra,
        };
        tmpSelectedDevs.push(tmp);
        tmpCost += cost;
      });
      setTotalCost(tmpCost);
      setSelectedDevs(tmpSelectedDevs);
      setSelectedLead(props.lead);
    } else {
      // setSelectedDevs([]);
      setDevSkills([]);
      setDevDuration(1);
      setDevLevel("");
      setDevNumber(1);
      setDevStartDate("");
      setCustomerEmail("");
      setCustomerType("");
      setNameOfClient("");
      setStartDate("");

      setIndustry("");
      setDuration("");
      setDescription("");
    }
  };

  const getLeads = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_leads`)
      .then((res) => {
        setLeads(res.data);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  const getClients = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_clients`)
      .then((res) => {
        setClients(res.data);
        res.data.forEach((client: any) => {
          if (client.username === props.request?.client?.username) {
            setSelectedClient(client);
          }
        });
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const addLead = () => {
    if (!props.request) {
      let tmp_devs: any[] = [];
      let tmp_leads: any[] = [];
      if (auth.selectedView === "GYLD MANAGER") {
        selectedDevs.forEach((dev: any) => {
          tmp_devs = [];
          selectedDevs.forEach((check_dev: any) => {
            if (
              dev.lead_provider === check_dev.lead_provider &&
              !check_dev.added
            ) {
              let tmp = {
                skills: check_dev.skills,
                duration: check_dev.duration,
                developer_level: check_dev.developer_level,
                number_of_devs: check_dev.number_of_devs,
                start_date: moment(check_dev.start_date)
                  .format("YYYY-MM-DD")
                  .toString(),
                dev_hours: check_dev.dev_hours,
              };

              tmp_devs.push(tmp);
              check_dev.added = true;
              console.log("added");
            }
          });
          let lead_provider_id: number = -1;
          users.forEach((user: any) => {
            if (user.username === dev.lead_provider) {
              lead_provider_id = user.id;
            }
          });
          let body = {
            user_id: lead_provider_id > 0 ? lead_provider_id : auth.account,
            name: nameOfClient,
            description: description,
            email_contact: customerEmail,
            customer_type: customerType,
            industry: industry,
            start_date: moment(startDate).format("YYYY-MM-DD").toString(),
            duration: duration,
            devs: tmp_devs,
            status: "PENDING",
            client:
              auth.selectedView === "CLIENT"
                ? auth.client_id
                : selectedClient.id,
          };
          if (tmp_devs.length > 0) {
            tmp_leads.push(body);
          }
        });
      } else {
        selectedDevs.forEach((dev: any) => {
          let tmp = {
            skills: dev.skills,
            duration: dev.duration,
            developer_level: dev.developer_level,
            number_of_devs: dev.number_of_devs,
            start_date: moment(dev.start_date).format("YYYY-MM-DD").toString(),
            dev_hours: dev.dev_hours,
          };
          tmp_devs.push(tmp);
        });
        let body = {
          user_id: auth.account,
          name: nameOfClient,
          description: description,
          email_contact: customerEmail,
          customer_type: customerType,
          industry: industry,
          start_date: moment(startDate).format("YYYY-MM-DD").toString(),
          duration: duration,
          devs: tmp_devs,
          status: "PENDING",
          client:
            auth.selectedView === "CLIENT" ? auth.client_id : selectedClient.id,
        };

        tmp_leads.push(body);
      }
      let tmp_lead_ids: number[] = [];
      if (tmp_leads.length === 0) {
        if (props.lead) {
          tmp_lead_ids.push(props.lead.id);
        }
        postRfpp(tmp_lead_ids);
      } else {
        tmp_leads.forEach((lead: any, i: number) => {
          axiosService
            .post(`${process.env.REACT_APP_API_URL}/add_lead`, lead)
            .then((res) => {
              tmp_lead_ids.push(res.data.id);
              if (i === tmp_leads.length - 1) {
                if (props.lead) {
                  tmp_lead_ids.push(props.lead.id);
                }
                postRfpp(tmp_lead_ids);
              }
            })

            .catch((err) => {
              if (err.response?.status !== 500) {
                if (err.response) {
                  Object.keys(err.response.data).forEach(function (key, index) {
                    alert.error(key + ":" + err.response.data[key]);
                  });
                } else {
                  alert.error(err.message);
                }
              }

              console.log(err);
            });
        });
      }
    } else if (props.request) {
      let tmp_devs: any[] = [];
      let tmp_leads: any[] = [];
      let unassigned_devs: any[] = [];

      selectedDevs.forEach((dev: any) => {
        tmp_devs = [];
        selectedDevs.forEach((check_dev: any) => {
          if (
            dev.lead_provider === check_dev.lead_provider &&
            !check_dev.added
          ) {
            let tmp = {
              skills: check_dev.skills,
              duration: check_dev.duration,
              developer_level: check_dev.developer_level,
              number_of_devs: check_dev.number_of_devs,
              start_date: moment(check_dev.start_date)
                .format("YYYY-MM-DD")
                .toString(),
              dev_hours: check_dev.dev_hours,
            };

            tmp_devs.push(tmp);
            check_dev.added = true;
          }
        });
        let lead_provider_id: number = -1;
        users.forEach((user: any) => {
          if (user.username === dev.lead_provider) {
            lead_provider_id = user.id;
          }
        });
        let body = {
          user_id: lead_provider_id > 0 ? lead_provider_id : auth.account,
          name: nameOfClient,
          description: description,
          email_contact: customerEmail,
          customer_type: customerType,
          industry: industry,
          start_date: moment(startDate).format("YYYY-MM-DD").toString(),
          duration: duration,
          devs: tmp_devs,
          status: "PENDING",
          client:
            auth.selectedView === "CLIENT" ? auth.client_id : selectedClient.id,
        };
        if (tmp_devs.length > 0) {
          tmp_leads.push(body);
        }
      });
      let tmp_lead_ids: number[] = [];

      tmp_leads.forEach((lead: any, i: number) => {
        axiosService
          .post(`${process.env.REACT_APP_API_URL}/add_lead`, lead)
          .then((res) => {
            tmp_lead_ids.push(res.data.id);
            if (i === tmp_leads.length - 1) {
              editRfpp(tmp_lead_ids);
            }
          })

          .catch((err) => {
            if (err.response?.status !== 500) {
              if (err.response) {
                Object.keys(err.response.data).forEach(function (key, index) {
                  alert.error(key + ":" + err.response.data[key]);
                });
              } else {
                alert.error(err.message);
              }
            }

            console.log(err);
          });
      });
      if (tmp_leads.length === 0) {
        editRfpp([]);
      }
    }
  };

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const postRfpp = (leads: number[]) => {
    const form = new FormData();
    form.append("file", file, file.name);
    form.append("name", nameOfClient);
    form.append(
      "client",
      auth.selectedView === "CLIENT"
        ? auth.client_id?.toString()
        : selectedClient.id.toString()
    );
    form.append("leads", JSON.stringify(leads));
    form.append("status", "PENDING");

    axiosService
      .post(`${process.env.REACT_APP_API_URL}/add_request`, form)
      .then((res) => {
        alert.success("The request was added");
        props.onClose(true);
      })

      .catch((err) => {
        if (err.response) {
          Object.keys(err.response.data).forEach(function (key, index) {
            alert.error(key + ":" + err.response.data[key]);
          });
        } else {
          alert.error(err.message);
        }

        console.log(err);
      });
  };

  const editRfpp = (leads: number[]) => {
    let tmpLeads = leads;
    props.request.leads.forEach((lead: any) => {
      tmpLeads.push(lead.id);
    });

    const form = new FormData();
    form.append("file", file, file.name);
    form.append("name", nameOfClient);
    form.append("id", props.request.id.toString());
    form.append(
      "client",
      auth.selectedView === "CLIENT"
        ? auth.client_id?.toString()
        : selectedClient.id.toString()
    );
    form.append("leads", JSON.stringify(tmpLeads));
    form.append("status", props.request.status);

    axiosService
      .post(`${process.env.REACT_APP_API_URL}/edit_request`, form)
      .then((res) => {
        alert.success("The request was edited");
        props.onClose(true);
      })

      .catch((err) => {
        if (err.response) {
          Object.keys(err.response.data).forEach(function (key, index) {
            alert.error(key + ":" + err.response.data[key]);
          });
        } else {
          alert.error(err.message);
        }

        console.log(err);
      });
  };
  // const handleChangeDevSkills = (
  //   event: SelectChangeEvent<typeof devSkills>
  // ) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setDevSkills(typeof value === "string" ? value.split(",") : value);
  // };

  const handleChangeDevSkills = (skill: string) => {
    if (devSkills.includes(skill)) {
      setDevSkills(
        devSkills.filter((name: string) => {
          return name !== skill;
        })
      );
    } else {
      setDevSkills([...devSkills, skill]);
    }
  };
  const removeSelectedDev = (index: number) => {
    let tmp = [...selectedDevs];
    tmp.splice(index, 1);
    setTotalCost(totalCost - selectedDevs[index].cost);
    setSelectedDevs(tmp);
  };

  const reset = () => {
    setSelectedDevs([]);
    setDevSkills([]);
    setDevDuration(1);
    setDevLevel("");
    setDevNumber(1);
    setDevStartDate("");
    setCustomerEmail("");
    setCustomerType("");
    setNameOfClient("");
    setStartDate("");
    setTotalCost(0);
    setIndustry("");
    setDuration("");
    setDescription("");
  };
  const handleAddDeveloper = () => {
    let cost = 0;
    let daily = 0;
    let extra = 0;
    devSkills.forEach((skill: string) => {
      hotSkills.forEach((hotSkill: any) => {
        if (hotSkill.name === skill) {
          extra += hotSkill.price_markup;
        }
      });
    });
    if (devLevel === "JUNIOR") {
      if (devHours === "FULL_TIME") {
        if (devDuration !== null) {
          cost = devNumber * 8 * devDuration * (junior + extra);
          daily = devNumber * 8 * (junior + extra);
        }
      } else if (devHours === "PART_TIME") {
        if (devDuration !== null) {
          cost = devNumber * 4 * devDuration * (junior + extra);
          daily = devNumber * 4 * (junior + extra);
        }
      }
    } else if (devLevel === "MID") {
      if (devHours === "FULL_TIME") {
        if (devDuration !== null) {
          cost = devNumber * 8 * devDuration * (mid + extra);
          daily = devNumber * 8 * (mid + extra);
        }
      } else if (devHours === "PART_TIME") {
        if (devDuration !== null) {
          cost = devNumber * 4 * devDuration * (mid + extra);
          daily = devNumber * 4 * (mid + extra);
        }
      }
    } else if (devLevel === "SENIOR") {
      if (devHours === "FULL_TIME") {
        if (devDuration !== null) {
          cost = devNumber * 8 * devDuration * (senior + extra);
          daily = devNumber * 8 * (senior + extra);
        }
      } else if (devHours === "PART_TIME") {
        if (devDuration !== null) {
          cost = devNumber * 4 * devDuration * (senior + extra);
          daily = devNumber * 4 * (senior + extra);
        }
      }
    }
    let tmp = {
      skills: devSkills,
      duration: devDuration,
      developer_level: devLevel,
      number_of_devs: devNumber,
      start_date: devStartDate,
      dev_hours: devHours,
      cost: cost,
      daily: daily,
      lead_provider: selectedUser ? selectedUser : auth.account,
      added: false,
      extra: extra,
    };
    setTotalCost(totalCost + cost);
    let tmpSelectedDevs = selectedDevs;
    tmpSelectedDevs.push(tmp);
    setSelectedDevs(tmpSelectedDevs);
    setDevSkills([]);
    setDevDuration(1);
    setDevLevel("");
    setDevNumber(1);
    setDevStartDate("");
    setDevHours("");
    setSelectedUser({});
  };
  const getSkills = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_skills`)
      .then((res) => {
        let tmpSkills: any[] = [];
        let tmpHotSkills: any[] = [];
        res.data.forEach((data: any) => {
          tmpSkills.push(data.name);
          if (data.is_hot_skill) {
            tmpHotSkills.push(data);
          }
        });
        setSkills(tmpSkills);
        setHotSkills(tmpHotSkills);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  const handleSelectLead = (lead: any) => {
    setSelectedLead(lead);

    setDevSkills(lead.devs[0].skills);
    setDevDuration(lead.devs[0].duration);
    setDevLevel(lead.devs[0].develoer_level);
    setDevNumber(lead.devs[0].num_developers);
    setDevStartDate(lead.devs[0].start_date);
    setCustomerEmail(lead.email_contact);
    setCustomerType(lead.customer_type);
    setNameOfClient(lead.name);
    setStartDate(lead.start_date);

    setIndustry(lead.industry);
    setDuration(lead.duration);
    setDescription(lead.description);
  };

  const fileData = () => {
    if (file?.name) {
      return (
        <div className="row-center padding-normal full-percent-width">
          <p className="filename"> {file?.name}</p>
        </div>
      );
    } else {
      return;
    }
  };
  return (
    <Modal
      open={show}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={"mobile-popup-add-request"}>
        <div className="row-space-between-center padding-b10">
          <div className="row-start">
            <span className="text-quicksand700">
              Configure Request for Project Proposal
            </span>
            <Tooltip title="Reset" placement="bottom" arrow>
              <button
                style={{ marginRight: 30 }}
                onClick={reset}
                disabled={props.onlyView ? props.onlyView : false}
              >
                <UndoIcon className="add-lead-reload-icon blue pointer" />
              </button>
            </Tooltip>
          </div>
          <span
            className={"close-add-lead margin-right-small"}
            onClick={closeHandler}
          >
            &times;
          </span>
        </div>

        <div className="mobile-add-request-scroll">
          {auth.selectedView === "GYLD MANAGER" && (
            <div className="row-space-between padding-up-down">
              <Box sx={{ minWidth: 200 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Client</InputLabel>

                  <Select
                    disabled={props.onlyView ? props.onlyView : false}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedClient}
                    label="Client"
                    onChange={(event) => setSelectedClient(event.target.value)}
                  >
                    {clients.map((client: any) => {
                      return (
                        <MenuItem key={client.username} value={client}>
                          {client.username}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            </div>
          )}

          <div className="leads-mob-row-to-column ">
            <Box className="leads-half-width padding-up-down">
              <FormControl fullWidth>
                {/* <InputLabel id="demo-simple-select-label">

                      Start Date
                    </InputLabel> */}
                <TextField
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  label="Name"
                  disabled={
                    (props.request && auth.selectedView === "CLIENT") ||
                    props.onlyView
                  }
                  id="name-request"
                  error={nameOfClient?.length >= 50}
                  inputProps={{ maxLength: 50 }}
                  helperText={errorMessage}
                  value={nameOfClient}
                  onChange={(event) => setNameOfClient(event.target.value)}
                />
              </FormControl>
            </Box>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale="en-gb"
            >
              <Box className="leads-half-width padding-up-down">
                <FormControl fullWidth>
                  <DatePicker
                    inputFormat="DD/MM/YYYY"
                    value={startDate || null}
                    onChange={(newValue) => {
                      if (newValue) {
                        setStartDate(newValue?.toString());
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        label="Start Date"
                      />
                    )}
                  />
                </FormControl>
              </Box>
            </LocalizationProvider>
          </div>

          <Box className="leads-full-width padding-up-down">
            <FormControl fullWidth>
              {/* <InputLabel shrink={true} id="demo-simple-select-label">
                      Description
                    </InputLabel> */}
              <TextField
                disabled={
                  (props.request && auth.selectedView === "CLIENT") ||
                  props.onlyView
                }
                InputLabelProps={{ shrink: true }}
                id="outlined-basic"
                label="Description"
                multiline
                // minRows={1}
                // maxRows={2}
                rows={1}
                value={description || ""}
                onChange={(event) => setDescription(event.target.value)}
              />
            </FormControl>
          </Box>

          <div className="leads-mob-row-to-column">
            <Box className="leads-half-width padding-up-down">
              <FormControl fullWidth>
                {/* <InputLabel shrink={true} id="demo-simple-select-label">
                      Length of Project
                    </InputLabel> */}
                <TextField
                  disabled={
                    (props.request && auth.selectedView === "CLIENT") ||
                    props.onlyView
                  }
                  id="demo-simple-select"
                  value={duration || ""}
                  label="Length of Project"
                  InputLabelProps={{ shrink: true }}
                  select
                  onChange={(event) => setDuration(event.target.value)}
                >
                  <MenuItem value={"ONE_TO_THREE"}>1-3 Months</MenuItem>
                  <MenuItem value={"THREE_TO_SIX"}>3-6 Months</MenuItem>
                  <MenuItem value={"OVER_SIX"}>Over 6 Months</MenuItem>
                </TextField>
              </FormControl>
            </Box>
            <div className="add-attachment padding-up-down">
              <label htmlFor="upload-contract">
                <input
                  style={{ display: "none" }}
                  id="upload-contract"
                  name="upload-contract"
                  type="file"
                  onChange={onFileChange}
                  accept="application/pdf"
                />
                <Button
                  color="primary"
                  size="small"
                  component="span"
                  aria-label="add"
                  disabled={
                    (props.request && auth.selectedView === "CLIENT") ||
                    props.onlyView
                  }
                >
                  Add an attachment
                </Button>
              </label>
              {fileData()}
            </div>
          </div>

          <div className="mob-lead-grey-bottom">
            <div className="mob-configurator-bottom-left">
              <span className="text-quicksand700-small padding-b10">
                Specifications of the Developers
              </span>
              <div className="leads-mob-row-to-column">
                <Box className="leads-half-width padding-up-down">
                  <FormControl fullWidth>
                    <TextField
                      disabled={
                        (props.request && auth.selectedView === "CLIENT") ||
                        props.onlyView
                      }
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      label="Devs"
                      id="demo-simple-select"
                      value={devNumber}
                      onChange={(event) =>
                        setDevNumber(parseInt(event.target.value))
                      }
                    />
                  </FormControl>
                </Box>
                <Box className="leads-half-width padding-up-down">
                  <FormControl fullWidth>
                    <TextField
                      disabled={
                        (props.request && auth.selectedView === "CLIENT") ||
                        props.onlyView
                      }
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      id="demo-simple-select-label"
                      label="Project Days"
                      value={devDuration}
                      onChange={(event) =>
                        setDevDuration(parseInt(event.target.value))
                      }
                    />
                  </FormControl>
                </Box>
              </div>
              <div className="leads-mob-row-to-column">
                <Box className="leads-half-width padding-up-down">
                  <FormControl fullWidth>
                    <TextField
                      select
                      disabled={
                        (props.request && auth.selectedView === "CLIENT") ||
                        props.onlyView
                      }
                      InputLabelProps={{ shrink: true }}
                      id="demo-simple-select"
                      value={devHours || ""}
                      label="Availability needed"
                      onChange={(event) => setDevHours(event.target.value)}
                    >
                      <MenuItem value={"FULL_TIME"}>
                        Full Time (40 hours/week)
                      </MenuItem>
                      <MenuItem value={"PART_TIME"}>
                        Part Time (20 hours/week)
                      </MenuItem>
                    </TextField>
                  </FormControl>
                </Box>

                <Box className="leads-half-width padding-up-down">
                  <FormControl fullWidth>
                    <TextField
                      disabled={
                        (props.request && auth.selectedView === "CLIENT") ||
                        props.onlyView
                      }
                      InputLabelProps={{ shrink: true }}
                      select
                      id="demo-simple-select"
                      value={devLevel || ""}
                      label="Developer Level"
                      onChange={(event) => setDevLevel(event.target.value)}
                    >
                      <MenuItem value={"JUNIOR"}>Junior</MenuItem>
                      <MenuItem value={"MID"}>Mid</MenuItem>
                      <MenuItem value={"SENIOR"}>Senior</MenuItem>
                    </TextField>
                  </FormControl>
                </Box>
              </div>
              <div className="leads-mob-row-to-column">
                <Box className="leads-half-width padding-up-down">
                  <FormControl fullWidth>
                    <InputLabel shrink={true} id="skills">
                      Skills
                    </InputLabel>
                    <Select
                      disabled={
                        (props.request && auth.selectedView === "CLIENT") ||
                        props.onlyView
                      }
                      labelId="skills"
                      id="demo-multiple-checkbox"
                      multiple
                      // label="Skills"
                      value={devSkills}
                      // onChange={handleChangeDevSkills}
                      input={
                        <OutlinedInput
                          id="select-multiple-chip"
                          label="Skills"
                          notched
                        />
                      }
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      <div className="skills-select-menu">
                        {skills.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            onClick={() => handleChangeDevSkills(name)}
                          >
                            <Checkbox checked={devSkills.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </div>
                    </Select>
                  </FormControl>
                </Box>
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  adapterLocale="en-gb"
                >
                  <Box className="leads-half-width padding-up-down">
                    <FormControl fullWidth>
                      <DatePicker
                        inputFormat="DD/MM/YYYY"
                        value={devStartDate || null}
                        onChange={(newValue) => {
                          if (newValue) {
                            setDevStartDate(newValue?.toString());
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{ shrink: true }}
                            label="Dev Start Date"
                          />
                        )}
                      />
                    </FormControl>
                  </Box>
                </LocalizationProvider>
              </div>

              {auth.selectedView === "GYLD MANAGER" && (
                <Box sx={{ minWidth: 225, maxWidth: 225 }}>
                  <FormControl fullWidth>
                    <Autocomplete
                      id="lead-provider"
                      options={users}
                      autoHighlight
                      getOptionLabel={(option) => option.username}
                      // onChange={(event: any, newValue: string | null) => {
                      //   console.log(newValue);
                      // }}

                      value={selectedUser.username}
                      inputValue={selectedUser || ""}
                      onInputChange={(event: any, newInputValue) => {
                        setSelectedUser(newInputValue);
                      }}
                      renderOption={(propsComp, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...propsComp}
                        >
                          <div>{option.username}</div>
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Lead Provider"
                          inputProps={{
                            ...params.inputProps,
                            shrink: true,
                            defaultValue: selectedUser,
                            autoComplete: "new-password", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Box>
              )}

              <button
                className="text-quicksand700-small blue padding-up-down"
                style={{
                  alignSelf: "flex-start",
                  opacity:
                    devNumber === 0 ||
                    devHours === "" ||
                    devLevel === "" ||
                    devStartDate === "" ||
                    devDuration === 0 ||
                    devSkills.length === 0
                      ? 0.5
                      : 1,
                }}
                onClick={handleAddDeveloper}
                disabled={
                  (props.request && auth.selectedView === "CLIENT") ||
                  props.onlyView ||
                  devNumber === 0 ||
                  devHours === "" ||
                  devLevel === "" ||
                  devStartDate === "" ||
                  devDuration === 0 ||
                  devSkills.length === 0
                }
              >
                + Add Developer and get Estimate
              </button>
            </div>

            <div className="mob-add-lead-resources">
              <span className="text-quicksand700 align-self-center">
                Resources:
              </span>
              <div className="configurator-column-list-scroll">
                {selectedDevs.length > 0 &&
                  selectedDevs.map((dev: any, i: number) => {
                    return (
                      <div className="mob-configurator-list-row-sb" key={i}>
                        <div className="column-start-start">
                          <div className="row-start text-quicksand-small">
                            <span className="text-quicksand700-small">
                              {dev.number_of_devs +
                                " " +
                                dev.developer_level +
                                " Developer" +
                                (parseInt(dev.number_of_devs) > 1 ? "s" : "") +
                                " for " +
                                dev.duration +
                                " Day" +
                                (parseInt(dev.duration) > 1 ? "s" : "") +
                                ", starting on " +
                                moment(dev.start_date).format("D MMM yy")}
                            </span>
                          </div>
                          <div className="row-start-start text-quicksand-small">
                            <span className="text-quicksand-small darkgrey">
                              Skills:
                            </span>

                            {dev.skills.map((skill: string, j: number) => {
                              return (
                                <span
                                  key={skill}
                                  className="text-quicksand-small darkgrey"
                                >
                                  &nbsp;{skill}{" "}
                                  {hotSkills.map((hotSkill: any) => {
                                    if (hotSkill.name === skill) {
                                      return (
                                        <WhatshotIcon
                                          key={hotSkill.name}
                                          style={{
                                            color: "#ff6210",
                                            marginBottom: 5,
                                          }}
                                        />
                                      );
                                    }
                                  })}
                                  {j < dev.skills.length - 1 ? " ," : ""}{" "}
                                </span>
                              );
                            })}
                          </div>

                          <div className="row-start text-quicksand700-small">
                            <span className="text-quicksand700-small margin-r3">
                              Daily Cost:
                            </span>
                            <span className="text-quicksand700-small">
                              {" "}
                              {dev.daily} €
                            </span>
                          </div>
                          <div className="row-start text-quicksand700-small">
                            <span className="text-quicksand700-small margin-r3">
                              Total Cost:
                            </span>
                            <span className="text-quicksand700-small">
                              {" "}
                              {dev.cost} €
                            </span>
                          </div>
                          {auth.selectedView === "GYLD MANAGER" && (
                            <div className="row-start text-quicksand-small">
                              <span className="text-quicksand-small darkgrey">
                                Lead Provider:
                              </span>
                              <span className="text-quicksand-small darkgrey">
                                {dev.lead_provider}
                              </span>
                            </div>
                          )}
                          {auth.selectedView === "GYLD MANAGER" &&
                            dev.status && (
                              <div className="row-start text-quicksand-small">
                                <span className="text-quicksand-small darkgrey">
                                  Status:
                                </span>
                                <span className="text-quicksand-small darkgrey">
                                  {dev.status}
                                </span>
                              </div>
                            )}
                        </div>
                        {!(props.request && auth.selectedView === "CLIENT") &&
                          !props.onlyView && (
                            <DeleteIcon
                              className="align-self-end pointer"
                              onClick={() => removeSelectedDev(i)}
                            />
                          )}
                      </div>
                    );
                  })}
              </div>
            </div>
            {totalCost > 0 && (
              <span className="lead-total-cost">
                TOTAL REQUEST COST: {totalCost}€
              </span>
            )}
          </div>

          <div className="mob-leads-end">
            <button
              className="button-black-small text-quicksand700-small white"
              onClick={() => props.onClose(true)}
            >
              {" "}
              Cancel
            </button>

            {!(props.request && auth.selectedView === "CLIENT") &&
              !props.onlyView && (
                <button
                  className="button-white-small text-quicksand700-small"
                  onClick={addLead}
                >
                  {" "}
                  Save Request
                </button>
              )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

MobileAddRequest.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  lead: PropTypes.object,
  request: PropTypes.object,
  onlyView: PropTypes.bool,
};

export default MobileAddRequest;
