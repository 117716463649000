import "./ClientUserProfile.css";
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";

import { useAlert } from "react-alert";
import {
  Button,
  Input,
  InputAdornment,
  OutlinedInput,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate, useLocation, Location } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import locationSlice from "../../store/slices/location";
import ImageUploading from "react-images-uploading";
import axiosService from "../../utils/axios";
import Autocomplete from "@mui/material/Autocomplete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";

const ClientUserProfile = (props: any) => {
  const [UserName, setUserName] = useState("");
  const [WorkingDays, setWorkingDays] = useState("");
  const [WorkingDaysWeekend, setWorkingDaysWeekend] = useState("");

  const [Unavailability, setUnavailability] = useState<any[]>([]);
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [role, setRole] = useState("");
  const [imageSel, setImageSel] = useState<any[]>([]);
  const [userId, setUserId] = useState<any>();
  const [devSkills, setDevSkills] = useState<any[]>([]);
  const [country, setCountry] = useState("");
  const [requests, setRequests] = useState<string[]>([]);
  const [user, setUser] = useState<any>();
  const dispatch = useDispatch();

  const alert = useAlert();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useSelector((state: RootState) => state.auth);
  // useEffect(() => {
  //   dispatch(
  //     locationSlice.actions.setLocation({
  //       path: "/platform/public-profile",
  //     })
  //   );
  //   if (!auth.account) {
  //     navigate("/login");
  //   }
  // }, []);

  useEffect(() => {
    if (location.state) {
      setUserId(location.state);
    }
  }, [location]);

  useEffect(() => {
    if (props.user) {
      setUserId(props.user);
    }
  }, [props]);

  useEffect(() => {
    if (userId) {
      getUserInfo();
    }
  }, [userId]);

  useEffect(() => {
    setUserName("");
    setWorkingDays("");
    setWorkingDaysWeekend("");

    setCountry("");
  }, []);

  const getUserInfo = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_client_user_profile/` + userId)
      .then((res) => {
        setUser(res.data);
        setUserName(res.data.username);
        setFirstName(res.data.first_name);
        setLastName(res.data.last_name);
        setRole(res.data.role);
        setMiddleName(res.data.middle_name);
        setRequests(res.data.rfpps);
        setCountry(res.data.country);
        if (res.data.image !== null) {
          setImageSel([
            {
              data_url:
                process.env.REACT_APP_API_URL?.split("/api")[0] +
                res.data.image,
            },
          ]);
        }
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }
        console.log(err);
      });
  };
  // const reset = () => {
  //   setUnavailableStart("");
  // }

  return (
    <div className="pub-profile-scroll">
    <div className="pub-profile-outline">
      <div className="client-user-prof-column">
        <div className="image-item margin-b10">
          {imageSel && imageSel.length > 0 ? (
            <img
              className="profile-page-pic"
              src={imageSel[imageSel.length - 1]["data_url"]}
              alt=""
              width="100"
            />
          ) : (
            <div className="profile-page-pic  no-pic">
              <span>{UserName.charAt(0)}</span>
            </div>
          )}
          {/* <div className="image-item__btn-wrapper">

                    <button onClick={() => onImageUpdate(index)}>Update</button>
                    <button onClick={() => onImageRemove(index)}>Remove</button>
                  </div> */}
        </div>
        {/* <span className="text-quicksand700 opp-status separate-area">
              Username: &nbsp;
              {UserName}
            </span>

            <span className="text-quicksand700 opp-status">
              Role: &nbsp;
              {role}
            </span> */}

        <span className="text-quicksand700 opp-status">
          {middleName && middleName !== ""
            ? firstName + " " + middleName + " " + lastName
            : firstName + " " + lastName}
        </span>
      </div>

      <div className="client-user-column-center">
        {/* <span className="text-quicksand700 opp-status">
                {middleName && middleName !== ""
                  ? firstName + " " + middleName + " " + lastName
                  : firstName + " " + lastName}
              </span> */}

        <span className="text-quicksand700 opp-status separate-area">
          Username: &nbsp;
          {UserName}
        </span>

        <span className="text-quicksand700 opp-status">
          Role: &nbsp;
          {role}
        </span>

        <div className="public-profile-add-skills">
          {requests.length > 0 && (
            <span className="heading-lora padding-up-down">Requests:</span>
          )}
          {requests.map((request: any) => {
            return (
              <div className="row-start  wrap">
                {window.innerWidth > 1200 && <div className="ball"></div>}{" "}
                <span className="text-quicksand700 opp-status">{request}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
    </div>
  );
};
export default ClientUserProfile;
