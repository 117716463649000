import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import CloseIcon from "@mui/icons-material/Close";

const alertStyle = {
  backgroundColor: "rgb(228, 237, 255)",
  color: "#626da1",
  padding: "10px",
  textTransform: "none",
  borderRadius: "3px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  boxShadow: "0px 2px 2px 2px rgba(0, 0, 0, 0.03)",
  fontFamily: "Arial",
  width: 300,
  hieght: "max-content",
  boxSizing: "border-box",
  zIndex: "5000 !important",
};

const buttonStyle = {
  marginBottom: "5px",
  marginRight: "10px",
  border: "none",
  backgroundColor: "transparent",
  cursor: "pointer",
  color: "#626da1",
};

const AlertTemplate = ({ message, options, style, close }) => {
  return (
    <div style={{ ...alertStyle, ...style }}>
      {options.type === "info" && <InfoIcon style={{ color: "#626da1" }} />}
      {options.type === "success" && (
        <CheckCircleIcon style={{ color: "#32CD32" }} />
      )}
      {options.type === "error" && <ErrorIcon style={{ color: "#e63540" }} />}
      <span className="text-quicksand-small" style={{ flex: 2 }}>
        {message}
      </span>
      <button onClick={close} style={buttonStyle}>
        <CloseIcon />
      </button>
    </div>
  );
};

export default AlertTemplate;
