import React, { useEffect, useState } from "react";

import axiosService from "../../utils/axios";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import Moment from "moment";
import MessageIcon from "@mui/icons-material/Message";
import "./Messages.css";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { Contacts } from "@mui/icons-material";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import Tooltip from "@mui/material/Tooltip";
import { useAlert } from "react-alert";
import ReplyIcon from "@mui/icons-material/Reply";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Messages = (props: any) => {
  const auth = useSelector((state: RootState) => state.auth);
  const [showIn, setShowIn] = useState(true);
  const [showOut, setShowOut] = useState(false);
  const [messages, setMessages] = useState<any[]>([]);
  const [selectedMessage, setSelectedMessage] = useState<any>({});
  const [toUsers, setToUsers] = useState<string[]>([]);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const alert = useAlert();
  const [newMessage, setNewMessage] = useState(false);

  useEffect(() => {
    if (showIn) {
      setMessages(props.messagesInbox);
    } else {
      setMessages(props.messagesOutbox);
    }
    if (
      props.messagesInbox.length > 0 &&
      !selectedMessage.subject &&
      !newMessage
    ) {
      setSelectedMessage(props.messagesInbox[0]);
    }
  }, [props]);

  const handleShowInbox = () => {
    setMessages(props.messagesInbox);
    if (props.messagesInbox.length > 0) {
      setSelectedMessage(props.messagesInbox[0]);
    } else {
      setSelectedMessage({});
    }
    setShowIn(true);
    setShowOut(false);
  };
  const handleShowOutbox = () => {
    setMessages(props.messagesOutbox);
    if (props.messagesOutbox.length > 0) {
      setSelectedMessage(props.messagesOutbox[0]);
    } else {
      setSelectedMessage({});
    }
    setShowOut(true);
    setShowIn(false);
  };
  Moment.locale("en");

  const handleReply = () => {
    if (selectedMessage.from_user.id) {
      setToUsers([selectedMessage.from_user.username]);
    } else if (selectedMessage.from_client.id) {
      setToUsers([selectedMessage.from_client.username]);
    } else {
      setToUsers(["Gyld"]);
    }
    setSubject("RE:" + selectedMessage.subject);
    setSelectedMessage({});
  };

  const handleForward = () => {
    setBody(selectedMessage.message);
    setSubject("FW:" + selectedMessage.subject);
    setSelectedMessage({});
  };

  const handleCancel = () => {
    setBody("");
    setSubject("");
    setToUsers([]);
    setSelectedMessage({});
  };

  const sendMessage = () => {
    let tmp_to_user: number | null = null;

    toUsers.forEach((toUser: string) => {
      props.contacts.forEach((contact: any) => {
        if (contact.username === toUser) {
          tmp_to_user = contact.id;
        }
      });
      let postBody = {};
      if (auth.selectedView === "GYLD MANAGER") {
        if (tmp_to_user === null) {
          postBody = {
            from_admin: true,
            message: body,
            subject: subject,

            to_admin: true,
          };
        } else {
          postBody = {
            from_admin: true,
            message: body,
            subject: subject,
            to_user_id: tmp_to_user,
          };
        }
      } else {
        if (tmp_to_user === null) {
          postBody = {
            from_user_id: auth.account,
            message: body,
            subject: subject,

            to_admin: true,
          };
        } else {
          postBody = {
            from_user_id: auth.account,
            message: body,
            subject: subject,
            to_user_id: tmp_to_user,
          };
        }
      }
      axiosService
        .post(`${process.env.REACT_APP_API_URL}/send_message`, postBody)
        .then((res) => {
          setBody("");
          setSubject("");
          setToUsers([]);
          setSelectedMessage({});
          setNewMessage(false);
          props.getMessages();
          alert.success("Your message has been sent");
        })

        .catch((err) => {
          if (err.response?.status !== 500) {
            if (err.response) {
              Object.keys(err.response.data).forEach(function (key, index) {
                alert.error(key + ":" + err.response.data[key]);
              });
            } else {
              alert.error(err.message);
            }
          }

          console.log(err);
        });
    });
  };
  const hideMessage = () => {
    if (selectedMessage.id) {
      let body = {
        message: selectedMessage.id,
        user: auth.account,
      };
      axiosService
        .post(`${process.env.REACT_APP_API_URL}/hide_message`, body)
        .then((res) => {
          setBody("");
          setSubject("");
          setToUsers([]);
          setSelectedMessage({});
          props.getMessages();
          alert.success("The message has been deleted");
        })

        .catch((err) => {
          if (err.response?.status !== 500) {
            if (err.response) {
              Object.keys(err.response.data).forEach(function (key, index) {
                alert.error(key + ":" + err.response.data[key]);
              });
            } else {
              alert.error(err.message);
            }
          }

          console.log(err);
        });
    }
  };
  const handleClose = () => {
    setSelectedMessage({});
    setNewMessage(true);
  };
  const handleChangeToUsers = (event: SelectChangeEvent<typeof toUsers>) => {
    const {
      target: { value },
    } = event;
    setToUsers(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <>
      {window.innerWidth >= 1200 ? (
        <div className="messages-card">
          <div className="row-start message-padding text-quicksand700">
            <span>My messages </span>{" "}
            <Tooltip title="Create new Message" placement="bottom" arrow>
              <AddCircleOutlineRoundedIcon
                className="create-new-message grey-blue"
                onClick={handleClose}
                style={{ fontSize: 20 }}
              />
            </Tooltip>
          </div>

          <div className="row-space-between text-quicksand-small padding-l25">
            <div style={{ marginLeft: 35 }} className="row-start">
              <button
                onClick={handleShowInbox}
                style={{
                  color: showIn ? "#626da1" : "grey",
                  borderBottom: showIn ? "2px solid rgb(98, 109, 161)" : "none",
                }}
                disabled={showIn}
              >
                Inbox
              </button>
              <button
                disabled={showOut}
                onClick={handleShowOutbox}
                style={{
                  marginLeft: 20,
                  color: showOut ? "#626da1" : "grey",
                  borderBottom: showOut ? "2px solid rgb(98, 109, 161)" : "none",
                }}
              >
                Sent
              </button>
            </div>
            {showIn && selectedMessage.id && (
              <Tooltip
                title="Reply"
                placement="bottom"
                arrow
                onClick={handleReply}
              >
                <ReplyIcon className="pointer" style={{ marginRight: 15 }} />
              </Tooltip>
            )}
            {selectedMessage.id && (
              <Tooltip title="Forward Message" placement="bottom" arrow>
                <ShortcutIcon
                  style={{ marginRight: 15 }}
                  className="pointer"
                  onClick={handleForward}
                />
              </Tooltip>
            )}
            {selectedMessage.id && (
              <Tooltip
                title="Delete Message"
                placement="bottom"
                arrow
                onClick={hideMessage}
              >
                <DeleteIcon style={{ marginRight: 45 }} className="pointer" />
              </Tooltip>
            )}
          </div>
          <div
            className="column-space-between  overflow-y-auto"
            style={{
              width: "100%",
              height: "100%",
              minHeight: 200,
              borderBottomLeftRadius: 24,
              borderBottomRightRadius: 24,
              paddingRight: 10,
              marginTop: 10,
            }}
          >
            <div className="row-space-between full-percent-height">
              <div className="messages-list-back">
                <div className="messages-list text-quicksand-small ">
                  {(!messages || messages.length === 0) && (
                    <span className="text-quicksand-small opp-grey padding-normal">
                      There are no messages
                    </span>
                  )}
                  {messages &&
                    messages.map((message: any) => {
                      return (
                        <div
                          key={message.id}
                          className="row-start-center padding-normal full-percent-width pointer"
                          style={{
                            color:
                              selectedMessage === message ? "black" : "#8d8d8d",
                            fontWeight:
                              selectedMessage === message ? "normal" : "lighter",
                            borderBottom: "1px solid lightgrey",
                          }}
                          onClick={() => setSelectedMessage(message)}
                        >
                          <div>
                            {message.from_client.image &&
                              message.from_client.image.includes("media") ? (
                              <div className="message-image">
                                <img
                                  src={
                                    process.env.REACT_APP_API_URL?.split(
                                      "/api"
                                    )[0] + message.from_client.image
                                  }
                                  className="message-image"
                                />
                              </div>
                            ) : message.from_user.image &&
                              message.from_user.image.includes("media") ? (
                              <div className="message-image">
                                <img
                                  src={
                                    process.env.REACT_APP_API_URL?.split(
                                      "/api"
                                    )[0] + message.from_user.image
                                  }
                                  className="message-image"
                                />
                              </div>
                            ) : message.from_admin ? (
                              <div className="message-image no-pic">
                                <span>G</span>
                              </div>
                            ) : (
                              <div className="message-image no-pic">
                                <span>
                                  {message.from_client &&
                                    message.from_client.username !== ""
                                    ? message.from_client.username.charAt(0)
                                    : message.from_user &&
                                      message.from_user.username !== ""
                                      ? message.from_user.username.charAt(0)
                                      : "M"}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="column-space-between">
                            <div className="row-start ">
                              <b className="text-quicksand">
                                {" "}
                                {message.from_user.username
                                  ? "From: " + message.from_user.username
                                  : message.from_client.username
                                    ? "From: " + message.from_client.username
                                    : "From: Gyld"}
                              </b>
                            </div>
                            <span className="text-quicksand-small">
                              {" "}
                              {message.subject}
                            </span>
                            <span style={{ fontSize: 12 }}>
                              {Moment(message.createdAt).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="messages-separator ">
                {selectedMessage.from_user ? (
                  <div className="message-content">
                    <div
                      className="column-start-between"
                      style={{
                        borderBottom: "1px solid #c2c2c2",
                        paddingBottom: 4,
                        marginBottom: 15,
                      }}
                    >
                      <div
                        style={{ fontSize: 16 }}
                        className="row-start text-quicksand"
                      >
                        <b>From:&nbsp; </b>
                        <span>
                          {selectedMessage.from_user.username
                            ? selectedMessage.from_user.username
                            : selectedMessage.from_client.username
                              ? selectedMessage.from_client.username
                              : "Gyld"}
                        </span>
                      </div>
                      <div
                        style={{ fontSize: 16 }}
                        className="row-start text-quicksand"
                      >
                        <b>To:&nbsp; </b>
                        <span>
                          {selectedMessage.to_user.username
                            ? selectedMessage.to_user.username
                            : selectedMessage.to_client.username
                              ? selectedMessage.to_client.username
                              : "Gyld"}
                        </span>
                      </div>
                      <div
                        style={{ fontSize: 16 }}
                        className="row-start text-quicksand"
                      >
                        <b>{"Subject: "}&nbsp;</b>

                        <span>{selectedMessage.subject}</span>
                      </div>
                    </div>

                    <span className="text-quicksand-small">
                      {selectedMessage.message}
                    </span>
                    <div className="row-space-between">
                      <div className="row-start text-quicksand-small"></div>
                    </div>
                  </div>
                ) : (
                  <div className="message-content-back">
                    <div className="column-start-between">
                      <div className="row-start">
                        <b>To:&nbsp; </b>

                        <Select
                          id="standard-basic"
                          label=""
                          value={toUsers}
                          multiple
                          variant="standard"
                          className="full-percent-width select-to-user"
                          // input={<OutlinedInput label="Tag" />}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                          onChange={handleChangeToUsers}
                        >
                          {props.contacts.length > 0 &&
                            props.contacts.map((contact: any) => {
                              return (
                                <MenuItem
                                  key={contact.username}
                                  value={contact.username}
                                >
                                  <Checkbox
                                    checked={
                                      toUsers.indexOf(contact.username) > -1
                                    }
                                  />
                                  <ListItemText primary={contact.username} />
                                </MenuItem>
                              );
                            })}
                          <MenuItem value={"Gyld"}>
                            <Checkbox checked={toUsers.indexOf("Gyld") > -1} />
                            <ListItemText primary={"Gyld"} />
                          </MenuItem>
                        </Select>
                      </div>
                      <div className="row-start padding-up-down">
                        <b>Subject:&nbsp; </b>
                        <TextField
                          id="standard-basic-subject"
                          className="full-percent-width"
                          label=""
                          value={subject}
                          variant="standard"
                          onChange={(event: any) =>
                            setSubject(event.target.value)
                          }
                        ></TextField>
                      </div>
                    </div>

                    <textarea
                      value={body}
                      className="full-percent-width body-message"
                      onChange={(event: any) => setBody(event?.target.value)}
                    />
                    <div className="row-space-between padding-up-down">
                      <button
                        className="text-quicksand700-small"
                        onClick={handleCancel}
                      >
                        Clear
                      </button>
                      {/* <button onClick={sendMessage}>Send</button> */}
                      <Tooltip title="Send" placement="bottom" arrow>
                        <SendIcon
                          style={{ marginTop: 3 }}
                          className="pointer"
                          onClick={sendMessage}
                        />
                      </Tooltip>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (




        <div className="mobile-messages-card">
          <div className="row-start text-quicksand700 margin-b20">
            <span>My messages </span>{" "}
            <Tooltip title="Create new Message" placement="bottom" arrow>
              <AddCircleOutlineRoundedIcon
                className="create-new-message grey-blue"
                onClick={handleClose}
                style={{ fontSize: 20 }}
              />
            </Tooltip>
          </div>

          <div className="row-center">
            <div className="align-self-center">
              <button
                onClick={handleShowInbox}
                style={{
                  color: showIn ? "#626da1" : "grey",
                  borderBottom: showIn ? "2px solid rgb(98, 109, 161)" : "none",
                }}
                disabled={showIn}
              >
                Inbox
              </button>
              <button
                disabled={showOut}
                onClick={handleShowOutbox}
                style={{
                  marginLeft: 20,
                  color: showOut ? "#626da1" : "grey",
                  borderBottom: showOut ? "2px solid rgb(98, 109, 161)" : "none",
                }}
              >
                Sent
              </button>
            </div>

          </div>
          <div
            className="column-space-between "
            style={{
              width: "100%",
              height: "100%",
              minHeight: 200,
              borderBottomLeftRadius: 24,
              borderBottomRightRadius: 24,
              marginTop: 10,
            }}
          >
            {/* <div className="column-space-between full-percent-height"> */}
            <div className="mobile-messages-list-back">
              <div className="messages-list text-quicksand-small ">
                {(!messages || messages.length === 0) && (
                  <span className="text-quicksand-small opp-grey padding-normal">
                    There are no messages
                  </span>
                )}
                {messages &&
                  messages.map((message: any) => {
                    return (
                      <div
                        key={message.id}
                        className="row-start-center padding-normal full-percent-width pointer"
                        style={{
                          color:
                            selectedMessage.id === message.id
                              ? "black"
                              : "#8d8d8d",
                          fontWeight:
                            selectedMessage.id === message.id
                              ? "normal"
                              : "lighter",
                          borderBottom: "1px solid lightgrey",
                        }}
                        onClick={() => setSelectedMessage(message)}
                      >
                        <div>
                          {message.from_client.image &&
                            message.from_client.image.includes("media") ? (
                            <div className="message-image">
                              <img
                                src={
                                  process.env.REACT_APP_API_URL?.split(
                                    "/api"
                                  )[0] + message.from_client.image
                                }
                                className="message-image"
                              />
                            </div>
                          ) : message.from_user.image &&
                            message.from_user.image.includes("media") ? (
                            <div className="message-image">
                              <img
                                src={
                                  process.env.REACT_APP_API_URL?.split(
                                    "/api"
                                  )[0] + message.from_user.image
                                }
                                className="message-image"
                              />
                            </div>
                          ) : message.from_admin ? (
                            <div className="message-image no-pic">
                              <span>G</span>
                            </div>
                          ) : (
                            <div className="message-image no-pic">
                              <span>
                                {message.from_client &&
                                  message.from_client.username !== ""
                                  ? message.from_client.username.charAt(0)
                                  : message.from_user &&
                                    message.from_user.username !== ""
                                    ? message.from_user.username.charAt(0)
                                    : "M"}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="column-space-between">
                          <div className="row-start ">
                            <b className="text-quicksand">
                              {" "}
                              {message.from_user.username
                                ? "From: " + message.from_user.username
                                : message.from_client.username
                                  ? "From: " + message.from_client.username
                                  : "From: Gyld"}
                            </b>
                          </div>
                          <span className="text-quicksand-small">
                            {" "}
                            {message.subject}
                          </span>
                          <span style={{ fontSize: 12 }}>
                            {Moment(message.createdAt).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )}
                          </span>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="full-percent-width">
              <div className="messages-mobile-row-end">

                {showIn && selectedMessage.id && (
                  <Tooltip
                    title="Reply"
                    placement="bottom"
                    arrow
                    onClick={handleReply}
                  >
                    <ReplyIcon className="pointer" style={{ marginRight: 15 }} />
                  </Tooltip>
                )}
                {selectedMessage.id && (
                  <Tooltip title="Forward Message" placement="bottom" arrow>
                    <ShortcutIcon
                      style={{ marginRight: 15 }}
                      className="pointer"
                      onClick={handleForward}
                    />
                  </Tooltip>
                )}
                {selectedMessage.id && (
                  <Tooltip
                    title="Delete Message"
                    placement="bottom"
                    arrow
                    onClick={hideMessage}
                  >
                    <DeleteIcon style={{ marginRight: 45 }} className="pointer" />
                  </Tooltip>
                )}
              </div>
              {selectedMessage.from_user ? (
                <div className="mobile-message-content">

                  <div
                    className="column-start-between"
                    style={{
                      borderBottom: "1px solid #c2c2c2",
                      paddingBottom: 4,
                      marginBottom: 15,
                    }}
                  >
                    <div
                      style={{ fontSize: 16 }}
                      className="row-start text-quicksand"
                    >
                      <b>From:&nbsp; </b>
                      <span>
                        {selectedMessage.from_user.username
                          ? selectedMessage.from_user.username
                          : selectedMessage.from_client.username
                            ? selectedMessage.from_client.username
                            : "Gyld"}
                      </span>
                    </div>
                    <div
                      style={{ fontSize: 16 }}
                      className="row-start text-quicksand"
                    >
                      <b>To:&nbsp; </b>
                      <span>
                        {selectedMessage.to_user.username
                          ? selectedMessage.to_user.username
                          : selectedMessage.to_client.username
                            ? selectedMessage.to_client.username
                            : "Gyld"}
                      </span>
                    </div>
                    <div
                      style={{ fontSize: 16 }}
                      className="row-start text-quicksand"
                    >
                      <b>{"Subject: "}&nbsp;</b>

                      <span>{selectedMessage.subject}</span>
                    </div>
                  </div>

                  <span className="text-quicksand-small">
                    {selectedMessage.message}
                  </span>
                  <div className="row-space-between">
                    <div className="row-start text-quicksand-small"></div>
                  </div>
                </div>
              ) : (
                <div className="mobile-message-content-back">
                  <div className="column-start-between full-percent-height">
                    <div className="row-start">
                      <b>To:&nbsp; </b>

                      <Select
                        id="standard-basic"
                        label=""
                        value={toUsers}
                        multiple
                        variant="standard"
                        className="full-percent-width select-to-user"
                        // input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                        onChange={handleChangeToUsers}
                      >
                        {props.contacts.length > 0 &&
                          props.contacts.map((contact: any) => {
                            return (
                              <MenuItem
                                key={contact.username}
                                value={contact.username}
                              >
                                <Checkbox
                                  checked={
                                    toUsers.indexOf(contact.username) > -1
                                  }
                                />
                                <ListItemText primary={contact.username} />
                              </MenuItem>
                            );
                          })}
                        <MenuItem value={"Gyld"}>
                          <Checkbox checked={toUsers.indexOf("Gyld") > -1} />
                          <ListItemText primary={"Gyld"} />
                        </MenuItem>
                      </Select>
                    </div>
                    <div className="row-start padding-up-down">
                      <b>Subject:&nbsp; </b>
                      <TextField
                        id="standard-basic-subject"
                        className="full-percent-width"
                        label=""
                        value={subject}
                        variant="standard"
                        onChange={(event: any) =>
                          setSubject(event.target.value)
                        }
                      ></TextField>
                    </div>
                  </div>

                  <textarea
                    value={body}
                    className="full-percent-width mobile-body-message"
                    onChange={(event: any) => setBody(event?.target.value)}
                  />
                  <div className="row-space-between padding-up-down">
                    <button
                      className="text-quicksand700-small"
                      onClick={handleCancel}
                    >
                      Clear
                    </button>
                    {/* <button onClick={sendMessage}>Send</button> */}
                    <Tooltip title="Send" placement="bottom" arrow>
                      <SendIcon
                        style={{ marginTop: 3 }}
                        className="pointer"
                        onClick={sendMessage}
                      />
                    </Tooltip>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* </div> */}
        </div>)}
    </>
  );
};

export default Messages;
