import { useEffect, useState } from "react";
import "./SelectCandidates.css";
import PropTypes from "prop-types";
import axiosService from "../../utils/axios";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import { Link, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Input, TextareaAutosize, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import DeleteIcon from "@mui/icons-material/Delete";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import PublicProfile from "../../screens/public-profile/PublicProfile";
import { useAlert } from "react-alert";
import Modal from "@mui/material/Modal";
import UndoIcon from "@mui/icons-material/Undo";
import Tooltip from "@mui/material/Tooltip";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SelectCandidates = (props: any) => {
  const [users, setUsers] = useState<any[]>([]);
  const [show, setShow] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>();
  const [devSkills, setDevSkills] = useState<string[]>([]);
  const [candidates, setCandidates] = useState<any[]>([]);
  const [isFirst, setIsFirst] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<any[]>([]);
  const [suggestedUsers, setSuggestedUsers] = useState<any[]>([]);
  const [suggestedGroups, setSuggestedGroups] = useState<any[]>([]);
  const [groups, setGroups] = useState<any[]>([]);
  const auth = useSelector((state: RootState) => state.auth);
  const alert = useAlert();
  const closeHandler = (e: any) => {
    setShow(false);
    props.onClose(false);
  };
  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        closeHandler(true);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);
  useEffect(() => {
    if (isFirst) {
      getUsers();

      setIsFirst(false);
    }
    if (
      (props.selected_candidates && props.selected_candidates.length > 0) ||
      (props.selected_groups && props.selected_groups.length > 0)
    ) {
      let tmp_users: string[] = [];
      props.selected_candidates.forEach((cand: any) => {
        tmp_users.push(cand.username);
      });

      setSelectedUsers(tmp_users);
      setCandidates(props.selected_candidates);
      setSelectedGroups(props.selected_groups);
      setGroups(props.selected_groups);
    } else if (
      (props.proposed_candidates && props.proposed_candidates.length > 0) ||
      (props.proposed_groups && props.proposed_groups.length > 0) ||
      (props.suggested_candidates && props.suggested_candidates.length > 0) ||
      (props.suggested_groups && props.suggested_groups.length > 0)
    ) {
      let tmp_users: string[] = [];
      let tmp_suggested_users: string[] = [];
      props.proposed_candidates.forEach((cand: any) => {
        tmp_users.push(cand.username);
      });
      props.suggested_candidates.forEach((cand: any) => {
        tmp_users.push(cand.username);
        tmp_suggested_users.push(cand.username);
      });
      setSelectedUsers(tmp_users);
      setCandidates([
        ...props.proposed_candidates,
        ...props.suggested_candidates,
      ]);
      setSelectedGroups([...props.proposed_groups, ...props.suggested_groups]);
      setGroups([...props.proposed_groups, ...props.suggested_groups]);
      setSuggestedUsers(tmp_suggested_users);
      setSuggestedGroups(props.suggested_groups);
    } else {
      setSelectedUsers([]);
      setCandidates([]);
      setSelectedGroups([]);
      setGroups([]);
      setSuggestedUsers([]);
      setSuggestedGroups([]);
    }
  }, [props]);

  const getUsers = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_devs`)
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  const acceptCandidates = () => {
    let tmp_proposed: number[] = [];
    let tmp_proposed_groups: any[] = [];
    selectedGroups.forEach((group: any) => {
      let tmp = {
        user_help: group.user_help.id,
        user_learn: group.user_learn.id,
      };
      tmp_proposed_groups.push(tmp);
    });
    props.proposed_candidates.forEach((user: any) => {
      if (selectedUsers.includes(user.username)) {
        tmp_proposed.push(user.id);
      }
    });
    props.suggested_candidates.forEach((user: any) => {
      if (selectedUsers.includes(user.username)) {
        tmp_proposed.push(user.id);
      }
    });
    let body = {
      accepted_groups: tmp_proposed_groups,
      accepted_candidates: tmp_proposed,
      job_id: props.job_id,
    };
    axiosService
      .post(`${process.env.REACT_APP_API_URL}/accept_candidates`, body)
      .then((res) => {
        props.onClose(true);
        alert.success("The candidates were selected");
      })

      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const unselectCandidates = () => {
    let tmp_proposed: number[] = [];
    let tmp_proposed_groups: any[] = [];
    selectedGroups.forEach((group: any) => {
      let tmp = {
        user_help: group.user_help.id,
        user_learn: group.user_learn.id,
      };
      tmp_proposed_groups.push(tmp);
    });
    props.proposed_candidates.forEach((user: any) => {
      if (selectedUsers.includes(user.username)) {
        tmp_proposed.push(user.id);
      }
    });
    props.suggested_candidates.forEach((user: any) => {
      if (selectedUsers.includes(user.username)) {
        tmp_proposed.push(user.id);
      }
    });
    let body = {
      accepted_groups: tmp_proposed_groups,
      accepted_candidates: tmp_proposed,
      job_id: props.job_id,
    };
    axiosService
      .post(`${process.env.REACT_APP_API_URL}/unselect_candidates`, body)
      .then((res) => {
        props.onClose(true);
        alert.success("The candidates were selected");
      })

      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const handleChangeProposedDevs = (username: string) => {
    if (selectedUsers.indexOf(username) > -1) {
      setSelectedUsers(
        selectedUsers.filter((user: any) => {
          return user !== username;
        })
      );
    } else {
      setSelectedUsers([...selectedUsers, username]);
    }
  };
  const handleChangeProposedGroup = (group: any) => {
    if (selectedGroups.indexOf(group) > -1) {
      setSelectedGroups(
        selectedGroups.filter((selGroup: any) => {
          return selGroup !== group;
        })
      );
    } else {
      setSelectedGroups([...selectedGroups, group]);
    }
  };
  const showPublicProfile = (user: any) => {
    setShowProfile(true);
    setSelectedUser(user.id);
  };
  const closeProfile = () => {
    setShowProfile(false);
    setSelectedUser(null);
  };

  return (
    // <div
    //   style={{
    //     visibility: show ? "visible" : "hidden",
    //     opacity: show ? "1" : "0",
    //   }}
    //   className={"overlay"}
    // >

    <Modal
      open={show}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {!showProfile ? (
        <div className={"popup-userRfpp"}>
          <div className="row-space-between-center padding-10-20">
            <span className="heading-lora padding-up-down">
              Select Candidates
            </span>
            <span className={"close"} onClick={closeHandler}>
              &times;
            </span>
          </div>

          <div className="column-space-between">
            <div className="column-start-start">
              <span style={{ alignSelf: "center" }}>Job # {props.job_id}</span>

              {/* <div className="select-cand-overflow"> */}
              {candidates.length > 0 && (
                <span className="text-quicksand700 opp-grey margin-left">
                  Candidates
                </span>
              )}
              {candidates.map((user: any) => (
                <div key={user} className="userRfpp-row-with-line">
                  <div className="row-start-center">
                    <Checkbox
                      onChange={() => handleChangeProposedDevs(user.username)}
                      checked={selectedUsers.indexOf(user.username) > -1}
                    />

                    {user.image && user.image.includes("media") ? (
                      <img
                        onClick={() => showPublicProfile(user)}
                        src={
                          process.env.REACT_APP_API_URL?.split("/api")[0] +
                          user.image
                        }
                        className="profile-img"
                      />
                    ) : (
                      <div
                        onClick={() => showPublicProfile(user)}
                        className="profile-img no-pic"
                      >
                        <span>{user.username.charAt(0)}</span>
                      </div>
                    )}

                    <span className="text-quicksand700 darkgrey padding-normal pointer">
                      {user.username}
                    </span>

                    {suggestedUsers.indexOf(user.username) > -1 && (
                       <Tooltip title="Suggested Candidate" placement="bottom" arrow>
                      <img
                        src={require("../../assets/images/star_with_white_outline.png")}
                        className="header-icon-color-star"
                      />
                     </Tooltip>
                    )}
                  </div>
                  {/* <Link
                        to={{ pathname: "/platform/public-profile" }}
                        state={user.id}
                      >
                        <ChevronRightIcon />
                      </Link> */}

                  <ChevronRightIcon
                    className="pointer"
                    onClick={() => showPublicProfile(user)}
                  />
                </div>
              ))}
              {groups.length > 0 && (
                <span className="text-quicksand700 opp-grey margin-left">
                  Groups
                </span>
              )}
              {groups.map((group: any) => (
                <div key={group} className="userRfpp-row-with-line-start">
                  <Checkbox
                    onChange={() => handleChangeProposedGroup(group)}
                    checked={selectedGroups.indexOf(group) > -1}
                  />
                  {suggestedGroups.indexOf(group) > -1 && (
                    <img
                      src={require("../../assets/images/star_with_white_outline.png")}
                      className="header-icon-color-star"
                    />
                    // <span className="header-icon-color-star">🚀</span>
                  )}

                  <div className="column-start full-percent-width">
                    <div className="row-space-between-center">
                      <div className="row-start-center">
                        {group.user_help.image &&
                        group.user_help.image.includes("media") ? (
                          <img
                            onClick={() => showPublicProfile(group.user_help)}
                            src={
                              process.env.REACT_APP_API_URL?.split("/api")[0] +
                              group.user_help.image
                            }
                            className="profile-img"
                          />
                        ) : (
                          <div
                            onClick={() => showPublicProfile(group.user_help)}
                            className="profile-img no-pic"
                          >
                            <span>{group.user_help.username.charAt(0)}</span>
                          </div>
                        )}

                        <span
                          onClick={() => showPublicProfile(group.user_help)}
                          className="text-quicksand700 darkgrey padding-normal pointer"
                        >
                          {group.user_help.username}
                        </span>
                      </div>

                      <ChevronRightIcon
                        className="pointer"
                        onClick={() => showPublicProfile(group.user_help)}
                      />
                    </div>
                    <div className="row-space-between-center margin-t20">
                      <div className="row-start-center">
                        {group.user_learn.image &&
                        group.user_learn.image.includes("media") ? (
                          <img
                            onClick={() => showPublicProfile(group.user_learn)}
                            src={
                              process.env.REACT_APP_API_URL?.split("/api")[0] +
                              group.user_learn.image
                            }
                            className="profile-img"
                          />
                        ) : (
                          <div
                            onClick={() => showPublicProfile(group.user_learn)}
                            className="profile-img no-pic"
                          >
                            <span>{group.user_learn.username.charAt(0)}</span>
                          </div>
                        )}

                        <span
                          onClick={() => showPublicProfile(group.user_learn)}
                          className="text-quicksand700 darkgrey padding-normal pointer"
                        >
                          {group.user_learn.username}
                        </span>
                      </div>
                      <ChevronRightIcon
                        className="pointer"
                        onClick={() => showPublicProfile(group.user_learn)}
                      />
                    </div>
                  </div>
                </div>
              ))}

              {/* </div> */}
            </div>
          </div>
          <div className="row-space-between-center margin-t20 padding-around20">
            <button
              className="button-white-small margin-r20 text-quicksand700-small"
              onClick={() => props.onClose(true)}
            >
              {" "}
              Close
            </button>
            {(!props.selected_candidates ||
              props.selected_candidates.length === 0) &&
            (!props.selected_groups || props.selected_groups.length === 0) ? (
              <button
                className="approve-candidate-button-black text-quicksand700-small"
                onClick={acceptCandidates}
                disabled={
                  selectedUsers.length == 0 && selectedGroups.length == 0
                }
              >
                {" "}
                Approve Candidates
              </button>
            ) : (
              <button
                className="button-black-small-nm text-quicksand700-small"
                onClick={unselectCandidates}
              >
                {" "}
                Update Candidates
              </button>
            )}
          </div>
        </div>
      ) : (
        <div className={"select-candi-to-pub-prof"}>
          <div className="row-space-between-center padding-pub-prof-top">
            <ChevronLeftIcon
              onClick={closeProfile}
              className="pointer chevron-color"
            />
            <span className={"close"} onClick={closeHandler}>
              &times;
            </span>
          </div>
          <PublicProfile user={selectedUser} />
        </div>
      )}
    </Modal>
  );
};

SelectCandidates.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  job_id: PropTypes.number.isRequired,
  proposed_candidates: PropTypes.array,
  suggested_candidates: PropTypes.array,
  selected_candidates: PropTypes.array,
  proposed_groups: PropTypes.array,
  suggested_groups: PropTypes.array,
  selected_groups: PropTypes.array,
};

export default SelectCandidates;
