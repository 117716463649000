import React, { useState, useEffect, useRef } from "react";
import axiosService from "../../utils/axios";
import authSlice from "../../store/slices/auth";

import locationSlice from "../../store/slices/location";
import "./Jobs.css";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";

import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import ForwardIcon from "@mui/icons-material/Forward";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import RequestCard from "../../components/request-card/RequestCard";

import JobCardClient from "../../components/job-card/JobCardClient";
import SelectCandidates from "../../components/select-candidates/SelectCandidates";
import JobsHiddenHints from "../../components/jobs-hidden-hints/JobsHiddenHints";
import { OutlinedInput } from "@mui/material";

import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Tooltip from "@mui/material/Tooltip";
import { useAlert } from "react-alert";
import StartIcon from "@mui/icons-material/Start";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import ClearAllIcon from "@mui/icons-material/ClearAll";

const JobsClient = () => {
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState<Array<any>>([]);
  const [filteredRfppJobs, setFilteredRfppJobs] = useState<Array<any>>([]);
  const [rejectedClicked, setRejectedClicked] = useState(false);
  const [pendingClicked, setPendingClicked] = useState(false);
  const [startedClicked, setStartedClicked] = useState(false);
  const [finishedClicked, setFinishedClicked] = useState(false);
  const [savedClicked, setSavedClicked] = useState(false);
  const [acceptedClicked, setAcceptedClicked] = useState(false);
  const [numRejected, setNumRejected] = useState(0);
  const [numPending, setNumPending] = useState(0);

  const [numAccepted, setNumAccepted] = useState(0);
  const [numStarted, setNumStarted] = useState(0);
  const [numFinished, setNumFinished] = useState(0);
  const [showSelectCandidatesPopup, setShowSelectCandidatesPopup] =
    useState(false);
  const [hotSkills, setHotSkills] = useState<any[]>([]);

  const [selectedJob, setSelectedJob] = useState<any>({});
  const [mobileFilter, setMobileFilter] = useState("");
  const [jobsAccepted, setJobsAccepted] = useState<any[]>([]);
  const [isFirst, setIsFirst] = useState(true);
  const [hideHints, setHideHints] = useState(false);

  const [requests, setRequests] = useState<Array<any>>([]);
  const [selectedRequest, setSelectedRequest] = useState<any>({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth);
  const alert = useAlert();
  const scrollToRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isFirst) {
      getJobs();
      getSkills();
      setIsFirst(false);
    }
    dispatch(
      locationSlice.actions.setLocation({
        path: "/platform/jobs",
      })
    );
    if (!auth.account) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (auth.selectedGroup === "JOB") {
      checkScrollTo();
    }
  });
  const getSkills = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_skills`)
      .then((res) => {
        let tmpHotSkills: any[] = [];
        res.data.forEach((data: any) => {
          if (data.is_hot_skill) {
            tmpHotSkills.push(data);
          }
        });

        setHotSkills(tmpHotSkills);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkScrollTo = () => {
    if (scrollToRef.current?.offsetTop) {
      window.scrollTo({
        top: scrollToRef.current?.offsetTop - 100,
        behavior: "smooth",
      });
      setTimeout(function () {
        clearSelected();
      }, 2000);
    }
  };

  const clearSelected = () => {
    dispatch(authSlice.actions.setSelectedSubgroup(null));
    dispatch(authSlice.actions.setSelectedSubgroupId(null));
    dispatch(authSlice.actions.setSelectedGroup(null));
    dispatch(authSlice.actions.setSelectedGroupId(null));
  };
  useEffect(() => {
    if (jobs.length > 0 && selectedRequest.id) {
      handleRequestSelect(selectedRequest.id);
    }
  }, [jobs]);
  useEffect(() => {
    if (jobs.length > 0 && selectedRequest.id) {
      handleRequestSelect(selectedRequest.id);
    }
  }, [selectedRequest]);

  const getJobs = async () => {
    axiosService
      .get(
        `${process.env.REACT_APP_API_URL}/get_user_jobs_client/` + auth.account
      )
      .then((res) => {
        setJobs(res.data);
        if (auth.selectedGroup === "JOB" || selectedRequest.name) {
          res.data.forEach((job: any) => {
            if (
              (auth.selectedGroup === "JOB" &&
                job.id === auth.selectedGroupId) ||
              selectedRequest.id === job.rfpp.id
            ) {
              getRequests(job.rfpp);
            }
          });
        } else {
          getRequests(0);
        }
        if (filteredJobs.length > 0) {
          let tmpFiltered: any[] = [];
          filteredJobs.forEach((filter: any) => {
            res.data.forEach((lead: any) => {
              if (
                lead.status === filter.status &&
                !tmpFiltered.includes(lead)
              ) {
                tmpFiltered.push(lead);
              }
            });
          });
          setFilteredJobs(tmpFiltered);
        }
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  const handleChangeMobileFilter = (value: string) => {
    setMobileFilter(value);
    if (value == "ACCEPTED") {
      handleAcceptedClickMobile();
    } else if (value == "FINISHED") {
      handleFinishedClickMobile();
    } else if (value == "STARTED") {
      handleStartedClickMobile();
    } else if (value == "REJECTED") {
      handleRejectedClickMobile();
    } else if (value == "PENDING") {
      handlePendingClickMobile();
    } else {
      setFilteredJobs([]);
    }
  };
  const handleAcceptedClickMobile = () => {
    setAcceptedClicked(true);
    setPendingClicked(false);
    setStartedClicked(false);
    setFinishedClicked(false);
    setRejectedClicked(false);

    let tmp: any[] = [];
    filteredRfppJobs.forEach((job: any) => {
      if (job.status === "ACCEPTED") {
        tmp.push(job);
      }
    });
    setFilteredJobs(tmp);
  };
  const handlePendingClickMobile = () => {
    setAcceptedClicked(false);
    setPendingClicked(true);
    setStartedClicked(false);
    setFinishedClicked(false);
    setRejectedClicked(false);

    let tmp: any[] = [];
    filteredRfppJobs.forEach((job: any) => {
      if (job.status === "PENDING" || job.status === "HAS_APPLICANTS") {
        tmp.push(job);
      }
    });
    setFilteredJobs(tmp);
  };
  const handleRejectedClickMobile = () => {
    setAcceptedClicked(false);
    setPendingClicked(false);
    setStartedClicked(false);
    setFinishedClicked(false);
    setRejectedClicked(true);

    let tmp: any[] = [];
    filteredRfppJobs.forEach((job: any) => {
      if (job.status === "REJECTED") {
        tmp.push(job);
      }
    });
    setFilteredJobs(tmp);
  };
  const handleStartedClickMobile = () => {
    setAcceptedClicked(false);
    setPendingClicked(false);
    setStartedClicked(true);
    setFinishedClicked(false);
    setRejectedClicked(false);

    let tmp: any[] = [];
    filteredRfppJobs.forEach((job: any) => {
      if (job.status === "STARTED") {
        tmp.push(job);
      }
    });
    setFilteredJobs(tmp);
  };
  const handleFinishedClickMobile = () => {
    setAcceptedClicked(false);
    setPendingClicked(false);
    setStartedClicked(false);
    setFinishedClicked(true);
    setRejectedClicked(false);

    let tmp: any[] = [];
    filteredRfppJobs.forEach((job: any) => {
      if (job.status === "FINISHED") {
        tmp.push(job);
      }
    });
    setFilteredJobs(tmp);
  };
  const handleAcceptedClick = () => {
    if (!acceptedClicked) {
      let tmp: any[] = [];
      filteredRfppJobs.forEach((job: any) => {
        if (job.status === "ACCEPTED") {
          tmp.push(job);
        }
      });
      setFilteredJobs(tmp);
    } else {
      setFilteredJobs([]);
    }
    setAcceptedClicked(!acceptedClicked);
    setPendingClicked(false);
    setStartedClicked(false);
    setFinishedClicked(false);
    setRejectedClicked(false);
  };
  const handlePendingClick = () => {
    if (!pendingClicked) {
      let tmp: any[] = [];
      filteredRfppJobs.forEach((job: any) => {
        if (job.status === "PENDING" || job.status === "HAS_APPLICANTS") {
          tmp.push(job);
        }
      });
      setFilteredJobs(tmp);
    } else {
      setFilteredJobs([]);
    }
    setPendingClicked(!pendingClicked);
    setAcceptedClicked(false);
    setStartedClicked(false);
    setFinishedClicked(false);
    setRejectedClicked(false);
  };
  const handleRejectedClick = () => {
    if (!rejectedClicked) {
      let tmp: any[] = [];
      filteredRfppJobs.forEach((job: any) => {
        if (job.status === "REJECTED") {
          tmp.push(job);
        }
      });
      setFilteredJobs(tmp);
    } else {
      setFilteredJobs([]);
    }
    setRejectedClicked(!rejectedClicked);
    setAcceptedClicked(false);
    setPendingClicked(false);
    setStartedClicked(false);
    setFinishedClicked(false);
  };
  const handleStartedClick = () => {
    if (!startedClicked) {
      let tmp: any[] = [];
      filteredRfppJobs.forEach((job: any) => {
        if (job.status === "STARTED") {
          tmp.push(job);
        }
      });
      setFilteredJobs(tmp);
    } else {
      setFilteredJobs([]);
    }
    setStartedClicked(!startedClicked);
    setAcceptedClicked(false);
    setPendingClicked(false);
    setFinishedClicked(false);
    setRejectedClicked(false);
  };
  const handleFinishedClick = () => {
    if (!rejectedClicked) {
      let tmp: any[] = [];
      filteredRfppJobs.forEach((job: any) => {
        if (job.status === "FINISHED") {
          tmp.push(job);
        }
      });
      setFilteredJobs(tmp);
    } else {
      setFilteredJobs([]);
    }
    setFinishedClicked(!finishedClicked);
    setAcceptedClicked(false);
    setPendingClicked(false);
    setStartedClicked(false);
    setRejectedClicked(false);
  };
  const closeSelectCandidatesPopup = () => {
    setShowSelectCandidatesPopup(false);
    getJobs();
    setSelectedJob({});
  };

  const getRequests = (selectedId: number) => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_user_requests/` + auth.account)
      .then((res) => {
        setRequests(res.data);

        if (res.data.length > 0) {
          if (selectedId !== 0) {
            res.data.forEach((request: any) => {
              if (request.id == selectedId) {
                setSelectedRequest(request);
              }
            });
          } else {
            setSelectedRequest(res.data[0]);
          }
        }
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const handleRequestSelect = (value: any) => {
    let tmpPending = 0;
    let tmpAccepted = 0;
    let tmpRejected = 0;
    let tmpStarted = 0;
    let tmpFinished = 0;
    let tmpJobsAccepted: Array<any> = [];
    let tmpJobsFiltered: Array<any> = [];

    if (jobs.length > 0) {
      jobs.forEach((job: any) => {
        if (job.rfpp === value) {
          tmpJobsFiltered.push(job);
        }

        if (job.rfpp === value && job.status === "ACCEPTED") {
          tmpAccepted++;

          tmpJobsAccepted.push(job);
        } else if (
          job.rfpp === value &&
          (job.status === "PENDING" || job.status === "HAS_APPLICANTS")
        ) {
          tmpPending++;
        } else if (job.rfpp === value && job.status === "REJECTED") {
          tmpRejected++;
        } else if (job.rfpp === value && job.status === "STARTED") {
          tmpStarted++;
        } else if (job.rfpp === value && job.status === "FINISHED") {
          tmpFinished++;
        }
      });
    }

    setNumAccepted(tmpAccepted);
    setNumPending(tmpPending);
    setNumRejected(tmpRejected);
    setNumStarted(tmpStarted);
    setNumFinished(tmpFinished);
    setJobsAccepted(tmpJobsAccepted);
    setFilteredRfppJobs(tmpJobsFiltered);
  };
  const selectCandidates = (job: any) => {
    setSelectedJob(job);
    setShowSelectCandidatesPopup(true);
  };

  return (
    <div className="column-space-between">
      <span className="heading margin-l20">Jobs</span>
      {!auth.expert && (
        <div
          className="row-space-between wrap "
          style={{ justifyContent: hideHints ? "flex-end" : "space-between" }}
        >
          <div
            className="jobs-card-hints"
            style={{ display: hideHints ? "none" : "" }}
          >
            <div className="leads-hints-left">
              <div className="projects-row-start-2center">
                <img
                  className="bulb"
                  src={require("../../assets/images/bulb.png")}
                />
                <b className="heading-lora">Hints</b>
              </div>

              <span className="text-quicksand opp-grey">
                Select the RfPP that you want to administer your jobs for. Check
                the proposed developers and choose which one you’d want for that
                job. Once all jobs of a RfPP are staffed, we’ll start the
                project together. If you need any assistance, please write us a
                message from the Dashboard page.
              </span>
              <div className="opp-arrow-out">
                <span className="text-quicksand-small blue">Select RfPP </span>
                <ForwardIcon className="arrow" sx={{ height: 20 }} />
                <span className="text-quicksand-small blue">
                  {" "}
                  Choose developer from the shortlist for a job{" "}
                </span>
                <ForwardIcon className="arrow" sx={{ height: 20 }} />
                <span className="text-quicksand-small blue">
                  Head to the Projects section once we’ve started the project
                  together{" "}
                </span>
              </div>
            </div>
            <img
              className="jobs-img"
              src={require("../../assets/images/jobs1.png")}
            />
            {!hideHints && (
              <Tooltip title="Hide" placement="bottom" arrow>
                <RemoveCircleIcon
                  sx={{ fontSize: 30 }}
                  className="jobs-help-icon"
                  onClick={() => setHideHints(!hideHints)}
                  style={{
                    justifySelf: hideHints ? "flex-end" : "",
                  }}
                ></RemoveCircleIcon>
              </Tooltip>
            )}
          </div>

          <div
            className="separate-area"
            style={{ display: hideHints ? "none" : "" }}
          />
        </div>
      )}

      <div
        className="row-space-between"
        style={{ display: hideHints ? "" : "none" }}
      >
        <JobsHiddenHints
          hideHints={hideHints}
          setHideHints={setHideHints}
        ></JobsHiddenHints>
      </div>

      <div className="leads-background-grey">
        {requests.length > 0 && (
          <Box
            sx={{ minWidth: 278 }}
            className="projects-search-bar-contract padding-up-down20"
          >
            <FormControl fullWidth>
              <InputLabel shrink={true} id="demo-simple-select-label">
                Select Request
              </InputLabel>
              <Select
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                    label="Select Request"
                    notched
                  />
                }
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedRequest || ""}
                label="selectedRequest"
                onChange={(event) => setSelectedRequest(event.target.value)}
              >
                {requests.map((request: any) => {
                  return (
                    <MenuItem key={request.id} value={request}>
                      {request.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        )}

        {requests.length === 0 && (
          <Box sx={{ minWidth: 120 }} className="margin-t20 margin-b40">
            <FormControl fullWidth>
              <InputLabel shrink={true} id="demo-simple-select-label">
                Select Request
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={"You don't have any request yet"}
                label="Selectedrequest"
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                    label="Select Request"
                    notched
                  />
                }
              >
                <MenuItem value={"You don't have any request yet"}>
                  You don't have any request yet
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}

        {selectedRequest && selectedRequest.name && (
          <RequestCard request={selectedRequest} hotSkills={hotSkills} />
        )}

        {selectedJob && selectedJob.name && (
          <SelectCandidates
            show={showSelectCandidatesPopup}
            onClose={closeSelectCandidatesPopup}
            job_id={selectedJob.id}
            proposed_candidates={selectedJob.proposed_devs}
            suggested_candidates={selectedJob.suggested_proposed_devs}
            suggested_groups={selectedJob.suggested_proposed_groups}
            selected_candidates={selectedJob.accepted_devs}
            proposed_groups={selectedJob.proposed_groups}
            selected_groups={selectedJob.accepted_groups}
          />
        )}

        <div className="row-lightblue-jobs-client">
          {window.innerWidth >= 1365 ? (
            <div className="row-start">
              <div
                className="kpi-card-jobs margin-right-small"
                style={{
                  border: !pendingClicked
                    ? "0px solid grey"
                    : "1px solid #FF6210",
                  boxShadow: !pendingClicked
                    ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                    : "none",
                }}
                onClick={handlePendingClick}
              >
                <HourglassEmptyIcon className="margin-kpi-projects req-turquoise" />

                <strong className="text-quicksand700-small darkgrey">
                  {" "}
                  Pending Jobs
                </strong>
                <strong className="text-quicksand-small grey-text-margin">
                  ({numPending})
                </strong>
              </div>

              <div
                className="kpi-card-jobs margin-right-small"
                style={{
                  border: !acceptedClicked
                    ? "0px solid grey"
                    : "1px solid #FF6210",
                  boxShadow: !acceptedClicked
                    ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                    : "none",
                }}
                onClick={handleAcceptedClick}
              >
                <ThumbUpOffAltIcon className="margin-kpi-projects req-purple" />

                <strong className="text-quicksand700-small darkgrey">
                  Accepted Jobs
                </strong>
                <strong className="text-quicksand-small grey-text-margin">
                  ({numAccepted})
                </strong>
              </div>

              <div
                className="kpi-card-jobs margin-right-small"
                style={{
                  border: !finishedClicked
                    ? "0px solid grey"
                    : "1px solid #FF6210",
                  boxShadow: !finishedClicked
                    ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                    : "none",
                }}
                onClick={handleFinishedClick}
              >
                <DoneAllIcon className="margin-kpi-projects req-green" />

                <strong className="text-quicksand700-small darkgrey">
                  Finished Jobs
                </strong>
                <strong className="text-quicksand-small grey-text-margin">
                  ({numFinished})
                </strong>
              </div>

              <div
                className="kpi-card-jobs margin-right-small"
                style={{
                  border: !rejectedClicked
                    ? "0px solid grey"
                    : "1px solid #FF6210",
                  boxShadow: !rejectedClicked
                    ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                    : "none",
                }}
                onClick={handleRejectedClick}
              >
                <DoDisturbIcon className="margin-kpi-projects req-red" />
                <strong className="text-quicksand700-small darkgrey">
                  Rejected Jobs
                </strong>
                <strong className="text-quicksand-small grey-text-margin">
                  ({numRejected})
                </strong>
              </div>

              <div
                className="kpi-card-jobs"
                style={{
                  border: !startedClicked
                    ? "0px solid grey"
                    : "1px solid #FF6210",
                  boxShadow: !startedClicked
                    ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                    : "none",
                }}
                onClick={handleStartedClick}
              >
                <StartIcon className="margin-kpi-projects req-blue" />
                <strong className="text-quicksand700-small darkgrey">
                  Started Jobs
                </strong>
                <strong className="text-quicksand-small grey-text-margin">
                  ({numStarted})
                </strong>
              </div>
            </div>
          ) : (
            <Box className="full-percent-width">
              <FormControl fullWidth>
                <InputLabel shrink={true} id="demo-simple-select-label">
                  Filter Jobs
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={mobileFilter}
                  onChange={(event) => {
                    handleChangeMobileFilter(event.target.value);
                  }}
                  input={
                    <OutlinedInput
                      id="select-multiple-chip"
                      label="Filter Jobs"
                      notched
                    />
                  }
                >
                  <MenuItem value={"ALL"}>
                    <ClearAllIcon className="margin-r3" />
                    All Jobs
                  </MenuItem>
                  <MenuItem value={"PENDING"}>
                    {" "}
                    <HourglassEmptyIcon className="margin-r3 req-turquoise" />
                    Pending Jobs {"( " + numPending.toString() + " )"}
                  </MenuItem>
                  <MenuItem value={"ACCEPTED"}>
                    {" "}
                    <ThumbUpOffAltIcon className="margin-kpi-projects req-purple" />
                    Accepted Jobs {"( " + numAccepted.toString() + " )"}
                  </MenuItem>
                  <MenuItem value={"FINISHED"}>
                    <DoneAllIcon className="margin-kpi-projects req-green" />
                    Finished Jobs {"( " + numFinished.toString() + " )"}
                  </MenuItem>
                  <MenuItem value={"REJECTED"}>
                    <DoDisturbIcon className="margin-kpi-projects req-red" />
                    Rejected Jobs {"( " + numRejected.toString() + " )"}
                  </MenuItem>
                  <MenuItem value={"STARTED"}>
                    <StartIcon className="margin-kpi-projects req-blue" />
                    Started Jobs {"( " + numStarted.toString() + " )"}
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          )}
        </div>

        <div className="separate-area">
          <div>
            {filteredJobs.length > 0 && (
              <div>
                {filteredJobs.map((job: any) => {
                  const itemProps =
                    auth.selectedGroup === "JOB" &&
                    auth.selectedGroupId === job.id
                      ? { ref: scrollToRef }
                      : {};
                  return (
                    <div key={job.id} {...itemProps} onClick={clearSelected}>
                      <JobCardClient
                        highlight={job.id === auth.selectedGroupId}
                        key={job.id}
                        job={job}
                        hotSkills={hotSkills}
                        selectCandidates={selectCandidates}
                      />
                    </div>
                  );
                })}
              </div>
            )}
            {filteredJobs.length === 0 && (
              <div>
                {filteredRfppJobs.length > 0 &&
                  filteredRfppJobs.map((job: any) => {
                    const itemProps =
                      auth.selectedGroup === "JOB" &&
                      auth.selectedGroupId === job.id
                        ? { ref: scrollToRef }
                        : {};
                    return (
                      <div key={job.id} {...itemProps} onClick={clearSelected}>
                        <JobCardClient
                          highlight={job.id === auth.selectedGroupId}
                          key={job.id}
                          job={job}
                          hotSkills={hotSkills}
                          selectCandidates={selectCandidates}
                        />
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobsClient;
