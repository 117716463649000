import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Login from "./screens/login/Login";
import store, { persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import Home from "./screens/home/Home";
import "./App.css";
import Header from "./components/header/Header";
import Developers from "./screens/developers/Developers";
import About from "./screens/about/About";
import Platform from "./screens/platform/Platform";
import { useSelector } from "react-redux";
import { RootState } from "./store";
import Calendar from "./screens/calendar/Calendar";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import Leads from "./screens/leads/Leads";
import Opportunities from "./screens/opportunities/Opportunities";
import Jobs from "./screens/jobs/Jobs";
import PlatformNavigation from "./components/platform-navigation/PlatformNavigation";
import Projects from "./screens/projects/Projects";
import { CSSTransition } from "react-transition-group";
import Pricing from "./screens/pricing/Pricing";
import Requests from "./screens/requests/Requests";
import Footer from "./components/footer/Footer";
import Users from "./screens/users/Users";
import Payments from "./screens/payments/Payments";
import PlatformProfile from "./screens/platform-profile/PlatformProfile";
import { positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "./components/alerts/AlertTemplate";
import StarIcon from "@mui/icons-material/Star";
import WorkIcon from "@mui/icons-material/Work";
import PeopleIcon from "@mui/icons-material/People";
import DvrIcon from "@mui/icons-material/Dvr";
import InventoryIcon from "@mui/icons-material/Inventory";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PaymentIcon from "@mui/icons-material/Payment";
import PublicProfile from "./screens/public-profile/PublicProfile";
import OrganizationProfile from "./screens/organization-profile/OrganizationProfile";
import ContactPage from "./screens/contact-page/ContactPage";
import Modal from "@mui/material/Modal";
import RequestsSignUp from "./screens/requests-sign-up/RequestsSignUp";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import BlogList from "./screens/blogs/BlogList";
import BlogDetail from "./screens/blogs/BlogDetail";
import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga4";
import Privacy from "./screens/privacy/Privacy";
import Terms from "./screens/terms/Terms";

declare global {
  interface Window {
    GA_INITIALIZED?: boolean;
  }
}

const options = {
  timeout: 10000,
  position: positions.TOP_RIGHT,
  containerStyle: { zIndex: 5000, borderRadius: 8 },
};

export default function AppWrapper() {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
}

function App() {
  const [sideBar, setSideBar] = useState(false);
  const nodeRef = React.useRef<HTMLDivElement>(null);
  const handleSidebar = () => {
    setSideBar(!sideBar);
  };
  const auth = useSelector((state: RootState) => state.auth);
  const path = useSelector((state: RootState) => state.location);
  const duration = 300;

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  };

  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  const [isAnalyticsEnabled, setIsAnalyticsEnabled] = useState(false);
  const [updateConsent, setUpdateConsent] = useState(false);

  const initializeGA = () => {
    if (!window.GA_INITIALIZED) {
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID || "");
      window.GA_INITIALIZED = true;
    }
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  };

  const checkConsent = () => {
    const consent = localStorage.getItem("analyticsConsent");
    if (consent === "true") {
      setIsAnalyticsEnabled(true);
      initializeGA();
    } else {
      setIsAnalyticsEnabled(false);
    }
  };

  useEffect(() => {
    checkConsent();
  }, [updateConsent]);

  const handleAccept = () => {
    localStorage.setItem("analyticsConsent", "true");
    setIsAnalyticsEnabled(true);
    initializeGA();
  };

  const handleDecline = () => {
    localStorage.setItem("analyticsConsent", "false");
    setIsAnalyticsEnabled(false);
    // Optionally: Remove any previously set GA cookies or identifiers
  };

  useEffect(() => {
    checkConsent();

    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === "analyticsConsent") {
        checkConsent();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const handleOutsideClicks = (event: MouseEvent) => {
    if (
      sideBar &&
      nodeRef &&
      nodeRef.current &&
      !nodeRef.current.contains(event.target as HTMLDivElement)
    ) {
      setSideBar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClicks);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClicks);
    };
  }, [sideBar]);

  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <CookieConsent
        onAccept={() => {
          localStorage.setItem("analyticsConsent", "true");
          setUpdateConsent((prev) => !prev);
        }}
        enableDeclineButton
        onDecline={() => {
          localStorage.setItem("analyticsConsent", "false");
          setUpdateConsent((prev) => !prev);
        }}
        declineButtonText="Decline"
        buttonText="Accept"
      >
        We use cookies to enhance your experience. By accepting, you agree to
        our use of cookies for analytics.
      </CookieConsent>
      <PersistGate persistor={persistor} loading={null}>
        <Router>
          <Header handleSidebar={handleSidebar} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              height: "100%",
            }}
          >
            {path.location &&
              path.location.includes("platform") &&
              window.innerWidth >= 800 && <PlatformNavigation />}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/developers" element={<Developers />} />
                <Route path="/platform" element={<Platform />} />
                <Route path="/blogs" element={<BlogList />} />
                <Route path="/blogs/:blog_id" element={<BlogDetail />} />
                <Route path="/platform/leads" element={<Leads />} />
                <Route path="/platform/users" element={<Users />} />
                <Route
                  path="/platform/public-profile"
                  element={<PublicProfile />}
                />
                <Route
                  path="/platform/organization-profile"
                  element={<OrganizationProfile />}
                />
                <Route path="/platform/projects" element={<Projects />} />
                <Route
                  path="/platform/opportunities"
                  element={<Opportunities />}
                />
                <Route path="/platform/requests" element={<Requests />} />
                <Route path="/platform/payments" element={<Payments />} />
                <Route
                  path="/platform/requests_sign_up"
                  element={<RequestsSignUp />}
                />
                <Route path="/platform/profile" element={<PlatformProfile />} />
                <Route path="/platform/jobs" element={<Jobs />} />
                <Route path="/platform/calendar" element={<Calendar />} />
                <Route path="/" element={<Home />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/privacy-policy" element={<Privacy />} />
                <Route path="/terms-conditions" element={<Terms />} />
                <Route path="/about" element={<About />} />
              </Routes>
            </div>
          </div>
          {path.location && !path.location.includes("platform") && (
            <Footer onUpdateConsent={() => setUpdateConsent((prev) => !prev)} />
          )}
          {sideBar && (
            <Modal
              open={sideBar}
              onClose={() => setSideBar(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ zIndex: 999 }}
            >
              <div>
                <div
                  style={{
                    position: "absolute",
                    top: 80,
                    left: 0,
                    right: 0,
                    height: "100%",
                    width: "100vw",
                    background: "grey",
                    opacity: 0.6,
                    zIndex: 10,
                  }}
                />
                <CSSTransition
                  in={sideBar}
                  timeout={200}
                  nodeRef={nodeRef}
                  classNames="my-node"
                >
                  {!path?.location?.includes("platform") ? (
                    <div ref={nodeRef} className="my-node">
                      <button
                        onClick={() => setSideBar(false)}
                        className="button nav"
                        style={{
                          borderBottom:
                            path.location === "/"
                              ? "4px solid lightGrey"
                              : "none",
                          marginTop: path.location === "/" ? 4 : 0,
                        }}
                      >
                        <Link className="link" to="/">
                          Home
                        </Link>
                      </button>
                      <button
                        onClick={() => setSideBar(false)}
                        className="button nav"
                        style={{
                          borderBottom:
                            path.location === "/pricing"
                              ? "4px solid lightGrey"
                              : "none",
                          marginTop: path.location === "/pricing" ? 4 : 0,
                        }}
                      >
                        <Link className="link" to="/pricing">
                          Pricing
                        </Link>
                      </button>
                      <button
                        onClick={() => setSideBar(false)}
                        className="button nav"
                        style={{
                          borderBottom:
                            path.location === "/developers"
                              ? "4px solid lightGrey"
                              : "none",
                          marginTop: path.location === "/developers" ? 4 : 0,
                        }}
                      >
                        <Link className="link" to="/developers">
                          Developers
                        </Link>
                      </button>
                      <button
                        onClick={() => setSideBar(false)}
                        className="button nav"
                        style={{
                          borderBottom:
                            path.location === "/about"
                              ? "4px solid lightGrey"
                              : "none",
                          marginTop: path.location === "/about" ? 4 : 0,
                        }}
                      >
                        <Link className="link" to="/about">
                          About
                        </Link>
                      </button>
                      <button
                        onClick={() => setSideBar(false)}
                        className="button nav"
                        style={{
                          borderBottom:
                            path.location === "/blogs"
                              ? "4px solid lightGrey"
                              : "none",
                          marginTop: path.location === "/blogs" ? 4 : 0,
                        }}
                      >
                        <Link className="link" to="/blogs">
                          Blogs
                        </Link>
                      </button>
                      {auth.account && (
                        <button
                          onClick={() => setSideBar(false)}
                          className="button nav"
                          style={{
                            borderBottom:
                              path.location === "/platform"
                                ? "4px solid lightGrey"
                                : "none",
                            marginTop: path.location === "/platform" ? 4 : 0,
                          }}
                        >
                          <Link className="link " to="/platform">
                            Platform
                          </Link>
                        </button>
                      )}
                      {!auth.account && (
                        <button
                          onClick={() => setSideBar(false)}
                          className="button nav"
                          style={{
                            borderBottom:
                              path.location === "/login"
                                ? "4px solid lightGrey"
                                : "none",
                            marginTop: path.location === "/login" ? 4 : 0,
                          }}
                        >
                          <Link className="link " to="/login">
                            Platform
                          </Link>
                        </button>
                      )}
                    </div>
                  ) : (
                    <div ref={nodeRef} className="my-node">
                      <button
                        onClick={() => setSideBar(false)}
                        className="nav-button"
                        style={{
                          backgroundColor:
                            path.location === "/platform"
                              ? "#E4EDFF"
                              : "transparent",
                          borderRight:
                            path.location === "/platform"
                              ? "3px solid #626da1"
                              : "0px solid #626da1",
                        }}
                      >
                        <Link className="nav-link" to="/platform">
                          {" "}
                          <DvrIcon className="nav-icon" />
                          Dashboard
                        </Link>
                      </button>
                      {auth.selectedView === "DEVELOPER" ? (
                        <button
                          onClick={() => setSideBar(false)}
                          className="nav-button "
                          style={{
                            backgroundColor:
                              path.location === "/platform/opportunities"
                                ? "#E4EDFF"
                                : "transparent",
                            borderRight:
                              path.location === "/platform/opportunities"
                                ? "3px solid #626da1"
                                : "0px solid #626da1",
                          }}
                        >
                          <Link
                            className="nav-link"
                            to="/platform/opportunities"
                          >
                            <InventoryIcon className="nav-icon" />
                            Opportunities
                          </Link>
                        </button>
                      ) : (
                        (auth.selectedView === "CLIENT" ||
                          auth.selectedView === "GYLD MANAGER") && (
                          <button
                            onClick={() => setSideBar(false)}
                            className="nav-button"
                            style={{
                              backgroundColor:
                                path.location === "/platform/requests"
                                  ? "#E4EDFF"
                                  : "transparent",
                            }}
                          >
                            <Link className="nav-link" to="/platform/requests">
                              <QuestionAnswerIcon className="nav-icon" />
                              Requests
                            </Link>
                          </button>
                        )
                      )}
                      {auth.selectedView !== "LEAD PROVIDER" && (
                        <button
                          onClick={() => setSideBar(false)}
                          className="nav-button"
                          style={{
                            backgroundColor:
                              path.location === "/platform/jobs"
                                ? "#E4EDFF"
                                : "transparent",
                            borderRight:
                              path.location === "/platform/jobs"
                                ? "3px solid #626da1"
                                : "0px solid #626da1",
                          }}
                        >
                          <Link className="nav-link" to="/platform/jobs">
                            <StarIcon className="nav-icon" />
                            Jobs
                          </Link>
                        </button>
                      )}
                      {auth.selectedView !== "LEAD PROVIDER" && (
                        <button
                          onClick={() => setSideBar(false)}
                          className="nav-button"
                          style={{
                            backgroundColor:
                              path.location === "/platform/projects"
                                ? "#E4EDFF"
                                : "transparent",
                            borderRight:
                              path.location === "/platform/projects"
                                ? "3px solid #626da1"
                                : "0px solid #626da1",
                          }}
                        >
                          <Link className="nav-link" to="/platform/projects">
                            <WorkIcon className="nav-icon" />
                            Projects
                          </Link>
                        </button>
                      )}
                      {auth.selectedView === "GYLD MANAGER" && (
                        <button
                          onClick={() => setSideBar(false)}
                          className="nav-button"
                          style={{
                            backgroundColor:
                              path.location === "/platform/users"
                                ? "#E4EDFF"
                                : "transparent",
                          }}
                        >
                          <Link className="nav-link" to="/platform/users">
                            <GroupAddIcon className="nav-icon" />
                            Users
                          </Link>
                        </button>
                      )}
                      {auth.selectedView === "GYLD MANAGER" && (
                        <button
                          onClick={() => setSideBar(false)}
                          className="nav-button "
                          style={{
                            backgroundColor:
                              path.location === "/platform/payments"
                                ? "#E4EDFF"
                                : "transparent",
                          }}
                        >
                          <Link className="nav-link" to="/platform/payments">
                            <PaymentIcon className="nav-icon" />
                            Payments
                          </Link>
                        </button>
                      )}
                      {auth.selectedView === "GYLD MANAGER" && (
                        <button
                          onClick={() => setSideBar(false)}
                          className="nav-button "
                          style={{
                            backgroundColor:
                              path.location === "/platform/requests_sign_up"
                                ? "#E4EDFF"
                                : "transparent",
                          }}
                        >
                          <Link
                            className="nav-link"
                            to="/platform/requests_sign_up"
                          >
                            <AddCircleIcon className="nav-icon" />
                            Requests for Sign Up
                          </Link>
                        </button>
                      )}
                      <button
                        onClick={() => setSideBar(false)}
                        className="nav-button"
                        style={{
                          backgroundColor:
                            path.location === "/platform/leads"
                              ? "#E4EDFF"
                              : "transparent",
                          borderRight:
                            path.location === "/platform/leads"
                              ? "3px solid #626da1"
                              : "0px solid #626da1",
                        }}
                      >
                        <Link className="nav-link" to="/platform/leads">
                          <PeopleIcon className="nav-icon" />
                          Leads
                        </Link>
                      </button>
                    </div>
                  )}
                </CSSTransition>
              </div>
            </Modal>
          )}
        </Router>
      </PersistGate>
    </AlertProvider>
  );
}
