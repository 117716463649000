import React, { useState, useEffect } from "react";
import Moment from "moment";
import "./OpportunityCard.css";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import VerifiedIcon from "@mui/icons-material/Verified";
import axiosService from "../../utils/axios";
import DeleteConfirmation from "../delete-confirmation/DeleteConfirmation";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import PushPinIcon from "@mui/icons-material/PushPin";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PublicProfileClient from "../../screens/public-profile/PublicProfileClient";
import { useAlert } from "react-alert";
import Modal from "@mui/material/Modal";
import WhatshotIcon from "@mui/icons-material/Whatshot";

function OpportunityCard(props: any) {
  const auth = useSelector((state: RootState) => state.auth);
  const alert = useAlert();
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [readMore, setReadMore] = useState(false);
  const [showProfileClient, setShowProfileClient] = useState(false);
  const [selectedClient, setSelectedClient] = useState<any>();
  const deleteOpportunity = () => {
    axiosService
      .delete(
        `${process.env.REACT_APP_API_URL}/delete_user_opportunity/` +
          props.opportunity.opportunity_id
      )
      .then((res) => {
        setShowDeletePopup(false);
        props.getJobs();
        props.getUserOpportunities();
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const closeProfile = () => {
    setShowProfileClient(false);
    setSelectedClient(null);
  };
  const showPublicProfileClient = (user: any) => {
    setShowProfileClient(true);
    setSelectedClient(user.id);
  };

  const hideJob = () => {
    let body = { user: auth.account, job_id: props.opportunity.id };
    axiosService
      .post(`${process.env.REACT_APP_API_URL}/hide_job`, body)
      .then((res) => {
        alert.success("The Opportunity is Hidden");
        props.getJobs();
        props.getUserOpportunities();
        props.handleSearch();
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  const unhideJob = () => {
    let body = { user: auth.account, job_id: props.opportunity.id };
    axiosService
      .post(`${process.env.REACT_APP_API_URL}/unhide_job`, body)
      .then((res) => {
        alert.success("The Opportunity is no longer Hidden");
        props.getJobs();
        props.getUserOpportunities();
        props.handleSearch();
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        closeProfile();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);
  return (
    <div
      className={
        props.highlight
          ? !readMore
            ? "opp-card-out highlight-selected"
            : "opp-boxes-readmore  highlight-selected"
          : !readMore
          ? "opp-card-out "
          : "opp-boxes-readmore  "
      }
    >
      {showDeletePopup && (
        <DeleteConfirmation
          deleteOpportunity={deleteOpportunity}
          setShowDeletePopup={setShowDeletePopup}
        />
      )}
      {showProfileClient && (
        // <div
        //   style={{
        //     visibility: showProfileClient ? "visible" : "hidden",
        //     opacity: showProfileClient ? "1" : "0",
        //   }}
        //   className={"overlay"}
        // >
        <Modal
          open={showProfileClient}
          onClose={closeProfile}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className={"popup-pub-profile-client"}>
            <span className={"close align-self-end"} onClick={closeProfile}>
              &times;
            </span>
            <PublicProfileClient user={selectedClient} />
          </div>
        </Modal>
      )}
      <div className="row-space-between margin-b10">
        {/* <div className="lead-card-info"> */}
        <span className="text-quicksand700">
          {props.opportunity.name}
        </span>
        {/* <span className="card-information">- Opportunity -</span> */}
        {/* </div> */}
        {props.opportunity.verified_client && <VerifiedIcon color="success" />}
      </div>
      {window.innerWidth >= 1000 ? (
        <div className="opp-card-row-2column">
          {/* <span className="opp-status">
  Status:{" "}
  {props.opportunity.status.charAt(0).toUpperCase() +
    props.opportunity.status.slice(1).toLowerCase()}
</span>
      { window.innerWidth >1200&&
        <div className="ball"></div>
} */}
          <span className="opp-status">
            Field:{" "}
            {props.opportunity.industry.charAt(0).toUpperCase() +
              props.opportunity.industry.slice(1).toLowerCase()}
          </span>
          {window.innerWidth > 1000 && <div className="ball"></div>}
          <span className="opp-status">
            Client:{" "}
            {props.opportunity.client_type.charAt(0).toUpperCase() +
              props.opportunity.client_type.slice(1).toLowerCase()}
          </span>
          {window.innerWidth > 1000 && <div className="ball"></div>}
          <span className="opp-status">
            {" "}
            {Moment(props.opportunity.start_date).format("D MMM yy")} -{" "}
            {Moment(props.opportunity.end_date).format("D MMM yy")}
          </span>
          {window.innerWidth > 1000 && <div className="ball"></div>}
          <span className="opp-status">
            {props.opportunity.job_type
              .toLowerCase()
              .replace(/_/g, " ")
              .charAt(0)
              .toUpperCase() +
              props.opportunity.job_type
                .toLowerCase()
                .replace(/_/g, " ")
                .slice(1)}
          </span>
        </div>
      ) : (
        <div className="opp-box-row">
          <span className="opp-status">
            Field:{" "}
            {props.opportunity.industry.charAt(0).toUpperCase() +
              props.opportunity.industry.slice(1).toLowerCase()}
          </span>

          <span className="opp-status">
            Client:{" "}
            {props.opportunity.client_type.charAt(0).toUpperCase() +
              props.opportunity.client_type.slice(1).toLowerCase()}
          </span>

          <span className="opp-status">
            {" "}
            {Moment(props.opportunity.start_date).format("D MMM yy")} -{" "}
            {Moment(props.opportunity.end_date).format("D MMM yy")}
          </span>

          <span className="opp-status">
            {props.opportunity.job_type
              .toLowerCase()
              .replace(/_/g, " ")
              .charAt(0)
              .toUpperCase() +
              props.opportunity.job_type
                .toLowerCase()
                .replace(/_/g, " ")
                .slice(1)}
          </span>
        </div>
      )}

      <div className="margin-t10 margin-b20">
        <span className="text-quicksand  ">
          {!readMore
            ? [...props.opportunity.description].slice(0, 400)
            : props.opportunity.description}
        </span>
        {[...props.opportunity.description].length > 400 && (
          <span className="text-quicksand-small opp-grey ">...</span>
        )}
        {[...props.opportunity.description].length > 400 && (
          <span
            className="text-quicksand-700 orange-text pointer margin-l10"
            onClick={() => setReadMore(!readMore)}
          >
            {readMore ? "Read Less" : "Read More"}
          </span>
        )}
      </div>

      <div className="opp-card-skills">
        {props.opportunity.skills.map((skill: any, i: number) => {
          return (
            <span key={skill + i.toString()} className="lead-card-skills-buttons">
              {skill}
              {props.hotSkills?.map((hotSkill: any) => {
                if (hotSkill.name === skill) {
                  return (
                    <WhatshotIcon
                      key={hotSkill.name}
                      style={{
                        color: "#ff6210",
                        marginBottom: 5,
                      }}
                    />
                  );
                }
              })}
            </span>
          );
        })}
      </div>

      <div
        className={
          [...props.opportunity.description].length > 400
            ? "opportunity-row-s-b margin-b10n"
            : "opportunity-row-s-b "
        }
      >
        <div className="opp-box-row-end">
          <div className="opp-margin row-start-center">
            <span className="text-quicksand-small margin-r3">
              Developer Level:{" "}
            </span>
            <span className="text-quicksand-small opp-lightgrey">
              {" "}
              {props.opportunity.developer_level.charAt(0).toUpperCase() +
                props.opportunity.developer_level.slice(1).toLowerCase()}
            </span>
          </div>
          <div className="opp-margin row-start-center">
            <span className="text-quicksand-small margin-r3">
              Developers needed:{" "}
            </span>
            <span className="text-quicksand-small opp-lightgrey">
              {props.opportunity.num_developers}
            </span>
          </div>
          {auth.selectedView !== "CLIENT" &&
            props.opportunity.client.username && (
              <div className="opp-margin row-start-center">
                <span className="text-quicksand-small">Client: </span>
                {props.opportunity.client.image &&
                props.opportunity.client.image.includes("media") ? (
                  <img
                    onClick={() =>
                      showPublicProfileClient(props.opportunity.client)
                    }
                    src={
                      process.env.REACT_APP_API_URL?.split("/api")[0] +
                      props.opportunity.client.image
                    }
                    className="profile-img-small"
                  />
                ) : (
                  <div
                    className="profile-img-small no-pic-small"
                    onClick={() =>
                      showPublicProfileClient(props.opportunity.client)
                    }
                  >
                    <span>{props.opportunity.client.username.charAt(0)}</span>
                  </div>
                )}

                <span className="text-quicksand700 darkgrey padding-normal">
                  {props.opportunity.client.username}
                </span>
              </div>
            )}
        </div>

        <div className="opp-box-end-end">
          <div className="circle-left">
            <Tooltip title="Save" placement="bottom" arrow>
              <PushPinIcon
                className="opp-box-end-icon"
                onClick={() => props.saveOpportunity(props.opportunity.id)}
              ></PushPinIcon>
            </Tooltip>
          </div>
          {props.isSuggested && (
            <div className="circle-right">
              <Tooltip title="Delete" placement="bottom" arrow>
                <DeleteIcon
                  className="opp-box-end-icon"
                  onClick={() => setShowDeletePopup(true)}
                ></DeleteIcon>
              </Tooltip>
            </div>
          )}
          {!props.opportunity.hidden && !props.isSuggested ? (
            <div className="circle-right">
              <Tooltip title="Hide" placement="bottom" arrow>
                <VisibilityOffIcon
                  onClick={hideJob}
                  style={{
                    color: "#626DA1",

                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </div>
          ) : (
            !props.isSuggested && (
              <div className="circle-right">
                <Tooltip title="Show" placement="bottom" arrow>
                  <VisibilityIcon
                    onClick={unhideJob}
                    style={{
                      color: "#626DA1",

                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default OpportunityCard;
