import React, { useState, useEffect } from "react";
import Moment from "moment";
import "./TimesheetCard.css";
import { useAlert } from "react-alert";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import axiosService from "../../utils/axios";
import DownloadIcon from "@mui/icons-material/Download";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";

function TimesheetCard(props: any) {
  const alert = useAlert();
  const deleteTimesheet = () => {
    axiosService
      .delete(
        `${process.env.REACT_APP_API_URL}/delete_timesheet/` +
          props.timesheet.id
      )
      .then((res) => {
        props.getProjects();
      })

      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  return (
    <div
      className={
        props.highlight
          ? "opp-box-timesheets-half full-percent-width highlight-selected"
          : "opp-box-timesheets-half full-percent-width"
      }
    >
      <div className="row-space-between">
        <span className="text-quicksand700 opp-grey">
          Timesheet {props.timesheet.id}
        </span>
      </div>
      <div className="opp-box-row">
        <span className="opp-status">
          {props.timesheet.from_date && (
            <span className="font">
              {Moment(props.timesheet.from_date).format("D MMM yy")}-
            </span>
          )}
          {props.timesheet.to_date && (
            <span className="font">
              {Moment(props.timesheet.to_date).format("D MMM yy")}
            </span>
          )}
        </span>
      </div>
      <div className="opp-box-row">
        <span
          style={{
            color:
              props.timesheet.status === "REJECTED"
                ? "#c47878"
                : props.timesheet.status === "NEW"
                ? "#767fad"
                : "#366B56",
          }}
          className="opp-status"
        >
          Status:{" "}
          {props.timesheet.status
            .toLowerCase()
            .replace(/_/g, " ")
            .charAt(0)
            .toUpperCase() +
            props.timesheet.status.toLowerCase().replace(/_/g, " ").slice(1)}
        </span>
        {window.innerWidth > 1200 && <div className="ball"></div>}
        <span className="opp-status">
          Hours:{" "}
          {props.timesheet.hours}
        </span>
      </div>
      {/* <span className="text-quicksand">
        <a href={props.timesheet.file} download>
          Download
        </a>
      </span> */}

      <div className="opp-box-end-end">
        <div className="circle-left">
          <a
            target="_blank"
            href={
              process.env.REACT_APP_API_URL?.split("/api")[0] +
              props.timesheet?.file
            }
            download
          >
            <Tooltip title="Download" placement="bottom" arrow>
              <DownloadIcon style={{ color: "#626DA1" }} />
            </Tooltip>
          </a>
        </div>

        {props.timesheet.status === "NEW" && (
          <div className="circle-right">
            <Tooltip title="Delete" placement="bottom" arrow>
              <DeleteIcon
                onClick={deleteTimesheet}
                style={{
                  color: "#626DA1",
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
}

export default TimesheetCard;
