import React, { useState, useEffect } from "react";
import axiosService from "../../utils/axios";
import LeadCard from "../../components/lead-card/LeadCard";
import locationSlice from "../../store/slices/location";
import "./Leads.css";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import CloseIcon from "@mui/icons-material/Close";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import PendingIcon from "@mui/icons-material/Pending";
import CheckIcon from "@mui/icons-material/Check";
import HelpIcon from "@mui/icons-material/Help";
import AddLead from "../../components/add-lead/AddLead";
import Box from "@mui/material/Box";
import { useAlert } from "react-alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import AddJob from "../../components/add-job/AddJob";
import AddRequest from "../../components/add-request/AddRequest";
import StartIcon from "@mui/icons-material/Start";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";

function LeadsAdmin() {
  const [leads, setLeads] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState<Array<any>>([]);

  const [checkedClicked, setCheckedClicked] = useState(false);
  const [acceptedClicked, setAcceptedClicked] = useState(false);
  const [rejectedClicked, setRejectedClicked] = useState(false);
  const [startedClicked, setStartedClicked] = useState(false);
  const [finishedClicked, setFinishedClicked] = useState(false);
  const [numChecked, setNumChecked] = useState(0);
  const [numAccepted, setNumAccepted] = useState(0);
  const [numRejected, setNumRejected] = useState(0);
  const [numStarted, setNumStarted] = useState(0);
  const [numFinished, setNumFinished] = useState(0);
  const [isFirst, setIsFirst] = useState(true);
  const [hideHints, setHideHints] = useState(false);
  const [showAddLeadPopup, setShowAddLeadPopup] = useState(false);
  const [showAddRequestPopup, setShowAddRequestPopup] = useState(false);
  const [selectedLead, setSelectedLead] = useState<any>({});
  const [openCreateJob, setOpenCreateJob] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [hotSkills, setHotSkills] = useState<any[]>([]);

  const [openError, setOpenError] = useState(false);
  const alert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (isFirst) {
      getLeads();
      getSkills();
      setIsFirst(false);
    }
  }, []);
  const getSkills = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_skills`)
      .then((res) => {
        let tmpHotSkills: any[] = [];
        res.data.forEach((data: any) => {
          if (data.is_hot_skill) {
            tmpHotSkills.push(data);
          }
        });

        setHotSkills(tmpHotSkills);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLeads = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_leads`)
      .then((res) => {
        setLeads(res.data);
        if (filteredLeads.length > 0) {
          let tmpFiltered: any[] = [];
          filteredLeads.forEach((filter: any) => {
            res.data.forEach((lead: any) => {
              if (
                lead.status === filter.status &&
                !tmpFiltered.includes(lead)
              ) {
                tmpFiltered.push(lead);
              }
            });
          });
          setFilteredLeads(tmpFiltered);
        }

        if (res.data.length > 0) {
          let tmpRej = 0;
          let tmpFin = 0;
          let tmpSt = 0;
          let tmpAcc = 0;
          let tmpPend = 0;

          res.data.forEach((lead: any) => {
            if (lead.status === "REJECTED") {
              tmpRej++;
            } else if (lead.status === "FINISHED") {
              tmpFin++;
            } else if (lead.status === "STARTED") {
              tmpSt++;
            } else if (lead.status === "ACCEPTED") {
              tmpAcc++;
            } else if (lead.status === "PENDING") {
              tmpPend++;
            }
          });
          setNumAccepted(tmpAcc);
          setNumChecked(tmpPend);
          setNumFinished(tmpFin);
          setNumStarted(tmpSt);
          setNumRejected(tmpRej);
        }
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const handleFinishedClick = () => {
    setFinishedClicked(!finishedClicked);
    if (!finishedClicked) {
      let tmp = filteredLeads;
      leads.forEach((lead: any) => {
        if (lead.status === "FINISHED") {
          tmp.push(lead);
        }
      });
      setFilteredLeads(tmp);
    } else {
      let tmp: any[] = [];
      filteredLeads.forEach((lead: any) => {
        if (lead.status !== "FINISHED") {
          tmp.push(lead);
        }
      });
      setFilteredLeads(tmp);
    }
  };
  const handleCheckedClick = () => {
    setCheckedClicked(!checkedClicked);
    if (!checkedClicked) {
      let tmp = filteredLeads;
      leads.forEach((lead: any) => {
        if (lead.status === "PENDING") {
          tmp.push(lead);
        }
      });
      setFilteredLeads(tmp);
    } else {
      let tmp: any[] = [];
      filteredLeads.forEach((lead: any) => {
        if (lead.status !== "PENDING") {
          tmp.push(lead);
        }
      });

      setFilteredLeads(tmp);
    }
  };
  const handleAcceptedClick = () => {
    setAcceptedClicked(!acceptedClicked);
    if (!acceptedClicked) {
      let tmp = filteredLeads;
      leads.forEach((lead: any) => {
        if (lead.status === "ACCEPTED") {
          tmp.push(lead);
        }
      });
      setFilteredLeads(tmp);
    } else {
      let tmp: any[] = [];
      filteredLeads.forEach((lead: any) => {
        if (lead.status !== "ACCEPTED") {
          tmp.push(lead);
        }
      });

      setFilteredLeads(tmp);
    }
  };
  const handleRejectedClick = () => {
    setRejectedClicked(!rejectedClicked);
    if (!rejectedClicked) {
      let tmp = filteredLeads;
      leads.forEach((lead: any) => {
        if (lead.status === "REJECTED") {
          tmp.push(lead);
        }
      });
      setFilteredLeads(tmp);
    } else {
      let tmp: any[] = [];
      filteredLeads.forEach((lead: any) => {
        if (lead.status !== "REJECTED") {
          tmp.push(lead);
        }
      });

      setFilteredLeads(tmp);
    }
  };
  const handleStartedClick = () => {
    setStartedClicked(!startedClicked);
    if (!startedClicked) {
      let tmp = filteredLeads;
      leads.forEach((lead: any) => {
        if (lead.status === "STARTED") {
          tmp.push(lead);
        }
      });
      setFilteredLeads(tmp);
    } else {
      let tmp: any[] = [];
      filteredLeads.forEach((lead: any) => {
        if (lead.status !== "STARTED") {
          tmp.push(lead);
        }
      });

      setFilteredLeads(tmp);
    }
  };
  const editLead = (lead: any) => {
    setSelectedLead(lead);
    setShowAddLeadPopup(true);
  };
  const addRequest = (lead: any) => {
    setSelectedLead(lead);
    setShowAddRequestPopup(true);
  };
  const closeRequestPopup = () => {
    setShowAddRequestPopup(false);
    getLeads();
    setSelectedLead({});
  };

  const closeLeadPopup = () => {
    setShowAddLeadPopup(false);
    getLeads();
    setSelectedLead({});
  };
  const closeCreateJob = () => {
    setOpenCreateJob(false);
    getLeads();
    setSelectedLead({});
  };

  const acceptLead = (id: number) => {
    changeStatusLead(id, "ACCEPTED");
  };
  const rejectLead = (id: number) => {
    changeStatusLead(id, "REJECTED");
  };
  const changeStatusLead = (id: number, status: string) => {
    let body = {
      id: id,
      status: status,
    };
    axiosService
      .post(`${process.env.REACT_APP_API_URL}/change_lead_status`, body)
      .then((res) => {
        alert.success("The status has been updated.");
        getLeads();
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }
      });
  };
  const createJob = (lead: any) => {
    setSelectedLead(lead);
    setOpenCreateJob(true);
  };
  return (
    <div className="column-space-between">
      <div className="leads-background-grey">
        <div
          className="row-space-between wrap "
          style={{ justifyContent: hideHints ? "flex-end" : "space-between" }}
        >
          <button
            className="signUp button add-lead-button"
            onClick={() => setShowAddLeadPopup(true)}
          >
            <span className="white">Add a Lead</span>
          </button>
          {showAddRequestPopup && selectedLead && (
            <AddRequest
              onClose={closeRequestPopup}
              show={showAddRequestPopup}
              lead={selectedLead}
            />
          )}
          {selectedLead && showAddLeadPopup ? (
            <AddLead
              show={showAddLeadPopup}
              onClose={closeLeadPopup}
              lead={selectedLead}
            />
          ) : (
            showAddLeadPopup && (
              <AddLead show={showAddLeadPopup} onClose={closeLeadPopup} />
            )
          )}
          {selectedLead && selectedLead.name && openCreateJob ? (
            <AddJob
              show={openCreateJob}
              onClose={closeCreateJob}
              lead={selectedLead}
            />
          ) : (
            <AddJob show={openCreateJob} onClose={closeCreateJob} />
          )}
        </div>
        <div className="projects-row-start">
          <div
            className="kpi-card-leads margin-right-small"
            style={{
              border: !finishedClicked ? "0px solid grey" : "1px solid #FF6210",
              boxShadow: !finishedClicked
                ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                : "none",
            }}
            onClick={handleFinishedClick}
          >
            <DoneAllIcon className="margin-kpi-projects req-green" />
            <strong className="text-quicksand700-small darkgrey padding-r5">
              Finished Leads
            </strong>
            <span className="grey-text">
              {" "}
              {" ( " + numFinished.toString() + " )"}
            </span>
            {/* <strong>{numStarted}</strong> */}
          </div>
          <div
            className="kpi-card-leads margin-right-small"
            style={{
              border: !startedClicked ? "0px solid grey" : "1px solid #FF6210",
              boxShadow: !startedClicked
                ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                : "none",
            }}
            onClick={handleStartedClick}
          >
            <StartIcon className="margin-kpi-projects req-blue" />
            <strong className="text-quicksand700-small darkgrey padding-r5">
              Started Leads
            </strong>
            <span className="grey-text">
              {" "}
              {" ( " + numStarted.toString() + " )"}
            </span>
            {/* <strong>{numStarted}</strong> */}
          </div>
          <div
            className="kpi-card-leads margin-right-small"
            style={{
              border: !acceptedClicked ? "0px solid grey" : "1px solid #FF6210",
              boxShadow: !acceptedClicked
                ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                : "none",
            }}
            onClick={handleAcceptedClick}
          >
            <ThumbUpOffAltIcon className="margin-kpi-projects req-purple" />
            <strong className="text-quicksand700-small darkgrey padding-r5">
              Accepted Leads
            </strong>
            <span className="grey-text">
              {" "}
              {" ( " + numAccepted.toString() + " )"}
            </span>
            {/* <strong>{numStarted}</strong> */}
          </div>

          <div
            className="kpi-card-leads margin-right-small"
            style={{
              border: !checkedClicked ? "0px solid grey" : "1px solid #FF6210",
              boxShadow: !checkedClicked
                ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                : "none",
            }}
            onClick={handleCheckedClick}
          >
            <RunningWithErrorsIcon className="margin-kpi-projects req-orange" />
            <strong className="text-quicksand700-small darkgrey padding-r5">
              Leads Being Checked
            </strong>
            <span className="grey-text">
              {" "}
              {" ( " + numChecked.toString() + " )"}
            </span>
            {/* <strong>{numStarted}</strong> */}
          </div>
          <div
            className="kpi-card-leads"
            style={{
              border: !rejectedClicked ? "0px solid grey" : "1px solid #FF6210",
              boxShadow: !rejectedClicked
                ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                : "none",
            }}
            onClick={handleRejectedClick}
          >
            <DoDisturbIcon className="margin-kpi-projects req-red" />
            <strong className="text-quicksand700-small darkgrey padding-r5">
              Rejected Leads
            </strong>
            <span className="grey-text">
              {" "}
              {" ( " + numRejected.toString() + " )"}
            </span>
            {/* <strong>{numStarted}</strong> */}
          </div>
        </div>

        <div className="separate-area ">
          {filteredLeads.length > 0 ? (
            <div>
              {filteredLeads.map((lead: any) => {
                return (
                  <div style={{marginTop:20}}>
                  <LeadCard
                    key={lead.id}
                    lead={lead}
                    editLead={editLead}
                    addRequest={addRequest}
                    hotSkills={hotSkills}
                    acceptLead={acceptLead}
                    rejectLead={rejectLead}
                    createJob={createJob}
                   
                  />
                   </div>
                );
              })}
            </div>
          ) : leads.length > 0 ? (
            <div>
              {leads.map((lead: any) => {
                return (
                  <div style={{marginTop:20}}>
                  <LeadCard
                    key={lead.id}
                    lead={lead}
                    editLead={editLead}
                    hotSkills={hotSkills}
                    addRequest={addRequest}
                    acceptLead={acceptLead}
                    rejectLead={rejectLead}
                    createJob={createJob}
                  />
                  </div>
                );
              })}
            </div>
          ) : (
            <br />
          )}
        </div>
      </div>
    </div>
  );
}

export default LeadsAdmin;
