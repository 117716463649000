import React, { useState, useEffect } from "react";
import axiosService from "../../utils/axios";

import locationSlice from "../../store/slices/location";
import "./Payments.css";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableSortLabel,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import moment from "moment";
import AddPayment from "../../components/add-payment/AddPayment";
import { useAlert } from "react-alert";
import AddInvoiceAdmin from "../../components/add-invoice/AddInvoiceAdmin";

function Payments() {
  const [chargesDevContracts, setChargesDevContracts] = useState<any[]>([]);
  const [chargesClientContracts, setChargesClientContracts] = useState<any[]>(
    []
  );
  const [chargesDevProfitShares, setChargesDevProfitShares] = useState<any[]>(
    []
  );
  const [chargesLeadRebates, setChargesLeadRebates] = useState<any[]>([]);
  const [chargesManagers, setChargesManagers] = useState<any[]>([]);
  const [chargesFounders, setChargesFounders] = useState<any[]>([]);
  const [incomingPayments, setIncomingPayments] = useState<any[]>([]);
  const [outgoingPayments, setOutgoingPayments] = useState<any[]>([]);
  const [selectedClientCharge, setSelectedClientCharge] = useState<any>({});

  const [totalChargesDevContracts, setTotalChargesDevContracts] = useState(0);
  const [totalChargesClientContracts, setTotalChargesClientContracts] =
    useState(0);
  const [totalChargesDevProfitShares, setTotalChargesDevProfitShares] =
    useState(0);
  const [paidChargesLeadRebates, setPaidChargesLeadRebates] = useState(0);
  const [paidChargesDevContracts, setPaidChargesDevContracts] = useState(0);
  const [paidChargesClientContracts, setPaidChargesClientContracts] =
    useState(0);
  const [paidChargesDevProfitShares, setPaidChargesDevProfitShares] =
    useState(0);
  const [paidChargesManagers, setPaidChargesManagers] = useState(0);
  const [paidChargesFounders, setPaidChargesFounders] = useState(0);
  const [totalChargesManagers, setTotalChargesManagers] = useState(0);
  const [totalChargesFounders, setTotalChargesFounders] = useState(0);
  const [totalChargesLeadRebates, setTotalChargesLeadRebates] = useState(0);

  const [showAddPaymentPopup, setShowAddPaymentPopup] = useState(false);
  const [showAddInvoicePopup, setShowAddInvoicePopup] = useState(false);

  const [years, setYears] = useState<number[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth);
  const alert = useAlert();

  // Sort configurations for each table
  const [sortConfig, setSortConfig] = useState<{
    [key: string]: { key: string; direction: "asc" | "desc" };
  }>({});

  // Filter configurations for each table
  const [filterConfig, setFilterConfig] = useState<{
    [key: string]: string;
  }>({});

  useEffect(() => {
    async function fetchData() {
      dispatch(
        locationSlice.actions.setLocation({
          path: "/platform/payments",
        })
      );
    }

    getCharges();
    getPayments();

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.account, auth.selectedView]);

  useEffect(() => {
    let yearsSet = new Set<number>();

    const collectYearsFromCharges = (charges: any[]) => {
      charges.forEach((charge) => {
        const date = moment(charge.createdAt);
        if (date.isValid()) {
          yearsSet.add(date.year());
        }
      });
    };

    const collectYearsFromPayments = (payments: any[]) => {
      payments.forEach((payment) => {
        const date = moment(payment.date_of_bank_transfer);
        if (date.isValid()) {
          yearsSet.add(date.year());
        }
      });
    };

    collectYearsFromCharges(chargesDevContracts);
    collectYearsFromCharges(chargesClientContracts);
    collectYearsFromCharges(chargesManagers);
    collectYearsFromCharges(chargesFounders);
    collectYearsFromCharges(chargesDevProfitShares);
    collectYearsFromCharges(chargesLeadRebates);
    collectYearsFromPayments(incomingPayments);
    collectYearsFromPayments(outgoingPayments);

    const sortedYears = Array.from(yearsSet).sort((a, b) => b - a);
    setYears(sortedYears);

    // If the selectedYear is not in years, set it to the latest year
    if (!sortedYears.includes(selectedYear)) {
      setSelectedYear(sortedYears[0] || new Date().getFullYear());
    }

    if (!auth.account) {
      navigate("/login");
    }
    if (auth.selectedView !== "GYLD MANAGER") {
      navigate("/platform");
    }
  }, [
    chargesDevContracts,
    chargesClientContracts,
    chargesManagers,
    chargesFounders,
    chargesDevProfitShares,
    chargesLeadRebates,
    incomingPayments,
    outgoingPayments,
    selectedYear,
    auth.account,
    auth.selectedView,
    navigate,
  ]);

  const createProfitShares = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/create_charge_profit_share`)
      .then((res) => {
        alert.success("Charges Created");
      })
      .catch((err: any) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const getPayments = async () => {
    try {
      const [resIncomingPayments, resOutgoingPayments] = await Promise.all([
        axiosService.get(
          `${process.env.REACT_APP_API_URL}/get_incoming_payments`
        ),
        axiosService.get(
          `${process.env.REACT_APP_API_URL}/get_outgoing_payments`
        ),
      ]);

      setIncomingPayments(resIncomingPayments.data);
      setOutgoingPayments(resOutgoingPayments.data);
    } catch (err: any) {
      if (err.response?.status !== 500) {
        if (err.response) {
          Object.keys(err.response.data).forEach(function (key, index) {
            alert.error(key + ":" + err.response.data[key]);
          });
        }
      }
      console.log(err);
    }
  };

  const getCharges = async () => {
    try {
      const [
        resDevContracts,
        resClientContracts,
        resManagers,
        resFounders,
        resDevProfitShares,
        resLeadRebates,
      ] = await Promise.all([
        axiosService.get(
          `${process.env.REACT_APP_API_URL}/get_charges_dev_contracts`
        ),
        axiosService.get(
          `${process.env.REACT_APP_API_URL}/get_charges_client_contracts`
        ),
        axiosService.get(
          `${process.env.REACT_APP_API_URL}/get_charges_managers`
        ),
        axiosService.get(
          `${process.env.REACT_APP_API_URL}/get_charges_founders`
        ),
        axiosService.get(
          `${process.env.REACT_APP_API_URL}/get_charges_dev_profit_shares`
        ),
        axiosService.get(
          `${process.env.REACT_APP_API_URL}/get_charges_lead_rebates`
        ),
      ]);

      setChargesDevContracts(resDevContracts.data);
      setChargesClientContracts(resClientContracts.data);
      setChargesManagers(resManagers.data);
      setChargesFounders(resFounders.data);
      setChargesDevProfitShares(resDevProfitShares.data);
      setChargesLeadRebates(resLeadRebates.data);
    } catch (err: any) {
      if (err.response?.status !== 500) {
        if (err.response) {
          Object.keys(err.response.data).forEach(function (key, index) {
            alert.error(key + ":" + err.response.data[key]);
          });
        }
      }
      console.log(err);
    }
  };

  const closePaymentPopup = () => {
    setShowAddPaymentPopup(false);
    getPayments();
    getCharges();
  };
  const closeAddInvoicePopup = () => {
    setShowAddInvoicePopup(false);
    getPayments();
    getCharges();
  };
  const handleAddClientInvoice = (charge: any) => {
    setSelectedClientCharge(charge);
    setShowAddInvoicePopup(true);
  };

  // Filter data based on selected year
  const filterDataByYear = (
    dataArray: any[],
    dateField: string = "createdAt"
  ) =>
    dataArray.filter((item) => {
      const date = moment(item[dateField]);
      return date.isValid() && date.year() === selectedYear;
    });

  const filteredChargesDevContracts = filterDataByYear(chargesDevContracts);
  const filteredChargesClientContracts = filterDataByYear(
    chargesClientContracts
  );
  const filteredChargesManagers = filterDataByYear(chargesManagers);
  const filteredChargesFounders = filterDataByYear(chargesFounders);
  const filteredChargesDevProfitShares = filterDataByYear(
    chargesDevProfitShares
  );
  const filteredChargesLeadRebates = filterDataByYear(chargesLeadRebates);
  const filteredIncomingPayments = filterDataByYear(
    incomingPayments,
    "date_of_bank_transfer"
  );
  const filteredOutgoingPayments = filterDataByYear(
    outgoingPayments,
    "date_of_bank_transfer"
  );

  // Recalculate totals based on filtered data
  useEffect(() => {
    const calculateTotals = (
      filteredData: any[],
      setTotal: React.Dispatch<React.SetStateAction<number>>,
      setPaid: React.Dispatch<React.SetStateAction<number>>
    ) => {
      let total = 0;
      let paid = 0;
      filteredData.forEach((charge: any) => {
        total += charge.net_amount;
        if (charge.status === "PAID") {
          paid += charge.net_amount;
        }
      });
      setTotal(total);
      setPaid(paid);
    };

    // Charges Dev Contracts
    calculateTotals(
      filteredChargesDevContracts,
      setTotalChargesDevContracts,
      setPaidChargesDevContracts
    );

    // Charges Client Contracts
    calculateTotals(
      filteredChargesClientContracts,
      setTotalChargesClientContracts,
      setPaidChargesClientContracts
    );

    // Charges Managers
    calculateTotals(
      filteredChargesManagers,
      setTotalChargesManagers,
      setPaidChargesManagers
    );

    // Charges Founders
    calculateTotals(
      filteredChargesFounders,
      setTotalChargesFounders,
      setPaidChargesFounders
    );

    // Charges Dev Profit Shares
    calculateTotals(
      filteredChargesDevProfitShares,
      setTotalChargesDevProfitShares,
      setPaidChargesDevProfitShares
    );

    // Charges Lead Rebates
    calculateTotals(
      filteredChargesLeadRebates,
      setTotalChargesLeadRebates,
      setPaidChargesLeadRebates
    );
  }, [
    selectedYear,
    filteredChargesDevContracts,
    filteredChargesClientContracts,
    filteredChargesManagers,
    filteredChargesFounders,
    filteredChargesDevProfitShares,
    filteredChargesLeadRebates,
  ]);

  // Sorting function
  function sortData(dataArray: any[], sortConfig: any): any[] {
    const sortedData = [...dataArray];
    if (sortConfig && sortConfig.key) {
      sortedData.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];
        if (typeof aValue === "string") {
          aValue = aValue.toLowerCase();
          bValue = bValue.toLowerCase();
        }
        if (aValue < bValue) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortedData;
  }

  // Handle sorting
  function handleSort(tableKey: string, key: string) {
    let direction: "asc" | "desc" = "asc";
    if (
      sortConfig[tableKey]?.key === key &&
      sortConfig[tableKey].direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ ...sortConfig, [tableKey]: { key, direction } });
  }

  // Handle filtering
  function handleFilter(tableKey: string, value: string) {
    setFilterConfig({ ...filterConfig, [tableKey]: value });
  }

  // Apply filtering
  function applyFilter(dataArray: any[], tableKey: string): any[] {
    const filterValue = filterConfig[tableKey];
    if (!filterValue) return dataArray;
    return dataArray.filter((item) =>
      item.status.toLowerCase().includes(filterValue.toLowerCase())
    );
  }

  // Sorted and filtered data
  const sortedChargesDevContracts = sortData(
    applyFilter(filteredChargesDevContracts, "devContracts"),
    sortConfig["devContracts"]
  );
  const sortedChargesClientContracts = sortData(
    applyFilter(filteredChargesClientContracts, "clientContracts"),
    sortConfig["clientContracts"]
  );

  const sortedChargesManagers = sortData(
    applyFilter(filteredChargesManagers, "managers"),
    sortConfig["managers"]
  );
  const sortedChargesFounders = sortData(
    applyFilter(filteredChargesFounders, "founders"),
    sortConfig["founders"]
  );
  const sortedChargesDevProfitShares = sortData(
    applyFilter(filteredChargesDevProfitShares, "devProfitShares"),
    sortConfig["devProfitShares"]
  );
  const sortedChargesLeadRebates = sortData(
    applyFilter(filteredChargesLeadRebates, "leadRebates"),
    sortConfig["leadRebates"]
  );

  const sortedIncomingPayments = sortData(
    filteredIncomingPayments,
    sortConfig["incomingPayments"]
  );
  const sortedOutgoingPayments = sortData(
    filteredOutgoingPayments,
    sortConfig["outgoingPayments"]
  );

  return (
    <div className="container column-center">
      <div className="row-start">
        {/* Year Selector */}
        <FormControl style={{ minWidth: 120, marginRight: 20 }}>
          <InputLabel id="year-select-label">Year</InputLabel>
          <Select
            labelId="year-select-label"
            id="year-select"
            value={selectedYear}
            onChange={(e) => setSelectedYear(Number(e.target.value))}
            label="Year"
          >
            {years.sort().map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          style={{ marginRight: 10 }}
          onClick={() => setShowAddPaymentPopup(true)}
        >
          Add A Payment
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={createProfitShares}
        >
          Create Profit Shares
        </Button>
      </div>
      <b className="margin-t40 margin-b20">
        Total Charges Incoming: {totalChargesClientContracts.toFixed(2)} € Total
        Charges Outgoing:{" "}
        {(
          totalChargesDevContracts +
          totalChargesDevProfitShares +
          totalChargesLeadRebates
        ).toFixed(2)}{" "}
        €{" "}
      </b>

      {showAddPaymentPopup && (
        <AddPayment show={showAddPaymentPopup} onClose={closePaymentPopup} />
      )}
      {selectedClientCharge && (
        <AddInvoiceAdmin
          show={showAddInvoicePopup}
          onClose={closeAddInvoicePopup}
          charge={selectedClientCharge}
        />
      )}

      {/* Charges Dev Contracts */}
      <Accordion className="full-percent-width" defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="dev-contracts-content"
          id="dev-contracts-header"
        >
          <b className="padding-normal">
            Charges Dev Contracts {totalChargesDevContracts.toFixed(2)} €
          </b>
          <b className="padding-normal">
            Paid {paidChargesDevContracts.toFixed(2)} €
          </b>
        </AccordionSummary>
        <AccordionDetails>
          {/* Filter by Status */}
          <FormControl style={{ minWidth: 200, marginBottom: 10 }}>
            <InputLabel id="status-filter-dev-contracts">
              Filter by Status
            </InputLabel>
            <Select
              labelId="status-filter-dev-contracts"
              id="status-filter-dev-contracts"
              value={filterConfig["devContracts"] || ""}
              onChange={(e) => handleFilter("devContracts", e.target.value)}
              label="Filter by Status"
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="NEW">NEW</MenuItem>
              <MenuItem value="PAID">PAID</MenuItem>
              <MenuItem value="OPEN">OPEN</MenuItem>
            </Select>
          </FormControl>

          <TableContainer component={Paper} className="separate-area">
            <Table sx={{ minWidth: 650 }} aria-label="dev contracts table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <TableSortLabel
                      active={sortConfig["devContracts"]?.key === "id"}
                      direction={sortConfig["devContracts"]?.direction || "asc"}
                      onClick={() => handleSort("devContracts", "id")}
                    >
                      ID
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={sortConfig["devContracts"]?.key === "net_amount"}
                      direction={sortConfig["devContracts"]?.direction || "asc"}
                      onClick={() => handleSort("devContracts", "net_amount")}
                    >
                      Net Amount
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">Contract</TableCell>
                  <TableCell align="center">Job</TableCell>
                  <TableCell align="center">Dev</TableCell>
                  <TableCell align="center">Timesheet</TableCell>
                  <TableCell align="center">Invoice</TableCell>
                  <TableCell align="center">Ref</TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={sortConfig["devContracts"]?.key === "status"}
                      direction={sortConfig["devContracts"]?.direction || "asc"}
                      onClick={() => handleSort("devContracts", "status")}
                    >
                      Status
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedChargesDevContracts.map((charge: any) => (
                  <TableRow key={charge.id}>
                    <TableCell align="center">{charge.id}</TableCell>
                    <TableCell align="center">
                      {charge.net_amount.toFixed(2)}
                    </TableCell>
                    <TableCell align="center">{charge.contract}</TableCell>
                    <TableCell align="center">{charge.job?.name}</TableCell>
                    <TableCell align="center">{charge.dev}</TableCell>
                    <TableCell align="center">{charge.timesheet}</TableCell>
                    <TableCell align="center">
                      {charge.invoice?.id}
                      {charge.invoice && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            process.env.REACT_APP_API_URL?.split("/api")[0] +
                            charge.invoice?.file
                          }
                          download
                        >
                          <DownloadIcon style={{ color: "#626DA1" }} />
                        </a>
                      )}
                    </TableCell>
                    <TableCell align="center">{charge.ref}</TableCell>
                    <TableCell align="center">{charge.status}</TableCell>
                    <TableCell align="center">
                      {moment(charge.createdAt).format("D MMM yy")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>

      {/* Charges Client Contracts */}
      <Accordion className="full-percent-width">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="client-contracts-content"
          id="client-contracts-header"
        >
          <b className="padding-normal">
            Charges Client Contracts {totalChargesClientContracts.toFixed(2)} €
          </b>
          <b className="padding-normal">
            Paid {paidChargesClientContracts.toFixed(2)} €
          </b>
        </AccordionSummary>
        <AccordionDetails>
          {/* Filter by Status */}
          <FormControl style={{ minWidth: 200, marginBottom: 10 }}>
            <InputLabel id="status-filter-client-contracts">
              Filter by Status
            </InputLabel>
            <Select
              labelId="status-filter-client-contracts"
              id="status-filter-client-contracts"
              value={filterConfig["clientContracts"] || ""}
              onChange={(e) => handleFilter("clientContracts", e.target.value)}
              label="Filter by Status"
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="NEW">NEW</MenuItem>
              <MenuItem value="PAID">PAID</MenuItem>
              <MenuItem value="OPEN">OPEN</MenuItem>
            </Select>
          </FormControl>

          <TableContainer component={Paper} className="separate-area">
            <Table sx={{ minWidth: 650 }} aria-label="client contracts table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <TableSortLabel
                      active={sortConfig["clientContracts"]?.key === "id"}
                      direction={
                        sortConfig["clientContracts"]?.direction || "asc"
                      }
                      onClick={() => handleSort("clientContracts", "id")}
                    >
                      ID
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={
                        sortConfig["clientContracts"]?.key === "net_amount"
                      }
                      direction={
                        sortConfig["clientContracts"]?.direction || "asc"
                      }
                      onClick={() =>
                        handleSort("clientContracts", "net_amount")
                      }
                    >
                      Net Amount
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">Contract</TableCell>
                  <TableCell align="center">Job</TableCell>
                  <TableCell align="center">User</TableCell>
                  <TableCell align="center">Client</TableCell>
                  <TableCell align="center">Timesheet</TableCell>
                  <TableCell align="center">Invoice</TableCell>
                  <TableCell align="center">Ref</TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={sortConfig["clientContracts"]?.key === "status"}
                      direction={
                        sortConfig["clientContracts"]?.direction || "asc"
                      }
                      onClick={() => handleSort("clientContracts", "status")}
                    >
                      Status
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">Created At</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedChargesClientContracts.map((charge: any) => (
                  <TableRow key={charge.id}>
                    <TableCell align="center">{charge.id}</TableCell>
                    <TableCell align="center">
                      {charge.net_amount.toFixed(2)}
                    </TableCell>
                    <TableCell align="center">{charge.contract}</TableCell>
                    <TableCell align="center">{charge.job?.name}</TableCell>
                    <TableCell align="center">{charge.user}</TableCell>
                    <TableCell align="center">{charge.client}</TableCell>
                    <TableCell align="center">{charge.timesheet}</TableCell>
                    <TableCell align="center">
                      {charge.invoice?.id}
                      {charge.invoice && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            process.env.REACT_APP_API_URL?.split("/api")[0] +
                            charge.invoice?.file
                          }
                          download
                        >
                          <DownloadIcon style={{ color: "#626DA1" }} />
                        </a>
                      )}
                    </TableCell>
                    <TableCell align="center">{charge.ref}</TableCell>
                    <TableCell align="center">{charge.status}</TableCell>
                    <TableCell align="center">
                      {moment(charge.createdAt).format("D MMM yy")}
                    </TableCell>
                    <TableCell align="center">
                      {charge.status === "NEW" && (
                        <Button onClick={() => handleAddClientInvoice(charge)}>
                          Add Invoice
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>

      {/* Charges Dev Profit Shares */}
      <Accordion className="full-percent-width">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="dev-profit-shares-content"
          id="dev-profit-shares-header"
        >
          <b className="padding-normal">
            Charges Dev Profit Shares {totalChargesDevProfitShares.toFixed(2)} €
          </b>
          <b className="padding-normal">
            Paid {paidChargesDevProfitShares.toFixed(2)} €
          </b>
        </AccordionSummary>
        <AccordionDetails>
          {/* Filter by Status */}
          <FormControl style={{ minWidth: 200, marginBottom: 10 }}>
            <InputLabel id="status-filter-dev-profit-shares">
              Filter by Status
            </InputLabel>
            <Select
              labelId="status-filter-dev-profit-shares"
              id="status-filter-dev-profit-shares"
              value={filterConfig["devProfitShares"] || ""}
              onChange={(e) => handleFilter("devProfitShares", e.target.value)}
              label="Filter by Status"
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="NEW">NEW</MenuItem>
              <MenuItem value="PAID">PAID</MenuItem>
              <MenuItem value="OPEN">OPEN</MenuItem>
            </Select>
          </FormControl>

          <TableContainer component={Paper} className="separate-area">
            <Table sx={{ minWidth: 650 }} aria-label="dev profit shares table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <TableSortLabel
                      active={sortConfig["devProfitShares"]?.key === "id"}
                      direction={
                        sortConfig["devProfitShares"]?.direction || "asc"
                      }
                      onClick={() => handleSort("devProfitShares", "id")}
                    >
                      ID
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={
                        sortConfig["devProfitShares"]?.key === "net_amount"
                      }
                      direction={
                        sortConfig["devProfitShares"]?.direction || "asc"
                      }
                      onClick={() =>
                        handleSort("devProfitShares", "net_amount")
                      }
                    >
                      Net Amount
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">Dev</TableCell>
                  <TableCell align="center">Invoice</TableCell>
                  <TableCell align="center">Ref</TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={sortConfig["devProfitShares"]?.key === "status"}
                      direction={
                        sortConfig["devProfitShares"]?.direction || "asc"
                      }
                      onClick={() => handleSort("devProfitShares", "status")}
                    >
                      Status
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedChargesDevProfitShares.map((charge: any) => (
                  <TableRow key={charge.id}>
                    <TableCell align="center">{charge.id}</TableCell>
                    <TableCell align="center">
                      {charge.net_amount.toFixed(2)}
                    </TableCell>
                    <TableCell align="center">{charge.dev}</TableCell>
                    <TableCell align="center">
                      {charge.invoice?.id}
                      {charge.invoice && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            process.env.REACT_APP_API_URL?.split("/api")[0] +
                            charge.invoice?.file
                          }
                          download
                        >
                          <DownloadIcon style={{ color: "#626DA1" }} />
                        </a>
                      )}
                    </TableCell>
                    <TableCell align="center">{charge.ref}</TableCell>
                    <TableCell align="center">{charge.status}</TableCell>
                    <TableCell align="center">
                      {moment(charge.createdAt).format("D MMM yy")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>

      {/* Charges Lead Rebates */}
      <Accordion className="full-percent-width">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="lead-rebates-content"
          id="lead-rebates-header"
        >
          <b className="padding-normal">
            Charges Lead Rebates {totalChargesLeadRebates.toFixed(2)} €
          </b>
          <b className="padding-normal">
            Paid {paidChargesLeadRebates.toFixed(2)} €
          </b>
        </AccordionSummary>
        <AccordionDetails>
          {/* Filter by Status */}
          <FormControl style={{ minWidth: 200, marginBottom: 10 }}>
            <InputLabel id="status-filter-lead-rebates">
              Filter by Status
            </InputLabel>
            <Select
              labelId="status-filter-lead-rebates"
              id="status-filter-lead-rebates"
              value={filterConfig["leadRebates"] || ""}
              onChange={(e) => handleFilter("leadRebates", e.target.value)}
              label="Filter by Status"
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="NEW">NEW</MenuItem>
              <MenuItem value="PAID">PAID</MenuItem>
              <MenuItem value="OPEN">OPEN</MenuItem>
            </Select>
          </FormControl>

          <TableContainer component={Paper} className="separate-area">
            <Table sx={{ minWidth: 650 }} aria-label="lead rebates table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <TableSortLabel
                      active={sortConfig["leadRebates"]?.key === "id"}
                      direction={sortConfig["leadRebates"]?.direction || "asc"}
                      onClick={() => handleSort("leadRebates", "id")}
                    >
                      ID
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={sortConfig["leadRebates"]?.key === "net_amount"}
                      direction={sortConfig["leadRebates"]?.direction || "asc"}
                      onClick={() => handleSort("leadRebates", "net_amount")}
                    >
                      Net Amount
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">User</TableCell>
                  <TableCell align="center">Timesheet</TableCell>
                  <TableCell align="center">Job</TableCell>
                  <TableCell align="center">Invoice</TableCell>
                  <TableCell align="center">Ref</TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={sortConfig["leadRebates"]?.key === "status"}
                      direction={sortConfig["leadRebates"]?.direction || "asc"}
                      onClick={() => handleSort("leadRebates", "status")}
                    >
                      Status
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedChargesLeadRebates.map((charge: any) => (
                  <TableRow key={charge.id}>
                    <TableCell align="center">{charge.id}</TableCell>
                    <TableCell align="center">
                      {charge.net_amount.toFixed(2)}
                    </TableCell>
                    <TableCell align="center">{charge.user}</TableCell>
                    <TableCell align="center">{charge.timesheet}</TableCell>
                    <TableCell align="center">{charge.job?.name}</TableCell>
                    <TableCell align="center">
                      {charge.invoice?.id}
                      {charge.invoice && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            process.env.REACT_APP_API_URL?.split("/api")[0] +
                            charge.invoice?.file
                          }
                          download
                        >
                          <DownloadIcon style={{ color: "#626DA1" }} />
                        </a>
                      )}
                    </TableCell>
                    <TableCell align="center">{charge.ref}</TableCell>
                    <TableCell align="center">{charge.status}</TableCell>
                    <TableCell align="center">
                      {moment(charge.createdAt).format("D MMM yy")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>

      {/* Charges Managers */}
      <Accordion className="full-percent-width">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="managers-content"
          id="managers-header"
        >
          <b className="padding-normal">
            Charges Managers {totalChargesManagers.toFixed(2)} €
          </b>
          <b className="padding-normal">
            Paid {paidChargesManagers.toFixed(2)} €
          </b>
        </AccordionSummary>
        <AccordionDetails>
          {/* Filter by Status */}
          <FormControl style={{ minWidth: 200, marginBottom: 10 }}>
            <InputLabel id="status-filter-managers">
              Filter by Status
            </InputLabel>
            <Select
              labelId="status-filter-managers"
              id="status-filter-managers"
              value={filterConfig["managers"] || ""}
              onChange={(e) => handleFilter("managers", e.target.value)}
              label="Filter by Status"
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="NEW">NEW</MenuItem>
              <MenuItem value="PAID">PAID</MenuItem>
              <MenuItem value="OPEN">OPEN</MenuItem>
            </Select>
          </FormControl>

          <TableContainer component={Paper} className="separate-area">
            <Table sx={{ minWidth: 650 }} aria-label="managers table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <TableSortLabel
                      active={sortConfig["managers"]?.key === "id"}
                      direction={sortConfig["managers"]?.direction || "asc"}
                      onClick={() => handleSort("managers", "id")}
                    >
                      ID
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={sortConfig["managers"]?.key === "net_amount"}
                      direction={sortConfig["managers"]?.direction || "asc"}
                      onClick={() => handleSort("managers", "net_amount")}
                    >
                      Net Amount
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">Manager</TableCell>
                  <TableCell align="center">Timesheet</TableCell>
                  <TableCell align="center">Job</TableCell>
                  <TableCell align="center">Invoice</TableCell>
                  <TableCell align="center">Ref</TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={sortConfig["managers"]?.key === "status"}
                      direction={sortConfig["managers"]?.direction || "asc"}
                      onClick={() => handleSort("managers", "status")}
                    >
                      Status
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedChargesManagers.map((charge: any) => (
                  <TableRow key={charge.id}>
                    <TableCell align="center">{charge.id}</TableCell>
                    <TableCell align="center">
                      {charge.net_amount.toFixed(2)}
                    </TableCell>
                    <TableCell align="center">{charge.manager}</TableCell>
                    <TableCell align="center">{charge.timesheet}</TableCell>
                    <TableCell align="center">{charge.job?.name}</TableCell>
                    <TableCell align="center">
                      {charge.invoice?.id}
                      {charge.invoice && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            process.env.REACT_APP_API_URL?.split("/api")[0] +
                            charge.invoice?.file
                          }
                          download
                        >
                          <DownloadIcon style={{ color: "#626DA1" }} />
                        </a>
                      )}
                    </TableCell>
                    <TableCell align="center">{charge.ref}</TableCell>
                    <TableCell align="center">{charge.status}</TableCell>
                    <TableCell align="center">
                      {moment(charge.createdAt).format("D MMM yy")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>

      {/* Charges Founders */}
      <Accordion className="full-percent-width">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="founders-content"
          id="founders-header"
        >
          <b className="padding-normal">
            Charges Founders {totalChargesFounders.toFixed(2)} €
          </b>
          <b className="padding-normal">
            Paid {paidChargesFounders.toFixed(2)} €
          </b>
        </AccordionSummary>
        <AccordionDetails>
          {/* Filter by Status */}
          <FormControl style={{ minWidth: 200, marginBottom: 10 }}>
            <InputLabel id="status-filter-founders">
              Filter by Status
            </InputLabel>
            <Select
              labelId="status-filter-founders"
              id="status-filter-founders"
              value={filterConfig["founders"] || ""}
              onChange={(e) => handleFilter("founders", e.target.value)}
              label="Filter by Status"
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="NEW">NEW</MenuItem>
              <MenuItem value="PAID">PAID</MenuItem>
              <MenuItem value="OPEN">OPEN</MenuItem>
            </Select>
          </FormControl>

          <TableContainer component={Paper} className="separate-area">
            <Table sx={{ minWidth: 650 }} aria-label="founders table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <TableSortLabel
                      active={sortConfig["founders"]?.key === "id"}
                      direction={sortConfig["founders"]?.direction || "asc"}
                      onClick={() => handleSort("founders", "id")}
                    >
                      ID
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={sortConfig["founders"]?.key === "net_amount"}
                      direction={sortConfig["founders"]?.direction || "asc"}
                      onClick={() => handleSort("founders", "net_amount")}
                    >
                      Net Amount
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">Co-Founder</TableCell>
                  <TableCell align="center">Timesheet</TableCell>
                  <TableCell align="center">Job</TableCell>
                  <TableCell align="center">Invoice</TableCell>
                  <TableCell align="center">Ref</TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={sortConfig["founders"]?.key === "status"}
                      direction={sortConfig["founders"]?.direction || "asc"}
                      onClick={() => handleSort("founders", "status")}
                    >
                      Status
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedChargesFounders.map((charge: any) => (
                  <TableRow key={charge.id}>
                    <TableCell align="center">{charge.id}</TableCell>
                    <TableCell align="center">
                      {charge.net_amount.toFixed(2)}
                    </TableCell>
                    <TableCell align="center">{charge.co_founder}</TableCell>
                    <TableCell align="center">{charge.timesheet}</TableCell>
                    <TableCell align="center">{charge.job?.name}</TableCell>
                    <TableCell align="center">
                      {charge.invoice?.id}
                      {charge.invoice && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            process.env.REACT_APP_API_URL?.split("/api")[0] +
                            charge.invoice?.file
                          }
                          download
                        >
                          <DownloadIcon style={{ color: "#626DA1" }} />
                        </a>
                      )}
                    </TableCell>
                    <TableCell align="center">{charge.ref}</TableCell>
                    <TableCell align="center">{charge.status}</TableCell>
                    <TableCell align="center">
                      {moment(charge.createdAt).format("D MMM yy")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>

      {/* Incoming Payments */}
      <Accordion className="full-percent-width">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="incoming-payments-content"
          id="incoming-payments-header"
        >
          <b className="padding-normal">Incoming Payments</b>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper} className="separate-area">
            <Table sx={{ minWidth: 650 }} aria-label="incoming payments table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <TableSortLabel
                      active={sortConfig["incomingPayments"]?.key === "id"}
                      direction={
                        sortConfig["incomingPayments"]?.direction || "asc"
                      }
                      onClick={() => handleSort("incomingPayments", "id")}
                    >
                      ID
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={
                        sortConfig["incomingPayments"]?.key === "gross_amount"
                      }
                      direction={
                        sortConfig["incomingPayments"]?.direction || "asc"
                      }
                      onClick={() =>
                        handleSort("incomingPayments", "gross_amount")
                      }
                    >
                      Gross Amount
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">Net Amount</TableCell>
                  <TableCell align="center">VAT</TableCell>
                  <TableCell align="center">From Client</TableCell>
                  <TableCell align="center">Charges</TableCell>
                  <TableCell align="center">Bank Transfer</TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={
                        sortConfig["incomingPayments"]?.key ===
                        "date_of_bank_transfer"
                      }
                      direction={
                        sortConfig["incomingPayments"]?.direction || "asc"
                      }
                      onClick={() =>
                        handleSort("incomingPayments", "date_of_bank_transfer")
                      }
                    >
                      Date of Bank Transfer
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedIncomingPayments.map((payment: any) => (
                  <TableRow key={payment.id}>
                    <TableCell align="center">{payment.id}</TableCell>
                    <TableCell align="center">{payment.gross_amount}</TableCell>
                    <TableCell align="center">{payment.net_amount}</TableCell>
                    <TableCell align="center">{payment.vat}</TableCell>
                    <TableCell align="center">{payment.from_client}</TableCell>
                    <TableCell align="center">
                      {payment.charges.map((charge: any, i: number) => (
                        <span key={charge.id}>
                          {charge.id}
                          {i !== payment.charges.length - 1 ? ", " : ""}
                        </span>
                      ))}
                    </TableCell>
                    <TableCell align="center">
                      {payment.bank_transfer}
                    </TableCell>
                    <TableCell align="center">
                      {moment(payment.date_of_bank_transfer).format("D MMM yy")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>

      {/* Outgoing Payments */}
      <Accordion className="full-percent-width">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="outgoing-payments-content"
          id="outgoing-payments-header"
        >
          <b className="padding-normal">Outgoing Payments</b>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper} className="separate-area">
            <Table sx={{ minWidth: 650 }} aria-label="outgoing payments table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <TableSortLabel
                      active={sortConfig["outgoingPayments"]?.key === "id"}
                      direction={
                        sortConfig["outgoingPayments"]?.direction || "asc"
                      }
                      onClick={() => handleSort("outgoingPayments", "id")}
                    >
                      ID
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">Gross Amount</TableCell>
                  <TableCell align="center">Net Amount</TableCell>
                  <TableCell align="center">VAT</TableCell>
                  <TableCell align="center">To User</TableCell>
                  <TableCell align="center">Charges Contracts</TableCell>
                  <TableCell align="center">Charges Leads</TableCell>
                  <TableCell align="center">Charges Profit Shares</TableCell>
                  <TableCell align="center">Bank Transfer</TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={
                        sortConfig["outgoingPayments"]?.key ===
                        "date_of_bank_transfer"
                      }
                      direction={
                        sortConfig["outgoingPayments"]?.direction || "asc"
                      }
                      onClick={() =>
                        handleSort("outgoingPayments", "date_of_bank_transfer")
                      }
                    >
                      Date of Bank Transfer
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedOutgoingPayments.map((payment: any) => (
                  <TableRow key={payment.id}>
                    <TableCell align="center">{payment.id}</TableCell>
                    <TableCell align="center">{payment.gross_amount}</TableCell>
                    <TableCell align="center">{payment.net_amount}</TableCell>
                    <TableCell align="center">{payment.vat}</TableCell>
                    <TableCell align="center">{payment.to_user}</TableCell>
                    <TableCell align="center">
                      {payment.charges_contract.map(
                        (charge: any, i: number) => (
                          <span key={charge.id}>
                            {charge.id}
                            {i !== payment.charges_contract.length - 1
                              ? ", "
                              : ""}
                          </span>
                        )
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {payment.charges_lead_rebate.map(
                        (charge: any, i: number) => (
                          <span key={charge.id}>
                            {charge.id}
                            {i !== payment.charges_lead_rebate.length - 1
                              ? ", "
                              : ""}
                          </span>
                        )
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {payment.charges_profit_share.map(
                        (charge: any, i: number) => (
                          <span key={charge.id}>
                            {charge.id}
                            {i !== payment.charges_profit_share.length - 1
                              ? ", "
                              : ""}
                          </span>
                        )
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {payment.bank_transfer}
                    </TableCell>
                    <TableCell align="center">
                      {moment(payment.date_of_bank_transfer).format("D MMM yy")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default Payments;
