import React, { useState, useEffect } from "react";
import axiosService from "../../utils/axios";

import locationSlice from "../../store/slices/location";
import "./Platform.css";

import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";

import PlatformAdmin from "./PlatformAdmin";
import PlatformUser from "./PlatformUser";

const Platform = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    dispatch(locationSlice.actions.setLocation({ path: "/platform" }));
    if (!auth.account) {
      navigate("/login");
    }
  }, []);

  return (
    <div className="platform">
      {auth.selectedView === "GYLD MANAGER" ? (
        <PlatformAdmin />
      ) : (
        <PlatformUser />
      )}
    </div>
  );
};

export default Platform;
