import React, { useEffect, useState } from "react";
import "./AddTimesheet.css";
import PropTypes from "prop-types";
import axiosService from "../../utils/axios";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import { Button, Fab, Input, TextareaAutosize, TextField } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "moment/locale/en-gb";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { useAlert } from "react-alert";
import Modal from "@mui/material/Modal";

import addWeeks from "date-fns/addWeeks";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddTimesheet = (props: any) => {
  const [show, setShow] = useState(false);

  const [fromDate, setFromDate] = useState(
    moment(new Date()).subtract(2, "weeks").format("DD/MM/YYYY")
  );
  const [toDate, setToDate] = useState(moment(new Date()).format("DD/MM/YYYY"));
  const [hours, setHours] = useState<number>(0);
  const [minDate, setMinDate] = useState(
    moment(new Date()).subtract(2, "weeks").format("DD/MM/YYYY")
  );
  const [maxDate, setMaxDate] = useState(
    moment(new Date()).format("DD/MM/YYYY")
  );
  const [file, setFile] = useState<any>();

  const [isFirst, setIsFirst] = useState(true);

  const auth = useSelector((state: RootState) => state.auth);
  const alert = useAlert();

  const closeHandler = (e: any) => {
    setShow(false);
    props.onClose(false);
  };

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        closeHandler(true);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);
  useEffect(() => {
    if (isFirst) {
      setIsFirst(false);
      getCapa();

    }


    if (props.timesheet && props.timesheet.id) {
      setFromDate(props.timesheet.from_date);
      setToDate(props.timesheet.to_date);
      setHours(props.timesheet.hours);
    } else {
      setHours(0);
    }
  }, []);

  const getCapa = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_user_capa/` + auth.account)
      .then((res: any) => {
        getDates(res.data.unavailable_dates);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  function getWeeksAfter(date: Date | null, amount: number) {
    return date ? addWeeks(date, amount) : undefined;
  }

  const onFileChange = (event: any) => {
    setFile(event.target.files[0]);
  };
  const getDates = (unavailableDates: any[]) => {
    let tmpMinDate = moment(props.contract.job.start_date);

    let tmpMaxDate = moment(props.contract.job.start_date).add(2, "weeks");
    let strTmpMaxDate = tmpMaxDate.toISOString();
    let strTmpMinDate = tmpMinDate.toISOString();
console.log(props.contract.timesheets)
    if (props.contract.timesheets.length > 0) {
      let i: number = 1;
      let foundOK = false;
      while (!foundOK) {
     
        let timesheet =
          props.contract.timesheets[props.contract.timesheets.length - i];
    
        if (timesheet.status === "OK"||timesheet.status==='NEW') {
          foundOK = true;
          if (moment(timesheet.to_date) > moment(tmpMinDate)) {
            tmpMinDate = moment(new Date(timesheet.to_date));
            strTmpMinDate = moment(new Date(timesheet.to_date)).toISOString();
            tmpMaxDate = moment(new Date(timesheet.to_date)).add(2, "weeks");
            strTmpMaxDate = moment(new Date(timesheet.to_date))
              .add(2, "weeks")
              .toISOString();
          }
        } else {
          i++;
        }
      }
    }

    unavailableDates?.forEach((date: any) => {
      console.log(
        "HERE",
        (moment(date.from_date) <= moment(tmpMinDate) &&
          moment(date.from_date) <= moment(tmpMinDate).add(2, "weeks") &&
          moment(date.to_date) >= moment(tmpMinDate)) ||
          (moment(date.from_date) >= moment(tmpMinDate) &&
            moment(date.to_date) <= moment(tmpMinDate).add(2, "weeks")),
        moment(date.from_date) <= moment(tmpMinDate) &&
          moment(date.from_date) <= moment(tmpMinDate).add(2, "weeks") &&
          moment(date.to_date) >= moment(tmpMinDate),
        moment(date.from_date) >= moment(tmpMinDate) &&
          moment(date.to_date) <= moment(tmpMinDate).add(2, "weeks"),
        date.from_date,
        date.to_date,
        tmpMinDate,
        tmpMaxDate
      );
      if (
        (moment(date.from_date) <= moment(tmpMinDate) &&
          moment(date.from_date) <= moment(tmpMinDate).add(2, "weeks") &&
          moment(date.to_date) >= moment(tmpMinDate)) ||
        (moment(date.from_date) >= moment(tmpMinDate) &&
          moment(date.from_date) <= moment(tmpMinDate).add(2, "weeks"))
      ) {
        tmpMinDate = moment(date.to_date).add(1, "days");
        tmpMaxDate = moment(tmpMinDate).add(2, "weeks");

        strTmpMinDate = moment(date.to_date).add(1, "days").toISOString();
        strTmpMaxDate = moment(strTmpMinDate).add(2, "weeks").toISOString();
      }
    });

    setMinDate(strTmpMinDate);
    setMaxDate(strTmpMaxDate);
    setFromDate(strTmpMinDate);
    setToDate(strTmpMaxDate);
    console.log("MIN", strTmpMinDate, "MAX", strTmpMaxDate);
  };

  const addTimesheet = () => {
    const formData = new FormData();

    const form = new FormData();

    form.append("status", "NEW");
    form.append("from_date", moment(fromDate).format("YYYY-MM-DD"));
    form.append("to_date", moment(toDate).format("YYYY-MM-DD"));
    form.append("file", file, file.name);
    form.append("dev", auth.account ? auth.account.toString() : "");
    form.append("hours", hours.toString());

    axiosService
      .post(
        `${process.env.REACT_APP_API_URL}/add_timesheet/` + props.contract_id,
        form
      )
      .then((res) => {
        alert.success("The timesheet was added");
        props.onClose(true);
      })

      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const fileData = () => {
    if (file) {
      return (
        <div>
          <h2 className="text-quicksand-small margin-r3">File Details:</h2>

          <p> {file.name}</p>
        </div>
      );
    } else {
      return <h4>Please select a File</h4>;
    }
  };

  return (
    // <div
    //   style={{
    //     visibility: show ? "visible" : "hidden",
    //     opacity: show ? "1" : "0",
    //   }}
    //   className={"overlay"}
    // >

    <Modal
      open={show}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={"popup-timesheet "}>
        <div className="timesheet-scroll">
          <div className="row-space-between-center">
            <span className="text-quicksand700">Add Timesheet</span>
            <span className={"close"} onClick={closeHandler}>
              &times;
            </span>
          </div>
          {moment(minDate) > moment(new Date()) ||
          moment(maxDate) >= moment(new Date()) ? (
            <span>You can't add a timesheet for a future time</span>
          ) : (props.contract.timesheets.length > 0 &&
              moment(
                props.contract.timesheets[props.contract.timesheets.length - 1]
                  .to_date
              ).add(2, "weeks") <= moment(new Date())) ||
            moment(props.contract.job.start_date).add(2, "weeks") <=
              moment(new Date()) ? (
            <div className={"timesheet-column"}>
              <div className="timesheet-row">
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  adapterLocale="en-gb"
                >
                  <div className="timesheet-width  padding-up-down">
                    <DatePicker
                    
                      inputFormat="DD/MM/YYYY"
                      value={fromDate}
                      label="From date"
                      disableFuture={true}
                      minDate={moment(minDate)}
                      maxDate={moment(maxDate)}
                      onChange={(newValue) => {
                        if (newValue) {
                          setFromDate(newValue?.toString());
                        }
                      }}
                      renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                  </div>
                  <div className="timesheet-width  padding-up-down">
                    <DatePicker
                      inputFormat="DD/MM/YYYY"
                      value={toDate}
                      label="To date"
                      minDate={moment(minDate)}
                      maxDate={moment(maxDate)}
                      onChange={(newValue) => {
                        if (newValue) {
                          setToDate(newValue?.toString());
                        }
                      }}
                      renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                  </div>
                </LocalizationProvider>

                <Box className="timesheet-width-small  padding-up-down">
                  <FormControl fullWidth>
                    <TextField
                    fullWidth
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      label="Hours"
                      id="hours"
                      value={hours}
                      onChange={(event) =>
                        setHours(
                          typeof event.target.value === "number"
                            ? event.target.value
                            : parseInt(event.target.value)
                        )
                      }
                    />
                  </FormControl>
                </Box>
              </div>

              <div className="column-center">
                <div className="row-center">
                  <label htmlFor="upload-photo">
                    <input
                      style={{ display: "none" }}
                      id="upload-photo"
                      name="upload-photo"
                      type="file"
                      accept="application/pdf,application/vnd.ms-excel"
                      onChange={onFileChange}
                    />
                    <Button
                      color="primary"
                      size="small"
                      component="span"
                      aria-label="add"
                    >
                      Upload File
                    </Button>
                  </label>
                </div>
                {fileData()}
              </div>
            </div>
          ) : props.contract.timesheets.length > 0 &&
            moment(
              props.contract.timesheets[props.contract.timesheets.length - 1]
                .to_date
            ).add(2, "weeks") > moment(new Date()) ? (
            <span>The last timesheet was submitted less than 2 weeks ago.</span>
          ) : (
            moment(props.contract.job.start_date).add(2, "weeks") >
              moment(new Date()) && (
              <span>
                You can't submit a timesheet until 2 weeks after the contract
                has started
              </span>
            )
          )}
          <div className="padding-up-down row-flex-end">
            <button
              className="button-white-small text-quicksand700-small margin-r20"
              onClick={() => props.onClose(true)}
            >
              {" "}
              Cancel
            </button>

            {((moment(minDate) < moment(new Date()) &&
              moment(maxDate) <= moment(new Date()) &&
              props.contract.timesheets.length > 0 &&
              moment(
                props.contract.timesheets[props.contract.timesheets.length - 1]
                  .to_date
              ).add(2, "weeks") <= moment(new Date())) ||
              (moment(props.contract.job.start_date).add(2, "weeks") <=
                moment(new Date()).add(1, "days") &&
                moment(minDate) < moment(new Date()) &&
                moment(maxDate) <= moment(new Date()))) && (
              <button
                className="button-black-small-nm text-quicksand700-small"
             
                onClick={addTimesheet}
              >
                {" "}
                Upload Timesheet
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

AddTimesheet.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  timesheet: PropTypes.object,
  contract_id: PropTypes.number.isRequired,
  contract: PropTypes.object.isRequired,
};

export default AddTimesheet;
