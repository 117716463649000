import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import axiosService from "../../utils/axios";
import authSlice from "../../store/slices/auth";

import locationSlice from "../../store/slices/location";
import "./Opportunities.css";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { useAlert } from "react-alert";
import SearchIcon from "@mui/icons-material/Search";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Tooltip from "@mui/material/Tooltip";
import OpportunityCard from "../../components/opportunity-card/OpportunityCard";
import OppHiddenHints from "../../components/opp-hidden-hints/OppHiddenHints";
import ForwardIcon from "@mui/icons-material/Forward";
import moment from "moment";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function Opportunities() {
  const [opportunities, setOpportunities] = useState([]);
  const [hiddenJobs, setHiddenJobs] = useState<any[]>([]);
  const [minimizeInbox, setMinimizeInbox] = useState(false);
  const [minimizeSearch, setMinimizeSearch] = useState(false);
  const [numHidden, setNumHidden] = useState(0);
  const [hiddenClicked, setHiddenClicked] = useState(false);
  const alert = useAlert();
  const [jobs, setJobs] = useState<any[]>([]);
  const [filteredJobs, setFilteredJobs] = useState<Array<any>>([]);
  const [searchString, setSearchString] = useState("");
  const [duration, setDuration] = useState("");
  const [price, setPrice] = useState("");
  const [jobType, setJobType] = useState("");
  const [clientType, setClientType] = useState("");
  const [skills, setSkills] = useState("");
  const [isFirst, setIsFirst] = useState(true);
  const [hideHints, setHideHints] = useState(false);
  const [hotSkills, setHotSkills] = useState<any[]>([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth);
  const scrollToRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isFirst) {
      getUserOpportunities();
      getJobs();
      getSkills();
      setIsFirst(false);
    }
    dispatch(
      locationSlice.actions.setLocation({
        path: "/platform/opportunities",
      })
    );
    if (!auth.account) {
      navigate("/login");
    }
    if (
      auth.selectedView !== "GYLD MANAGER" &&
      auth.selectedView !== "DEVELOPER"
    ) {
      navigate("/platform");
    }
  }, []);

  const getSkills = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_skills`)
      .then((res) => {
        let tmpHotSkills: any[] = [];
        res.data.forEach((data: any) => {
          if (data.is_hot_skill) {
            tmpHotSkills.push(data);
          }
        });

        setHotSkills(tmpHotSkills);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (auth.selectedGroup === "OPPORTUNITY") {
      checkScrollTo();
    }
  });

  const checkScrollTo = () => {
    if (scrollToRef.current?.offsetTop) {
      window.scrollTo({
        top: scrollToRef.current?.offsetTop - 100,
        behavior: "smooth",
      });
      setTimeout(function () {
        clearSelected();
      }, 2000);
    }
  };

  // useLayoutEffect(() => {
  //   return () => {
  //     clearSelected();
  //   };
  // }, []);

  const clearSelected = () => {
    dispatch(authSlice.actions.setSelectedSubgroup(null));
    dispatch(authSlice.actions.setSelectedSubgroupId(null));
    dispatch(authSlice.actions.setSelectedGroup(null));
    dispatch(authSlice.actions.setSelectedGroupId(null));
  };

  useEffect(() => {
    handleSearch();
  }, [
    searchString,
    jobType,
    clientType,
    skills,
    duration,
    jobs,
    hiddenClicked,
  ]);

  const getJobs = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_jobs_for_user/` + auth.account)
      .then((res) => {
        let jobsShow: any[] = [];
        let jobsHidden: any[] = [];
        let tmpHidden = 0;
        if (res.data.length > 0) {
          res.data.forEach(async (job: any) => {
            let div = moment(job.end_date).diff(
              moment(job.start_date),
              "months",
              true
            );
            if (div <= 3) {
              job.duration = "ONE_TO_THREE";
            } else if (div <= 6) {
              job.duration = "THREE_TO_SIX";
            } else {
              job.duration = "OVER_SIX";
            }
            if (job.hidden) {
              tmpHidden++;
              jobsHidden.push(job);
            } else {
              jobsShow.push(job);
            }
          });
        }

        setNumHidden(tmpHidden);
        setJobs(jobsShow);
        setHiddenJobs(jobsHidden);
        if (hiddenClicked) {
          setFilteredJobs(jobsHidden);
        } else {
          setFilteredJobs(jobsShow);
        }
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }
      });
  };

  const saveOpportunity = (job_id: number) => {
    let body = {
      user_id: auth.account,
      job_id: job_id,
      applied: false,
    };
    axiosService
      .post(`${process.env.REACT_APP_API_URL}/add_application`, body)
      .then((res) => {
        alert.success(
          "The Opportunity was saved, you can find it in the Jobs Tab"
        );

        getUserOpportunities();
        getJobs();
      })

      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }
      });
  };

  const getUserOpportunities = () => {
    axiosService
      .get(
        `${process.env.REACT_APP_API_URL}/get_user_opportunities/` +
          auth.account
      )
      .then((res) => {
        setOpportunities(res.data);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }
      });
  };

  const handleSearch = () => {
    let tmp_jobs: any[] = [];
    if (hiddenClicked) {
      tmp_jobs = hiddenJobs;
    } else {
      tmp_jobs = jobs;
    }

    let tmpJobsString: any[] = [];
    let tmpJobsSkills: any[] = [];
    let tmpJobsType: any[] = [];
    let tmpJobsDuration: any[] = [];
    let tmpJobsClient: any[] = [];

    if (searchString) {
      tmp_jobs.forEach((project: any) => {
        if (
          project.name.toLowerCase().includes(searchString.toLowerCase()) ||
          project.description.toLowerCase().includes(searchString.toLowerCase())
        ) {
          tmpJobsString.push(project);
        }
        project.skills.forEach((skill: string) => {
          if (
            skill.toLowerCase().includes(searchString.toLowerCase()) &&
            !tmpJobsString.includes(project)
          ) {
            tmpJobsString.push(project);
          }
        });
      });
    }

    if (duration !== "") {
      tmp_jobs.forEach((project: any) => {
        if (
          project.duration === duration &&
          !tmpJobsDuration.includes(project)
        ) {
          tmpJobsDuration.push(project);
        }
      });
    }

    if (jobType !== "") {
      tmp_jobs.forEach((project: any) => {
        if (project.job_type === jobType && !tmpJobsType.includes(project)) {
          tmpJobsType.push(project);
        }
      });
    }
    if (clientType !== "") {
      tmp_jobs.forEach((project: any) => {
        if (clientType === "VERIFIED") {
          if (project.verified_client && !tmpJobsClient.includes(project)) {
            tmpJobsClient.push(project);
          }
        } else if (
          project.client_type === clientType &&
          !tmpJobsClient.includes(project)
        ) {
          tmpJobsClient.push(project);
        }
      });
    }
    if (skills === "my_skills") {
      let userSkills = auth.skills;
      tmp_jobs.forEach((project: any) => {
        if (
          userSkills &&
          userSkills.some((r) => project.skills.includes(r)) &&
          !tmpJobsSkills.includes(project)
        ) {
          tmpJobsSkills.push(project);
        }
      });
    }

    let data = [];
    if (tmpJobsClient.length > 0 || clientType !== "") {
      data.push([...tmpJobsClient]);
    }
    if (tmpJobsSkills.length > 0 || skills === "my_skills") {
      data.push([...tmpJobsSkills]);
    }
    if (tmpJobsType.length > 0 || jobType !== "") {
      data.push([...tmpJobsType]);
    }
    if (tmpJobsDuration.length > 0 || duration !== "") {
      data.push([...tmpJobsDuration]);
    }
    if (tmpJobsString.length > 0 || searchString !== "") {
      data.push([...tmpJobsString]);
    }
    if (data.length > 0) {
      let result = data.reduce((a, b) => a.filter((c: any) => b.includes(c)));
      setFilteredJobs(result);
    } else if (
      searchString === "" &&
      duration === "" &&
      jobType === "" &&
      skills === "" &&
      clientType === ""
    ) {
      setFilteredJobs(tmp_jobs);
    } else {
      setFilteredJobs([]);
    }
  };

  const clearAll = () => {
    setFilteredJobs(jobs);
    setSearchString("");
    setDuration("");
    setJobType("");
    setClientType("");
    setSkills("");
    setPrice("");
  };
  const handleHiddenClick = () => {
    setHiddenClicked(!hiddenClicked);
  };

  return (
    <div className="platform container">
      <div className="leads-basis">
        <span className="heading margin-l20">Opportunities</span>
        {!auth.expert && (
          <div
            className="row-space-between wrap "
            style={{ justifyContent: hideHints ? "flex-end" : "space-between" }}
          >
            <div
              className="card-hints"
              style={{ display: hideHints ? "none" : "" }}
            >
              <div className="leads-hints-left">
                <div className="row-start">
                  <img
                    className="bulb"
                    src={require("../../assets/images/bulb.png")}
                  />
                  <b className="heading-lora">Your path to next big project!</b>
                </div>
                <span className="text-quicksand opp-grey">
                  Search for job opportunities or respond to one of the job
                  opportunities in your Opportunities Inbox provided by your
                  Gyld Manager (GM). Once you find an opportunity you like,
                  please make sure to save it and you will find it in your Jobs
                  page.
                  <br />
                </span>

                <div className="opp-arrow-out">
                  <span className="text-quicksand-small blue">
                    Surf opportunities
                  </span>
                  <ForwardIcon className="arrow" sx={{ height: 20 }} />
                  <span className="text-quicksand-small blue">
                    Save them to Jobs
                  </span>
                  <ForwardIcon className="arrow" sx={{ height: 20 }} />
                  <span className="text-quicksand-small blue">
                    Manage your Jobs on the Jobs Page
                  </span>
                </div>
              </div>
              <img
                className="opp-img"
                src={require("../../assets/images/opportunities-box.png")}
              />
              {!hideHints && (
                <Tooltip title="Hide" placement="bottom" arrow>
                  <RemoveCircleIcon
                    sx={{ fontSize: 30 }}
                    className="help-icon"
                    onClick={() => setHideHints(!hideHints)}
                    style={{
                      justifySelf: hideHints ? "flex-end" : "",
                    }}
                  ></RemoveCircleIcon>
                </Tooltip>
              )}
            </div>

            <div
              className="row-space-between"
              style={{ display: hideHints ? "" : "none" }}
            >
              <OppHiddenHints
                hideHints={hideHints}
                setHideHints={setHideHints}
              ></OppHiddenHints>
            </div>
          </div>
        )}

        <div className="light-grey-box margin-b50">
          <span className="opp-box-heading">Opportunity Inbox</span>

          <div style={{ display: minimizeInbox ? "none" : "" }}>
            {opportunities.length > 0 ? (
              <div>
                {opportunities.map((opportunity: any) => {
                  const itemProps =
                    auth.selectedGroup === "OPPORTUNITY" &&
                    auth.selectedGroupId === opportunity.opportunity_id
                      ? { ref: scrollToRef }
                      : {};
                  return (
                    <div
                      key={opportunity.id}
                      {...itemProps}
                      onClick={clearSelected}
                    >
                      <OpportunityCard
                        highlight={
                          opportunity.opportunity_id === auth.selectedGroupId
                        }
                        saveOpportunity={saveOpportunity}
                        key={opportunity.id}
                        hotSkills={hotSkills}
                        opportunity={opportunity}
                        isSuggested={true}
                        getUserOpportunities={getUserOpportunities}
                        getJobs={getJobs}
                        handleSearch={handleSearch}
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              <span className="opp-status">
                There are no suggested opportunities at the moment. You can find
                all opportunities below.
              </span>
            )}
          </div>
        </div>

        {/* <HelpIcon
              className="help-icon"
              onClick={() => setHideHints(!hideHints)}
              style={{ justifySelf: hideHints ? "flex-end" : "" }}
            />

            <div
              className="separate-area"
              style={{ display: hideHints ? "none" : "" }}
            />
          </div> */}

        {/* <div className="separate-area">
            <div className="row-start">
              Opportunity Inbox
              {minimizeInbox ? (
                <ExpandIcon onClick={() => setMinimizeInbox(false)} />
              ) : (
                <MinimizeIcon onClick={() => setMinimizeInbox(true)} />
              )}
            </div>
          </div> */}
        <div className="separate-area">
          <div style={{ display: minimizeSearch ? "none" : "" }}>
            <div className="light-grey-box margin-b150">
              <div className="opp-row-s-b-c">
                <div className="full-percent-width">
                  <span className="opp-box-heading margin-right">
                    Search Opportunities
                  </span>
                </div>
                <div className="opp-search-right">
                  <div className="top-circle-right margin-r20">
                    {!hiddenClicked ? (
                      <Tooltip title="View Hidden" placement="bottom" arrow>
                        <VisibilityOffIcon
                          onClick={handleHiddenClick}
                          style={{
                            color: "#626DA1",
                            marginLeft: 8,
                            marginRight: 8,
                            cursor: "pointer",
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title="View Opportunities"
                        placement="bottom"
                        arrow
                      >
                        <VisibilityIcon
                          onClick={handleHiddenClick}
                          style={{
                            color: "#626DA1",
                            marginLeft: 8,
                            marginRight: 8,
                            cursor: "pointer",
                          }}
                        />
                      </Tooltip>
                    )}
                  </div>
                  <div className="opp-searchBar">
                    <input
                      type={"text"}
                      value={searchString}
                      placeholder={"Search"}
                      onEmptied={() => {
                        setSearchString("");
                      }}
                      onChange={(event) => setSearchString(event.target.value)}
                      className="searchInput"
                    />
                    <SearchIcon className="greyblue" />
                  </div>
                </div>
              </div>

              {window.innerWidth >= 1200 ? (
                <div className="opp-categories">
                  <Box className="opp-categories-box-width">
                    <FormControl fullWidth>
                      <InputLabel
                        shrink={true}
                        id="demo-simple-select-label-skills"
                      >
                        Skills
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label-skills"
                        id="demo-simple-select-skills"
                        value={skills || ""}
                        // label="SelectedProject"
                        onChange={(event) => setSkills(event.target.value)}
                        input={
                          <OutlinedInput
                            id="select-multiple-chip-skills"
                            label="Skills"
                            notched
                          />
                        }
                      >
                        <MenuItem value={""}>-</MenuItem>
                        <MenuItem value={"all"}>All Jobs</MenuItem>
                        <MenuItem value={"my_skills"}>Only my Skills</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                  {/* <select
                  value={jobType}
                  className="opp-selectCategory"
                  onChange={(event) => setJobType(event.target.value)}
                >
                  <option value={""} disabled>
                    Job Type
                  </option>
                  <option value={""}>-</option>
                  <option value={"FULL_TIME"}>Full Time</option>
                  <option value={"PART_TIME"}>Part Time</option>
                </select> */}
                  <Box className="opp-categories-box-width">
                    <FormControl fullWidth>
                      <InputLabel
                        shrink={true}
                        id="demo-simple-select-label-jobtype"
                      >
                        Job Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label-jobtype"
                        id="demo-simple-select-jobtype"
                        value={jobType || ""}
                        onChange={(event) => setJobType(event.target.value)}
                        input={
                          <OutlinedInput
                            id="select-multiple-chip-jobtype"
                            label="Job Type"
                            notched
                          />
                        }
                      >
                        <MenuItem value={""}>-</MenuItem>
                        <MenuItem value={"FULL_TIME"}>Full Time</MenuItem>
                        <MenuItem value={"PART_TIME"}>Part Time</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                  {/* <select
                  value={duration}
                  className="opp-selectCategory"
                  onChange={(event) => setDuration(event.target.value)}
                >
                  <option value={""} disabled>
                    Duration
                  </option>
                  <option value={""}>-</option>
                  <option value={"ONE_TO_THREE"}>1-3 months</option>
                  <option value={"THREE_TO_SIX"}>3-6 months</option>
                  <option value={"OVER_SIX"}>Over 6 months</option>
                </select> */}

                  <Box className="opp-categories-box-width">
                    <FormControl fullWidth>
                      <InputLabel
                        shrink={true}
                        id="demo-simple-select-label-duration"
                      >
                        Duration
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label-duration"
                        id="demo-simple-select-duration"
                        value={duration || ""}
                        onChange={(event) => setDuration(event.target.value)}
                        input={
                          <OutlinedInput
                            id="select-multiple-chip-duration"
                            label="Duration"
                            notched
                          />
                        }
                      >
                        <MenuItem value={""}>-</MenuItem>
                        <MenuItem value={"ONE_TO_THREE"}>1-3 months</MenuItem>
                        <MenuItem value={"THREE_TO_SIX"}>3-6 months</MenuItem>
                        <MenuItem value={"OVER_SIX"}>over 6 months</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                  {/* <select
                  value={clientType}
                  className="opp-selectCategory"
                  onChange={(event) => setClientType(event.target.value)}
                >
                  <option value={""} disabled>
                    Client Type
                  </option>
                  <option value={""}>-</option>
                  <option value={"VERIFIED"}>Only Verified </option>
                  <option value={"PRIVATE"}>Private Client</option>
                  <option value={"COMPANY"}>Company</option>
                </select> */}

                  <Box className="opp-categories-box-width">
                    <FormControl fullWidth>
                      <InputLabel
                        shrink={true}
                        id="demo-simple-select-label-clienttype"
                      >
                        Client Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label-clienttype"
                        id="demo-simple-select-clienttype"
                        value={clientType || ""}
                        onChange={(event) => setClientType(event.target.value)}
                        input={
                          <OutlinedInput
                            id="select-multiple-chip-clienttype"
                            label="Client Type"
                            notched
                          />
                        }
                      >
                        <MenuItem value={""}>-</MenuItem>
                        <MenuItem value={"VERIFIED"}>Only verified</MenuItem>
                        <MenuItem value={"PRIVATE"}>Private Client</MenuItem>
                        <MenuItem value={"COMPANY"}>Company</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                  <button
                    className="opp-clearAll text-quicksand700-small"
                    onClick={clearAll}
                  >
                    Clear all
                  </button>
                </div>
              ) : (
                <div className="mob-opp-categories">
                  <div className="opportunity-row-s-b ">
                    <Box className="mob-opp-categories-box-width padding-up-down">
                      <FormControl fullWidth>
                        <InputLabel
                          shrink={true}
                          id="demo-simple-select-label-skills"
                        >
                          Skills
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label-skills"
                          id="demo-simple-select-skills"
                          value={skills || ""}
                          // label="SelectedProject"
                          onChange={(event) => setSkills(event.target.value)}
                          input={
                            <OutlinedInput
                              id="select-multiple-chip-skills"
                              label="Skills"
                              notched
                            />
                          }
                        >
                          <MenuItem value={""}>-</MenuItem>
                          <MenuItem value={"all"}>All Jobs</MenuItem>
                          <MenuItem value={"my_skills"}>
                            Only my Skills
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>

                    {/* <select
                  value={jobType}
                  className="opp-selectCategory"
                  onChange={(event) => setJobType(event.target.value)}
                >
                  <option value={""} disabled>
                    Job Type
                  </option>
                  <option value={""}>-</option>
                  <option value={"FULL_TIME"}>Full Time</option>
                  <option value={"PART_TIME"}>Part Time</option>
                </select> */}
                    <Box className="mob-opp-categories-box-width padding-up-down">
                      <FormControl fullWidth>
                        <InputLabel
                          shrink={true}
                          id="demo-simple-select-label-jobtype"
                        >
                          Job Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label-jobtype"
                          id="demo-simple-select-jobtype"
                          value={jobType || ""}
                          onChange={(event) => setJobType(event.target.value)}
                          input={
                            <OutlinedInput
                              id="select-multiple-chip-jobtype"
                              label="Job Type"
                              notched
                            />
                          }
                        >
                          <MenuItem value={""}>-</MenuItem>
                          <MenuItem value={"FULL_TIME"}>Full Time</MenuItem>
                          <MenuItem value={"PART_TIME"}>Part Time</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>

                    {/* <select
                  value={duration}
                  className="opp-selectCategory"
                  onChange={(event) => setDuration(event.target.value)}
                >
                  <option value={""} disabled>
                    Duration
                  </option>
                  <option value={""}>-</option>
                  <option value={"ONE_TO_THREE"}>1-3 months</option>
                  <option value={"THREE_TO_SIX"}>3-6 months</option>
                  <option value={"OVER_SIX"}>Over 6 months</option>
                </select> */}
                  </div>
                  <div className="opportunity-row-s-b">
                    <Box className="mob-opp-categories-box-width padding-up-down">
                      <FormControl fullWidth>
                        <InputLabel
                          shrink={true}
                          id="demo-simple-select-label-duration"
                        >
                          Duration
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label-duration"
                          id="demo-simple-select-duration"
                          value={duration || ""}
                          onChange={(event) => setDuration(event.target.value)}
                          input={
                            <OutlinedInput
                              id="select-multiple-chip-duration"
                              label="Duration"
                              notched
                            />
                          }
                        >
                          <MenuItem value={""}>-</MenuItem>
                          <MenuItem value={"ONE_TO_THREE"}>1-3 months</MenuItem>
                          <MenuItem value={"THREE_TO_SIX"}>3-6 months</MenuItem>
                          <MenuItem value={"OVER_SIX"}>over 6 months</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>

                    {/* <select
                  value={clientType}
                  className="opp-selectCategory"
                  onChange={(event) => setClientType(event.target.value)}
                >
                  <option value={""} disabled>
                    Client Type
                  </option>
                  <option value={""}>-</option>
                  <option value={"VERIFIED"}>Only Verified </option>
                  <option value={"PRIVATE"}>Private Client</option>
                  <option value={"COMPANY"}>Company</option>
                </select> */}

                    <Box className="mob-opp-categories-box-width padding-up-down">
                      <FormControl fullWidth>
                        <InputLabel
                          shrink={true}
                          id="demo-simple-select-label-clienttype"
                        >
                          Client Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label-clienttype"
                          id="demo-simple-select-clienttype"
                          value={clientType || ""}
                          onChange={(event) =>
                            setClientType(event.target.value)
                          }
                          input={
                            <OutlinedInput
                              id="select-multiple-chip-clienttype"
                              label="Client Type"
                              notched
                            />
                          }
                        >
                          <MenuItem value={""}>-</MenuItem>
                          <MenuItem value={"VERIFIED"}>Only verified</MenuItem>
                          <MenuItem value={"PRIVATE"}>Private Client</MenuItem>
                          <MenuItem value={"COMPANY"}>Company</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                  <button
                    className="opp-clearAll text-quicksand700-small"
                    onClick={clearAll}
                  >
                    Clear all
                  </button>
                </div>
              )}

              {filteredJobs.length > 0 ? (
                <div>
                  {filteredJobs.map((job: any, i: number) => {
                    return (
                      <OpportunityCard
                        highlight={false}
                        key={i}
                        opportunity={job}
                        hotSkills={hotSkills}
                        isSuggested={false}
                        saveOpportunity={saveOpportunity}
                        getJobs={getJobs}
                        getUserOpportunities={getUserOpportunities}
                        handleSearch={handleSearch}
                      />
                    );
                  })}
                </div>
              ) : (
                <span className="opp-status">
                  There are no opportunities that fit your search.
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>

    // </div>
  );
}

export default Opportunities;
