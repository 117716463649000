import React, { useState, useEffect } from "react";
import "./About.css";
import Box from "@mui/material/Box";
import locationSlice from "../../store/slices/location";
import { useDispatch } from "react-redux";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const About = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(locationSlice.actions.setLocation({ path: "/about" }));
    // getSkills();
  }, []);

  return (
    <div className="container">
      <div className="column-start-center">
        <div className="headerBar">
          <div className="leftSideHeader">
            <strong className="home-title">About The Gyld</strong>
            <span className="home-subtitle lightgrey padding-up-down">
              Empowering Developers, Empowering Businesses: Where Talent Meets
              Opportunity.
            </span>
            <span className="text-quicksand darkgrey  text-align-left">
              <span className="text-quicksand darkgrey text-align-left">
                The Gyld is more than a platform—it's a community of skilled
                developers and innovative thinkers dedicated to building
                impactful solutions. We believe that by connecting talented
                developers with meaningful projects, we create value for both
                individuals and businesses alike.
              </span>
            </span>
          </div>

          <img
            className="dev-image1"
            src={require("../../assets/images/About.png")}
            alt="developing vector"
          />
        </div>
        <span className="text-quicksand darkgrey  ">
          The Gyld was founded in July of 2022. It initially sprung from
          discussions with an Athens based software development community and
          interviews conducted with various Europe based clients. During those
          discussions following issues where seen as crucial over and over
          again:
        </span>

        <div className="about-grey-back">
          <div className="row-start-center margin-b10">
            <li className="text-quicksand darkgrey">
              The quality of both the developers and the projects offered
              fluctuate greatly{" "}
            </li>
          </div>
          <div className="row-start-center margin-b10">
            <li className="text-quicksand darkgrey">
              The software delivery process needs to be managed from a service
              providers perspective, which is work outside of the developers
              responsibility{" "}
            </li>
          </div>
          <div className="row-start-center margin-b10">
            <li className="text-quicksand darkgrey">
              Administrative tasks (e.g. invoicing and escalation management)
              eat up the developers time and bog down their productive work{" "}
            </li>
          </div>
          <div className="row-start-center margin-b10">
            <li className="text-quicksand darkgrey">
              Finding the right/qualified developer for a job is challenging{" "}
            </li>
          </div>
          <div className="row-start-center margin-b10">
            <li className="text-quicksand darkgrey">
              {" "}
              Developers will in some cases not be able resolve all challenges
              of a project in an acceptable timeframe and therefore would
              greatly benefit from a support network of developers
            </li>
          </div>
        </div>
        <div className="row-start-center margin-b10 full-percent-width">
          <span className="text-quicksand darkgrey">
            The Gyld is an organization consisting of Gyld Members and Gyld
            Managers. It’s goal is to alleviate those major pain points by:
          </span>
        </div>

        <div className="about-grey-back">
          <div className="row-start-center margin-b10">
            <li className="text-quicksand darkgrey">
              Vetting both the client and developer (Gyld Member) before
              suggesting a job or a specific developer
            </li>
          </div>
          <div className="row-start-center margin-b10">
            <li className="text-quicksand darkgrey">
              Taking care of the administrative overhead via the Gyld Managers{" "}
            </li>
          </div>
          <div className="row-start-center margin-b10">
            <li className="text-quicksand darkgrey">
              Providing a dedicated Gyld Manager as a single point of contact
              for the client’s managerial concerns{" "}
            </li>
          </div>
          <div className="row-start-center margin-b10">
            <li className="text-quicksand darkgrey">
              Providing a developer network and monetary incentives to the Gyld
              Members for quick support and knowledge exchange{" "}
            </li>
          </div>
        </div>

        <span className="text-quicksand darkgrey">
          The Gyld members are predominantly based in Greece. Although we focus
          on project delivery in the EU timezones - feel free to contact us to
          check if we can work in a your time flexibly even if your project is
          non EU based.
        </span>

        {/* <div className="dev-row-dots margin-b40">
          <div className="dev-dots margin-right"></div>
          <div className="dev-dots margin-right"></div>
          <div className="dev-dots"></div>
        </div> */}

        <span className="text-quicksand darkgrey margin-t40">
          The Gyld IKE, AVEROF 16, 10433 ATHENS, VAT NUMBER: 801880490, COMPANY
          NUMBER: 165089803000
        </span>
      </div>
    </div>
  );
};

export default About;
