import React, { useState, useEffect } from "react";
import axiosService from "../../utils/axios";
import LeadCard from "../../components/lead-card/LeadCard";
import locationSlice from "../../store/slices/location";
import "./Jobs.css";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import CloseIcon from "@mui/icons-material/Close";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import PendingIcon from "@mui/icons-material/Pending";
import CheckIcon from "@mui/icons-material/Check";
import HelpIcon from "@mui/icons-material/Help";
import AddLead from "../../components/add-lead/AddLead";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import JobCard from "../../components/job-card/JobCard";
import JobCardAdmin from "../../components/job-card/JobCardAdmin";
import AddJob from "../../components/add-job/AddJob";
import ProposeCandidates from "../../components/propose-candidates/ProposeCandidates";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import SelectCandidates from "../../components/select-candidates/SelectCandidates";
import AddContract from "../../components/add-contract/AddContract";
import InviteCandidates from "../../components/invite-candidates/InviteCandidates";
import { useAlert } from "react-alert";
function JobsAdmin() {
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState<Array<any>>([]);
  const alert = useAlert();
  const [checkedClicked, setCheckedClicked] = useState(false);
  const [acceptedClicked, setAcceptedClicked] = useState(false);
  const [rejectedClicked, setRejectedClicked] = useState(false);
  const [startedClicked, setStartedClicked] = useState(false);
  const [finishedClicked, setFinishedClicked] = useState(false);
  const [newClicked, setNewClicked] = useState(false);
  const [hasApplicantsClicked, setHasApplicantsClicked] = useState(false);
  const [numChecked, setNumChecked] = useState(0);
  const [numAccepted, setNumAccepted] = useState(0);
  const [numRejected, setNumRejected] = useState(0);
  const [numStarted, setNumStarted] = useState(0);
  const [numFinished, setNumFinished] = useState(0);
  const [numNew, setNumNew] = useState(0);
  const [numHasApplicants, setNumHasApplicants] = useState(0);
  const [isFirst, setIsFirst] = useState(true);
  const [hideHints, setHideHints] = useState(false);
  const [showAddJobPopup, setShowAddJobPopup] = useState(false);
  const [showProposeCandidatesPopup, setShowProposeCandidatesPopup] =
    useState(false);
  const [showInviteCandidatesPopup, setShowInviteCandidatesPopup] =
    useState(false);
  const [showCreateContractPopup, setShowCreateContractPopup] = useState(false);
  const [showCreateProjectPopup, setShowCreateProjectPopup] = useState(false);
  const [showSelectCandidatesPopup, setShowSelectCandidatesPopup] =
    useState(false);
  const [selectedJob, setSelectedJob] = useState<any>({});
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [hotSkills, setHotSkills] = useState<any[]>([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (isFirst) {
      getJobs();
      getSkills();
      setIsFirst(false);
    }
  }, []);

  const getSkills = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_skills`)
      .then((res) => {
        let tmpHotSkills: any[] = [];
        res.data.forEach((data: any) => {
          if (data.is_hot_skill) {
            tmpHotSkills.push(data);
          }
        });

        setHotSkills(tmpHotSkills);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getJobs = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_jobs`)
      .then((res) => {
        setJobs(res.data);
        if (filteredJobs.length > 0) {
          let tmpFiltered: any[] = [];
          filteredJobs.forEach((filter: any) => {
            res.data.forEach((lead: any) => {
              if (
                lead.status === filter.status &&
                !tmpFiltered.includes(lead)
              ) {
                tmpFiltered.push(lead);
              }
            });
          });
          setFilteredJobs(tmpFiltered);
        }
        let tmpRej = 0;
        let tmpFin = 0;
        let tmpSt = 0;
        let tmpAcc = 0;
        let tmpPend = 0;
        let tmpNew = 0;
        let tmpAppl = 0;
        res.data.forEach((job: any) => {
          if (job.status === "REJECTED") {
            tmpRej++;
          } else if (job.status === "FINISHED") {
            tmpFin++;
          } else if (job.status === "STARTED") {
            tmpSt++;
          } else if (job.status === "ACCEPTED") {
            tmpAcc++;
          } else if (job.status === "PENDING") {
            tmpPend++;
          } else if (job.status === "NEW") {
            tmpNew++;
          } else if (job.status === "HAS_APPLICANTS") {
            tmpAppl++;
          }
        });
        setNumAccepted(tmpAcc);
        setNumNew(tmpNew);
        setNumHasApplicants(tmpAppl);
        setNumChecked(tmpPend);
        setNumFinished(tmpFin);
        setNumStarted(tmpSt);
        setNumRejected(tmpRej);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const handleFinishedClick = () => {
    setFinishedClicked(!finishedClicked);
    if (!finishedClicked) {
      let tmp = filteredJobs;
      jobs.forEach((job: any) => {
        if (job.status === "FINISHED") {
          tmp.push(job);
        }
      });
      setFilteredJobs(tmp);
    } else {
      let tmp: any[] = [];
      filteredJobs.forEach((job: any) => {
        if (job.status !== "FINISHED") {
          tmp.push(job);
        }
      });
      setFilteredJobs(tmp);
    }
  };
  const handleNewClick = () => {
    setNewClicked(!newClicked);
    if (!newClicked) {
      let tmp = filteredJobs;
      jobs.forEach((job: any) => {
        if (job.status === "NEW") {
          tmp.push(job);
        }
      });
      setFilteredJobs(tmp);
    } else {
      let tmp: any[] = [];
      filteredJobs.forEach((job: any) => {
        if (job.status !== "NEW") {
          tmp.push(job);
        }
      });
      setFilteredJobs(tmp);
    }
  };
  const handleHasApplicantsClick = () => {
    setHasApplicantsClicked(!hasApplicantsClicked);
    if (!hasApplicantsClicked) {
      let tmp = filteredJobs;
      jobs.forEach((job: any) => {
        if (job.status === "HAS_APPLICANTS") {
          tmp.push(job);
        }
      });
      setFilteredJobs(tmp);
    } else {
      let tmp: any[] = [];
      filteredJobs.forEach((job: any) => {
        if (job.status !== "HAS_APPLICANTS") {
          tmp.push(job);
        }
      });
      setFilteredJobs(tmp);
    }
  };
  const handleCheckedClick = () => {
    setCheckedClicked(!checkedClicked);
    if (!checkedClicked) {
      let tmp = filteredJobs;
      jobs.forEach((job: any) => {
        if (job.status === "PENDING") {
          tmp.push(job);
        }
      });
      setFilteredJobs(tmp);
    } else {
      let tmp: any[] = [];
      filteredJobs.forEach((job: any) => {
        if (job.status !== "PENDING") {
          tmp.push(job);
        }
      });

      setFilteredJobs(tmp);
    }
  };
  const handleAcceptedClick = () => {
    setAcceptedClicked(!acceptedClicked);
    if (!acceptedClicked) {
      let tmp = filteredJobs;
      jobs.forEach((job: any) => {
        if (job.status === "ACCEPTED") {
          tmp.push(job);
        }
      });
      setFilteredJobs(tmp);
    } else {
      let tmp: any[] = [];
      filteredJobs.forEach((job: any) => {
        if (job.status !== "ACCEPTED") {
          tmp.push(job);
        }
      });

      setFilteredJobs(tmp);
    }
  };
  const handleRejectedClick = () => {
    setRejectedClicked(!rejectedClicked);
    if (!rejectedClicked) {
      let tmp = filteredJobs;
      jobs.forEach((job: any) => {
        if (job.status === "REJECTED") {
          tmp.push(job);
        }
      });
      setFilteredJobs(tmp);
    } else {
      let tmp: any[] = [];
      filteredJobs.forEach((job: any) => {
        if (job.status !== "REJECTED") {
          tmp.push(job);
        }
      });

      setFilteredJobs(tmp);
    }
  };

  const closeSelectCandidatesPopup = () => {
    setShowSelectCandidatesPopup(false);
    getJobs();
    setSelectedJob({});
  };
  const handleStartedClick = () => {
    setStartedClicked(!startedClicked);
    if (!startedClicked) {
      let tmp = filteredJobs;
      jobs.forEach((job: any) => {
        if (job.status === "STARTED") {
          tmp.push(job);
        }
      });
      setFilteredJobs(tmp);
    } else {
      let tmp: any[] = [];
      filteredJobs.forEach((job: any) => {
        if (job.status !== "STARTED") {
          tmp.push(job);
        }
      });

      setFilteredJobs(tmp);
    }
  };
  const editJob = (job: any) => {
    setSelectedJob(job);
    setShowAddJobPopup(true);
  };

  const proposeCandidates = (job: any) => {
    setSelectedJob(job);
    setShowProposeCandidatesPopup(true);
  };
  const inviteCandidates = (job: any) => {
    setSelectedJob(job);
    setShowInviteCandidatesPopup(true);
  };
  const createContract = (job: any) => {
    setSelectedJob(job);
    setShowCreateContractPopup(true);
  };

  const closeJobPopup = () => {
    setShowAddJobPopup(false);
    getJobs();
    setSelectedJob({});
  };
  const closeProposeCandidatesPopup = () => {
    setShowProposeCandidatesPopup(false);
    getJobs();
    setSelectedJob({});
  };
  const closeInviteCandidatesPopup = () => {
    setShowInviteCandidatesPopup(false);
    getJobs();
    setSelectedJob({});
  };
  const closeCreateContractPopup = () => {
    setShowCreateContractPopup(false);
    getJobs();
    setSelectedJob({});
  };

  const viewAcceptedCandidates = (job: any) => {
    setSelectedJob(job);
    setShowSelectCandidatesPopup(true);
  };
  const createProject = (job: any) => {
    setSelectedJob(job);
    setShowCreateProjectPopup(true);
  };

  const acceptLead = (id: number) => {
    changeStatusLead(id, "ACCEPTED");
  };
  const rejectLead = (id: number) => {
    changeStatusLead(id, "REJECTED");
  };
  const changeStatusLead = (id: number, status: string) => {
    let body = {
      id: id,
      status: status,
    };
    axiosService
      .post(`${process.env.REACT_APP_API_URL}/change_lead_status`, body)
      .then((res) => {
        alert.success("The lead status has been changed");
        getJobs();
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }
      });
  };
  return (
    <div className="column-space-between">
      <div
        className="row-space-between wrap "
        style={{ justifyContent: hideHints ? "flex-end" : "space-between" }}
      >
        <button
          className="signUp button add-lead-button"
          onClick={() => setShowAddJobPopup(true)}
        >
          <span className="signUpLink">Add A Job</span>
        </button>

        {showAddJobPopup && selectedJob && selectedJob.name ? (
          <AddJob
            show={showAddJobPopup}
            onClose={closeJobPopup}
            job={selectedJob}
          />
        ) : (
          showAddJobPopup && (
            <AddJob show={showAddJobPopup} onClose={closeJobPopup} />
          )
        )}
        {selectedJob && selectedJob.name && showProposeCandidatesPopup && (
          <ProposeCandidates
            show={showProposeCandidatesPopup}
            onClose={closeProposeCandidatesPopup}
            job_id={selectedJob.id}
            proposed_candidates={selectedJob.proposed_devs}
            proposed_groups={selectedJob.proposed_groups}
            suggested_candidates={selectedJob.suggested_proposed_devs}
            suggested_groups={selectedJob.suggested_proposed_groups}
          />
        )}
        {selectedJob && selectedJob.name && showInviteCandidatesPopup && (
          <InviteCandidates
            show={showInviteCandidatesPopup}
            onClose={closeInviteCandidatesPopup}
            job_id={selectedJob.id}
          />
        )}
        {selectedJob && selectedJob.name && showCreateContractPopup && (
          <AddContract
            show={showCreateContractPopup}
            onClose={closeCreateContractPopup}
            job={selectedJob}
          />
        )}
      </div>
      <div className="leads-background-grey">
        <div className="row-start-center full-percent-width wrap">
          <div
            className="kpi-card-jobs margin-t20"
            style={{
              border: !finishedClicked ? "0px solid grey" : "1px solid #FF6210",
              boxShadow: !finishedClicked
                ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                : "none",
            }}
            onClick={handleFinishedClick}
          >
            <img
              src={require("../../assets/images/leads1.png")}
              className="leads-icons"
            />
            <strong className="text-quicksand700-small darkgrey padding-r5">
              {" "}
              Finished Jobs
            </strong>
            <strong className="text-quicksand-small lightgrey">
              ({numFinished})
            </strong>
          </div>
          <div
            className="kpi-card-jobs margin-t20"
            style={{
              border: !newClicked ? "0px solid grey" : "1px solid #FF6210",
              boxShadow: !newClicked
                ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                : "none",
            }}
            onClick={handleNewClick}
          >
            <img
              src={require("../../assets/images/leads1.png")}
              className="leads-icons"
            />
            <strong className="text-quicksand700-small darkgrey padding-r5">
              {" "}
              New Jobs
            </strong>
            <strong className="text-quicksand-small lightgrey">
              ({numNew})
            </strong>
          </div>
          <div
            className="kpi-card-jobs margin-t20"
            style={{
              border: !hasApplicantsClicked
                ? "0px solid grey"
                : "1px solid #FF6210",
              boxShadow: !hasApplicantsClicked
                ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                : "none",
            }}
            onClick={handleHasApplicantsClick}
          >
            <img
              src={require("../../assets/images/leads1.png")}
              className="leads-icons"
            />
            <strong className="text-quicksand700-small darkgrey padding-r5">
              {" "}
              Jobs with Applicants
            </strong>
            <strong className="text-quicksand-small lightgrey">
              ({numHasApplicants})
            </strong>
          </div>
          <div
            className="kpi-card-jobs margin-t20"
            style={{
              border: !startedClicked ? "0px solid grey" : "1px solid #FF6210",
              boxShadow: !startedClicked
                ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                : "none",
            }}
            onClick={handleStartedClick}
          >
            <img
              src={require("../../assets/images/leads1.png")}
              className="leads-icons"
            />
            <strong className="text-quicksand700-small darkgrey padding-r5">
              {" "}
              Started Jobs
            </strong>
            <strong className="text-quicksand-small lightgrey">
              ({numStarted})
            </strong>
          </div>
          <div
            className="kpi-card-jobs margin-t20"
            style={{
              border: !acceptedClicked ? "0px solid grey" : "1px solid #FF6210",
              boxShadow: !acceptedClicked
                ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                : "none",
            }}
            onClick={handleAcceptedClick}
          >
            <img
              src={require("../../assets/images/leads1.png")}
              className="leads-icons"
            />
            <strong className="text-quicksand700-small darkgrey padding-r5">
              {" "}
              Accepted Jobs
            </strong>
            <strong className="text-quicksand-small lightgrey">
              ({numAccepted})
            </strong>
          </div>
          <div
            className="kpi-card-jobs margin-t20"
            style={{
              border: !checkedClicked ? "0px solid grey" : "1px solid #FF6210",
              boxShadow: !checkedClicked
                ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                : "none",
            }}
            onClick={handleCheckedClick}
          >
            <img
              src={require("../../assets/images/leads1.png")}
              className="leads-icons"
            />
            <strong className="text-quicksand700-small darkgrey padding-r5">
              {" "}
              Pending Jobs
            </strong>
            <strong className="text-quicksand-small lightgrey">
              ({numChecked})
            </strong>
          </div>
          <div
            className="kpi-card-jobs margin-t20"
            style={{
              border: !rejectedClicked ? "0px solid grey" : "1px solid #FF6210",
              boxShadow: !rejectedClicked
                ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                : "none",
            }}
            onClick={handleRejectedClick}
          >
            <img
              src={require("../../assets/images/leads1.png")}
              className="leads-icons"
            />
            <strong className="text-quicksand700-small darkgrey padding-r5">
              {" "}
              Rejected Jobs
            </strong>
            <strong className="text-quicksand-small lightgrey">
              ({numRejected})
            </strong>
          </div>
        </div>

        {selectedJob && selectedJob.name && (
          <SelectCandidates
            show={showSelectCandidatesPopup}
            onClose={closeSelectCandidatesPopup}
            job_id={selectedJob.id}
            proposed_candidates={selectedJob.proposed_devs}
            selected_candidates={selectedJob.accepted_devs}
            suggested_candidates={selectedJob.suggested_proposed_devs}
            suggested_groups={selectedJob.suggested_proposed_groups}
            proposed_groups={selectedJob.proposed_groups}
            selected_groups={selectedJob.accepted_groups}
          />
        )}
        <div className="separate-area ">
          {filteredJobs.length > 0 ? (
            <div>
              {filteredJobs.map((job: any) => {
                return (
                  <JobCardAdmin
                    key={job.id}
                    job={job}
                    editJob={editJob}
                    hotSkills={hotSkills}
                    inviteCandidates={inviteCandidates}
                    proposeCandidates={proposeCandidates}
                    viewAcceptedCandidates={viewAcceptedCandidates}
                    createProject={createProject}
                    createContract={createContract}
                  />
                );
              })}
            </div>
          ) : jobs.length > 0 ? (
            <div>
              {jobs.map((job: any) => {
                return (
                  <JobCardAdmin
                    key={job.id}
                    job={job}
                    editJob={editJob}
                    hotSkills={hotSkills}
                    inviteCandidates={inviteCandidates}
                    proposeCandidates={proposeCandidates}
                    viewAcceptedCandidates={viewAcceptedCandidates}
                    createProject={createProject}
                    createContract={createContract}
                  />
                );
              })}
            </div>
          ) : (
            <br />
          )}
        </div>
      </div>
    </div>
  );
}

export default JobsAdmin;
