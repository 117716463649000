import { useEffect, useState } from "react";
import "./ProposeCandidates.css";
import PropTypes from "prop-types";
import axiosService from "../../utils/axios";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Input, TextareaAutosize, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAlert } from "react-alert";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import PublicProfile from "../../screens/public-profile/PublicProfile";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";

import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";
import Modal from "@mui/material/Modal";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export interface SimpleDialogProps {
  open: boolean;

  usersHelp: any;
  usersLearn: any;
  showPublicProfile: any;
  onClose: (value: any) => void;
}

function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, usersHelp, usersLearn, showPublicProfile, open } = props;
  const [selectedGroup, setSelectedGroup] = useState<any[]>([]);
  const [selectedLearn, setSelectedLearn] = useState<any>({});
  const [selectedHelp, setSelectedHelp] = useState<any>({});
  const handleClose = () => {
    onClose(selectedGroup);
  };

  const handleListItemClick = (value: any) => {
    onClose(value);
  };
  const handleGroupSelect = () => {
    onClose({ user_help: selectedHelp, user_learn: selectedLearn });
  };
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Create Group</DialogTitle>

      <span className="text-quicksand700 opp-grey margin-left">Learn</span>

      {props.usersLearn.map((user: any) => (
        <div key={user.id} className="userRfpp-row-with-line">
          <div className="row-start-center">
            <Checkbox
              onChange={() => setSelectedLearn(user)}
              checked={selectedLearn === user}
            />
            {user.image && user.image.includes("media") ? (
              <img
                src={
                  process.env.REACT_APP_API_URL?.split("/api")[0] + user.image
                }
                className="profile-img"
              />
            ) : (
              <div className="profile-img no-pic">
                <span>{user.username.charAt(0)}</span>
              </div>
            )}

            <span className="text-quicksand700 darkgrey padding-normal">
              {user.username}
            </span>
          </div>
        </div>
      ))}

      <span className="text-quicksand700 opp-grey margin-left ">Help</span>

      {props.usersHelp.map((user: any) => (
        <div key={user.id} className="userRfpp-row-with-line">
          <div className="row-start-center">
            <Checkbox
              onChange={() => setSelectedHelp(user)}
              checked={selectedHelp === user}
            />
            {user.image && user.image.includes("media") ? (
              <img
                src={
                  process.env.REACT_APP_API_URL?.split("/api")[0] + user.image
                }
                className="profile-img"
              />
            ) : (
              <div className="profile-img no-pic">
                <span>{user.username.charAt(0)}</span>
              </div>
            )}

            <span className="text-quicksand700 darkgrey padding-normal">
              {user.username}
            </span>
          </div>
        </div>
      ))}
      <div className="row-space-between-center padding-invite-candi-top">
        <button
          className="button-white-small margin-r20 text-quicksand700-small"
          onClick={() => props.onClose(true)}
        >
          {" "}
          Cancel
        </button>
        <button
          className="button-black-small-nm text-quicksand700-small"
          onClick={handleGroupSelect}
        >
          {" "}
          Add Group
        </button>
      </div>
    </Dialog>
  );
}

const ProposeCandidates = (props: any) => {
  const [devs, setDevs] = useState<any[]>([]);
  const alert = useAlert();
  const [show, setShow] = useState(false);
  const [usersProject, setUsersProject] = useState<any[]>([]);
  const [usersHelp, setUsersHelp] = useState<any[]>([]);
  const [usersLearn, setUsersLearn] = useState<any[]>([]);
  const [leads, setLeads] = useState<any[]>([]);
  const [showProfile, setShowProfile] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>();
  const [isFirst, setIsFirst] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<any[]>([]);
  const [selectedSuggestedUsers, setSelectedSuggestedUsers] = useState<any[]>(
    []
  );
  const [selectedSuggestedGroups, setSelectedSuggestedGroups] = useState<any[]>(
    []
  );
  const [groups, setGroups] = useState<any[]>([]);

  const auth = useSelector((state: RootState) => state.auth);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: any) => {
    setOpen(false);
    if (value.user_help && value.user_learn) {
      setGroups([...groups, value]);
    }
  };
  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        closeHandler(true);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  const closeHandler = (e: any) => {
    setShow(false);
    props.onClose(false);
  };
  useEffect(() => {
    if (isFirst) {
      getUsers();

      setIsFirst(false);
    }
    if (
      (props.proposed_candidates && props.proposed_candidates.length > 0) ||
      (props.proposed_groups && props.proposed_groups.length > 0) ||
      (props.suggested_candidates && props.suggested_candidates.length > 0) ||
      (props.suggested_candidates && props.suggested_candidates.length > 0)
    ) {
      let tmp_users: string[] = [];
      let tmp_suggested: string[] = [];
      props.proposed_candidates.forEach((cand: any) => {
        tmp_users.push(cand.username);
      });
      props.suggested_candidates.forEach((cand: any) => {
        tmp_suggested.push(cand.username);
        if (!tmp_users.includes(cand.username)) {
          tmp_users.push(cand.username);
        }
      });
      setSelectedSuggestedUsers(tmp_suggested);
      setSelectedSuggestedGroups(props.suggested_groups);
      setSelectedUsers(tmp_users);
      setSelectedGroups(props.proposed_groups);
      setGroups(props.proposed_groups);
    } else {
      setSelectedUsers([]);
    }
  }, [props]);

  const getUsers = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_devs`)
      .then((res) => {
        setDevs(res.data);
        axiosService
          .get(
            `${process.env.REACT_APP_API_URL}/get_applied_devs/` + props.job_id
          )
          .then((response) => {
            let tmp_devsProject: any[] = [];
            let tmp_devsHelp: any[] = [];
            let tmp_devsLearn: any[] = [];
            response.data.forEach((user: any) => {
              res.data.forEach((dev: any) => {
                if (dev.id === user.user_id_id) {
                  if (user.type === "PROJECT") {
                    tmp_devsProject.push(dev);
                  } else if (user.type === "LEARN") {
                    tmp_devsLearn.push(dev);
                  } else {
                    tmp_devsHelp.push(dev);
                  }
                }
              });
            });
            setUsersProject(tmp_devsProject);

            setUsersHelp(tmp_devsHelp);
            setUsersLearn(tmp_devsLearn);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const addCandidates = () => {
    let tmp_proposed: number[] = [];
    let tmp_proposed_groups: any[] = [];
    let tmp_suggested: number[] = [];
    let tmp_suggested_groups: any[] = [];
    usersProject.forEach((user: any) => {
      if (selectedSuggestedUsers.includes(user.username)) {
        tmp_suggested.push(user.id);
      }
    });
    selectedSuggestedGroups.forEach((group: any) => {
      let tmp = {
        user_help: group.user_help.id,
        user_learn: group.user_learn.id,
      };
      tmp_suggested_groups.push(tmp);
    });
    usersProject.forEach((user: any) => {
      if (
        selectedUsers.includes(user.username) &&
        !selectedSuggestedUsers.includes(user.username)
      ) {
        tmp_proposed.push(user.id);
      }
    });
    selectedGroups.forEach((group: any) => {
      let tmp = {
        user_help: group.user_help.id,
        user_learn: group.user_learn.id,
      };
      if (!tmp_suggested_groups.includes(tmp)) {
        tmp_proposed_groups.push(tmp);
      }
    });

    let body = {
      proposed_candidates: tmp_proposed,
      proposed_groups: tmp_proposed_groups,
      suggested_proposed_candidates: tmp_suggested,
      suggested_proposed_groups: tmp_suggested_groups,
      job_id: props.job_id,
    };
    axiosService
      .post(`${process.env.REACT_APP_API_URL}/propose_candidates`, body)
      .then((res) => {
        props.onClose(true);
      })

      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const showPublicProfile = (user: any) => {
    setShowProfile(true);
    setSelectedUser(user.id);
  };
  const closeProfile = () => {
    setShowProfile(false);
    setSelectedUser(null);
  };
  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const handleChangeProposedDevs = (username: string) => {
    if (selectedUsers.indexOf(username) > -1) {
      setSelectedUsers(
        selectedUsers.filter((user: any) => {
          return user !== username;
        })
      );
    } else {
      setSelectedUsers([...selectedUsers, username]);
    }
  };

  const handleChangeProposedGroup = (group: any) => {
    if (selectedGroups.indexOf(group) > -1) {
      setSelectedGroups(
        selectedGroups.filter((selGroup: any) => {
          return selGroup !== group;
        })
      );
    } else {
      setSelectedGroups([...selectedGroups, group]);
    }
  };

  const handleChangeSuggestedProposedDevs = (username: string) => {
    if (selectedSuggestedUsers.indexOf(username) > -1) {
      setSelectedSuggestedUsers(
        selectedSuggestedUsers.filter((user: any) => {
          return user !== username;
        })
      );
    } else {
      setSelectedSuggestedUsers([...selectedSuggestedUsers, username]);
    }
    if (selectedUsers.indexOf(username) > -1) {
      setSelectedUsers(
        selectedUsers.filter((user: any) => {
          return user !== username;
        })
      );
    } else {
      setSelectedUsers([...selectedUsers, username]);
    }
  };

  const handleChangeSuggestedProposedGroup = (group: any) => {
    if (selectedSuggestedGroups.indexOf(group) > -1) {
      setSelectedSuggestedGroups(
        selectedSuggestedGroups.filter((selGroup: any) => {
          return selGroup !== group;
        })
      );
    } else {
      setSelectedSuggestedGroups([...selectedSuggestedGroups, group]);
    }
    if (selectedGroups.indexOf(group) > -1) {
      setSelectedGroups(
        selectedGroups.filter((selGroup: any) => {
          return selGroup !== group;
        })
      );
    } else {
      setSelectedGroups([...selectedGroups, group]);
    }
  };

  return (
    // <div
    //   style={{
    //     visibility: show ? "visible" : "hidden",
    //     opacity: show ? "1" : "0",
    //   }}
    //   className={"overlay"}
    // >

    <Modal
      open={show}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div>
        {open && (
          <SimpleDialog
            open={open}
            onClose={handleClose}
            usersHelp={usersHelp}
            usersLearn={usersLearn}
            showPublicProfile={showPublicProfile}
          />
        )}
        {!showProfile ? (
          <div className={"popup-invite-candi"}>
            <div className="row-space-between-center padding-invite-candi-top">
              <span className="heading-lora padding-up-down">
                Propose Candidates
              </span>
              <span className={"close"} onClick={closeHandler}>
                &times;
              </span>
            </div>

         
             
                <span style={{ alignSelf: "center" }}>
                  Job # {props.job_id}
                </span>

                <div className="column-space-between invite-candi-scroll">
                <div className="column-start-start">
                  {usersProject.length > 0 && (
                    <span className="text-quicksand700 opp-grey margin-left">
                      Do the Project
                    </span>
                  )}
                  {usersProject.map((user: any) => (
                    <div key={user.id} className="userRfpp-row-with-line">
                      <div className="row-start-center">
                        {(!props.selected_candidates ||
                          props.selected_candidates.length === 0) && (
                            <Checkbox
                              icon={<StarBorderIcon />}
                              checkedIcon={<StarIcon />}
                              onChange={() =>
                                handleChangeSuggestedProposedDevs(user.username)
                              }
                              checked={
                                selectedSuggestedUsers.indexOf(user.username) > -1
                              }
                            />
                          )}
                        {(!props.selected_candidates ||
                          props.selected_candidates.length === 0) && (
                            <Checkbox
                              onChange={() =>
                                handleChangeProposedDevs(user.username)
                              }
                              checked={selectedUsers.indexOf(user.username) > -1}
                            />
                          )}

                        {user.image && user.image.includes("media") ? (
                          <img
                            onClick={() => showPublicProfile(user)}
                            src={
                              process.env.REACT_APP_API_URL?.split("/api")[0] +
                              user.image
                            }
                            className="profile-img"
                          />
                        ) : (
                          <div
                            onClick={() => showPublicProfile(user)}
                            className="profile-img no-pic"
                          >
                            <span>{user.username.charAt(0)}</span>
                          </div>
                        )}

                        <span
                          onClick={() => showPublicProfile(user)}
                          className="text-quicksand700 darkgrey padding-normal"
                        >
                          {user.username}
                        </span>
                      </div>

                      <ChevronRightIcon
                        className="pointer"
                        onClick={() => showPublicProfile(user)}
                      />
                    </div>
                  ))}
                  {groups.length > 0 && (
                    <span className="text-quicksand700 opp-grey margin-left">
                      Groups
                    </span>
                  )}
                  {groups.map((group: any) => (
                    <div key={group.id} className="userRfpp-row-with-line">
                      <Checkbox
                        icon={<StarBorderIcon />}
                        checkedIcon={<StarIcon />}
                        onChange={() => handleChangeSuggestedProposedGroup(group)}
                        checked={selectedSuggestedGroups.indexOf(group) > -1}
                      />
                      <Checkbox
                        onChange={() => handleChangeProposedGroup(group)}
                        checked={selectedGroups.indexOf(group) > -1}
                      />
                      <div className="row-start-center">
                        {group.user_help.image &&
                          group.user_help.image.includes("media") ? (
                          <img
                            src={
                              process.env.REACT_APP_API_URL?.split("/api")[0] +
                              group.user_help.image
                            }
                            className="profile-img"
                          />
                        ) : (
                          <div className="profile-img no-pic">
                            <span>{group.user_help.username.charAt(0)}</span>
                          </div>
                        )}

                        <span className="text-quicksand700 darkgrey padding-normal">
                          {group.user_help.username}
                        </span>
                      </div>
                      <div className="row-start-center">
                        {group.user_learn.image &&
                          group.user_learn.image.includes("media") ? (
                          <img
                            src={
                              process.env.REACT_APP_API_URL?.split("/api")[0] +
                              group.user_learn.image
                            }
                            className="profile-img"
                          />
                        ) : (
                          <div className="profile-img no-pic">
                            <span>{group.user_learn.username.charAt(0)}</span>
                          </div>
                        )}

                        <span className="text-quicksand700 darkgrey padding-normal">
                          {group.user_learn.username}
                        </span>
                      </div>
                    </div>
                  ))}

                  {usersLearn.length > 0 && (
                    <span className="text-quicksand700 opp-grey margin-left">
                      Learn
                    </span>
                  )}
                  {usersLearn.map((user: any) => (
                    <div key={user.id} className="userRfpp-row-with-line">
                      <div className="row-start-center">
                        {user.image && user.image.includes("media") ? (
                          <img
                            src={
                              process.env.REACT_APP_API_URL?.split("/api")[0] +
                              user.image
                            }
                            className="profile-img"
                          />
                        ) : (
                          <div className="profile-img no-pic">
                            <span>{user.username.charAt(0)}</span>
                          </div>
                        )}

                        <span className="text-quicksand700 darkgrey padding-normal">
                          {user.username}
                        </span>
                      </div>

                      <ChevronRightIcon
                        className="pointer"
                        onClick={() => showPublicProfile(user)}
                      />
                    </div>
                  ))}
                  {usersHelp.length > 0 && (
                    <span className="text-quicksand700 opp-grey margin-left ">
                      Help
                    </span>
                  )}
                  {usersHelp.map((user: any) => (
                    <div key={user.id} className="userRfpp-row-with-line">
                      <div className="row-start-center">
                        {user.image && user.image.includes("media") ? (
                          <img
                            src={
                              process.env.REACT_APP_API_URL?.split("/api")[0] +
                              user.image
                            }
                            className="profile-img"
                          />
                        ) : (
                          <div className="profile-img no-pic">
                            <span>{user.username.charAt(0)}</span>
                          </div>
                        )}

                        <span className="text-quicksand700 darkgrey padding-normal">
                          {user.username}
                        </span>
                      </div>

                      <ChevronRightIcon
                        className="pointer"
                        onClick={() => showPublicProfile(user)}
                      />
                    </div>
                  ))}
                </div>
                {usersHelp.length > 0 && usersLearn.length > 0 && (
                  <span
                    className="text-quicksand-700 orange-text pointer max-content-width margin-left"
                    onClick={handleClickOpen}
                  >
                    Add Group
                  </span>
                )}
              </div>
          

            <div className="row-flex-end-end margin-t20 padding-20-30">
              <button
                className="button-white-small margin-r20 text-quicksand700-small"
                onClick={() => props.onClose(true)}
              >
                {" "}
                Cancel
              </button>
              <button
                className="button-black-small-nm text-quicksand700-small"
                onClick={addCandidates}
                disabled={
                  selectedUsers.length == 0 &&
                  selectedGroups.length == 0 &&
                  selectedSuggestedGroups.length == 0 &&
                  selectedSuggestedUsers.length == 0
                }
              >
                {" "}
                Add Candidates
              </button>
            </div>
          </div>
        ) : (
          <div className={"invite-candi-to-pub-prof"}>
             <div className="row-space-between-center">
            <ChevronLeftIcon onClick={closeProfile} className="pointer"/>
            <span className={"close"} onClick={closeHandler}>
              &times;
            </span>
            </div>
            <PublicProfile user={selectedUser} />
           
          </div>
        )}
      </div>
    </Modal>
  );
};

ProposeCandidates.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  job_id: PropTypes.number.isRequired,
  proposed_candidates: PropTypes.array,
  proposed_groups: PropTypes.array,
  suggested_candidates: PropTypes.array,
  suggested_groups: PropTypes.array,
};

export default ProposeCandidates;
