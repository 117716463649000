import React, { useState, useEffect } from "react";
import axiosService from "../../utils/axios";
import RequestCard from "../../components/request-card/RequestCard";
import locationSlice from "../../store/slices/location";
import "./Requests.css";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import PlatformNavigation from "../../components/platform-navigation/PlatformNavigation";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import PendingIcon from "@mui/icons-material/Pending";
import CheckIcon from "@mui/icons-material/Check";
import HelpIcon from "@mui/icons-material/Help";
import AddRequest from "../../components/add-request/AddRequest";
import AddJob from "../../components/add-job/AddJob";
import StartIcon from "@mui/icons-material/Start";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { OutlinedInput, TextField } from "@mui/material";

const RequestsAdmin = () => {
  const [requests, setRequests] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState<Array<any>>([]);
  const [selectedLead, setSelectedLead] = useState<any>({});
  const [openCreateJob, setOpenCreateJob] = useState(false);
  const [checkedClicked, setCheckedClicked] = useState(false);
  const [acceptedClicked, setAcceptedClicked] = useState(false);
  const [rejectedClicked, setRejectedClicked] = useState(false);
  const [startedClicked, setStartedClicked] = useState(false);
  const [finishedClicked, setFinishedClicked] = useState(false);
  const [numChecked, setNumChecked] = useState(0);
  const [numAccepted, setNumAccepted] = useState(0);
  const [numRejected, setNumRejected] = useState(0);
  const [numStarted, setNumStarted] = useState(0);
  const [numFinished, setNumFinished] = useState(0);
  const [isFirst, setIsFirst] = useState(true);
  const [hideHints, setHideHints] = useState(false);
  const [showAddRequestPopup, setShowAddRequestPopup] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth);
  const alert = useAlert();
  useEffect(() => {
    if (isFirst) {
      getRequests();

      setIsFirst(false);
    }

    if (!auth.account) {
      navigate("/login");
    }
  }, []);

  const getRequests = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_rfpps`)
      .then((res) => {
        setRequests(res.data);
        let tmpRej = 0;
        let tmpFin = 0;
        let tmpSt = 0;
        let tmpAcc = 0;
        let tmpPend = 0;
        res.data.forEach((request: any) => {
          if (request.status === "REJECTED") {
            tmpRej++;
          } else if (request.status === "FINISHED") {
            tmpFin++;
          } else if (request.status === "STARTED") {
            tmpSt++;
          } else if (request.status === "ACCEPTED") {
            tmpAcc++;
          } else if (request.status === "PENDING") {
            tmpPend++;
          }
        });
        setNumAccepted(tmpAcc);
        setNumChecked(tmpPend);
        setNumFinished(tmpFin);
        setNumStarted(tmpSt);
        setNumRejected(tmpRej);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const handleFinishedClick = () => {
    setFinishedClicked(!finishedClicked);
    if (!finishedClicked) {
      let tmp = filteredRequests;
      requests.forEach((request: any) => {
        if (request.status === "FINISHED") {
          tmp.push(request);
        }
      });
      setFilteredRequests(tmp);
    } else {
      let tmp: any[] = [];
      filteredRequests.forEach((request: any) => {
        if (request.status !== "FINISHED") {
          tmp.push(request);
        }
      });
      setFilteredRequests(tmp);
    }
  };
  const handleCheckedClick = () => {
    setCheckedClicked(!checkedClicked);
    if (!checkedClicked) {
      let tmp = filteredRequests;
      requests.forEach((request: any) => {
        if (request.status === "PENDING") {
          tmp.push(request);
        }
      });
      setFilteredRequests(tmp);
    } else {
      let tmp: any[] = [];
      filteredRequests.forEach((request: any) => {
        if (request.status !== "PENDING") {
          tmp.push(request);
        }
      });

      setFilteredRequests(tmp);
    }
  };
  const handleAcceptedClick = () => {
    setAcceptedClicked(!acceptedClicked);
    if (!acceptedClicked) {
      let tmp = filteredRequests;
      requests.forEach((request: any) => {
        if (request.status === "ACCEPTED") {
          tmp.push(request);
        }
      });
      setFilteredRequests(tmp);
    } else {
      let tmp: any[] = [];
      filteredRequests.forEach((request: any) => {
        if (request.status !== "ACCEPTED") {
          tmp.push(request);
        }
      });

      setFilteredRequests(tmp);
    }
  };
  const handleRejectedClick = () => {
    setRejectedClicked(!rejectedClicked);
    if (!rejectedClicked) {
      let tmp = filteredRequests;
      requests.forEach((request: any) => {
        if (request.status === "REJECTED") {
          tmp.push(request);
        }
      });

      setFilteredRequests(tmp);
    } else {
      let tmp: any[] = [];
      filteredRequests.forEach((request: any) => {
        if (request.status !== "REJECTED") {
          tmp.push(request);
        }
      });

      setFilteredRequests(tmp);
    }
  };
  const handleStartedClick = () => {
    setStartedClicked(!startedClicked);
    if (!startedClicked) {
      let tmp = filteredRequests;
      requests.forEach((request: any) => {
        if (request.status === "STARTED") {
          tmp.push(request);
        }
      });
      setFilteredRequests(tmp);
    } else {
      let tmp: any[] = [];
      filteredRequests.forEach((request: any) => {
        if (request.status !== "STARTED") {
          tmp.push(request);
        }
      });

      setFilteredRequests(tmp);
    }
  };
  const handleClosePopup = () => {
    setShowAddRequestPopup(false);
    getRequests();
  };
  const closeCreateJob = () => {
    setOpenCreateJob(false);
    getRequests();
    setSelectedLead({});
  };

  const createJob = (lead: any) => {
    setSelectedLead(lead);
    setOpenCreateJob(true);
  };
  return (
    <div className="column-space-between">
      {showAddRequestPopup && (
        <AddRequest show={showAddRequestPopup} onClose={handleClosePopup} />
      )}{" "}
      <button
        className="signUp button add-lead-button"
        onClick={() => setShowAddRequestPopup(true)}
      >
        <span className="signUpLink">Add A Request</span>
      </button>
      {selectedLead && selectedLead.name && openCreateJob ? (
        <AddJob
          show={openCreateJob}
          onClose={closeCreateJob}
          lead={selectedLead}
        />
      ) : (
        openCreateJob && (
          <AddJob show={openCreateJob} onClose={closeCreateJob} />
        )
      )}
      <div className="leads-background-grey">

      {window.innerWidth >= 1508 ? (
        <div className="projects-row-start">
          <div
            className="kpi-card-requests margin-right-small"
            style={{
              border: !finishedClicked ? "0px solid grey" : "1px solid #FF6210",
              boxShadow: !finishedClicked
                ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                : "none",
            }}
            onClick={handleFinishedClick}
          >
            <DoneAllIcon className="margin-kpi-projects req-green" />
            <strong className="text-quicksand700-small darkgrey padding-r5">
              {" "}
              Finished Requests
            </strong>
            <strong className="text-quicksand-small lightgrey">
              ({numFinished})
            </strong>
          </div>

          <div
            className="kpi-card-requests margin-right-small"
            style={{
              border: !startedClicked ? "0px solid grey" : "1px solid #FF6210",
              boxShadow: !startedClicked
                ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                : "none",
            }}
            onClick={handleStartedClick}
          >
            <StartIcon className="margin-kpi-projects req-blue" />
            <strong className="text-quicksand700-small darkgrey padding-r5">
              {" "}
              Started Requests
            </strong>
            <strong className="text-quicksand-small lightgrey">
              ({numStarted})
            </strong>
          </div>

          <div
            className="kpi-card-requests margin-right-small"
            style={{
              border: !acceptedClicked ? "0px solid grey" : "1px solid #FF6210",
              boxShadow: !acceptedClicked
                ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                : "none",
            }}
            onClick={handleAcceptedClick}
          >
            <ThumbUpOffAltIcon className="margin-kpi-projects req-purple" />
            <strong className="text-quicksand700-small darkgrey padding-r5">
              Accepted Requests
            </strong>
            <strong className="text-quicksand-small lightgrey">
              ({numAccepted})
            </strong>
          </div>

          <div
            className="kpi-card-requests margin-right-small"
            style={{
              border: !checkedClicked ? "0px solid grey" : "1px solid #FF6210",
              boxShadow: !checkedClicked
                ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                : "none",
            }}
            onClick={handleCheckedClick}
          >
            <HourglassEmptyIcon className="margin-kpi-projects req-turquoise" />
            <strong className="text-quicksand700-small darkgrey padding-r5">
              {" "}
              Requests Processing
            </strong>
            <strong className="text-quicksand-small lightgrey">
              ({numChecked})
            </strong>
          </div>

          <div
            className="kpi-card-requests"
            style={{
              border: !rejectedClicked ? "0px solid grey" : "1px solid #FF6210",
              boxShadow: !rejectedClicked
                ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                : "none",
            }}
            onClick={handleRejectedClick}
          >
            <DoDisturbIcon className="margin-kpi-projects req-red" />
            <strong className="text-quicksand700-small darkgrey padding-r5">
              {" "}
              Rejected Requests
            </strong>
            <strong className="text-quicksand-small lightgrey">
              ({numRejected})
            </strong>
          </div>
        </div>
 ) : (
  <Box className="full-percent-width">
    <FormControl fullWidth>
      <InputLabel shrink={true} id="demo-simple-select-label">
        Filter Requests
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        // value={skill.level}
        input={
          <OutlinedInput
            id="select-multiple-chip"
            label="Filter Requests"
            notched
          />
        }
      >

        <MenuItem value={"FINISHEDREQUESTS"}>
          {" "}
          <DoneAllIcon className="margin-r3 " />
          Finished Requests {"( " + numFinished.toString() + " )"}
        </MenuItem>

        <MenuItem value={"STARTEDREQUESTS"}>
          {" "}
          <StartIcon className="margin-r3 req-green" />
          Started Requests {"( " + numStarted.toString() + " )"}
        </MenuItem>

        <MenuItem value={"ACCEPTEDREQUESTS"}>
          {" "}
          <StartIcon className="margin-r3 req-blue" />
          Accepted Requests {"( " + numAccepted.toString() + " )"}
        </MenuItem>

        <MenuItem value={"PROCESSINGREQUESTS"}>
          <HourglassEmptyIcon className="margin-r3 req-purple" />
         Requests Processing{"( " + numChecked.toString() + " )"}
        </MenuItem>

        <MenuItem value={"REJECTEDREQUESTS"}>
          <HourglassEmptyIcon className="margin-r3 req-turquoise" />
          Rejected Requests {"( " + numRejected.toString() + " )"}
        </MenuItem>

        <MenuItem value={"REJECTEDREQUESTS"}>
          <DoDisturbIcon className="margin-r3 req-red" />
          Rejected Requests {"( " + numRejected.toString() + " )"}
        </MenuItem>
      </Select>
    </FormControl>
  </Box>
)}
        <div className="separate-area requests-body">
          {filteredRequests.length > 0 ? (
            <div>
              {filteredRequests.map((request: any) => {
                return (
                  <RequestCard
                    createJob={createJob}
                    key={request.id}
                    request={request}
                    getRequests={getRequests}
                  />
                );
              })}
            </div>
          ) : requests.length > 0 ? (
            <div>
              {requests.map((request: any) => {
                return (
                  <RequestCard
                    createJob={createJob}
                    key={request.id}
                    request={request}
                    getRequests={getRequests}
                  />
                );
              })}
            </div>
          ) : (
            <br />
          )}
        </div>
      </div>
    </div>
  );
};

export default RequestsAdmin;
