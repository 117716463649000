import "./PlatformProfile.css";
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import { useAlert } from "react-alert";
import {
  Button,
  Input,
  InputAdornment,
  OutlinedInput,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import Switch from "@mui/material/Switch";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// @ts-ignore
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import locationSlice from "../../store/slices/location";
import ImageUploading from "react-images-uploading";
import axiosService from "../../utils/axios";
import Autocomplete from "@mui/material/Autocomplete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import "moment/locale/en-gb";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import authSlice from "../../store/slices/auth";
interface CountryType {
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
}

const languages = [
  { code: "ab", name: "Abkhaz", nativeName: "аҧсуа" },
  { code: "aa", name: "Afar", nativeName: "Afaraf" },
  { code: "af", name: "Afrikaans", nativeName: "Afrikaans" },
  { code: "ak", name: "Akan", nativeName: "Akan" },
  { code: "sq", name: "Albanian", nativeName: "Shqip" },
  { code: "am", name: "Amharic", nativeName: "አማርኛ" },
  { code: "ar", name: "Arabic", nativeName: "العربية" },
  { code: "an", name: "Aragonese", nativeName: "Aragonés" },
  { code: "hy", name: "Armenian", nativeName: "Հայերեն" },
  { code: "as", name: "Assamese", nativeName: "অসমীয়া" },
  { code: "av", name: "Avaric", nativeName: "авар мацӀ, магӀарул мацӀ" },
  { code: "ae", name: "Avestan", nativeName: "avesta" },
  { code: "ay", name: "Aymara", nativeName: "aymar aru" },
  { code: "az", name: "Azerbaijani", nativeName: "azərbaycan dili" },
  { code: "bm", name: "Bambara", nativeName: "bamanankan" },
  { code: "ba", name: "Bashkir", nativeName: "башҡорт теле" },
  { code: "eu", name: "Basque", nativeName: "euskara, euskera" },
  { code: "be", name: "Belarusian", nativeName: "Беларуская" },
  { code: "bn", name: "Bengali", nativeName: "বাংলা" },
  { code: "bh", name: "Bihari", nativeName: "भोजपुरी" },
  { code: "bi", name: "Bislama", nativeName: "Bislama" },
  { code: "bs", name: "Bosnian", nativeName: "bosanski jezik" },
  { code: "br", name: "Breton", nativeName: "brezhoneg" },
  { code: "bg", name: "Bulgarian", nativeName: "български език" },
  { code: "my", name: "Burmese", nativeName: "ဗမာစာ" },
  { code: "ca", name: "Catalan; Valencian", nativeName: "Català" },
  { code: "ch", name: "Chamorro", nativeName: "Chamoru" },
  { code: "ce", name: "Chechen", nativeName: "нохчийн мотт" },
  {
    code: "ny",
    name: "Chichewa; Chewa; Nyanja",
    nativeName: "chiCheŵa, chinyanja",
  },
  { code: "zh", name: "Chinese", nativeName: "中文 (Zhōngwén), 汉语, 漢語" },
  { code: "cv", name: "Chuvash", nativeName: "чӑваш чӗлхи" },
  { code: "kw", name: "Cornish", nativeName: "Kernewek" },
  { code: "co", name: "Corsican", nativeName: "corsu, lingua corsa" },
  { code: "cr", name: "Cree", nativeName: "ᓀᐦᐃᔭᐍᐏᐣ" },
  { code: "hr", name: "Croatian", nativeName: "hrvatski" },
  { code: "cs", name: "Czech", nativeName: "česky, čeština" },
  { code: "da", name: "Danish", nativeName: "dansk" },
  { code: "dv", name: "Divehi; Dhivehi; Maldivian;", nativeName: "ދިވެހި" },
  { code: "nl", name: "Dutch", nativeName: "Nederlands, Vlaams" },
  { code: "en", name: "English", nativeName: "English" },
  { code: "eo", name: "Esperanto", nativeName: "Esperanto" },
  { code: "et", name: "Estonian", nativeName: "eesti, eesti keel" },
  { code: "ee", name: "Ewe", nativeName: "Eʋegbe" },
  { code: "fo", name: "Faroese", nativeName: "føroyskt" },
  { code: "fj", name: "Fijian", nativeName: "vosa Vakaviti" },
  { code: "fi", name: "Finnish", nativeName: "suomi, suomen kieli" },
  { code: "fr", name: "French", nativeName: "français, langue française" },
  {
    code: "ff",
    name: "Fula; Fulah; Pulaar; Pular",
    nativeName: "Fulfulde, Pulaar, Pular",
  },
  { code: "gl", name: "Galician", nativeName: "Galego" },
  { code: "ka", name: "Georgian", nativeName: "ქართული" },
  { code: "de", name: "German", nativeName: "Deutsch" },
  { code: "el", name: "Greek", nativeName: "Ελληνικά" },
  { code: "gn", name: "Guaraní", nativeName: "Avañeẽ" },
  { code: "gu", name: "Gujarati", nativeName: "ગુજરાતી" },
  { code: "ht", name: "Haitian; Haitian Creole", nativeName: "Kreyòl ayisyen" },
  { code: "ha", name: "Hausa", nativeName: "Hausa, هَوُسَ" },
  { code: "he", name: "Hebrew ", nativeName: "עברית" },
  { code: "hz", name: "Herero", nativeName: "Otjiherero" },
  { code: "hi", name: "Hindi", nativeName: "हिन्दी, हिंदी" },
  { code: "ho", name: "Hiri Motu", nativeName: "Hiri Motu" },
  { code: "hu", name: "Hungarian", nativeName: "Magyar" },
  { code: "ia", name: "Interlingua", nativeName: "Interlingua" },
  { code: "id", name: "Indonesian", nativeName: "Bahasa Indonesia" },
  {
    code: "ie",
    name: "Interlingue",
    nativeName: "Originally called Occidental; then Interlingue after WWII",
  },
  { code: "ga", name: "Irish", nativeName: "Gaeilge" },
  { code: "ig", name: "Igbo", nativeName: "Asụsụ Igbo" },
  { code: "ik", name: "Inupiaq", nativeName: "Iñupiaq, Iñupiatun" },
  { code: "io", name: "Ido", nativeName: "Ido" },
  { code: "is", name: "Icelandic", nativeName: "Íslenska" },
  { code: "it", name: "Italian", nativeName: "Italiano" },
  { code: "iu", name: "Inuktitut", nativeName: "ᐃᓄᒃᑎᑐᑦ" },
  { code: "ja", name: "Japanese", nativeName: "日本語 (にほんご／にっぽんご)" },
  { code: "jv", name: "Javanese", nativeName: "basa Jawa" },
  {
    code: "kl",
    name: "Kalaallisut, Greenlandic",
    nativeName: "kalaallisut, kalaallit oqaasii",
  },
  { code: "kn", name: "Kannada", nativeName: "ಕನ್ನಡ" },
  { code: "kr", name: "Kanuri", nativeName: "Kanuri" },
  { code: "ks", name: "Kashmiri", nativeName: "कश्मीरी, كشميري‎" },
  { code: "kk", name: "Kazakh", nativeName: "Қазақ тілі" },
  { code: "km", name: "Khmer", nativeName: "ភាសាខ្មែរ" },
  { code: "ki", name: "Kikuyu, Gikuyu", nativeName: "Gĩkũyũ" },
  { code: "rw", name: "Kinyarwanda", nativeName: "Ikinyarwanda" },
  { code: "ky", name: "Kirghiz, Kyrgyz", nativeName: "кыргыз тили" },
  { code: "kv", name: "Komi", nativeName: "коми кыв" },
  { code: "kg", name: "Kongo", nativeName: "KiKongo" },
  {
    code: "ko",
    name: "Korean",
    nativeName: "한국어 (韓國語), 조선말 (朝鮮語)",
  },
  { code: "ku", name: "Kurdish", nativeName: "Kurdî, كوردی‎" },
  { code: "kj", name: "Kwanyama, Kuanyama", nativeName: "Kuanyama" },
  { code: "la", name: "Latin", nativeName: "latine, lingua latina" },
  {
    code: "lb",
    name: "Luxembourgish, Letzeburgesch",
    nativeName: "Lëtzebuergesch",
  },
  { code: "lg", name: "Luganda", nativeName: "Luganda" },
  {
    code: "li",
    name: "Limburgish, Limburgan, Limburger",
    nativeName: "Limburgs",
  },
  { code: "ln", name: "Lingala", nativeName: "Lingála" },
  { code: "lo", name: "Lao", nativeName: "ພາສາລາວ" },
  { code: "lt", name: "Lithuanian", nativeName: "lietuvių kalba" },
  { code: "lu", name: "Luba-Katanga", nativeName: "" },
  { code: "lv", name: "Latvian", nativeName: "latviešu valoda" },
  { code: "gv", name: "Manx", nativeName: "Gaelg, Gailck" },
  { code: "mk", name: "Macedonian", nativeName: "македонски јазик" },
  { code: "mg", name: "Malagasy", nativeName: "Malagasy fiteny" },
  { code: "ms", name: "Malay", nativeName: "bahasa Melayu, بهاس ملايو‎" },
  { code: "ml", name: "Malayalam", nativeName: "മലയാളം" },
  { code: "mt", name: "Maltese", nativeName: "Malti" },
  { code: "mi", name: "Māori", nativeName: "te reo Māori" },
  { code: "mr", name: "Marathi (Marāṭhī)", nativeName: "मराठी" },
  { code: "mh", name: "Marshallese", nativeName: "Kajin M̧ajeļ" },
  { code: "mn", name: "Mongolian", nativeName: "монгол" },
  { code: "na", name: "Nauru", nativeName: "Ekakairũ Naoero" },
  { code: "nv", name: "Navajo, Navaho", nativeName: "Diné bizaad, Dinékʼehǰí" },
  { code: "nb", name: "Norwegian Bokmål", nativeName: "Norsk bokmål" },
  { code: "nd", name: "North Ndebele", nativeName: "isiNdebele" },
  { code: "ne", name: "Nepali", nativeName: "नेपाली" },
  { code: "ng", name: "Ndonga", nativeName: "Owambo" },
  { code: "nn", name: "Norwegian Nynorsk", nativeName: "Norsk nynorsk" },
  { code: "no", name: "Norwegian", nativeName: "Norsk" },
  { code: "ii", name: "Nuosu", nativeName: "ꆈꌠ꒿ Nuosuhxop" },
  { code: "nr", name: "South Ndebele", nativeName: "isiNdebele" },
  { code: "oc", name: "Occitan", nativeName: "Occitan" },
  { code: "oj", name: "Ojibwe, Ojibwa", nativeName: "ᐊᓂᔑᓈᐯᒧᐎᓐ" },
  {
    code: "cu",
    name: "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
    nativeName: "ѩзыкъ словѣньскъ",
  },
  { code: "om", name: "Oromo", nativeName: "Afaan Oromoo" },
  { code: "or", name: "Oriya", nativeName: "ଓଡ଼ିଆ" },
  { code: "os", name: "Ossetian, Ossetic", nativeName: "ирон æвзаг" },
  { code: "pa", name: "Panjabi, Punjabi", nativeName: "ਪੰਜਾਬੀ, پنجابی‎" },
  { code: "pi", name: "Pāli", nativeName: "पाऴि" },
  { code: "fa", name: "Persian", nativeName: "فارسی" },
  { code: "pl", name: "Polish", nativeName: "polski" },
  { code: "ps", name: "Pashto, Pushto", nativeName: "پښتو" },
  { code: "pt", name: "Portuguese", nativeName: "Português" },
  { code: "qu", name: "Quechua", nativeName: "Runa Simi, Kichwa" },
  { code: "rm", name: "Romansh", nativeName: "rumantsch grischun" },
  { code: "rn", name: "Kirundi", nativeName: "kiRundi" },
  { code: "ro", name: "Romanian, Moldavian, Moldovan", nativeName: "română" },
  { code: "ru", name: "Russian", nativeName: "русский язык" },
  { code: "sa", name: "Sanskrit (Saṁskṛta)", nativeName: "संस्कृतम्" },
  { code: "sc", name: "Sardinian", nativeName: "sardu" },
  { code: "sd", name: "Sindhi", nativeName: "सिन्धी, سنڌي، سندھی‎" },
  { code: "se", name: "Northern Sami", nativeName: "Davvisámegiella" },
  { code: "sm", name: "Samoan", nativeName: "gagana faa Samoa" },
  { code: "sg", name: "Sango", nativeName: "yângâ tî sängö" },
  { code: "sr", name: "Serbian", nativeName: "српски језик" },
  { code: "gd", name: "Scottish Gaelic; Gaelic", nativeName: "Gàidhlig" },
  { code: "sn", name: "Shona", nativeName: "chiShona" },
  { code: "si", name: "Sinhala, Sinhalese", nativeName: "සිංහල" },
  { code: "sk", name: "Slovak", nativeName: "slovenčina" },
  { code: "sl", name: "Slovene", nativeName: "slovenščina" },
  { code: "so", name: "Somali", nativeName: "Soomaaliga, af Soomaali" },
  { code: "st", name: "Southern Sotho", nativeName: "Sesotho" },
  { code: "es", name: "Spanish; Castilian", nativeName: "español, castellano" },
  { code: "su", name: "Sundanese", nativeName: "Basa Sunda" },
  { code: "sw", name: "Swahili", nativeName: "Kiswahili" },
  { code: "ss", name: "Swati", nativeName: "SiSwati" },
  { code: "sv", name: "Swedish", nativeName: "svenska" },
  { code: "ta", name: "Tamil", nativeName: "தமிழ்" },
  { code: "te", name: "Telugu", nativeName: "తెలుగు" },
  { code: "tg", name: "Tajik", nativeName: "тоҷикӣ, toğikī, تاجیکی‎" },
  { code: "th", name: "Thai", nativeName: "ไทย" },
  { code: "ti", name: "Tigrinya", nativeName: "ትግርኛ" },
  {
    code: "bo",
    name: "Tibetan Standard, Tibetan, Central",
    nativeName: "བོད་ཡིག",
  },
  { code: "tk", name: "Turkmen", nativeName: "Türkmen, Түркмен" },
  { code: "tl", name: "Tagalog", nativeName: "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔" },
  { code: "tn", name: "Tswana", nativeName: "Setswana" },
  { code: "to", name: "Tonga (Tonga Islands)", nativeName: "faka Tonga" },
  { code: "tr", name: "Turkish", nativeName: "Türkçe" },
  { code: "ts", name: "Tsonga", nativeName: "Xitsonga" },
  { code: "tt", name: "Tatar", nativeName: "татарча, tatarça, تاتارچا‎" },
  { code: "tw", name: "Twi", nativeName: "Twi" },
  { code: "ty", name: "Tahitian", nativeName: "Reo Tahiti" },
  { code: "ug", name: "Uighur, Uyghur", nativeName: "Uyƣurqə, ئۇيغۇرچە‎" },
  { code: "uk", name: "Ukrainian", nativeName: "українська" },
  { code: "ur", name: "Urdu", nativeName: "اردو" },
  { code: "uz", name: "Uzbek", nativeName: "zbek, Ўзбек, أۇزبېك‎" },
  { code: "ve", name: "Venda", nativeName: "Tshivenḓa" },
  { code: "vi", name: "Vietnamese", nativeName: "Tiếng Việt" },
  { code: "vo", name: "Volapük", nativeName: "Volapük" },
  { code: "wa", name: "Walloon", nativeName: "Walon" },
  { code: "cy", name: "Welsh", nativeName: "Cymraeg" },
  { code: "wo", name: "Wolof", nativeName: "Wollof" },
  { code: "fy", name: "Western Frisian", nativeName: "Frysk" },
  { code: "xh", name: "Xhosa", nativeName: "isiXhosa" },
  { code: "yi", name: "Yiddish", nativeName: "ייִדיש" },
  { code: "yo", name: "Yoruba", nativeName: "Yorùbá" },
  { code: "za", name: "Zhuang, Chuang", nativeName: "Saɯ cueŋƅ, Saw cuengh" },
];

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
const countries: readonly CountryType[] = [
  { code: "AD", label: "Andorra", phone: "376" },
  {
    code: "AE",
    label: "United Arab Emirates",
    phone: "971",
  },
  { code: "AF", label: "Afghanistan", phone: "93" },
  {
    code: "AG",
    label: "Antigua and Barbuda",
    phone: "1-268",
  },
  { code: "AI", label: "Anguilla", phone: "1-264" },
  { code: "AL", label: "Albania", phone: "355" },
  { code: "AM", label: "Armenia", phone: "374" },
  { code: "AO", label: "Angola", phone: "244" },
  { code: "AQ", label: "Antarctica", phone: "672" },
  { code: "AR", label: "Argentina", phone: "54" },
  { code: "AS", label: "American Samoa", phone: "1-684" },
  { code: "AT", label: "Austria", phone: "43" },
  {
    code: "AU",
    label: "Australia",
    phone: "61",
    suggested: true,
  },
  { code: "AW", label: "Aruba", phone: "297" },
  { code: "AX", label: "Alland Islands", phone: "358" },
  { code: "AZ", label: "Azerbaijan", phone: "994" },
  {
    code: "BA",
    label: "Bosnia and Herzegovina",
    phone: "387",
  },
  { code: "BB", label: "Barbados", phone: "1-246" },
  { code: "BD", label: "Bangladesh", phone: "880" },
  { code: "BE", label: "Belgium", phone: "32" },
  { code: "BF", label: "Burkina Faso", phone: "226" },
  { code: "BG", label: "Bulgaria", phone: "359" },
  { code: "BH", label: "Bahrain", phone: "973" },
  { code: "BI", label: "Burundi", phone: "257" },
  { code: "BJ", label: "Benin", phone: "229" },
  { code: "BL", label: "Saint Barthelemy", phone: "590" },
  { code: "BM", label: "Bermuda", phone: "1-441" },
  { code: "BN", label: "Brunei Darussalam", phone: "673" },
  { code: "BO", label: "Bolivia", phone: "591" },
  { code: "BR", label: "Brazil", phone: "55" },
  { code: "BS", label: "Bahamas", phone: "1-242" },
  { code: "BT", label: "Bhutan", phone: "975" },
  { code: "BV", label: "Bouvet Island", phone: "47" },
  { code: "BW", label: "Botswana", phone: "267" },
  { code: "BY", label: "Belarus", phone: "375" },
  { code: "BZ", label: "Belize", phone: "501" },
  {
    code: "CA",
    label: "Canada",
    phone: "1",
    suggested: true,
  },
  {
    code: "CC",
    label: "Cocos (Keeling) Islands",
    phone: "61",
  },
  {
    code: "CD",
    label: "Congo, Democratic Republic of the",
    phone: "243",
  },
  {
    code: "CF",
    label: "Central African Republic",
    phone: "236",
  },
  {
    code: "CG",
    label: "Congo, Republic of the",
    phone: "242",
  },
  { code: "CH", label: "Switzerland", phone: "41" },
  { code: "CI", label: "Cote d'Ivoire", phone: "225" },
  { code: "CK", label: "Cook Islands", phone: "682" },
  { code: "CL", label: "Chile", phone: "56" },
  { code: "CM", label: "Cameroon", phone: "237" },
  { code: "CN", label: "China", phone: "86" },
  { code: "CO", label: "Colombia", phone: "57" },
  { code: "CR", label: "Costa Rica", phone: "506" },
  { code: "CU", label: "Cuba", phone: "53" },
  { code: "CV", label: "Cape Verde", phone: "238" },
  { code: "CW", label: "Curacao", phone: "599" },
  { code: "CX", label: "Christmas Island", phone: "61" },
  { code: "CY", label: "Cyprus", phone: "357" },
  { code: "CZ", label: "Czech Republic", phone: "420" },
  {
    code: "DE",
    label: "Germany",
    phone: "49",
    suggested: true,
  },
  { code: "DJ", label: "Djibouti", phone: "253" },
  { code: "DK", label: "Denmark", phone: "45" },
  { code: "DM", label: "Dominica", phone: "1-767" },
  {
    code: "DO",
    label: "Dominican Republic",
    phone: "1-809",
  },
  { code: "DZ", label: "Algeria", phone: "213" },
  { code: "EC", label: "Ecuador", phone: "593" },
  { code: "EE", label: "Estonia", phone: "372" },
  { code: "EG", label: "Egypt", phone: "20" },
  { code: "EH", label: "Western Sahara", phone: "212" },
  { code: "ER", label: "Eritrea", phone: "291" },
  { code: "ES", label: "Spain", phone: "34" },
  { code: "ET", label: "Ethiopia", phone: "251" },
  { code: "FI", label: "Finland", phone: "358" },
  { code: "FJ", label: "Fiji", phone: "679" },
  {
    code: "FK",
    label: "Falkland Islands (Malvinas)",
    phone: "500",
  },
  {
    code: "FM",
    label: "Micronesia, Federated States of",
    phone: "691",
  },
  { code: "FO", label: "Faroe Islands", phone: "298" },
  {
    code: "FR",
    label: "France",
    phone: "33",
    suggested: true,
  },
  { code: "GA", label: "Gabon", phone: "241" },
  { code: "GB", label: "United Kingdom", phone: "44" },
  { code: "GD", label: "Grenada", phone: "1-473" },
  { code: "GE", label: "Georgia", phone: "995" },
  { code: "GF", label: "French Guiana", phone: "594" },
  { code: "GG", label: "Guernsey", phone: "44" },
  { code: "GH", label: "Ghana", phone: "233" },
  { code: "GI", label: "Gibraltar", phone: "350" },
  { code: "GL", label: "Greenland", phone: "299" },
  { code: "GM", label: "Gambia", phone: "220" },
  { code: "GN", label: "Guinea", phone: "224" },
  { code: "GP", label: "Guadeloupe", phone: "590" },
  { code: "GQ", label: "Equatorial Guinea", phone: "240" },
  { code: "GR", label: "Greece", phone: "30" },
  {
    code: "GS",
    label: "South Georgia and the South Sandwich Islands",
    phone: "500",
  },
  { code: "GT", label: "Guatemala", phone: "502" },
  { code: "GU", label: "Guam", phone: "1-671" },
  { code: "GW", label: "Guinea-Bissau", phone: "245" },
  { code: "GY", label: "Guyana", phone: "592" },
  { code: "HK", label: "Hong Kong", phone: "852" },
  {
    code: "HM",
    label: "Heard Island and McDonald Islands",
    phone: "672",
  },
  { code: "HN", label: "Honduras", phone: "504" },
  { code: "HR", label: "Croatia", phone: "385" },
  { code: "HT", label: "Haiti", phone: "509" },
  { code: "HU", label: "Hungary", phone: "36" },
  { code: "ID", label: "Indonesia", phone: "62" },
  { code: "IE", label: "Ireland", phone: "353" },
  { code: "IL", label: "Israel", phone: "972" },
  { code: "IM", label: "Isle of Man", phone: "44" },
  { code: "IN", label: "India", phone: "91" },
  {
    code: "IO",
    label: "British Indian Ocean Territory",
    phone: "246",
  },
  { code: "IQ", label: "Iraq", phone: "964" },
  {
    code: "IR",
    label: "Iran, Islamic Republic of",
    phone: "98",
  },
  { code: "IS", label: "Iceland", phone: "354" },
  { code: "IT", label: "Italy", phone: "39" },
  { code: "JE", label: "Jersey", phone: "44" },
  { code: "JM", label: "Jamaica", phone: "1-876" },
  { code: "JO", label: "Jordan", phone: "962" },
  {
    code: "JP",
    label: "Japan",
    phone: "81",
    suggested: true,
  },
  { code: "KE", label: "Kenya", phone: "254" },
  { code: "KG", label: "Kyrgyzstan", phone: "996" },
  { code: "KH", label: "Cambodia", phone: "855" },
  { code: "KI", label: "Kiribati", phone: "686" },
  { code: "KM", label: "Comoros", phone: "269" },
  {
    code: "KN",
    label: "Saint Kitts and Nevis",
    phone: "1-869",
  },
  {
    code: "KP",
    label: "Korea, Democratic People's Republic of",
    phone: "850",
  },
  { code: "KR", label: "Korea, Republic of", phone: "82" },
  { code: "KW", label: "Kuwait", phone: "965" },
  { code: "KY", label: "Cayman Islands", phone: "1-345" },
  { code: "KZ", label: "Kazakhstan", phone: "7" },
  {
    code: "LA",
    label: "Lao People's Democratic Republic",
    phone: "856",
  },
  { code: "LB", label: "Lebanon", phone: "961" },
  { code: "LC", label: "Saint Lucia", phone: "1-758" },
  { code: "LI", label: "Liechtenstein", phone: "423" },
  { code: "LK", label: "Sri Lanka", phone: "94" },
  { code: "LR", label: "Liberia", phone: "231" },
  { code: "LS", label: "Lesotho", phone: "266" },
  { code: "LT", label: "Lithuania", phone: "370" },
  { code: "LU", label: "Luxembourg", phone: "352" },
  { code: "LV", label: "Latvia", phone: "371" },
  { code: "LY", label: "Libya", phone: "218" },
  { code: "MA", label: "Morocco", phone: "212" },
  { code: "MC", label: "Monaco", phone: "377" },
  {
    code: "MD",
    label: "Moldova, Republic of",
    phone: "373",
  },
  { code: "ME", label: "Montenegro", phone: "382" },
  {
    code: "MF",
    label: "Saint Martin (French part)",
    phone: "590",
  },
  { code: "MG", label: "Madagascar", phone: "261" },
  { code: "MH", label: "Marshall Islands", phone: "692" },
  {
    code: "MK",
    label: "Macedonia, the Former Yugoslav Republic of",
    phone: "389",
  },
  { code: "ML", label: "Mali", phone: "223" },
  { code: "MM", label: "Myanmar", phone: "95" },
  { code: "MN", label: "Mongolia", phone: "976" },
  { code: "MO", label: "Macao", phone: "853" },
  {
    code: "MP",
    label: "Northern Mariana Islands",
    phone: "1-670",
  },
  { code: "MQ", label: "Martinique", phone: "596" },
  { code: "MR", label: "Mauritania", phone: "222" },
  { code: "MS", label: "Montserrat", phone: "1-664" },
  { code: "MT", label: "Malta", phone: "356" },
  { code: "MU", label: "Mauritius", phone: "230" },
  { code: "MV", label: "Maldives", phone: "960" },
  { code: "MW", label: "Malawi", phone: "265" },
  { code: "MX", label: "Mexico", phone: "52" },
  { code: "MY", label: "Malaysia", phone: "60" },
  { code: "MZ", label: "Mozambique", phone: "258" },
  { code: "NA", label: "Namibia", phone: "264" },
  { code: "NC", label: "New Caledonia", phone: "687" },
  { code: "NE", label: "Niger", phone: "227" },
  { code: "NF", label: "Norfolk Island", phone: "672" },
  { code: "NG", label: "Nigeria", phone: "234" },
  { code: "NI", label: "Nicaragua", phone: "505" },
  { code: "NL", label: "Netherlands", phone: "31" },
  { code: "NO", label: "Norway", phone: "47" },
  { code: "NP", label: "Nepal", phone: "977" },
  { code: "NR", label: "Nauru", phone: "674" },
  { code: "NU", label: "Niue", phone: "683" },
  { code: "NZ", label: "New Zealand", phone: "64" },
  { code: "OM", label: "Oman", phone: "968" },
  { code: "PA", label: "Panama", phone: "507" },
  { code: "PE", label: "Peru", phone: "51" },
  { code: "PF", label: "French Polynesia", phone: "689" },
  { code: "PG", label: "Papua New Guinea", phone: "675" },
  { code: "PH", label: "Philippines", phone: "63" },
  { code: "PK", label: "Pakistan", phone: "92" },
  { code: "PL", label: "Poland", phone: "48" },
  {
    code: "PM",
    label: "Saint Pierre and Miquelon",
    phone: "508",
  },
  { code: "PN", label: "Pitcairn", phone: "870" },
  { code: "PR", label: "Puerto Rico", phone: "1" },
  {
    code: "PS",
    label: "Palestine, State of",
    phone: "970",
  },
  { code: "PT", label: "Portugal", phone: "351" },
  { code: "PW", label: "Palau", phone: "680" },
  { code: "PY", label: "Paraguay", phone: "595" },
  { code: "QA", label: "Qatar", phone: "974" },
  { code: "RE", label: "Reunion", phone: "262" },
  { code: "RO", label: "Romania", phone: "40" },
  { code: "RS", label: "Serbia", phone: "381" },
  { code: "RU", label: "Russian Federation", phone: "7" },
  { code: "RW", label: "Rwanda", phone: "250" },
  { code: "SA", label: "Saudi Arabia", phone: "966" },
  { code: "SB", label: "Solomon Islands", phone: "677" },
  { code: "SC", label: "Seychelles", phone: "248" },
  { code: "SD", label: "Sudan", phone: "249" },
  { code: "SE", label: "Sweden", phone: "46" },
  { code: "SG", label: "Singapore", phone: "65" },
  { code: "SH", label: "Saint Helena", phone: "290" },
  { code: "SI", label: "Slovenia", phone: "386" },
  {
    code: "SJ",
    label: "Svalbard and Jan Mayen",
    phone: "47",
  },
  { code: "SK", label: "Slovakia", phone: "421" },
  { code: "SL", label: "Sierra Leone", phone: "232" },
  { code: "SM", label: "San Marino", phone: "378" },
  { code: "SN", label: "Senegal", phone: "221" },
  { code: "SO", label: "Somalia", phone: "252" },
  { code: "SR", label: "Suriname", phone: "597" },
  { code: "SS", label: "South Sudan", phone: "211" },
  {
    code: "ST",
    label: "Sao Tome and Principe",
    phone: "239",
  },
  { code: "SV", label: "El Salvador", phone: "503" },
  {
    code: "SX",
    label: "Sint Maarten (Dutch part)",
    phone: "1-721",
  },
  {
    code: "SY",
    label: "Syrian Arab Republic",
    phone: "963",
  },
  { code: "SZ", label: "Swaziland", phone: "268" },
  {
    code: "TC",
    label: "Turks and Caicos Islands",
    phone: "1-649",
  },
  { code: "TD", label: "Chad", phone: "235" },
  {
    code: "TF",
    label: "French Southern Territories",
    phone: "262",
  },
  { code: "TG", label: "Togo", phone: "228" },
  { code: "TH", label: "Thailand", phone: "66" },
  { code: "TJ", label: "Tajikistan", phone: "992" },
  { code: "TK", label: "Tokelau", phone: "690" },
  { code: "TL", label: "Timor-Leste", phone: "670" },
  { code: "TM", label: "Turkmenistan", phone: "993" },
  { code: "TN", label: "Tunisia", phone: "216" },
  { code: "TO", label: "Tonga", phone: "676" },
  { code: "TR", label: "Turkey", phone: "90" },
  {
    code: "TT",
    label: "Trinidad and Tobago",
    phone: "1-868",
  },
  { code: "TV", label: "Tuvalu", phone: "688" },
  {
    code: "TW",
    label: "Taiwan, Province of China",
    phone: "886",
  },
  {
    code: "TZ",
    label: "United Republic of Tanzania",
    phone: "255",
  },
  { code: "UA", label: "Ukraine", phone: "380" },
  { code: "UG", label: "Uganda", phone: "256" },
  {
    code: "US",
    label: "United States",
    phone: "1",
    suggested: true,
  },
  { code: "UY", label: "Uruguay", phone: "598" },
  { code: "UZ", label: "Uzbekistan", phone: "998" },
  {
    code: "VA",
    label: "Holy See (Vatican City State)",
    phone: "379",
  },
  {
    code: "VC",
    label: "Saint Vincent and the Grenadines",
    phone: "1-784",
  },
  { code: "VE", label: "Venezuela", phone: "58" },
  {
    code: "VG",
    label: "British Virgin Islands",
    phone: "1-284",
  },
  {
    code: "VI",
    label: "US Virgin Islands",
    phone: "1-340",
  },
  { code: "VN", label: "Vietnam", phone: "84" },
  { code: "VU", label: "Vanuatu", phone: "678" },
  { code: "WF", label: "Wallis and Futuna", phone: "681" },
  { code: "WS", label: "Samoa", phone: "685" },
  { code: "XK", label: "Kosovo", phone: "383" },
  { code: "YE", label: "Yemen", phone: "967" },
  { code: "YT", label: "Mayotte", phone: "262" },
  { code: "ZA", label: "South Africa", phone: "27" },
  { code: "ZM", label: "Zambia", phone: "260" },
  { code: "ZW", label: "Zimbabwe", phone: "263" },
];

const PlatformProfile = () => {
  const [errorMessage, setErrorMessage] = React.useState("");
  const [errorMessageName, setErrorMessageName] = React.useState("");
  const [errorMessageLastName, setErrorMessageLastName] = React.useState("");
  const [Expert, setExpert] = useState(false);
  const [UserName, setUserName] = useState("");
  const [WorkingDays, setWorkingDays] = useState("");
  const [WorkingDaysWeekend, setWorkingDaysWeekend] = useState("");
  const [FirstName, setFirstName] = useState("");
  const [UnavailableStart, setUnavailableStart] = useState("");
  const [UnavailableEnd, setUnavailableEnd] = useState("");
  const [Unavailability, setUnavailability] = useState<any[]>([]);
  const [Email, setEmail] = useState("");
  const [LastName, setLastName] = useState("");
  const [description, setDescription] = useState("");
  const [MiddleName, setMiddleName] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [newSkillName, setNewSkillName] = useState("");
  const [newLanguageName, setNewLanguageName] = useState("");
  const [newLanguageLevel, setNewLanguageLevel] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [newSkillLevel, setNewSkillLevel] = useState("");
  const [frameworkAgreement, setFrameworkAgreement] = useState<any>();
  const [selectedCountry, setSelectedCountry] = useState<CountryType>();
  const [VatNumber, setVatNumber] = useState("");
  const [Level, setLevel] = useState("");
  const [imageSel, setImageSel] = useState<any[]>([]);
  const [iban, setIban] = useState("");
  const [skills, setSkills] = useState<string[]>([]);
  const [devSkills, setDevSkills] = useState<any[]>([]);
  const [devLanguages, setDevLanguages] = useState<any[]>([]);
  const [country, setCountry] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [user, setUser] = useState<any>({});
  const dispatch = useDispatch();

  const alert = useAlert();
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth);
  useEffect(() => {
    dispatch(
      locationSlice.actions.setLocation({
        path: "/platform/profile",
      })
    );
    if (!auth.account) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (UserName.length >= 25) {
      setErrorMessage("The username can not exceed 25 characters");
    }
  }, [UserName]);

  useEffect(() => {
    if (FirstName.length < 20 && errorMessageName) {
      setErrorMessageName("");
    }
  }, [FirstName, errorMessageName]);

  useEffect(() => {
    if (FirstName.length >= 20) {
      setErrorMessageName("The name can not exceed 25 characters");
    }
  }, [FirstName]);

  useEffect(() => {
    if (UserName.length < 25 && errorMessage) {
      setErrorMessage("");
    }
  }, [UserName, errorMessage]);

  useEffect(() => {
    if (LastName.length < 20 && errorMessageLastName) {
      setErrorMessageLastName("");
    }
  }, [LastName, errorMessageLastName]);

  useEffect(() => {
    if (LastName.length >= 20) {
      setErrorMessageLastName("The name can not exceed 25 characters");
    }
  }, [LastName]);
  useEffect(() => {
    getSkills();
    getUserInfo();
    setUserName("");
    setWorkingDays("");
    setWorkingDaysWeekend("");
    setFirstName("");
    setEmail("");
    setLastName("");
    setDescription("");
    setStartTime("");
    setEndTime("");
    setMiddleName("");
    setPhoneNumber("");
    setVatNumber("");
    setSkills([]);
    setLevel("");
    setIban("");
    setCountry("");
    setUnavailableStart("");
    setUnavailableEnd("");
  }, []);

  const getUserInfo = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_user_info`)
      .then((res) => {
        setUser(res.data);
        setUserName(res.data.username);
        setStartTime(res.data.start_time);
        setEndTime(res.data.end_time);
        setFirstName(res.data.first_name);
        setLastName(res.data.last_name);
        setEmail(res.data.email);
        setVatNumber(res.data.vat);
        setDescription(res.data.description);
        setPhoneCode(res.data.phone_code);
        setExpert(res.data.expert);
        setPhoneNumber(res.data.phone_number);
        setFrameworkAgreement(res.data.framework_agreement_dev);
        setIban(res.data.iban);
        if (res.data.expert !== auth.expert) {
          dispatch(authSlice.actions.setExpert(res.data.expert));
        }
        if (res.data.country !== null) {
          setCountry(res.data.country);
          countries.forEach((c: any) => {
            if (c.label === res.data.country) {
              setSelectedCountry(c);
            }
          });
        } else {
          setCountry("");
        }
        if (res.data.image) {
          setImageSel([
            {
              data_url:
                process.env.REACT_APP_API_URL?.split("/api")[0] +
                res.data.image,
            },
          ]);
        }
        setDevSkills(res.data.dev_skills);
        setDevLanguages(res.data.languages);

        setWorkingDays(res.data.capa_hours_per_week);
        setWorkingDaysWeekend(res.data.days_weekend);
        let tmp_un: any[] = [];

        res.data.unavailable_dates.forEach((date: any) => {
          let tmp = { from_date: date.from_date, to_date: date.to_date };
          tmp_un.push(tmp);
        });
        setUnavailability(tmp_un);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  // const reset = () => {
  //   setUnavailableStart("");
  // }

  const addUserInfo = () => {
    const form = new FormData();

    if (
      imageSel.length >= 1 &&
      !imageSel[imageSel.length - 1]["data_url"].includes(
        "/media/profile_pictures"
      )
    ) {
      form.append("image", imageSel[imageSel.length - 1]["file"]);
    }
    let tmpUnavail: any[] = [];
    Unavailability.forEach((un: any) => {
      let tmp = {
        from_date: moment(un.from_date).format("YYYY-MM-DD").toString(),
        to_date: moment(un.to_date).format("YYYY-MM-DD").toString(),
      };
      tmpUnavail.push(tmp);
    });
    form.append("first_name", FirstName);
    form.append("last_name", LastName);
    form.append("username", UserName);
    form.append("country", country);
    form.append("iban", iban);
    form.append("vat", VatNumber);
    form.append("expert", Expert.toString());
    form.append("description", description);
    form.append("dev_skills", JSON.stringify(devSkills));
    form.append("languages", JSON.stringify(devLanguages));
    form.append("phone", PhoneNumber);
    form.append("phone_code", phoneCode);
    form.append("middle_name", MiddleName);
    form.append("email", Email);
    if (auth.selectedView === "DEVELOPER") {
      form.append("start_time", startTime);
      form.append("end_time", endTime);
      form.append("capa_hours_per_week", WorkingDays);
      form.append("days_weekend", WorkingDaysWeekend);
      form.append("currently_available", "true");
      form.append("unavailable_dates", JSON.stringify(tmpUnavail));
    }
    form.append("user", auth.account ? auth.account.toString() : "");

    axiosService
      .post(`${process.env.REACT_APP_API_URL}/edit_user_info`, form)
      .then(() => {
        alert.success("The User Information were updated");
        getUserInfo();
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }
        console.log(err);
      });
  };

  const getSkills = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_skills`)
      .then((res) => {
        let tmpSkills: any[] = [];
        res.data.forEach((data: any) => {
          tmpSkills.push(data.name);
        });
        setSkills(tmpSkills);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  const onChange = (imageList: any) => {
    setImageSel(imageList);
  };

  const handleAddSkill = () => {
    setDevSkills((current) => [
      ...current,
      { skill: newSkillName, level: newSkillLevel },
    ]);
    setNewSkillLevel("");
    setNewSkillName("");
  };

  const handleAddLanguage = () => {
    setDevLanguages((current) => [
      ...current,
      { language: newLanguageName, level: newLanguageLevel },
    ]);
    setNewLanguageLevel("");
    setNewLanguageName("");
  };

  const handleAddUnavailability = () => {
    setUnavailability((current) => [
      ...current,
      { from_date: UnavailableStart, to_date: UnavailableEnd },
    ]);
    setUnavailableStart("");
    setUnavailableEnd("");
  };

  return (
    <div className="column-start-no-width full-percent-width container">
      {imageSel && (
        <div className="profile-card">
          <ImageUploading
            value={imageSel}
            onChange={onChange}
            maxNumber={10}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,

              isDragging,
              dragProps,
            }) => (
              // write your building UI
              <div className="column-start-no-width ">
                <div className="image-item">
                  {imageList.length > 0 ? (
                    <img
                      className="profile-page-pic"
                      src={imageList[imageList.length - 1]["data_url"]}
                      alt=""
                      width="100"
                    />
                  ) : (
                    <div className="profile-page-pic no-pic">
                      <span>{UserName.charAt(0)}</span>
                    </div>
                  )}
                  {/* <div className="image-item__btn-wrapper">

                    <button onClick={() => onImageUpdate(index)}>Update</button>
                    <button onClick={() => onImageRemove(index)}>Remove</button>
                  </div> */}
                </div>

                <button
                  style={isDragging ? { color: "red" } : undefined}
                  {...dragProps}
                >
                  <div className="column-flex-start">
                    <span className="heading-lora text-name">
                      {FirstName + " " + LastName}
                    </span>
                    <span
                      onClick={onImageUpload}
                      className="text-quicksand blue text-change-picture margin-r20"
                    >
                      Change Profile Photo
                    </span>
                    {frameworkAgreement !== "" && (
                      <a
                        target="_blank"
                        href={
                          process.env.REACT_APP_API_URL?.split("/api")[0] +
                          frameworkAgreement
                        }
                        download
                      >
                        <span className="selected-view pointer orange text-change-picture margin-r20">
                          View Framework Agreement
                        </span>
                      </a>
                    )}
                  </div>
                </button>
              </div>
            )}
          </ImageUploading>

          <div className="profile-column margin-t40">
            <div className="profile-row">
              <Box className="profile-box-height">
                <FormControl fullWidth>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label="User name"
                    error={UserName.length >= 25}
                    inputProps={{ maxLength: 25 }}
                    helperText={errorMessage}
                    id="outlined-basic"
                    type="text"
                    value={UserName}
                    onChange={(event) => setUserName(event.target.value)}
                  />
                </FormControl>
              </Box>

              <Box
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                className="profile-box-height"
              >
                <FormControl fullWidth>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label="First name"
                    id="outlined-basic-first-name"
                    error={FirstName.length >= 20}
                    inputProps={{ maxLength: 20 }}
                    helperText={errorMessageName}
                    type="text"
                    value={FirstName}
                    onChange={(event) => setFirstName(event.target.value)}
                  />
                </FormControl>
              </Box>

              <Box className="profile-box-height">
                <FormControl fullWidth>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label="Last name"
                    error={LastName.length >= 20}
                    inputProps={{ maxLength: 20 }}
                    helperText={errorMessageLastName}
                    id="outlined-basic"
                    type="text"
                    value={LastName}
                    onChange={(event) => setLastName(event.target.value)}
                  />
                </FormControl>
              </Box>
            </div>

            <div className="profile-row">
              <Box className="profile-box-height">
                <FormControl fullWidth>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label="E-mail"
                    id="outlined-basic-email"
                    type="text"
                    value={Email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </FormControl>
              </Box>

              <Autocomplete
                className="profile-box-height"
                id="country-select-demo"
                options={countries}
                getOptionLabel={(option) => option.label}
                value={selectedCountry || null}
                inputValue={country}
                onInputChange={(event: any, newInputValue) => {
                  setCountry(newInputValue);
                  if (newInputValue == "") {
                    setSelectedCountry({ code: "", label: "", phone: "" });
                  } else {
                    countries.forEach((c: any) => {
                      if (c.label === newInputValue) {
                        setSelectedCountry(c);
                        setPhoneCode("+" + c.phone);
                      }
                    });
                  }
                }}
                renderOption={(propsComp, option) => (
                  <Box
                    className="profile-box-height"
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...propsComp}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    <div onClick={() => setPhoneCode("+" + option.phone)}>
                      {" "}
                      {option.label} ({option.code}) +{option.phone}
                    </div>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose a country"
                    value={selectedCountry}
                    inputProps={{
                      ...params.inputProps,
                      shrink: true,
                      defaultValue: country,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />

              <Box className="profile-box-height">
                <FormControl fullWidth>
                  <TextField
                    type="text"
                    InputLabelProps={{ shrink: true }}
                    id="demo-simple-select-label-duration"
                    label="Phone number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {phoneCode}
                        </InputAdornment>
                      ),
                    }}
                    value={PhoneNumber}
                    onChange={(event) => setPhoneNumber(event.target.value)}
                  />
                </FormControl>
              </Box>
            </div>

            {/* <div className="profile-row">
             */}
            {/* <Box className="profile-box-height">
                  <FormControl fullWidth>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      label="Middle name (optional)"
                      id="outlined-basic"
                      type="text"
                      value={MiddleName}
                      onChange={(event) => setMiddleName(event.target.value)}
                    />
                  </FormControl>
                </Box> */}
            {/* <Box className="profile-box-height">
                  <FormControl fullWidth>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      label="VAT number"
                      id="outlined-basic"
                      type="text"
                      value={VatNumber}
                      onChange={(event) => setVatNumber(event.target.value)}
                    />
                  </FormControl>
                </Box> */}
            {/* <Box className="profile-box-height">
                  <FormControl fullWidth>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      label="IBAN"
                      id="outlined-basic"
                      type="text"
                      value={iban}
                      onChange={(event) => setIban(event.target.value)}
                    />
                  </FormControl>
                </Box> */}
            {/* 
              </div> */}
          </div>
          <div className="flex-start-center">
            <Tooltip
              title="If the expert mode is turned on, you will no longer see the hints "
              placement="bottom"
              arrow
            >
              <span className="text-quicksand-small">Expert mode</span>
            </Tooltip>
            <Switch
              checked={Expert}
              onChange={() => setExpert(!Expert)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </div>
        </div>
      )}
      {auth.selectedView === "DEVELOPER" && (
        <div className="profile-add-skills">
          <span className="heading-lora">Add skills</span>
          {devSkills.map((skill: any) => {
            return (
              <div key={skill.id} className="row-skills">
                <Box className="profile-skills-width">
                  <FormControl fullWidth>
                    <InputLabel shrink={true} id="demo-simple-select-label">
                      Skill
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={skill.skill}
                      input={
                        <OutlinedInput
                          id="select-multiple-chip"
                          label="Skill"
                          notched
                        />
                      }
                      label="SelectedProject"
                      onChange={(event) => {
                        setDevSkills(
                          devSkills.filter((devSkill: any) => {
                            if (devSkill === skill) {
                              devSkill.skill = event.target.value;
                            }
                            return devSkill;
                          })
                        );
                      }}
                    >
                      {skills.map((skillName: string) => {
                        return (
                          <MenuItem key={skillName} value={skillName}>
                            {skillName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>

                <Box className="profile-skills-width">
                  <FormControl fullWidth>
                    <InputLabel shrink={true} id="demo-simple-select-label">
                      Level
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={skill.level}
                      input={
                        <OutlinedInput
                          id="select-multiple-chip"
                          label="Level"
                          notched
                        />
                      }
                      label="SelectedProject"
                      onChange={(event) => {
                        setDevSkills(
                          devSkills.filter((devSkill: any) => {
                            if (devSkill === skill) {
                              devSkill.level = event.target.value;
                            }
                            return devSkill;
                          })
                        );
                      }}
                    >
                      <MenuItem value={"JUNIOR"}>Junior</MenuItem>
                      <MenuItem value={"MID"}>Mid</MenuItem>
                      <MenuItem value={"SENIOR"}>Senior</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                <Tooltip title="Delete" placement="right" arrow>
                  <DeleteIcon
                    sx={{ fontSize: 28 }}
                    className="trashbin"
                    onClick={() =>
                      setDevSkills(
                        devSkills.filter((devSkill: any) => {
                          return devSkill !== skill;
                        })
                      )
                    }
                  ></DeleteIcon>
                </Tooltip>
              </div>
            );
          })}
          <div className="row-skills">
            <Box className="profile-skills-width">
              <FormControl fullWidth>
                <InputLabel shrink={true} id="demo-simple-select-label">
                  New Skill
                </InputLabel>
                <Select
                  input={
                    <OutlinedInput
                      id="select-multiple-chip"
                      label="New Skill"
                      notched
                    />
                  }
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={newSkillName}
                  onChange={(event) => setNewSkillName(event.target.value)}
                >
                  {skills.map((skillName: string) => {
                    return (
                      <MenuItem key={skillName} value={skillName}>
                        {skillName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>

            <Box className="profile-skills-width">
              <FormControl fullWidth>
                <InputLabel shrink={true} id="demo-simple-select-label">
                  Level
                </InputLabel>
                <Select
                  input={
                    <OutlinedInput
                      id="select-multiple-chip"
                      label="Level"
                      notched
                    />
                  }
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={newSkillLevel}
                  label="SelectedProject"
                  onChange={(event) => setNewSkillLevel(event.target.value)}
                >
                  <MenuItem value={"JUNIOR"}>Junior</MenuItem>
                  <MenuItem value={"MID"}>Mid</MenuItem>
                  <MenuItem value={"SENIOR"}>Senior</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Tooltip title="Delete" placement="right" arrow>
              <DeleteIcon
                sx={{ fontSize: 28 }}
                className="trashbin"
                onClick={() => {
                  setNewSkillLevel("");
                  setNewSkillName("");
                }}
              ></DeleteIcon>
            </Tooltip>
          </div>
          <div className="add-skills-div" onClick={handleAddSkill}>
            <AddIcon className="add blue" sx={{ fontSize: 22 }} />
            <span className="text-quicksand-small blue">Add skills</span>
          </div>
        </div>
      )}

      <div className="profile-add-skills">
        <span className="heading-lora">Add Languages</span>
        {devLanguages.map((language: any) => {
          return (
            <div key={language.id} className="row-skills">
              <Box className="profile-skills-width padding-up-down">
                <FormControl fullWidth>
                  <InputLabel shrink={true} id="demo-simple-select-label">
                    Language
                  </InputLabel>

                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={language.language}
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label="Language"
                        notched
                      />
                    }
                    label="SelectedProject"
                    onChange={(event) => {
                      setDevLanguages(
                        devLanguages.filter((devLanguage: any) => {
                          if (devLanguage === language) {
                            devLanguage.language = event.target.value;
                          }
                          return devLanguage;
                        })
                      );
                    }}
                  >
                    {languages.map((language: any) => {
                      return (
                        <MenuItem key={language.code} value={language.name}>
                          {language.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>

              <Box className="profile-skills-width padding-up-down">
                <FormControl fullWidth>
                  <InputLabel shrink={true} id="demo-simple-select-label">
                    Level
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={language.level}
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label="Level"
                        notched
                      />
                    }
                    label="SelectedProject"
                    onChange={(event) => {
                      setDevLanguages(
                        devLanguages.filter((devLanguage: any) => {
                          if (devLanguage === language) {
                            devLanguage.level = event.target.value;
                          }
                          return devLanguage;
                        })
                      );
                    }}
                  >
                    <MenuItem value={"NOVICE"}>Novice</MenuItem>
                    <MenuItem value={"INTERMEDIATE"}>Intermediate</MenuItem>
                    <MenuItem value={"ADVANCED"}>Advanced</MenuItem>
                    <MenuItem value={"FLUENT"}>Fluent</MenuItem>
                    <MenuItem value={"NATIVE"}>Native</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Tooltip title="Delete" placement="right" arrow>
                <DeleteIcon
                  sx={{ fontSize: 28 }}
                  className="trashbin"
                  onClick={() =>
                    setDevLanguages(
                      devLanguages.filter((devLanguage: any) => {
                        return devLanguage !== language;
                      })
                    )
                  }
                ></DeleteIcon>
              </Tooltip>
            </div>
          );
        })}
        <div className="row-skills">
          <Box className="profile-skills-width padding-up-down">
            <FormControl fullWidth>
              <InputLabel shrink={true} id="demo-simple-select-label">
                New Language
              </InputLabel>
              <Select
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                    label="New Language"
                    notched
                  />
                }
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={newLanguageName}
                onChange={(event) => setNewLanguageName(event.target.value)}
              >
                {languages.map((language: any) => {
                  return (
                    <MenuItem key={language.code} value={language.name}>
                      {language.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>

          <Box className="profile-skills-width padding-up-down">
            <FormControl fullWidth>
              <InputLabel shrink={true} id="demo-simple-select-label">
                Level
              </InputLabel>
              <Select
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                    label="Level"
                    notched
                  />
                }
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={newLanguageLevel}
                label="SelectedProject"
                onChange={(event) => setNewLanguageLevel(event.target.value)}
              >
                <MenuItem value={"NOVICE"}>Novice</MenuItem>
                <MenuItem value={"INTERMEDIATE"}>Intermediate</MenuItem>
                <MenuItem value={"ADVANCED"}>Advanced</MenuItem>
                <MenuItem value={"FLUENT"}>Fluent</MenuItem>
                <MenuItem value={"NATIVE"}>Native</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Tooltip title="Delete" placement="right" arrow>
            <DeleteIcon
              sx={{ fontSize: 28 }}
              className="trashbin"
              onClick={() => {
                setNewLanguageLevel("");
                setNewLanguageName("");
              }}
            ></DeleteIcon>
          </Tooltip>
        </div>
        <div className="add-skills-div" onClick={handleAddLanguage}>
          <AddIcon className="add blue" sx={{ fontSize: 22 }} />
          <span className="text-quicksand-small blue">Add language</span>
        </div>
      </div>

      {auth.selectedView === "DEVELOPER" && (
        <div className="profile-add-skills">
          <span className="heading-lora padding-up-down">
            Tell us about yourself
          </span>

          <div className="row-space-between-center padding-up-down">
            <Box sx={{ minWidth: "100%" }}>
              <FormControl fullWidth>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="outlined-basic"
                  label="Description"
                  multiline
                  minRows={3}
                  maxRows={5}
                  value={description || ""}
                  onChange={(event) => setDescription(event.target.value)}
                />
              </FormControl>
            </Box>
          </div>
        </div>
      )}
      {auth.selectedView === "DEVELOPER" && (
        <div className="profile-add-skills">
          <span className="heading-lora padding-up-down">
            Add availability and your working capacity
          </span>

          <div className="row-space-between-center padding-up-down padding-b20">
            <Box className="profile-availability-width">
              <FormControl fullWidth>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="Working hours per week"
                  id="outlined-basic"
                  type="text"
                  value={WorkingDays}
                  onChange={(event) => setWorkingDays(event.target.value)}
                />
              </FormControl>
            </Box>

            {/* <Box className="profile-min-width-margin">
              <FormControl fullWidth>
                <InputLabel shrink={true} id="demo-simple-select-label">
                  Number of working days on weekends (Saturday till Sunday)
                </InputLabel>
                <Select
                  input={
                    <OutlinedInput
                      id="select-multiple-chip"
                      label="Number of working days on weekends (Saturday till Sunday)"
                      notched
                    />
                  }
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={WorkingDaysWeekend}
                  label="SelectedProject"
                  onChange={(event) =>
                    setWorkingDaysWeekend(event.target.value)
                  }
                >
                  <MenuItem value={0}>none</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                </Select>
              </FormControl>
            </Box> */}
          </div>
          <div className="row-space-between-center padding-up-down padding-b20">
            <Box className="profile-availability-width">
              <FormControl fullWidth>
                <TextField
                  id="time-start"
                  label="Start of working day (CET)"
                  type="time"
                  value={startTime}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(event: any) => {
                    setStartTime(event.target.value);
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  className="full-percent-width"
                />
              </FormControl>
            </Box>

            <Box className="profile-availability-width margin-r132">
              <FormControl fullWidth>
                <TextField
                  id="time-start"
                  label="End of working day (CET)"
                  type="time"
                  value={endTime}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(event: any) => {
                    setEndTime(event.target.value);
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
              </FormControl>
            </Box>
          </div>
          {Unavailability.map((unavailable: any) => {
            return (
              <div
                key={unavailable.from_date}
                className="availability-row padding-up-down"
              >
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  adapterLocale="en-gb"
                >
                  <Box className="profile-availability-width">
                    <FormControl fullWidth>
                      <DatePicker
                        inputFormat="DD/MM/YYYY"
                        value={unavailable.from_date || null}
                        onChange={(newValue) => {
                          setUnavailability(
                            Unavailability.filter((date: any) => {
                              if (date === unavailable) {
                                date.from_date = newValue?.toSTring();
                              }
                              return date;
                            })
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{ shrink: true }}
                            label="Unavailable From"
                          />
                        )}
                      />
                    </FormControl>
                  </Box>
                  <Box className="profile-availability-width">
                    <FormControl fullWidth>
                      <DatePicker
                        inputFormat="DD/MM/YYYY"
                        value={unavailable.to_date || null}
                        minDate={moment(unavailable.from_date)}
                        onChange={(newValue) => {
                          setUnavailability(
                            Unavailability.filter((date: any) => {
                              if (date === unavailable) {
                                date.to_date = newValue?.toString();
                              }
                              return date;
                            })
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{ shrink: true }}
                            label="Unavailable To"
                          />
                        )}
                      />
                    </FormControl>
                  </Box>
                </LocalizationProvider>

                <Tooltip title="Delete" placement="right" arrow>
                  <DeleteIcon
                    sx={{ fontSize: 28 }}
                    className="trashbin"
                    onClick={() => {
                      setUnavailability(
                        Unavailability.filter((date: any) => {
                          return date !== unavailable;
                        })
                      );
                    }}
                  ></DeleteIcon>
                </Tooltip>
              </div>
            );
          })}
          <div className="availability-row padding-up-down">
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale="en-gb"
            >
              <Box className="profile-availability-width">
                <FormControl fullWidth>
                  <DatePicker
                    inputFormat="DD/MM/YYYY"
                    value={UnavailableStart || null}
                    onChange={(newValue) => {
                      if (newValue) {
                        setUnavailableStart(newValue?.toString());
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        label="New Unavailable from"
                      />
                    )}
                  />
                </FormControl>
              </Box>
              <Box className="profile-availability-width">
                <FormControl fullWidth>
                  <DatePicker
                    inputFormat="DD/MM/YYYY"
                    value={UnavailableEnd || null}
                    minDate={moment(UnavailableStart)}
                    onChange={(newValue) => {
                      if (newValue) {
                        setUnavailableEnd(newValue?.toString());
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        label="New Unavailable to"
                      />
                    )}
                  />
                </FormControl>
              </Box>
            </LocalizationProvider>

            <Tooltip title="Delete" placement="right" arrow>
              <DeleteIcon
                sx={{ fontSize: 28 }}
                className="trashbin"
                onClick={() => {
                  setUnavailableStart("");
                  setUnavailableEnd("");
                }}
              ></DeleteIcon>
            </Tooltip>
          </div>

          <div
            className="add-skills-div padding-up-down"
            onClick={handleAddUnavailability}
          >
            <AddIcon className="add blue" sx={{ fontSize: 22 }} />
            <span className="text-quicksand-small blue">
              Add unavailability
            </span>
          </div>
        </div>
      )}

      <Button
        className="profile-save-button"
        onClick={addUserInfo}
        disabled={
          errorMessage !== "" &&
          errorMessageLastName !== "" &&
          errorMessageName !== ""
        }
      >
        Save Changes
      </Button>
    </div>
  );
};
export default PlatformProfile;
