import { useEffect, useState } from "react";
import "./AddContract.css";
import PropTypes from "prop-types";
import axiosService from "../../utils/axios";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Button, Input, TextareaAutosize, TextField } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import UndoIcon from "@mui/icons-material/Undo";
import Modal from "@mui/material/Modal";
import { useAlert } from "react-alert";
import UploadIcon from "@mui/icons-material/Upload";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddContract = (props: any) => {
  const [show, setShow] = useState(false);

  const [selectedDev, setSelectedDev] = useState("");
  const [selectedClientManager, setSelectedClientManager] = useState("");
  const [clientUsers, setClientUsers] = useState<Array<string>>([]);
  const [isFirst, setIsFirst] = useState(true);
  const [hoursPerWeek, setHoursPerWeek] = useState<number>(40);
  const [eurosPerHour, setEurosPerHour] = useState<number>(0);
  const [file, setFile] = useState<any>();
  const [fileClient, setFileClient] = useState<any>();
  const [projectDays, setProjectDays] = useState<number>(0);
  const alert = useAlert();

  const closeHandler = (e: any) => {
    setShow(false);
    props.onClose(false);
  };

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        closeHandler(true);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);
  useEffect(() => {
    if (isFirst) {
      setIsFirst(false);
      if (props.job) {
        getClientUsers();
      }
    }
  }, []);

  useEffect(() => {
    if (props.contract) {
      setSelectedDev(props.contract.dev.username);
      setSelectedClientManager(props.contract.client_manager.username);
      setHoursPerWeek(props.contract.hours_per_week);
      setProjectDays(props.contract.project_days);
      setEurosPerHour(props.contract.euros_per_hour);
    }
  }, []);

  const changeStatusLead = (id: number, status: string) => {
    let body = {
      id: id,
      status: status,
    };
    axiosService
      .post(`${process.env.REACT_APP_API_URL}/change_lead_status`, body)
      .then((res) => {})
      .catch((err) => {});
  };

  const getClientUsers = () => {
    axiosService
      .get(
        `${process.env.REACT_APP_API_URL}/get_client_users/` + props.job.client
      )
      .then((res) => {
        setClientUsers(res.data);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const addContract = () => {
    if (!props.contract) {
      const form = new FormData();
      let user_id = 0;
      let client_manager_id = 0;
      if (props.job.accepted_devs && props.job.accepted_devs.length > 0) {
        props.job.accepted_devs.forEach((dev: any) => {
          if (dev.username === selectedDev) {
            user_id = dev.id;
          }
        });
      }
      if (props.job.accepted_groups && props.job.accepted_groups.length > 0) {
        props.job.accepted_groups.forEach((dev: any) => {
          if (dev.help.username === selectedDev) {
            user_id = dev.help.id;
          } else if (dev.learn.username === selectedDev) {
            user_id = dev.learn.id;
          }
        });
      }
      clientUsers.forEach((user: any) => {
        if (user.username === selectedClientManager) {
          client_manager_id = user.id;
        }
      });

      form.append("file", file, file.name);

      form.append("file_client", fileClient, fileClient.name);
      form.append("client", props.job.client.toString());
      form.append("dev", user_id.toString());
      form.append("client_manager", client_manager_id.toString());
      form.append("job", props.job.id.toString());
      form.append("hours_per_week", hoursPerWeek.toString());
      form.append("status", "UNASSIGNED");
      form.append("euros_per_hour", eurosPerHour.toString());
      form.append("project_days", projectDays.toString());

      axiosService
        .post(`${process.env.REACT_APP_API_URL}/add_contract`, form)
        .then((res) => {
          changeStatusLead(props.job.lead_id, "STARTED");
          alert.success("The Contract was added");
          props.onClose(true);
        })

        .catch((err) => {
          if (err.response?.status !== 500) {
            if (err.response) {
              Object.keys(err.response.data).forEach(function (key, index) {
                alert.error(key + ":" + err.response.data[key]);
              });
            }
          }

          console.log(err);
        });
    } else {
      let user_id = 0;
      let client_manager_id = 0;
      if (props.job.accepted_devs && props.job.accepted_devs.length > 0) {
        props.job.accepted_devs.forEach((dev: any) => {
          if (dev.username === selectedDev) {
            user_id = dev.id;
          }
        });
      }
      if (props.job.accepted_groups && props.job.accepted_groups.length > 0) {
        props.job.accepted_groups.forEach((dev: any) => {
          if (dev.help.username === selectedDev) {
            user_id = dev.help.id;
          } else if (dev.learn.username === selectedDev) {
            user_id = dev.learn.id;
          }
        });
      }
      clientUsers.forEach((user: any) => {
        if (user.username === selectedClientManager) {
          client_manager_id = user.id;
        }
      });
      let body = {
        id: props.contract.id,
        dev: user_id,
        client_manager: client_manager_id,
        project_days: projectDays,
        hours_per_week: hoursPerWeek,
        euros_per_hour: eurosPerHour,
      };
      axiosService
        .post(`${process.env.REACT_APP_API_URL}/edit_contract`, body)
        .then((res) => {
          alert.success("The Contract was edited");
          props.onClose(true);
        })

        .catch((err) => {
          if (err.response?.status !== 500) {
            if (err.response) {
              Object.keys(err.response.data).forEach(function (key, index) {
                alert.error(key + ":" + err.response.data[key]);
              });
            }
          }

          console.log(err);
        });
    }
  };

  const editContract = () => {
    const form = new FormData();

    form.append("id", props.contract_id.toString());
    if (props.dev) {
      form.append("file", file, file.name);
    }
    if (props.client) {
      form.append("file_client", fileClient, fileClient.name);
    }

    axiosService
      .post(`${process.env.REACT_APP_API_URL}/edit_contract_files`, form)
      .then((res) => {
        alert.success("The Contract was added");
        props.onClose(true);
      })

      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const reset = () => {
    setSelectedDev("");
    setHoursPerWeek(0);
    setEurosPerHour(0);
    setFile({});
    setFileClient({});
    setSelectedDev("");
    setSelectedClientManager("");
  };

  const onFileChange = (event: any) => {
    setFile(event.target.files[0]);
  };

  const onFileChangeClient = (event: any) => {
    setFileClient(event.target.files[0]);
  };

  const fileData = () => {
    if (file?.name) {
      return (
        <div className="row-center padding-normal full-percent-width">
          <h2 className="text-quicksand-small margin-r3">File Details: </h2>

          <p className="filename text-quicksand700-small"> {file?.name}</p>
        </div>
      );
    } else {
      return (
        <div className="row-center full-percent-width">
          <br />
          <h4 className="small-quicksand-grey">Please select a File</h4>
        </div>
      );
    }
  };
  const fileDataClient = () => {
    if (fileClient?.name) {
      return (
        <div className="row-center padding-normal">
          <h2 className="text-quicksand-small margin-r3">File Details:</h2>

          <p className="filename text-quicksand700-small">
            {" "}
            {fileClient?.name}
          </p>
        </div>
      );
    } else {
      return (
        <div className="row-center">
          <br />
          <h4 className="small-quicksand-grey">Please select a File</h4>
        </div>
      );
    }
  };

  return (
    // <div
    //   style={{
    //     visibility: show ? "visible" : "hidden",
    //     opacity: show ? "1" : "0",
    //   }}
    //   className={"overlay"}
    // >
    <Modal
      open={show}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={"popup-add-contract"}>
        <div className="add-contract-scroll">
          <div className="row-space-between-center">
            <div className="row-start-no-width">
              <span className="heading-lora">Add a Contract</span>
              {/* <button className="reset-button" onClick={reset}>
              Reset
            </button> */}

              <Tooltip title="Reset" placement="bottom" arrow>
                <UndoIcon
                  className="add-lead-reload-icon blue pointer"
                  onClick={reset}
                />
              </Tooltip>
            </div>
            <span className={"close"} onClick={closeHandler}>
              &times;
            </span>
          </div>

          <div className="column-space-between">
            <div className="column-start-start">
              <strong className="text-quicksand700-small darkgrey padding-up-down">
                {props.job?.name}
              </strong>
              {!props.contract_id && (
                <div className="add-contract-row-to-column ">
                  {props.job.accepted_devs &&
                    props.job.accepted_devs.length > 0 && (
                      <Box className="add-contract-width padding-up-down">
                        <FormControl fullWidth>
                          <InputLabel
                            shrink={true}
                            id="demo-simple-select-label"
                          >
                            Select the Developer
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            value={selectedDev}
                            onChange={(event) =>
                              setSelectedDev(event?.target.value)
                            }
                            input={
                              <OutlinedInput
                                id="select-multiple-chip"
                                label="Select the Developer"
                                notched
                              />
                            }
                            MenuProps={MenuProps}
                          >
                            {props.job.accepted_devs.map((dev: any) => (
                              <MenuItem
                                style={{ width: 600 }}
                                key={dev.id}
                                value={dev.username}
                              >
                                <ListItemText primary={dev.username} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    )}
                  {props.job.accepted_groups &&
                    props.job.accepted_groups.length > 0 && (
                      <Box className="add-contract-width padding-up-down">
                        <FormControl fullWidth>
                          <InputLabel
                            shrink={true}
                            id="demo-simple-select-label"
                          >
                            Select the Developer from the Group
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            value={selectedDev}
                            onChange={(event) =>
                              setSelectedDev(event?.target.value)
                            }
                            input={
                              <OutlinedInput
                                id="select-multiple-chip"
                                label="Select the Developer"
                                notched
                              />
                            }
                            MenuProps={MenuProps}
                          >
                            {props.job.accepted_groups.map((dev: any) => (
                              <div key={dev.help.id}>
                                <MenuItem
                                  style={{ width: 600 }}
                                  key={dev.help.id}
                                  value={dev.help.username}
                                >
                                  <ListItemText
                                    primary={dev.help.username + " HELP"}
                                  />
                                </MenuItem>
                                <MenuItem
                                  style={{ width: 600 }}
                                  key={dev.learn.id}
                                  value={dev.learn.username}
                                >
                                  <ListItemText
                                    primary={dev.learn.username + " LEARN"}
                                  />
                                </MenuItem>
                              </div>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    )}
                  <Box className="add-contract-width padding-up-down">
                    <FormControl fullWidth>
                      <InputLabel shrink={true} id="demo-simple-select-label">
                        Select the Manager
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        value={selectedClientManager}
                        onChange={(event) =>
                          setSelectedClientManager(event?.target.value)
                        }
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Select the Manager"
                            notched
                          />
                        }
                        MenuProps={MenuProps}
                      >
                        {clientUsers.length > 0 &&
                          clientUsers.map((user: any) => (
                            <MenuItem
                              style={{ width: 600 }}
                              key={user.id}
                              value={user.username}
                            >
                              <ListItemText primary={user.username} />
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                </div>
              )}
              {!props.contract_id && (
                <div className="add-contract-row-to-column ">
                  <Box className="add-contract-width-small padding-up-down ">
                    <FormControl fullWidth>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        label=" Project Days"
                        id="days-outlined-basic"
                        type="number"
                        InputProps={{
                          inputProps: { min: 0 },
                        }}
                        value={projectDays}
                        onChange={(event) =>
                          setProjectDays(parseInt(event.target.value))
                        }
                      />
                    </FormControl>
                  </Box>
                  <Box className="add-contract-width-small padding-up-down ">
                    <FormControl fullWidth>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        label=" Hours Per Week"
                        id="hours-outlined-basic"
                        type="number"
                        InputProps={{
                          inputProps: { min: 0 },
                        }}
                        value={hoursPerWeek}
                        onChange={(event) =>
                          setHoursPerWeek(parseInt(event.target.value))
                        }
                      />
                    </FormControl>
                  </Box>
                  <Box className="add-contract-width-small padding-up-down ">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label"></InputLabel>

                      <TextField
                        InputLabelProps={{ shrink: true }}
                        label=" Euros Per Hour"
                        id="euro-outlined-basic"
                        type="number"
                        value={eurosPerHour}
                        inputProps={{ min: 0, step: "any" }}
                        onChange={(event) =>
                          setEurosPerHour(parseFloat(event.target.value))
                        }
                      />
                    </FormControl>
                  </Box>
                </div>
              )}

              <div className="column-start-start padding-up-down">
                {(!props.contract_id || (props.contract_id && props.dev)) &&
                  !props.contract && (
                    <div className="add-contract-row-start-center nowrap">
                      <label htmlFor="upload-contract">
                        <input
                          style={{ display: "none" }}
                          id="upload-contract"
                          name="upload-contract"
                          type="file"
                          onChange={onFileChange}
                          accept="application/pdf"
                        />

                        <Button
                          style={{ marginTop: 4, marginRight: 31 }}
                          color="primary"
                          size="small"
                          component="span"
                          aria-label="add"
                        >
                          Upload the Contract for the Dev
                        </Button>
                      </label>
                      {fileData()}
                    </div>
                  )}
                {(!props.contract_id || (props.contract_id && props.client)) &&
                  !props.contract && (
                    <div className="add-contract-row-start-center nowrap">
                      <label htmlFor="upload-contract-client">
                        <input
                          style={{ display: "none" }}
                          id="upload-contract-client"
                          name="upload-contract-client"
                          type="file"
                          onChange={onFileChangeClient}
                          accept="application/pdf"
                        />
                        <Button
                          style={{ marginTop: 4, marginRight: 10 }}
                          color="primary"
                          size="small"
                          component="span"
                          aria-label="add"
                        >
                          Upload the Contract for the Client
                        </Button>
                      </label>
                      {fileDataClient()}
                    </div>
                  )}
              </div>
            </div>
          </div>

          <div className="row-flex-end-end">
            <button
              className="button-white-small margin-r20 text-quicksand700-small"
              onClick={() => props.onClose(true)}
            >
              {" "}
              Cancel
            </button>
            {!props.contract_id ? (
              <button
                className="button-black-small-nm text-quicksand700-small"
                onClick={addContract}
                disabled={
                  (!file && !props.contract) ||
                  (!fileClient && !props.contract) ||
                  !selectedClientManager ||
                  !selectedDev ||
                  eurosPerHour === 0 ||
                  eurosPerHour < 0 ||
                  projectDays === 0 ||
                  projectDays < 0
                }
                style={{
                  opacity:
                    (!file && !props.contract) ||
                    (!fileClient && !props.contract) ||
                    !selectedClientManager ||
                    !selectedDev ||
                    eurosPerHour === 0 ||
                    eurosPerHour < 0 ||
                    projectDays === 0 ||
                    projectDays < 0
                      ? 0.5
                      : 1,
                }}
              >
                {" "}
                Create Contract
              </button>
            ) : (
              <button
                className="button-black-small-nm text-quicksand700-small"
                onClick={editContract}
              >
                {" "}
                Add Contract
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

AddContract.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  job: PropTypes.object,
  contract_id: PropTypes.number,
  dev: PropTypes.bool,
  client: PropTypes.bool,
  contract: PropTypes.object,
};

export default AddContract;
