import { useEffect, useState } from "react";
import "./AddPayment.css";
import PropTypes from "prop-types";
import axiosService from "../../utils/axios";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Button, Fab, Input, TextareaAutosize, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { AddIcCallOutlined } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "moment/locale/en-gb";

import { useAlert } from "react-alert";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 500,
    },
  },
};

const AddPayment = (props: any) => {
  const [errorMessage, setErrorMessage] = useState("");

  const [show, setShow] = useState(false);
  const [chargesDevContracts, setChargesDevContracts] = useState<any[]>([]);
  const [chargesClientContracts, setChargesClientContracts] = useState<any[]>(
    []
  );
  const [chargesDevProfitShares, setChargesDevProfitShares] = useState<any[]>(
    []
  );
  const alert = useAlert();
  const [selectedEntity, setSelectedEntity] = useState("OUTGOING");
  const [chargesLeadRebates, setChargesLeadRebates] = useState<any[]>([]);
  const [filteredChargesDevContracts, setFilteredChargesDevContracts] =
    useState<any[]>([]);
  const [filteredChargesClientContracts, setFilteredChargesClientContracts] =
    useState<any[]>([]);
  const [filteredChargesDevProfitShares, setFilteredChargesDevProfitShares] =
    useState<any[]>([]);

  const [filteredChargesLeadRebates, setFilteredChargesLeadRebates] = useState<
    any[]
  >([]);
  const [selectedChargesDevContracts, setSelectedChargesDevContracts] =
    useState<any[]>([]);
  const [selectedChargesClientContracts, setSelectedChargesClientContracts] =
    useState<any[]>([]);
  const [selectedChargesDevProfitShares, setSelectedChargesDevProfitShares] =
    useState<any[]>([]);
  const [selectedChargesLeadRebates, setSelectedChargesLeadRebates] = useState<
    any[]
  >([]);
  const [availableIncomingUsers, setAvailableIncomingUsers] = useState<any[]>(
    []
  );
  const [availableOutgoingUsers, setAvailableOutgoingUsers] = useState<any[]>(
    []
  );
  const [selectedUser, setSelectedUser] = useState<number>();
  const [dateBankTransfer, setDateBankTransfer] = useState("");
  const [totalAmountContracts, setTotalAmountContracts] = useState(0);
  const [totalAmountClientContracts, setTotalAmountClientContracts] =
    useState(0);
  const [totalAmountProfitShares, setTotalAmountProfitShares] = useState(0);
  const [totalAmountLeadRebates, setTotalAmountLeadRebates] = useState(0);
  const [bankTransfer, setBankTransfer] = useState("");
  const [vat, setVat] = useState(0);
  const [isFirst, setIsFirst] = useState(true);

  const auth = useSelector((state: RootState) => state.auth);

  const closeHandler = (e: any) => {
    reset();
    setShow(false);
    props.onClose(false);
  };
  useEffect(() => {
    if (isFirst) {
      setIsFirst(false);
    }

    getCharges();
  }, []);

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        closeHandler(true);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  useEffect(() => {
    calculateTotal();
  }, [
    vat,
    selectedChargesClientContracts,
    selectedChargesDevContracts,
    selectedChargesDevProfitShares,
    selectedChargesLeadRebates,
  ]);
  useEffect(() => {
    handleSelectUser(selectedUser);
  }, [selectedUser]);

  useEffect(() => {
    if (bankTransfer.length >= 25) {
      setErrorMessage("The id can not exceed 25 characters");
    }
  }, [bankTransfer]);

  useEffect(() => {
    if (bankTransfer.length < 25 && errorMessage) {
      setErrorMessage("");
    }
  }, [bankTransfer, errorMessage]);

  const reset = () => {
    setBankTransfer("");
    setSelectedChargesClientContracts([]);
    setSelectedChargesDevContracts([]);
    setSelectedChargesDevProfitShares([]);
    setSelectedChargesLeadRebates([]);
    setSelectedEntity("OUTGOING");
    setSelectedUser(undefined);
    setVat(0);
    setChargesClientContracts([]);
    setChargesDevContracts([]);
    setChargesDevProfitShares([]);
    setChargesLeadRebates([]);
  };
  const getCharges = () => {
    let outgoing_users: number[] = [];
    let incoming_users: number[] = [];
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_charges_dev_contracts`)
      .then((res) => {
        let charges: any[] = [];
        res.data.map((charge: any) => {
          if (charge.status === "OPEN") {
            charges.push(charge);
            if (!outgoing_users.includes(charge.dev)) {
              outgoing_users.push(charge.dev);
            }
          }
        });
        setChargesDevContracts(charges);
        setAvailableIncomingUsers(incoming_users);
        setAvailableOutgoingUsers(outgoing_users);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });

    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_charges_dev_profit_shares`)
      .then((res) => {
        let charges: any[] = [];
        res.data.map((charge: any) => {
          if (charge.status === "OPEN") {
            charges.push(charge);
            if (!outgoing_users.includes(charge.dev)) {
              outgoing_users.push(charge.dev);
            }
          }
        });
        setChargesDevProfitShares(charges);
        setAvailableIncomingUsers(incoming_users);
        setAvailableOutgoingUsers(outgoing_users);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_charges_lead_rebates`)
      .then((res) => {
        let charges: any[] = [];
        res.data.map((charge: any) => {
          if (charge.status === "OPEN") {
            charges.push(charge);
            if (!outgoing_users.includes(charge.user)) {
              outgoing_users.push(charge.user);
            }
          }
        });
        setChargesLeadRebates(charges);
        setAvailableIncomingUsers(incoming_users);
        setAvailableOutgoingUsers(outgoing_users);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_charges_client_contracts`)
      .then((res) => {
        let charges: any[] = [];
        res.data.map((charge: any) => {
          if (charge.status === "OPEN") {
            charges.push(charge);
            if (!incoming_users.includes(charge.client)) {
              incoming_users.push(charge.client);
            }
          }
        });

        setChargesClientContracts(charges);
        setAvailableIncomingUsers(incoming_users);
        setAvailableOutgoingUsers(outgoing_users);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const addPayment = () => {
    if (selectedEntity === "OUTGOING") {
      let body = {
        charges_contract: selectedChargesDevContracts,
        charges_profit_share: selectedChargesDevProfitShares,
        charges_lead_rebate: selectedChargesLeadRebates,
        bank_transfer: bankTransfer,
        date_of_bank_transfer: moment(dateBankTransfer)
          .format("YYYY-MM-DD")
          .toString(),
        vat: vat,
        to_user: selectedUser,
        gross_amount:
          (totalAmountContracts +
            totalAmountLeadRebates +
            totalAmountProfitShares) *
          (vat === 0 ? 1 : 1.24),
        net_amount:
          totalAmountContracts +
          totalAmountLeadRebates +
          totalAmountProfitShares,
      };
      axiosService
        .post(`${process.env.REACT_APP_API_URL}/add_outgoing_payment`, body)
        .then((res) => {
          alert.success("The Payment was added");
          props.onClose(true);
        })

        .catch((err) => {
          if (err.response?.status !== 500) {
            if (err.response) {
              Object.keys(err.response.data).forEach(function (key, index) {
                alert.error(key + ":" + err.response.data[key]);
              });
            } else {
              alert.error(err.message);
            }
          }

          console.log(err);
        });
    } else {
      let body = {
        charges: selectedChargesClientContracts,
        bank_transfer: bankTransfer,
        date_of_bank_transfer: moment(dateBankTransfer)
          .format("YYYY-MM-DD")
          .toString(),
        vat: vat,
        from_client: selectedUser,

        gross_amount: totalAmountClientContracts * (vat === 0 ? 1 : 1.24),
        net_amount: totalAmountClientContracts,
      };
      axiosService
        .post(`${process.env.REACT_APP_API_URL}/add_incoming_payment`, body)
        .then((res) => {
          alert.success("The Payment was added");
          props.onClose(true);
        })

        .catch((err) => {
          if (err.response?.status !== 500) {
            if (err.response) {
              Object.keys(err.response.data).forEach(function (key, index) {
                alert.error(key + ":" + err.response.data[key]);
              });
            } else {
              alert.error(err.message);
            }
          }

          console.log(err);
        });
    }
  };

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const handleChangeSelectedChargesContracts = (
    event: SelectChangeEvent<typeof selectedChargesDevContracts>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedChargesDevContracts(
      typeof value === "string" ? value.split(",") : value
    );
    let tot = 0;
  };
  const handleChangeSelectedChargesClientContracts = (
    event: SelectChangeEvent<typeof selectedChargesClientContracts>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedChargesClientContracts(
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleChangeSelectedChargesProfitShares = (
    event: SelectChangeEvent<typeof selectedChargesDevProfitShares>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedChargesDevProfitShares(
      typeof value === "string" ? value.split(",") : value
    );
    let tot = 0;

    chargesDevProfitShares.forEach((charge: any) => {
      if (typeof value === "string") {
        value.split(",").forEach((val: string) => {
          if (val === charge.id.toString() || val === charge.id) {
            tot += vat === 0 ? charge.net_amount : charge.net_amount * 1.24;
          }
        });
      } else {
        value.forEach((val: string) => {
          if (val === charge.id.toString() || val === charge.id) {
            tot += vat === 0 ? charge.net_amount : charge.net_amount * 1.24;
          }
        });
      }
    });
    setTotalAmountProfitShares(tot);
  };

  const handleChangeSelectedChargesLeadRebates = (
    event: SelectChangeEvent<typeof selectedChargesLeadRebates>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedChargesLeadRebates(
      typeof value === "string" ? value.split(",") : value
    );
    let tot = 0;

    chargesLeadRebates.forEach((charge: any) => {
      if (typeof value === "string") {
        value.split(",").forEach((val: string) => {
          if (val === charge.id.toString() || val === charge.id) {
            tot += charge.net_amount;
          }
        });
      } else {
        value.forEach((val: string) => {
          if (val === charge.id.toString() || val === charge.id) {
            tot += charge.net_amount;
          }
        });
      }
    });
    setTotalAmountLeadRebates(tot);
  };
  const handleSelectUser = (user: any) => {
    if (selectedEntity === "OUTGOING") {
      let tmp_charge_contract: any[] = [];
      let tmp_charge_lead: any[] = [];
      let tmp_charge_profit: any[] = [];
      chargesDevContracts.forEach((charge: any) => {
        if (charge.dev === user) {
          tmp_charge_contract.push(charge);
        }
      });
      chargesDevProfitShares.forEach((charge: any) => {
        if (charge.dev === user) {
          tmp_charge_profit.push(charge);
        }
      });
      chargesLeadRebates.forEach((charge: any) => {
        if (charge.user === user) {
          tmp_charge_lead.push(charge);
        }
      });
      setFilteredChargesDevContracts(tmp_charge_contract);
      setFilteredChargesDevProfitShares(tmp_charge_profit);
      setFilteredChargesLeadRebates(tmp_charge_lead);
    } else {
      let tmp_charge_contract: any[] = [];
      chargesClientContracts.forEach((charge: any) => {
        if (charge.client === user) {
          tmp_charge_contract.push(charge);
        }
      });
      setFilteredChargesClientContracts(tmp_charge_contract);
    }
  };
  const calculateTotal = () => {
    let tot = 0;

    selectedChargesClientContracts.forEach((charge_c: any) => {
      chargesClientContracts.forEach((charge: any) => {
        if (charge.id === charge_c) {
          tot += charge.net_amount;
        }
      });
    });
    setTotalAmountClientContracts(tot);

    tot = 0;

    selectedChargesDevContracts.forEach((charge_c: any) => {
      chargesDevContracts.forEach((charge: any) => {
        if (charge.id === charge_c) {
          tot += charge.net_amount;
        }
      });
    });
    setTotalAmountContracts(tot);

    tot = 0;
    selectedChargesDevProfitShares.forEach((charge_c: any) => {
      chargesDevProfitShares.forEach((charge: any) => {
        if (charge.id === charge_c) {
          tot += charge.net_amount;
        }
      });
    });
    setTotalAmountProfitShares(tot);
    tot = 0;
    selectedChargesLeadRebates.forEach((charge_c: any) => {
      chargesLeadRebates.forEach((charge: any) => {
        if (charge.id === charge_c) {
          tot += charge.net_amount;
        }
      });
    });
    setTotalAmountLeadRebates(tot);
  };
  const handleChangeVat = (value: number) => {
    setVat(value);
  };

  return (
    <div
      style={{
        visibility: show ? "visible" : "hidden",
        opacity: show ? "1" : "0",
      }}
      className={"overlay"}
    >
      {/* {(availableIncomingUsers.length > 0 ||
        availableOutgoingUsers.length > 0) && ( */}
      <div className={"popup-phase "}>
        <div className="row-space-between">
          <h2>Add Payment</h2>
          <span className={"close"} onClick={closeHandler}>
            &times;
          </span>
        </div>
        <div className={"content "} style={{ height: "90%" }}>
          <div className="row-start full-percent-height">
            <div className="column-start full-percent-width padding-normal">
              <div className="row-center padding-normal full-percent-width padding-normal">
                <Box sx={{ width: 200 }}>
                  <FormControl fullWidth>
                    <InputLabel shrink={true} id="demo-simple-select-label">
                      Select Incoming/Outgoing
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      value={selectedEntity}
                      onChange={(event) =>
                        setSelectedEntity(event?.target.value)
                      }
                      input={
                        <OutlinedInput
                          id="select-multiple-chip"
                          label="Select Incoming/Outgoing"
                          notched
                        />
                      }
                      MenuProps={MenuProps}
                    >
                      <MenuItem value={"OUTGOING"}>
                        <ListItemText primary={"Outgoing"} />
                      </MenuItem>
                      <MenuItem value={"INCOMING"}>
                        <ListItemText primary={"Incoming"} />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                {((selectedEntity === "OUTGOING" &&
                  availableOutgoingUsers.length > 0) ||
                  (selectedEntity === "INCOMING" &&
                    availableIncomingUsers.length > 0)) && (
                  <Box sx={{ width: 200 }}>
                    <FormControl fullWidth>
                      <InputLabel shrink={true} id="demo-simple-select-label">
                        Select User
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        value={selectedUser}
                        onChange={(event) =>
                          setSelectedUser(
                            typeof event?.target.value === "string"
                              ? parseInt(event?.target.value)
                              : event?.target.value
                          )
                        }
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Select User"
                            notched
                          />
                        }
                        MenuProps={MenuProps}
                      >
                        {selectedEntity === "OUTGOING"
                          ? availableOutgoingUsers.map((user: number) => {
                              return (
                                <MenuItem key={user} value={user}>
                                  <ListItemText primary={user} />
                                </MenuItem>
                              );
                            })
                          : availableIncomingUsers.map((user: number) => {
                              return (
                                <MenuItem key={user} value={user}>
                                  <ListItemText primary={user} />
                                </MenuItem>
                              );
                            })}
                      </Select>
                    </FormControl>
                  </Box>
                )}
              </div>
              <div className="row-center padding-normal full-percent-width padding-normal">
                <Box sx={{ width: 200 }}>
                  <FormControl fullWidth>
                    <InputLabel shrink={true} id="demo-simple-select-label">
                      Select VAT
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      value={vat}
                      onChange={(event) =>
                        handleChangeVat(
                          typeof event?.target.value === "string"
                            ? parseInt(event?.target.value)
                            : event?.target.value
                        )
                      }
                      input={
                        <OutlinedInput
                          id="select-multiple-chip"
                          label="Select VAT"
                          notched
                        />
                      }
                    >
                      <MenuItem value={0}>
                        <ListItemText primary={"0%"} />
                      </MenuItem>
                      <MenuItem value={24}>
                        <ListItemText primary={"24%"} />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
              {selectedEntity === "OUTGOING" &&
                filteredChargesDevContracts.length > 0 && (
                  <div className="row-center padding-normal full-percent-width padding-normal">
                    <Box sx={{ width: 200 }}>
                      <FormControl fullWidth>
                        <InputLabel shrink={true} id="demo-simple-select-label">
                          Charges from Contracts
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={selectedChargesDevContracts}
                          onChange={handleChangeSelectedChargesContracts}
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              label="Charges from Contracts"
                              notched
                            />
                          }
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          {filteredChargesDevContracts.map((charge: any) => (
                            <MenuItem
                              style={{ width: 600 }}
                              key={charge.id}
                              value={charge.id}
                            >
                              <Checkbox
                                checked={
                                  selectedChargesDevContracts.indexOf(
                                    charge.id
                                  ) > -1
                                }
                              />
                              <ListItemText
                                primary={
                                  charge.ref + " Amount " + charge.net_amount
                                }
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                )}
              {selectedEntity === "INCOMING" &&
                filteredChargesClientContracts.length > 0 && (
                  <div className="row-center padding-normal full-percent-width padding-normal">
                    <Box sx={{ width: 200 }}>
                      <FormControl fullWidth>
                        <InputLabel shrink={true} id="demo-simple-select-label">
                          Charges from Contracts
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={selectedChargesClientContracts}
                          onChange={handleChangeSelectedChargesClientContracts}
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              label="Charges from Contracts"
                              notched
                            />
                          }
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          {filteredChargesClientContracts.map((charge: any) => (
                            <MenuItem
                              style={{ width: 600 }}
                              key={charge.id}
                              value={charge.id}
                            >
                              <Checkbox
                                checked={
                                  selectedChargesClientContracts.indexOf(
                                    charge.id
                                  ) > -1
                                }
                              />
                              <ListItemText
                                primary={
                                  charge.ref + " Amount " + charge.net_amount
                                }
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                )}
              {selectedEntity === "OUTGOING" &&
                filteredChargesDevProfitShares.length > 0 && (
                  <div className="row-center padding-normal full-percent-width padding-normal">
                    <Box sx={{ width: 200 }}>
                      <FormControl fullWidth>
                        <InputLabel shrink={true} id="demo-simple-select-label">
                          Charges from Profit Shares
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={selectedChargesDevProfitShares}
                          onChange={handleChangeSelectedChargesProfitShares}
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              label="Charges from Profit Shares"
                              notched
                            />
                          }
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          {filteredChargesDevProfitShares.map((charge: any) => (
                            <MenuItem
                              style={{ width: 600 }}
                              key={charge.id}
                              value={charge.id}
                            >
                              <Checkbox
                                checked={
                                  selectedChargesDevProfitShares.indexOf(
                                    charge.id
                                  ) > -1
                                }
                              />
                              <ListItemText
                                primary={
                                  charge.ref + " Amount " + charge.net_amount
                                }
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                )}
              {selectedEntity === "OUTGOING" &&
                filteredChargesLeadRebates.length > 0 && (
                  <div className="row-center padding-normal full-percent-width padding-normal">
                    <Box sx={{ width: 200 }}>
                      <FormControl fullWidth>
                        <InputLabel shrink={true} id="demo-simple-select-label">
                          Charges from Lead Rebates
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={selectedChargesLeadRebates}
                          onChange={handleChangeSelectedChargesLeadRebates}
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              label="Charges from Lead Rebates"
                              notched
                            />
                          }
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          {filteredChargesLeadRebates.map((charge: any) => (
                            <MenuItem
                              style={{ width: 600 }}
                              key={charge.id}
                              value={charge.id}
                            >
                              <Checkbox
                                checked={
                                  selectedChargesLeadRebates.indexOf(
                                    charge.id
                                  ) > -1
                                }
                              />
                              <ListItemText
                                primary={
                                  charge.ref + " Amount " + charge.net_amount
                                }
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                )}
              <div className="row-space-between padding-normal ">
                <Box sx={{ minWidth: 200 }}>
                  <FormControl fullWidth>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      label="Bank Transfer"
                      id="bank-transfer"
                      value={bankTransfer}
                      error={bankTransfer.length >= 25}
                      inputProps={{ maxLength: 25 }}
                      helperText={errorMessage}
                      onChange={(event) => setBankTransfer(event.target.value)}
                    />
                  </FormControl>
                </Box>
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  adapterLocale="en-gb"
                >
                  <Box sx={{ minWidth: 200 }}>
                    <FormControl fullWidth>
                      <DatePicker
                        inputFormat="DD/MM/YYYY"
                        value={dateBankTransfer || null}
                        onChange={(newValue) => {
                          if (newValue) {
                            setDateBankTransfer(newValue?.toString());
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{ shrink: true }}
                            label="Date of transfer"
                          />
                        )}
                      />
                    </FormControl>
                  </Box>
                </LocalizationProvider>
                {/* <Box sx={{ minWidth: 200 }}>
                  <FormControl fullWidth>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      type="date"
                      label="Date of Bank Transfer"
                      id="demo-simple-select"
                      value={dateBankTransfer}
                      onChange={(event) =>
                        setDateBankTransfer(event.target.value)
                      }
                      InputProps={{
                        inputComponent: InputComponentDatePicker,
                      }}
                    />
                  </FormControl>
                </Box> */}
              </div>
              <div className="row-center padding-normal full-percent-width padding-normal">
                <b>
                  Total Amount:{" "}
                  {selectedEntity === "OUTGOING"
                    ? (totalAmountContracts +
                        totalAmountLeadRebates +
                        totalAmountProfitShares) *
                      (vat === 0 ? 1 : 1.24)
                    : totalAmountClientContracts * (vat === 0 ? 1 : 1.24)}{" "}
                  €
                </b>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            alignSelf: "flex-end",
            position: "relative",

            top: 0,
          }}
          className="padding-normal"
        >
          <button className="button nav" onClick={() => props.onClose(true)}>
            {" "}
            Cancel
          </button>
          <button
            className="button signUp"
            style={{ color: "white" }}
            onClick={addPayment}
            disabled={errorMessage !== ""}
          >
            {" "}
            Add Payment
          </button>
        </div>
      </div>
      {/* )} */}
    </div>
  );
};

AddPayment.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddPayment;
