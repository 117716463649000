import React, { useState, useEffect, useRef } from "react";
import axiosService from "../../utils/axios";

import locationSlice from "../../store/slices/location";
import "./Platform.css";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { useAlert } from "react-alert";
import Messages from "../../components/messages/Messages";
import DragII from "../../components/draw-line/DrawLine";
import FinancesDash from "../../components/finances-dash/FinancesDash";
import DragI from "../../components/drag1/Drag1";
import Streamgraph from "../../components/steamgraph/SteamGraph";
import FinancesDashCustom from "../../components/finances-dash/FinancesDashCustom";
import moment from "moment";
import FinancesDashClient from "../../components/finances-dash/FinancesDashClient";
import LeadInvoices from "../../components/lead-invoices/LeadInvoices";

const PlatformUser = () => {
  const [leadsClicked, setLeadsClicked] = useState(false);
  const [jobsClicked, setJobsClicked] = useState(false);
  const [projectsClicked, setProjectsClicked] = useState(false);
  const [requestsClicked, setRequestsClicked] = useState(false);
  const [totalIncomingAmount, setTotalIncomingAmount] = useState(0);
  const [totalClientContractsAmount, setTotalClientContractsAmount] =
    useState(0);
  const [totalClientLeadsAmount, setTotalClientLeadsAmount] = useState(0);
  const alert = useAlert();
  const [dataPaymentsLeads, setDataPaymentsLeads] = useState<any[]>([]);
  const [showFinances, setShowFinances] = useState(false);
  const [leads, setLeads] = useState([]);
  const [requests, setRequests] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [dataPayments, setDataPayments] = useState<any[]>([]);
  const [projects, setProjects] = useState([]);
  const [messagesInbox, setMessagesInbox] = useState<Array<any>>([]);
  const [messagesOutbox, setMessagesOutbox] = useState<Array<any>>([]);
  const [contacts, setContacts] = useState<any[]>([]);
  const [selectedLead, setSelectedLead] = useState<any>({});
  const [selectedRequest, setSelectedRequest] = useState<any>({});
  const [selectedJob, setSelectedJob] = useState<any>({});
  const [selectedProject, setSelectedProject] = useState<any>({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth);
  const [notifications, setNotifications] = React.useState<Array<any>>([]);
  const [width, setWidth] = useState(0);
  const [resized, setResized] = useState(false);
  const [filteredNotifications, setFilteredNotifications] = React.useState<
    Array<any>
  >([]);
  const ref = useRef<any>(null);
  useEffect(() => {
    if (auth.account) {
      if (auth.selectedView == "CLIENT") {
        getJobsClient();
        getLeadsClient();
        getRequests();
        getProjectsClient();
        getFinancesClient();
      } else {
        getJobs();
        getLeads();
        getProjects();
        getFinances();
      }

      getMessages();
      getContacts();
      dispatch(locationSlice.actions.setLocation({ path: "/platform" }));
      if (ref.current) {
        setWidth(ref.current.offsetWidth - 100);
      }
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    getMessages();

    const timer = setInterval(() => getMessages(), 360000);
    return () => clearInterval(timer);
  }, [auth]);

  useEffect(() => {
    const resize = () => {
      if (ref.current) {
        setWidth(ref.current.offsetWidth - 150);
      }
    };
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, []);

  const getFinancesClient = () => {
    axiosService
      .get(
        `${process.env.REACT_APP_API_URL}/get_client_finances/` + auth.account
      )
      .then((res: any) => {
        let tmp_payments: any[] = [];
        let tmp_payments_leads: any[] = [];
        let totalPayments = 0;
        let totalLeads = 0;
        let i = 0;
        while (i < 12 && res.data.length > 0) {
          let firstDate = new Date(moment().subtract(i, "M").format());
          let firstDateEnd = new Date(
            moment()
              .subtract(i + 1, "M")
              .format()
          );

          tmp_payments.push({
            date: moment(firstDate).format("YYYY-MM-DD").toString(),
            Contract: 0,
          });
          tmp_payments_leads.push({
            date: moment(firstDate).format("YYYY-MM-DD").toString(),
            Lead: 0,
          });

          res.data.forEach((payment: any) => {
            payment.charges?.forEach((charge: any) => {
              if (
                moment(payment.date_of_bank_transfer) > moment(firstDateEnd) &&
                moment(payment.date_of_bank_transfer) < moment(firstDate)
              ) {
                tmp_payments[i].date = moment(firstDate)
                  .format("YYYY-MM-DD")
                  .toString();

                tmp_payments[i].Contract += charge.net_amount;
                totalPayments += charge.net_amount;
              }
            });
            payment.charges_lead_rebate?.forEach((charge: any) => {
              if (
                moment(payment.date_of_bank_transfer) > moment(firstDateEnd) &&
                moment(payment.date_of_bank_transfer) < moment(firstDate)
              ) {
                tmp_payments_leads[i].date = moment(firstDate)
                  .format("YYYY-MM-DD")
                  .toString();

                tmp_payments_leads[i].Lead += charge.net_amount;
                totalLeads += charge.net_amount;
              }
            });
          });
          i++;
        }
        const reversed = [...tmp_payments].reverse();
        const reversed_leads = [...tmp_payments_leads].reverse();

        setDataPayments(reversed);
        setDataPaymentsLeads(reversed_leads);
        setTotalClientLeadsAmount(totalLeads);
        setTotalClientContractsAmount(totalPayments);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const getFinances = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_dev_finances/` + auth.account)
      .then((res: any) => {
        let tmp_payments: any[] = [];
        let i = 0;
        let totalIncoming = 0;
        while (i < 12 && res.data.length > 0) {
          let firstDate = new Date(
            moment().subtract(i, "M").endOf("month").format()
          );
          let firstDateEnd = new Date(
            moment().subtract(i, "M").startOf("month").format()
          );

          tmp_payments.push({
            date: moment(firstDate).format("YYYY-MM-DD").toString(),
            Contract: 0,
            Lead: 0,
            "Profit Share": 0,
          });

          res.data.forEach((payment: any) => {
            payment.charges_contract.forEach((charge: any) => {
              if (
                moment(payment.date_of_bank_transfer) > moment(firstDateEnd) &&
                moment(payment.date_of_bank_transfer) < moment(firstDate)
              ) {
                tmp_payments[i].date = moment(firstDate)
                  .format("YYYY-MM-DD")
                  .toString();

                tmp_payments[i].Contract += charge.net_amount;
                totalIncoming += charge.net_amount;
              }
            });
            payment.charges_lead_rebate.forEach((charge: any) => {
              if (
                moment(payment.date_of_bank_transfer) > moment(firstDateEnd) &&
                moment(payment.date_of_bank_transfer) < moment(firstDate)
              ) {
                tmp_payments[i].date = moment(firstDate)
                  .format("YYYY-MM-DD")
                  .toString();

                tmp_payments[i].Lead += charge.net_amount;
                totalIncoming += charge.net_amount;
              }
            });
            payment.charges_profit_share.forEach((charge: any) => {
              if (
                moment(payment.date_of_bank_transfer) > moment(firstDateEnd) &&
                moment(payment.date_of_bank_transfer) < moment(firstDate)
              ) {
                tmp_payments[i].date = moment(firstDate)
                  .format("YYYY-MM-DD")
                  .toString();

                tmp_payments[i]["Profit Share"] += charge.net_amount;
                totalIncoming += charge.net_amount;
              }
            });
          });
          i++;
        }
        const reversed = [...tmp_payments].reverse();
        console.log(reversed);
        setDataPayments(reversed);
        setTotalIncomingAmount(totalIncoming);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const getContacts = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_contacts`)
      .then((res) => {
        if (res.data.length > 0) {
          setContacts(res.data);
        }
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const getMessages = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_messages`)
      .then((res: any) => {
        if (res.data) {
          let tmpIn: any[] = [];
          let tmpOut: any[] = [];
          res.data.forEach((message: any) => {
            if (message.from_user.id === auth.account) {
              tmpOut.push(message);
            } else {
              tmpIn.push(message);
            }
          });
          setMessagesInbox(tmpIn);
          setMessagesOutbox(tmpOut);
        } else {
          setNotifications([]);
        }
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  const getJobsClient = () => {
    axiosService
      .get(
        `${process.env.REACT_APP_API_URL}/get_user_jobs_client/` + auth.account
      )
      .then((res) => {
        setJobs(res.data);
        setSelectedJob(res.data[0]);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const getJobs = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_user_jobs/` + auth.account)
      .then((res) => {
        setJobs(res.data);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  const getRequests = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_user_requests/` + auth.account)
      .then((res) => {
        setRequests(res.data);
        setSelectedRequest(res.data[0]);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const getLeadsClient = () => {
    axiosService
      .get(
        `${process.env.REACT_APP_API_URL}/get_user_leads_client/` + auth.account
      )
      .then((res) => {
        setLeads(res.data);
        setSelectedLead(res.data[0]);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const getLeads = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_user_leads/` + auth.account)
      .then((res) => {
        setLeads(res.data);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  const getProjectsClient = () => {
    axiosService
      .get(
        `${process.env.REACT_APP_API_URL}/get_user_projects_client/` +
          auth.account
      )
      .then((res) => {
        setProjects(res.data);
        setSelectedProject(res.data[0]);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  const getProjects = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_user_projects/` + auth.account)
      .then((res) => {
        setProjects(res.data);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const handleProjectSelect = (event: any) => {
    projects.forEach((project: any) => {
      if (project.id === event.target.value) {
        setSelectedProject(project);
      }
    });
  };

  const handleLeadSelect = (event: any) => {
    leads.forEach((lead: any) => {
      if (lead.id === event.target.value) {
        setSelectedLead(lead);
      }
    });
  };

  const handleRequestSelect = (event: any) => {
    requests.forEach((request: any) => {
      if (request.id === event.target.value) {
        setSelectedRequest(request);
      }
    });
  };

  const handleJobSelect = (event: any) => {
    jobs.forEach((job: any) => {
      if (job.id === event.target.value) {
        setSelectedJob(job);
      }
    });
  };

  return (
    <div className="column-space-between ">
      <div className="dashboard-box-out margin-b20">
        <div className="dashboard-box">
          <img
            src={require("../../assets/images/line.png")}
            className="dash-line-disappear"
          />
          <div className="dash-row-space-around">
            {auth.selectedView === "CLIENT" && (
              <div
                className={
                  auth.selectedView === "CLIENT"
                    ? "kpi-card-dash"
                    : "kpi-card-dash-dev"
                }
              >
                <Link className="nav-link" to="/platform/requests">
                  <div className="column-center pointer">
                    <strong className="dashboard-text-blue">
                      {" "}
                      My Requests
                    </strong>
                    <strong className="dashboard-number">
                      {requests.length}
                    </strong>
                  </div>
                </Link>
              </div>
            )}
            {auth.selectedView === "CLIENT" && (
              <img
                src={require("../../assets/images/line-vertical.png")}
                className="dash-line-between"
              />
            )}

            <div
              className={
                auth.selectedView === "CLIENT"
                  ? "kpi-card-dash"
                  : "kpi-card-dash-dev"
              }
            >
              <Link className="nav-link" to="/platform/jobs">
                <div className="column-center">
                  <strong className="dashboard-text-blue"> My jobs</strong>
                  <strong className="dashboard-number">{jobs.length}</strong>
                </div>
              </Link>
            </div>
            <img
              src={require("../../assets/images/line-vertical.png")}
              className="dash-line-between"
            />
            <div
              className={
                auth.selectedView === "CLIENT"
                  ? "kpi-card-dash"
                  : "kpi-card-dash-dev"
              }
            >
              <Link className="nav-link" to="/platform/projects">
                <div className="column-center">
                  <strong className="dashboard-text-blue"> My projects</strong>
                  <strong className="dashboard-number">
                    {projects.length}
                  </strong>
                </div>
              </Link>
            </div>
            <img
              src={require("../../assets/images/line-vertical.png")}
              className="dash-line-between"
            />
            <div
              className={
                auth.selectedView === "CLIENT"
                  ? "kpi-card-dash"
                  : "kpi-card-dash-dev"
              }
            >
              <Link className="nav-link" to="/platform/leads">
                <div className="column-center">
                  <strong className="dashboard-text-blue">My leads</strong>
                  <strong className="dashboard-number">{leads.length}</strong>
                </div>
              </Link>
            </div>
          </div>
          <img
            src={require("../../assets/images/line.png")}
            className="dash-line-disappear"
          />
        </div>
        <img
          className="dash-img"
          src={
            auth.selectedView === "CLIENT"
              ? require("../../assets/images/dashboard-client.png")
              : require("../../assets/images/dashboard-img.png")
          }
        />
      </div>
      <div className=" separate-area margin-b20" ref={ref}>
        {dataPayments.length > 0 &&
          auth.selectedView === "DEVELOPER" &&
          (resized ? (
            <FinancesDashCustom
              width={width}
              height={400}
              data={dataPayments}
              totalIncoming={totalIncomingAmount}
            />
          ) : (
            <FinancesDashCustom
              width={width}
              height={400}
              data={dataPayments}
              totalIncoming={totalIncomingAmount}
            />
          ))}
        {dataPayments.length > 0 &&
          auth.selectedView === "CLIENT" &&
          (resized ? (
            <FinancesDashClient
              width={width}
              height={400}
              data={dataPayments}
              dataLeads={dataPaymentsLeads}
              totalPayments={totalClientContractsAmount}
              totalLeads={totalClientLeadsAmount}
            />
          ) : (
            <FinancesDashClient
              width={width}
              height={400}
              data={dataPayments}
              dataLeads={dataPaymentsLeads}
              totalPayments={totalClientContractsAmount}
              totalLeads={totalClientLeadsAmount}
            />
          ))}
      </div>
      {auth.selectedView === "LEAD PROVIDER" && (
        <div className="separate-area margin-b20">
          <LeadInvoices />
        </div>
      )}
      <div className="separate-area margin-b20">
        <Messages
          contacts={contacts}
          messagesInbox={messagesInbox}
          messagesOutbox={messagesOutbox}
          getMessages={getMessages}
        />
      </div>
      &nbsp;
    </div>
  );
};

export default PlatformUser;
