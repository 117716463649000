import React, { useState, useEffect } from "react";
import Moment from "moment";
import "./ReportCard.css";
import EuroIcon from "@mui/icons-material/Euro";
import BusinessIcon from "@mui/icons-material/Business";
import ComputerIcon from "@mui/icons-material/Computer";
import AvTimerIcon from "@mui/icons-material/AvTimer";

function ReportCard(props: any) {
  return (
    <div className="opp-boxes">
      <div className="row-space-between full-percent-width">
        <span className="text-quicksand700 opp-grey row-start-center">
          {" "}
          {Moment(props.report.createdAt).format("D MMM yy")}
        </span>
        <div className="row-start-center">
          <img
            className="image-reports"
            src={
              props.report.rate === "ANGRY"
                ? require("../../assets/images/angry.png")
                : props.report.rate === "SAD"
                ? require("../../assets/images/sad.png")
                : props.report.rate === "NEUTRAL"
                ? require("../../assets/images/neutral.png")
                : props.report.rate === "HAPPY"
                ? require("../../assets/images/happy.png")
                : require("../../assets/images/excited.png")
            }
          />
          <span className="opp-status">
            {props.report.escalate ? "Escalate" : "Don't Escalate"}
          </span>
        </div>
      </div>

      <span className="opp-status">
        What went really well: {props.report.went_well}
      </span>
      <span className="opp-status">
        What should be done more often: {props.report.do_more}
      </span>
      <span className="opp-status">
        What should not be done anymore: {props.report.not_do}
      </span>
      <span className="opp-status">
        What is blocking your work: {props.report.blocking}
      </span>
      <span className="opp-status">
        What you would like to highlight: {props.report.highlight}
      </span>
    </div>
  );
}

export default ReportCard;
