import React, { useEffect, useState } from "react";
import "./Pricing.css";
import locationSlice from "../../store/slices/location";
import { useDispatch } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import check from "../../assets/images/check.png";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import { Input, TextareaAutosize, TextField } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import axiosService from "../../utils/axios";
import DeleteIcon from "@mui/icons-material/Delete";
import PricingConfigurator from "../../components/pricing-configurator/PricingConfigurator";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";

import { useAlert } from "react-alert";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Pricing = () => {
  const [nameOfClient, setNameOfClient] = useState("");
  const [startDate, setStartDate] = useState("");
  const [customerType, setCustomerType] = useState("");
  const [industry, setIndustry] = useState("");
  const [duration, setDuration] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [description, setDescription] = useState("");
  const dispatch = useDispatch();
  const [skills, setSkills] = useState<Array<string>>([]);
  const [devSkills, setDevSkills] = useState<string[]>([]);
  const alert = useAlert();
  const [devHours, setDevHours] = useState("");
  const [devLevel, setDevLevel] = useState("");
  const [devDuration, setDevDuration] = useState("");
  const [devNumber, setDevNumber] = useState(1);
  const [devStartDate, setDevStartDate] = useState("");
  const [selectedDevs, setSelectedDevs] = useState<any[]>([]);

  useEffect(() => {
    dispatch(locationSlice.actions.setLocation({ path: "/pricing" }));
    // getSkills();
  }, []);

  const handleChangeDevSkills = (
    event: SelectChangeEvent<typeof devSkills>
  ) => {
    const {
      target: { value },
    } = event;
    setDevSkills(typeof value === "string" ? value.split(",") : value);
  };
  // const getSkills = () => {
  //   axiosService
  //     .get(`${process.env.REACT_APP_API_URL}/get_skills`)
  //     .then((res) => {
  //       let tmpSkills: any[] = [];
  //       res.data.forEach((data: any) => {
  //         tmpSkills.push(data.name);
  //       });
  //       setSkills(tmpSkills);
  //     })
  //     .catch((err) => {
  //       if (err.response?.status !== 500) {
  //         if (err.response) {
  //           Object.keys(err.response.data).forEach(function (key, index) {
  //             alert.error(key + ":" + err.response.data[key]);
  //           });
  //         }
  //       }

  //       console.log(err);
  //     });
  // };

  const removeSelectedDev = (index: number) => {
    let tmp = [...selectedDevs];
    tmp.splice(index, 1);
    setSelectedDevs(tmp);
  };

  const reset = () => {
    setSelectedDevs([]);
    setDevSkills([]);
    setDevDuration("");
    setDevLevel("");
    setDevNumber(1);
    setDevStartDate("");
    setCustomerEmail("");
    setCustomerType("");
    setNameOfClient("");
    setStartDate("");

    setIndustry("");
    setDuration("");
    setDescription("");
  };

  return (
    <div className="container">
      <div className="pricingBar">
        <div className="pricing-leftSideHeader">
          <strong className="home-title">Pricing</strong>
          <span className="text-quicksand darkgrey text-align-left">
            The Gyld offers fixed rates according to the developer‘s skill
            level. Hot skill markups for ultra hot technologies & industries may
            apply. Get a quick overview with our project configurator.
          </span>
          <div className="carousel-buttons">
            {/* <button className="button-black-small text-quicksand700-small">
              Calculate
            </button> */}
          </div>
        </div>
        <img
          className="image"
          src={require("../../assets/images/mom.png")}
          alt="developing vector"
        />
      </div>

      <div className="pricing-cards">
        <div className="pricing-cards-out">
          <div className="pricing-cards-top">
            <span className="home-title">Junior</span>
            <div>
              <span className="home-title">30€</span>
              <span className="text-quicksand700">/hour</span>
            </div>
            
          </div>

          <div className="pricing-cards-bottom">
            <div className="row-start">
              <DoneRoundedIcon className="margin-right-small" />
              <span className="text-quicksand700">
                Typically starting out their career
              </span>
            </div>
            <div className="row-start ">
              <DoneRoundedIcon className="margin-right-small" />
              <span className="text-quicksand700">
                Experience with the skill will typically be 1 year or less
              </span>
            </div>
            <div className="row-start">
              <DoneRoundedIcon className="margin-right-small" />
              <span className="text-quicksand700">
                Can rely on senior support network
              </span>
            </div>
            <div className="row-start">
              <DoneRoundedIcon className="margin-right-small" />
              <span className="text-quicksand700">
                Can be actively mentored by veteran developers
              </span>
            </div>
          </div>
        </div>

        <div className="pricing-cards-out">
          <div className="pricing-cards-top">
            <span className="home-title">Mid</span>
            <div>
              <span className="home-title">40€</span>
              <span className="text-quicksand700">/hour</span>
            </div>
           
          </div>

          <div className="pricing-cards-bottom">
            <div className="row-start">
              <DoneRoundedIcon className="margin-right-small" />
              <span className="text-quicksand700">
                Typically supported multiple clients & projects
              </span>
            </div>
            <div className="row-start ">
              <DoneRoundedIcon className="margin-right-small" />
              <span className="text-quicksand700">
                Skill experience will typically be 2+ years
              </span>
            </div>
            <div className="row-start ">
              <DoneRoundedIcon className="margin-right-small" />
              <span className="text-quicksand700">
                Is building up industry specific hot skills
              </span>
            </div>
            <div className="row-start ">
              <DoneRoundedIcon className="margin-right-small" />
              <span className="text-quicksand700">
                Will actively exchange with other industry and technology
                experts
              </span>
            </div>
          </div>
        </div>

        <div className="pricing-cards-out">
          <div className="pricing-cards-top">
            <span className="home-title">Senior</span>
            <div>
              <span className="home-title">50€</span>
              <span className="text-quicksand700">/hour</span>
            </div>
           
          </div>
          <div className="pricing-cards-bottom">
            <div className="row-start">
              <DoneRoundedIcon className="margin-right-small" />
              <span className="text-quicksand700">
                Typically supported dozens of clients & projects
              </span>
            </div>
            <div className="row-start">
              <DoneRoundedIcon className="margin-right-small" />
              <span className="text-quicksand700">
                Skill experience will typically be 5+ years
              </span>
            </div>
            <div className="row-start">
              <DoneRoundedIcon className="margin-right-small" />
              <span className="text-quicksand700">
                Has one or more favorite industries
              </span>
            </div>
            <div className="row-start">
              <DoneRoundedIcon className="margin-right-small" />
              <span className="text-quicksand700">
                Is an industry and technology expert in the field
              </span>
            </div>
          </div>
        </div>
      </div>

      <PricingConfigurator />

      {/* {window.innerWidth >= 800 ? ( */}
      {/* <div className={"configurator-out"}>
          <div className={"configurator-left"}>
            <div className={"row-space-between"}>
              <span className="text-quicksand700">Project Configurator</span>
              <button className="reset-button" onClick={reset}>
                Reset
              </button>
            </div>
            <span className={"text-quicksand700-small padding-t50"}>
              How many developers do you need and for how long?
            </span>

            <div className="configurator-left-row">
              <div className="configurator-left-row2">
                <Box sx={{ width: 80, paddingRight: 2 }}>
                  <FormControl fullWidth>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      type="number"
                      label=""
                      id="demo-simple-select"
                      value={devNumber}
                      onChange={(event) =>
                        setDevNumber(parseInt(event.target.value))
                      }
                    />
                  </FormControl>
                </Box>
                <Box sx={{ width: 200, paddingRight: 2 }}>
                  <FormControl fullWidth>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      id="demo-simple-select-duration"
                      label=" Duration in Months"
                      value={devDuration}
                      onChange={(event) => setDevDuration(event.target.value)}
                    />
                  </FormControl>
                </Box>
              </div>

              <Box sx={{ minWidth: 200, maxWidth: 220 }}>
                <FormControl fullWidth>
                  <InputLabel shrink={true} id="demo-simple-select-label">
                    Availability Needed
                  </InputLabel>
                  <Select
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label="Availability Needed"
                        notched
                      />
                    }
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={devHours || ""}
                    label="SelectedProject"
                    onChange={(event) => setDevHours(event.target.value)}
                  >
                    <MenuItem value={"FULL_TIME"}>Full Time</MenuItem>
                    <MenuItem value={"PART_TIME"}>Part Time</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>

            <span className={"text-quicksand700-small padding-t50"}>
              What skills do you need the developer to have?
            </span>

            <div className="row-space-between">
              <div className="row-space-between-ajusted">
                <Box
                  sx={{ minWidth: 200, paddingRight: 2 }}
                  className="configurator-box-small"
                >
                  <FormControl fullWidth>
                    <InputLabel shrink={true} id="demo-simple-select-label">
                      Developer Level
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={devLevel || ""}
                      input={
                        <OutlinedInput
                          id="select-multiple-chip"
                          label="Developer Level"
                          notched
                        />
                      }
                      label="SelectedProject"
                      onChange={(event) => setDevLevel(event.target.value)}
                    >
                      <MenuItem value={"JUNIOR"}>Junior</MenuItem>
                      <MenuItem value={"MID"}>Mid</MenuItem>
                      <MenuItem value={"SENIOR"}>Senior</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                <Box
                  sx={{ minWidth: 200, paddingRight: 2 }}
                  className="configurator-box-small"
                >
                  <FormControl fullWidth>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      type="date"
                      label="Dev Start Date"
                      id="demo-simple-select"
                      value={devStartDate}
                      onChange={(event) => setDevStartDate(event.target.value)}
                    />
                  </FormControl>
                </Box>
              </div>
              <div className="row-space-between">
                <Box sx={{ minWidth: 200 }} className="configurator-box-small">
                  <FormControl fullWidth>
                    <InputLabel shrink={true} id="demo-simple-select-label">
                      Skills
                    </InputLabel>
                    <Select
                      input={
                        <OutlinedInput
                          id="select-multiple-chip"
                          label="Skills"
                          notched
                        />
                      }
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={devSkills}
                      onChange={handleChangeDevSkills}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {skills.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={devSkills.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </div>

            <button
              style={{ alignSelf: "flex-end" }}
              className="text-quicksand700-small blue"
              onClick={handleAddDeveloper}
            >
              + Add developer and get estimate
            </button>
          </div>

          <div className={"configurator-right"}>
            <span className="text-quicksand700 white">Resources:</span>
            <div className="pricing-total">
              <div className={"configurator-right-overflow"}>
                {selectedDevs.map((dev: any, i: number) => {
                  return (
                    <div key={i} style={{ width: 500 }}>
                      <div className="column-flex-start">
                        <div className="row-start text-quicksand700-small white">
                          <span>
                            {dev.number_of_devs} &nbsp;
                            {dev.developer_level} &nbsp; Developers for &nbsp;
                            {dev.duration}&nbsp; Months, starting on &nbsp;
                            {moment(dev.start_date).format("D MMM yy")}
                          </span>
                        </div>
                        <div className="row-start wrap">
                          <span className="text-quicksand700-small white">
                            Skills:
                          </span>
                          {dev.skills.map((skill: string) => {
                            return (
                              <span className="text-quicksand-small white">
                                &nbsp;{skill}{" "}
                              </span>
                            );
                          })}
                        </div>
                      </div>

                      <div className="row-space-between">
                        <div className="column-flex-start">
                          <div className="row-start">
                            <span className="text-quicksand700-small white">
                              Daily Cost:
                            </span>
                            <span className="text-quicksand-small white">
                              {dev.number_of_devs}
                              &nbsp;Developers&nbsp;x&nbsp;
                              {dev.developer_level === "JUNIOR"
                                ? 20
                                : dev.developer_level === "MID"
                                ? 30
                                : 40}
                              €&nbsp; x&nbsp;
                              {dev.dev_hours === "FULL_TIME" ? 8 : 4}
                              &nbsp;hours&nbsp;={dev.daily}€
                            </span>{" "}
                          </div>
                          <div className="row-start-config-line text-quicksand-small white">
                            <span className="text-quicksand700-small white">
                              Total Cost:
                            </span>
                            <span className="text-quicksand700-small white">
                              {" "}
                              {dev.cost} €
                            </span>
                          </div>
                        </div>
                        <DeleteIcon
                          onClick={() => removeSelectedDev(i)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <span className="pricing-total-cost">TOTAL COST: €</span>
            </div>
          </div>
        </div> */}
      {/* // ) : (
      //   <MobileConfigurator />
      // )} */}

      <div className="pricing-cta">
        <div className="pricing-one-click-left">
          <strong className="home-title">You’re just one click away!</strong>
          <span className="home-subtitle22 lightgrey">
            After calculating your project cost, sign-up to The Gyld to receive
            your short list of peer vetted profiles.
          </span>
          <span className="home-subtitle22 lightgrey">
            Our platform makes it easy to exchange the profiles with your
            colleagues (e.g. HR or management).
          </span>
        </div>
        <img
          className="pricing-end-image"
          src={require("../../assets/images/img3-nocat.png")}
          alt="developing vector"
        />
      </div>
    </div>
  );
};

export default Pricing;
