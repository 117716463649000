import React, { useState, useEffect, useRef } from "react";
import axiosService from "../../utils/axios";
import { useAlert } from "react-alert";
import authSlice from "../../store/slices/auth";
import locationSlice from "../../store/slices/location";
import "./Jobs.css";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Tooltip from "@mui/material/Tooltip";

import ForwardIcon from "@mui/icons-material/Forward";

import JobCard from "../../components/job-card/JobCard";

import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import JobsHiddenHints from "../../components/jobs-hidden-hints/JobsHiddenHints";

import CapaJobsCustom from "../../components/capa-jobs/CapaJobsCustom";
import moment from "moment";
import Example from "../../components/capa-jobs/Test";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import JoinLeftIcon from "@mui/icons-material/JoinLeft";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import PushPinIcon from "@mui/icons-material/PushPin";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { OutlinedInput } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import ClearAllIcon from "@mui/icons-material/ClearAll";

const JobsDev = () => {
  const [capaData, setCapaData] = useState<any>([]);
  const [capa, setCapa] = useState(0);

  const [capaLineData, setCapaLineData] = useState<any>([]);
  const [jobs, setJobs] = useState<any[]>([]);
  const [hiddenJobs, setHiddenJobs] = useState<any[]>([]);
  const [filteredJobs, setFilteredJobs] = useState<Array<any>>([]);
  const [rejectedClicked, setRejectedClicked] = useState(false);
  const [matchedClicked, setMatchedClicked] = useState(false);
  const [savedClicked, setSavedClicked] = useState(false);
  const [hiddenClicked, setHiddenClicked] = useState(false);
  const [wonClicked, setWonClicked] = useState(false);
  const [numRejected, setNumRejected] = useState(0);
  const [numMatched, setNumMatched] = useState(0);
  const [numWon, setNumWon] = useState(0);
  const [numSaved, setNumSaved] = useState(0);
  const [numHidden, setNumHidden] = useState(0);
  const [changeSimulation, setChangeSimulation] = useState(false);
  const [hotSkills, setHotSkills] = useState<any[]>([]);

  const [isFirst, setIsFirst] = useState(true);
  const [hideHints, setHideHints] = useState(false);
  const [mobileFilter, setMobileFilter] = useState("");
  const [jobsWon, setJobsWon] = useState<Array<any>>([]);
  const [capaWasSet, setCapaWasSet] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth);
  const alert = useAlert();
  const [width, setWidth] = useState(0);
  const scrollToRef = useRef<HTMLDivElement>(null);

  const ref = useRef<any>(null);
  useEffect(() => {
    if (isFirst) {
      getJobs();
      getSkills();
      setIsFirst(false);
    }
    dispatch(
      locationSlice.actions.setLocation({
        path: "/platform/jobs",
      })
    );
    if (!auth.account) {
      navigate("/login");
    }
    if (ref.current) {
      setWidth(ref.current.offsetWidth - 50);
    }
  }, []);

  useEffect(() => {
    if (auth.selectedGroup === "JOB") {
      checkScrollTo();
    }
  });
  const getSkills = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_skills`)
      .then((res) => {
        let tmpHotSkills: any[] = [];
        res.data.forEach((data: any) => {
          if (data.is_hot_skill) {
            tmpHotSkills.push(data);
          }
        });

        setHotSkills(tmpHotSkills);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkScrollTo = () => {
    if (scrollToRef.current?.offsetTop) {
      window.scrollTo({
        top: scrollToRef.current?.offsetTop - 100,
        behavior: "smooth",
      });
      setTimeout(function () {
        clearSelected();
      }, 2000);
    }
  };

  // useLayoutEffect(() => {
  //   return () => {
  //     clearSelected();
  //   };
  // }, []);

  const clearSelected = () => {
    dispatch(authSlice.actions.setSelectedSubgroup(null));
    dispatch(authSlice.actions.setSelectedSubgroupId(null));
    dispatch(authSlice.actions.setSelectedGroup(null));
    dispatch(authSlice.actions.setSelectedGroupId(null));
  };

  useEffect(() => {
    const resize = () => {
      if (ref.current) {
        setWidth(ref.current.offsetWidth - 50);
      }
    };
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, []);
  const getCapa = (jobsData: any) => {
    setCapaData([]);

    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_user_capa/` + auth.account)
      .then((res: any) => {
        let capaWeek = res.data.capa_hours_per_week;

        let tmp_jobs: any[] = [];

        if (capaWeek !== 0) {
          setCapa(capaWeek);
          setCapaWasSet(true);
          let i = 0;
          let tmpdata_jobs: any[] = [];
          let tmp_line: any[] = [];

          while (i < 12) {
            let firstDate = new Date(
              moment().add(i, "M").startOf("month").format()
            );
            let firstDateEnd = new Date(
              moment()
                .add(i + 1, "M")
                .startOf("month")
                .format()
            );

            tmpdata_jobs.push({
              date: moment(firstDate).format("YYYY-MM-DD").toString(),

              won: 0,
              applied: 0,
              simulation: 0,
            });
            tmp_line.push({
              date: moment(firstDate).format("YYYY-MM-DD").toString(),
              job: 100,
            });

            jobsData.forEach((job: any) => {
              if (
                moment(job.job.start_date) < moment(firstDateEnd) &&
                moment(job.job.end_date) > moment(firstDate)
              ) {
                if (!job.simulation && job.applied && job.status === "NEW") {
                  tmpdata_jobs[i].applied =
                    tmpdata_jobs[i].applied +
                    (job.job.hours_per_week / capaWeek) * 100;
                } else if (job.simulation) {
                  tmpdata_jobs[i].simulation =
                    tmpdata_jobs[i].simulation +
                    (job.job.hours_per_week / capaWeek) * 100;
                } else if (job.status === "WON") {
                  tmpdata_jobs[i].won =
                    tmpdata_jobs[i].won +
                    (job.job.hours_per_week / capaWeek) * 100;
                }
              }
            });
            i++;
          }
          if (tmpdata_jobs.length > 0) {
            let tmp = {
              label: "Jobs",
              dataType: "time",
              data: tmpdata_jobs,
            };
            tmp_jobs.push(tmpdata_jobs);
          }

          setCapaData(tmpdata_jobs);
          setCapaLineData(tmp_line);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const apply = (job_id: number, type: string, id: number) => {
    let body = {
      user_id: auth.account,
      job_id: job_id,
      applied: true,
      type: type,
      status: "NEW",
      id: id,
    };
    axiosService
      .post(`${process.env.REACT_APP_API_URL}/edit_application`, body)
      .then((res) => {
        getJobs();
      })

      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }
      });
  };

  const getJobs = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_user_jobs/` + auth.account)
      .then((res) => {
        let jobsShow: any[] = [];
        let jobsHidden: any[] = [];

        let tmpMatched = 0;
        let tmpWon = 0;
        let tmpRejected = 0;
        let tmpSaved = 0;
        let tmpHidden = 0;
        let tmpJobsWon: Array<any> = [];
        if (res.data.length > 0) {
          res.data.forEach(async (job: any) => {
            job.job.simulation = false;
            if (job.hidden) {
              tmpHidden++;
              jobsHidden.push(job);
            } else if (job.status === "WON") {
              tmpWon++;
              jobsShow.push(job);
              tmpJobsWon.push(job);
            } else if (job.status === "MATCHED" || job.status === "NEW") {
              tmpMatched++;
              jobsShow.push(job);
            } else if (job.status === "REJECTED") {
              tmpRejected++;
              jobsShow.push(job);
            } else if (!job.applied) {
              tmpSaved++;
              jobsShow.push(job);
            }
          });
        }
        setNumWon(tmpWon);
        setNumMatched(tmpMatched);
        setNumRejected(tmpRejected);
        setJobsWon(tmpJobsWon);
        setNumSaved(tmpSaved);
        setNumHidden(tmpHidden);
        setJobs(jobsShow);
        setHiddenJobs(jobsHidden);
        getCapa(res.data);

        if (filteredJobs.length > 0) {
          let tmpFiltered: any[] = [];
          filteredJobs.forEach((filter: any) => {
            res.data.forEach((job: any) => {
              if (hiddenClicked && job.hidden && !tmpFiltered.includes(job)) {
                tmpFiltered.push(job);
              }
              if (
                ((savedClicked && !job.applied) ||
                  (wonClicked && job.status === "WON") ||
                  (matchedClicked &&
                    (job.status === "MATCHED" || job.status === "NEW")) ||
                  (rejectedClicked && job.status === "REJECTED")) &&
                !tmpFiltered.includes(job)
              ) {
                tmpFiltered.push(job);
              }
            });
          });
          setFilteredJobs(tmpFiltered);
        }
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  const handleChangeMobileFilter = (value: string) => {
    setMobileFilter(value);
    if (value == "WON") {
      handleWonClickMobile();
    } else if (value == "MATCHED") {
      handleMatchedClickMobile();
    } else if (value == "REJECTED") {
      handleRejectedClickMobile();
    } else if (value == "SAVED") {
      handleSavedClickMobile();
    } else if (value == "HIDDEN") {
      handleHiddenClickMobile();
    } else {
      setFilteredJobs([]);
    }
  };
  const handleWonClickMobile = () => {
    setWonClicked(true);
    setHiddenClicked(false);
    setSavedClicked(false);
    setMatchedClicked(false);
    setRejectedClicked(false);

    let tmp: any[] = [];
    jobs.forEach((job: any) => {
      if (job.status === "WON") {
        tmp.push(job);
      }
    });

    setFilteredJobs(tmp);
  };
  const handleMatchedClickMobile = () => {
    setWonClicked(false);
    setHiddenClicked(false);
    setSavedClicked(false);
    setMatchedClicked(true);
    setRejectedClicked(false);

    let tmp: any[] = [];
    jobs.forEach((job: any) => {
      if (job.status === "MATCHED") {
        tmp.push(job);
      }
    });

    setFilteredJobs(tmp);
  };
  const handleRejectedClickMobile = () => {
    setWonClicked(false);
    setHiddenClicked(false);
    setSavedClicked(false);
    setMatchedClicked(false);
    setRejectedClicked(true);

    let tmp: any[] = [];
    jobs.forEach((job: any) => {
      if (job.status === "REJECTED") {
        tmp.push(job);
      }
    });

    setFilteredJobs(tmp);
  };
  const handleSavedClickMobile = () => {
    setWonClicked(false);
    setHiddenClicked(false);
    setSavedClicked(true);
    setMatchedClicked(false);
    setRejectedClicked(false);

    let tmp: any[] = [];
    jobs.forEach((job: any) => {
      if (!job.applied) {
        tmp.push(job);
      }
    });

    setFilteredJobs(tmp);
  };
  const handleHiddenClickMobile = () => {
    setWonClicked(false);
    setHiddenClicked(true);
    setSavedClicked(false);
    setMatchedClicked(false);
    setRejectedClicked(false);

    let tmp: any[] = [];
    hiddenJobs.forEach((job: any) => {
      tmp.push(job);
    });

    setFilteredJobs(tmp);
  };

  const handleWonClick = () => {
    setWonClicked(!wonClicked);

    setHiddenClicked(false);
    setSavedClicked(false);
    setMatchedClicked(false);
    setRejectedClicked(false);
    if (!wonClicked) {
      let tmp = filteredJobs;
      jobs.forEach((job: any) => {
        if (job.status === "WON") {
          tmp.push(job);
        }
      });

      setFilteredJobs(tmp);
    } else {
      setFilteredJobs([]);
    }
  };
  const handleMatchedClick = () => {
    setMatchedClicked(!matchedClicked);
    setWonClicked(false);
    setHiddenClicked(false);
    setSavedClicked(false);
    setRejectedClicked(false);
    if (!matchedClicked) {
      let tmp = filteredJobs;
      jobs.forEach((job: any) => {
        if (job.status === "MATCHED" || job.status === "NEW") {
          tmp.push(job);
        }
      });
      setFilteredJobs(tmp);
    } else {
      setFilteredJobs([]);
    }
  };
  const handleRejectedClick = () => {
    setRejectedClicked(!rejectedClicked);
    setWonClicked(false);
    setHiddenClicked(false);
    setSavedClicked(false);
    setMatchedClicked(false);

    if (!rejectedClicked) {
      let tmp = filteredJobs;
      jobs.forEach((job: any) => {
        if (job.status === "REJECTED") {
          tmp.push(job);
        }
      });
      setFilteredJobs(tmp);
    } else {
      setFilteredJobs([]);
    }
  };
  const handleSavedClick = () => {
    setSavedClicked(!savedClicked);
    setWonClicked(false);
    setHiddenClicked(false);
    setMatchedClicked(false);
    setRejectedClicked(false);
    if (!savedClicked) {
      let tmp = filteredJobs;
      jobs.forEach((job: any) => {
        if (!job.applied) {
          tmp.push(job);
        }
      });
      setFilteredJobs(tmp);
    } else {
      setFilteredJobs([]);
    }
  };
  const handleHiddenClick = () => {
    setHiddenClicked(!hiddenClicked);
    setWonClicked(false);
    setSavedClicked(false);
    setMatchedClicked(false);
    setRejectedClicked(false);
    if (!hiddenClicked) {
      let tmp = filteredJobs;
      hiddenJobs.forEach((job: any) => {
        tmp.push(job);
      });
      setFilteredJobs(tmp);
    } else {
      setFilteredJobs([]);
    }
  };

  const handleSimulate = (job: any) => {
    job.simulation = !job.simulation;
    let tmp = jobs;

    tmp.forEach((tm: any) => {
      if (tm.job_id_id === job.id) {
        tm.simulation = !tm.simulation;
      }
    });

    setJobs([]);
    setJobs(tmp);
    setChangeSimulation(!changeSimulation);
    getCapa(tmp);
  };

  return (
    <div className="column-space-between">
      <span className="heading margin-l20 margin-b20">Jobs</span>
      {!auth.expert && (
        <div
          className="row-space-between wrap "
          style={{ justifyContent: hideHints ? "flex-end" : "space-between" }}
        >
          <div
            className="card-hints"
            style={{ display: hideHints ? "none" : "" }}
          >
            <div className="leads-hints-left">
              <div className="row-start">
                <img
                  className="bulb"
                  src={require("../../assets/images/bulb.png")}
                />
                <b className="heading-lora">Apply to your saved jobs!</b>
              </div>
              <span className="text-quicksand opp-grey">
                Apply to a saved job and the Gyld Manager will match you up with
                the client. There are three modes of application: In the “I want
                to do the job” mode, you will be applying on your own, if you’d
                rather work in tandem either choose “I want to learn” or “I want
                to help”, to team up with another developer.
              </span>
              <div className="opp-arrow-out">
                <span className="text-quicksand-small blue">
                  Apply to a job
                </span>
                <ForwardIcon className="arrow" sx={{ height: 20 }} />
                <span className="text-quicksand-small blue">
                  Interview with the client
                </span>
                <ForwardIcon className="arrow" sx={{ height: 20 }} />
                <span className="text-quicksand-small blue">
                  The Gyld will set up the contract
                </span>
              </div>

              {/* <button className="manual-search-button opp-button">
              <span>Go to projects</span>
            </button> */}
            </div>
            <img
              className="jobs-img"
              src={require("../../assets/images/jobs.png")}
            />
            {!hideHints && (
              <Tooltip title="Hide" placement="bottom" arrow>
                <RemoveCircleIcon
                  sx={{ fontSize: 30 }}
                  className="help-icon"
                  onClick={() => setHideHints(!hideHints)}
                  style={{
                    justifySelf: hideHints ? "flex-end" : "",
                  }}
                ></RemoveCircleIcon>
              </Tooltip>
            )}
            {/* <img
            src={require("../../assets/images/block.png")}
            className="help-icon"
            onClick={() => setHideHints(!hideHints)}
            style={{ justifySelf: hideHints ? "flex-end" : "" }}
          /> */}
          </div>

          <div
            className="separate-area"
            style={{ display: hideHints ? "none" : "" }}
          />
        </div>
      )}

      <div
        className="row-space-between"
        style={{ display: hideHints ? "" : "none" }}
      >
        <JobsHiddenHints
          hideHints={hideHints}
          setHideHints={setHideHints}
        ></JobsHiddenHints>
      </div>

      <div className="row-start" ref={ref}>
        {/* <CapaJobs jobs={jobs} changeSimulation={changeSimulation} /> */}
        {capaData.length > 0 && capaLineData.length > 0 && (
          <CapaJobsCustom
            width={width}
            height={450}
            data={capaData}
            capaWasSet={capaWasSet}
            lineData={capaLineData}
            capa={capa}
          />
        )}
      </div>

      <div className="leads-background-grey">
        {window.innerWidth >= 1400 ? (
          <div className="jobs-row-start wrap">
            <div
              className="kpi-card-jobs margin-r20"
              style={{
                border: !wonClicked ? "0px solid grey" : "1px solid #FF6210",
                boxShadow: !wonClicked
                  ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                  : "none",
              }}
              onClick={handleWonClick}
            >
              <EmojiEventsIcon className="margin-kpi-projects req-green" />
              <strong className="text-quicksand700-small darkgrey padding-r5">
                {" "}
                Jobs won
              </strong>
              <strong className="text-quicksand-small lightgrey">
                ({numWon})
              </strong>
            </div>

            <div
              className="kpi-card-jobs margin-r20"
              style={{
                border: !matchedClicked
                  ? "0px solid grey"
                  : "1px solid #FF6210",
                boxShadow: !matchedClicked
                  ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                  : "none",
              }}
              onClick={handleMatchedClick}
            >
              <JoinLeftIcon className="margin-kpi-projects req-purple" />
              <strong className="text-quicksand700-small darkgrey padding-r5">
                {" "}
                Jobs being matched
              </strong>
              <strong className="text-quicksand-small lightgrey">
                ({numMatched})
              </strong>
            </div>

            <div
              className="kpi-card-jobs margin-r20"
              style={{
                border: !rejectedClicked
                  ? "0px solid grey"
                  : "1px solid #FF6210",
                boxShadow: !rejectedClicked
                  ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                  : "none",
              }}
              onClick={handleRejectedClick}
            >
              <DoDisturbIcon className="margin-kpi-projects req-red" />
              <strong className="text-quicksand700-small darkgrey padding-r5">
                Rejected jobs{" "}
              </strong>
              <strong className="text-quicksand-small lightgrey">
                ({numRejected})
              </strong>
            </div>

            <div
              className="kpi-card-jobs margin-r20"
              style={{
                border: !savedClicked ? "0px solid grey" : "1px solid #FF6210",
                boxShadow: !savedClicked
                  ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                  : "none",
              }}
              onClick={handleSavedClick}
            >
              <PushPinIcon className="margin-kpi-projects req-blue" />

              <strong className="text-quicksand700-small darkgrey padding-r5">
                Saved opportunities{" "}
              </strong>
              <strong className="text-quicksand-small lightgrey">
                ({numSaved})
              </strong>
            </div>
            <div
              className="kpi-card-jobs"
              style={{
                border: !hiddenClicked ? "0px solid grey" : "1px solid #FF6210",
                boxShadow: !hiddenClicked
                  ? "0px 2px 6px rgba(0, 0, 0, 0.16)"
                  : "none",
              }}
              onClick={handleHiddenClick}
            >
              <VisibilityOffIcon className="margin-kpi-projects req-orange" />

              <strong className="text-quicksand700-small darkgrey padding-r5">
                Hidden jobs{" "}
              </strong>
              <strong className="text-quicksand-small lightgrey">
                ({numHidden})
              </strong>
            </div>
          </div>
        ) : (
          <Box className="full-percent-width">
            <FormControl fullWidth>
              <InputLabel shrink={true} id="demo-simple-select-label">
                Filter Jobs
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={mobileFilter}
                onChange={(event) => {
                  handleChangeMobileFilter(event.target.value);
                }}
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                    label="Filter Jobs"
                    notched
                  />
                }
              >
                {" "}
                <MenuItem value={"ALL"}>
                  <ClearAllIcon className="margin-r3" />
                  All Jobs
                </MenuItem>
                <MenuItem value={"WON"}>
                  {" "}
                  <EmojiEventsIcon className="margin-r3 req-green" />
                  Jobs won ({numWon})
                </MenuItem>
                <MenuItem value={"MATCHED"}>
                  <JoinLeftIcon className="margin-r3 req-purple" />
                  Jobs being matched ({numMatched})
                </MenuItem>
                <MenuItem value={"REJECTED"}>
                  {" "}
                  <DoDisturbIcon className="margin-r3 req-red" />
                  Rejected Jobs ({numRejected})
                </MenuItem>
                <MenuItem value={"SAVED"}>
                  {" "}
                  <PushPinIcon className="margin-r3 req-blue" />
                  Saved Opportunities ({numSaved})
                </MenuItem>
                <MenuItem value={"HIDDEN"}>
                  <VisibilityOffIcon className="margin-r3 req-orange" />
                  Hidden Jobs ({numHidden})
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}

        <div className="separate-area">
          <div>
            {filteredJobs.length > 0 && (
              <div>
                {filteredJobs.map((job: any) => {
                  const itemProps =
                    auth.selectedGroup === "JOB" &&
                    auth.selectedGroupId === job.job_id_id
                      ? { ref: scrollToRef }
                      : {};
                  return (
                    <div key={job.id} {...itemProps} onClick={clearSelected}>
                      <JobCard
                        highlight={job.job_id_id === auth.selectedGroupId}
                        apply={apply}
                        key={job.id}
                        getJobs={getJobs}
                        hotSkills={hotSkills}
                        job={job}
                        handleSimulate={handleSimulate}
                      />
                    </div>
                  );
                })}
              </div>
            )}
            {filteredJobs.length === 0 && (
              <div>
                {jobs.length > 0 &&
                  jobs.map((job: any) => {
                    const itemProps =
                      auth.selectedGroup === "JOB" &&
                      auth.selectedGroupId === job.job_id_id
                        ? { ref: scrollToRef }
                        : {};
                    return (
                      <div key={job.id} {...itemProps} onClick={clearSelected}>
                        <JobCard
                          highlight={job.job_id_id === auth.selectedGroupId}
                          apply={apply}
                          key={job.id}
                          getJobs={getJobs}
                          hotSkills={hotSkills}
                          job={job}
                          handleSimulate={handleSimulate}
                        />
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobsDev;
