import React, { useState, useEffect } from "react";
import Moment from "moment";
import "./ContractCard.css";
import EuroIcon from "@mui/icons-material/Euro";
import BusinessIcon from "@mui/icons-material/Business";
import ComputerIcon from "@mui/icons-material/Computer";
import AvTimerIcon from "@mui/icons-material/AvTimer";

function ContractCard(props: any) {
  return (
    <div className="card">
      {console.log(props)}
      <div className="header">
        <h1 className="font bold title ">{props.job.name}</h1>

        <div className="headerIcons">
          <ComputerIcon />
          <span className="font">
            {props.job.job_type.replace(/_/g, " ").charAt(0).toUpperCase() +
              props.job.job_type.replace(/_/g, " ").slice(1)}
          </span>
        </div>
        <div className="headerIcons">
          <BusinessIcon />
          <span className="font">
            {props.job.client_type.charAt(0).toUpperCase() +
              props.job.client_type.slice(1)}
          </span>
        </div>
        <div className="headerIcons">
          <AvTimerIcon />
          {props.job.duration && (
            <span className="font">
              {props.job.duration.replace(/_/g, " ").charAt(0).toUpperCase() +
                props.job.duration.replace(/_/g, " ").slice(1) +
                " Months"}
            </span>
          )}

          {props.job.start_date && (
            <span className="font">
              {Moment(props.job.start_date).format("D MMM yy")} -
              {Moment(props.job.end_date).format("D MMM yy")}
            </span>
          )}
        </div>

        {props.job.verified_client && (
          <h2 className="greenFont font">Verified Client</h2>
        )}
        {!props.job.verified_client && (
          <h2 className="greyFont font">Not Verified Client</h2>
        )}
      </div>
      <div className="card-body">
        <div className="skills">
          {props.job.skills.map((skill: string, i: number) => {
            return (
              <strong key={skill} className="font skill">
                {i !== props.job.skills.length - 1 ? skill + "," : skill}
              </strong>
            );
          })}
        </div>
        <h2>{props.job.description}</h2>

        <h3 className="greyText">
          {Moment(props.job.createdAt).format("D MMM yy")}
        </h3>
      </div>
      <div className="footer">
        {props.job.contract_status === "ACCEPTED" && (
          <button className="primary">
            <span className="font">View Contract</span>
          </button>
        )}
        {props.job.contract_status === "NEW" && (
          <div>
            <button className="secondary">
              <span className="font">View Contract</span>
            </button>
            <button className="secondary">
              <span className="font">Reject Contract</span>
            </button>
            <button className="primary">
              <span className="font">Accept Contract</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ContractCard;
